import { css } from './DatePicker.style';

import { Text, TextProps } from '@core/components';
import { useDidUpdate, useMediatedState, useMemoizeValue, useValueMemo } from '@core/hooks';
import { CalendarSvg } from '@core/images';
import { useCss, useTheme } from '@core/theme';
import { ClassName, Nullable } from '@core/typings';
import { cn, isDev } from '@core/util';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { forwardRef, HTMLProps, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Calendar, CalendarProps } from 'react-date-range';
import { Popover, PopoverProps } from 'react-tiny-popover';

type Dateish = Nullable<Date | number | string | Dayjs> | undefined;
type InternalVal = Nullable<Dayjs>;

type ValueMap = {
  calendar: Date | undefined;
  display: string;
  output: Nullable<string>;
};

export interface DatePickerProps {
  /** controlled value; when provided, this will dictate value of calendar and input */
  value?: Dateish;
  /** date string format to use in display input */
  displayFormat?: string;
  /** default value when no value is provided (i.e. input is uncontrolled) */
  initialValue?: Dateish;
  /** passed to dayjs {@link https://day.js.org/docs/en/plugin/custom-parse-format|strict parse paramater}  */
  strictlyParseValue?: boolean;
  /**
   * defines the string format(s) to use when parsing a date. If an array is provided, the first
   * entry is taken as the output format and the default displayFormat
   */
  valueFormat?: string | string[];
  /** onChange handler. NOTE: for controlled components, this must update value for change to take effect */
  onChange?: (value: Nullable, date?: Date) => void;
  /** whether popover should close when value is selected */
  closeCalendarOnChange?: boolean;

  /** passed to calendar; date is ignored */
  calendarProps?: Partial<CalendarProps>;
  /** passed to display ${@link Text} input */
  displayTextProps?: Partial<TextProps>;
  /** passed to display div (pseudo-input) */
  displayWrapperProps?: Partial<HTMLProps<HTMLDivElement>>;
  /** passed to popover some critical props (content, isOpen) are ignored */
  popoverProps?: Partial<PopoverProps>;

  /** passed to wrapper div (doesn't have any effect on calendar; use `popoverProps.containerClassName`) */
  className?: ClassName;

  isDisabled?: boolean;
}

function createParseToDayJs({
  valueFormat,
  strictlyParseValue
}: Pick<DatePickerProps, 'valueFormat' | 'strictlyParseValue'>) {
  return function parseToDayJs(val: Dateish): InternalVal {
    let ret = null;

    if (!val) return null;
    if (_.isString(val)) ret = dayjs(val, valueFormat, strictlyParseValue);
    else ret = dayjs(val);
    if (!ret.isValid()) {
      isDev && console.warn('could not parse invalid date value', val);
      ret = null;
    }
    return ret;
  };
}

function makeValueComparable(val: Dateish): string {
  if (!val) return '';
  if (_.isString(val)) return val;
  if (val instanceof dayjs || _.isDate(val)) return dayjs(val).toJSON();
  return _.toString(val);
}

function datesAreEqual(a: Dateish, b: Dateish) {
  return _.isEqual(makeValueComparable(a), makeValueComparable(b));
}
type UseDidUpdateDeps = [Dateish, boolean];
function compareValueMonitorDeps([valA, boolA]: UseDidUpdateDeps, [valB, boolB]: UseDidUpdateDeps) {
  return boolA === boolB && datesAreEqual(valA, valB);
}

export const DatePicker = forwardRef(function DatePicker(props: DatePickerProps, ref): JSX.Element {
  const {
    calendarProps,
    className,
    closeCalendarOnChange = true,
    isDisabled,
    displayFormat,
    displayTextProps,
    displayWrapperProps,
    initialValue,
    onChange,
    popoverProps,
    strictlyParseValue = false,
    value: _value,
    valueFormat = 'MM-DD-YYYY'
  } = props;
  const wprRef = useRef<HTMLDivElement>(null);
  const cls = useCss(css);
  const theme = useTheme();
  const isControlled = useMemoizeValue<boolean>(!_.isUndefined(_value));
  const [calIsOpen, setCalIsOpen] = useState<boolean>(false);
  const outFormat = useValueMemo<string>(
    () => (_.isString(valueFormat) ? valueFormat : valueFormat[0]),
    [valueFormat]
  );
  /* create parser based on props */
  const parseValueToDayJs = useMemo(
    () => createParseToDayJs({ valueFormat, strictlyParseValue }),
    [valueFormat, strictlyParseValue]
  );

  const iVal = useMemo<InternalVal>(
    () => parseValueToDayJs(isControlled ? _value : initialValue),
    []
  );
  const [Value, setValue] = useMediatedState<InternalVal>(parseValueToDayJs, iVal);

  const values = useValueMemo<ValueMap>(
    () => ({
      calendar: Value?.toDate() ?? undefined,
      display: Value?.format(displayFormat ?? outFormat) ?? '',
      output: Value?.format(outFormat) ?? null
    }),
    [Value?.toString(), displayFormat, outFormat]
  );

  /* monitor controlled value and update internal value accordingly */
  useDidUpdate(
    () => {
      if (isControlled) setValue(_value);
    },
    [_value, isControlled],
    compareValueMonitorDeps
  );

  useImperativeHandle(
    ref,
    () => ({
      getValue() {
        return values.output;
      },
      blur: () => setCalIsOpen(false),
      focus: () => setCalIsOpen(true),
      isOpen: () => calIsOpen
    }),
    []
  );

  return (
    <Popover
      parentElement={wprRef.current ?? undefined}
      positions={['bottom']}
      align='start'
      padding={_.isArray(popoverProps?.positions) ? 0 : -8}
      {...popoverProps}
      containerClassName={cn(
        'date-picker-popover-ctr',
        popoverProps?.containerClassName,
        cls.popover
      )}
      content={
        <Calendar
          color={theme.colors.primary.primary}
          {...calendarProps}
          date={values.calendar}
          className={cn('date-picker-calendar', calendarProps?.className)}
          onChange={(val) => {
            if (!isControlled) setValue(val);
            const parsed = parseValueToDayJs(val);
            onChange?.(parsed?.format(outFormat) ?? null, val);
            if (closeCalendarOnChange) setCalIsOpen(false);
          }}
        />
      }
      onClickOutside={(e) => {
        setCalIsOpen(false);
        popoverProps?.onClickOutside?.(e);
      }}
      isOpen={calIsOpen}
    >
      <div
        role='button'
        {...displayWrapperProps}
        ref={wprRef}
        className={cn(
          'date-picker-display-wrapper',
          cls.displayWpr,
          displayWrapperProps?.className,
          className,
          { '--is-disabled': isDisabled, '--is-focused': calIsOpen }
        )}
        onClick={(e) => {
          if (isDisabled) return;
          setCalIsOpen(true);
          displayWrapperProps?.onClick?.(e);
        }}
      >
        <Text
          {...displayTextProps}
          className={cn('date-picker-display', displayTextProps?.className)}
          text={values.display}
        />
        <CalendarSvg className='date-picker-icon' />
      </div>
    </Popover>
  );
});
