import {
  ButtonSize,
  ButtonType,
  OxTypography,
  TextColor,
  TextSize,
  TextWeight
} from '@core/components';
import { ChevronDownSvg, ExclamationCircleSvg } from '@core/images';
import { useAppDispatch } from '@core/redux';
import { themes } from '@core/theme';
import { IStaticData } from '@core/typings';
import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { ThemeProvider } from 'styled-components';
import { StyledFieldErrorMessage } from '../components';
import { SelectOption } from '../OxSelect';
import {
  StyledDropDownContent,
  StyledDropDownField,
  StyledDropDownFieldError,
  StyledScrollable
} from './OxDropDownField.styles';

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  text: string;
  defaultOption?: SelectOption<any>;
  options: SelectOption<any>[];
  dropDownAction?: AsyncThunk<AxiosResponse<IStaticData[], any>, void, Record<string, unknown>>;
  errors?: any;
  classes?: string;
  setFieldValue?: any;
  fieldName?: string;
  emptyMessage?: string;
  groupForm?: boolean;
}
interface ButtonSizeStyle {
  sizeClasses: string;
  iconWidth: number;
  iconHeight: number;
  textSize: TextSize;
}

export const OxDropDownField = (props: ButtonProps): React.ReactElement<ButtonProps> => {
  const {
    text,
    defaultOption,
    errors,
    options,
    fieldName,
    buttonType = ButtonType.primary,
    buttonSize = ButtonSize.default,
    setFieldValue,
    emptyMessage,
    classes,
    disabled = false,
    groupForm,
    ...rest
  } = props;

  const [isOpen, setOpen] = useState(false);
  const [option, setOption] = useState(defaultOption || { value: '', label: '' });
  const handleToggle = () => setOpen((isOpen) => !isOpen);

  const buttonRef = useRef<HTMLElement | undefined>();

  const buttonSizeStylesMap = new Map<ButtonSize, ButtonSizeStyle>([
    [
      ButtonSize.compact,
      {
        sizeClasses: 'text-sm px-1 py-0',
        iconWidth: 15,
        iconHeight: 30,
        textSize: TextSize.Small
      }
    ],
    [
      ButtonSize.default,
      {
        sizeClasses: 'text-md px-2.5 py-0.5',
        iconWidth: 18,
        iconHeight: 30,
        textSize: TextSize.Medium
      }
    ],
    [
      ButtonSize.large,
      {
        sizeClasses: 'text-lg px-3 py-1 h-[38px]',
        iconWidth: 20,
        iconHeight: 30,
        textSize: TextSize.Large
      }
    ]
  ]);
  const { sizeClasses, iconWidth, iconHeight, textSize } = buttonSizeStylesMap.get(buttonSize) || {
    sizeClasses: '',
    iconWidth: 18,
    iconHeight: 30,
    textSize: TextSize.Medium
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isOpen || !props.dropDownAction) return;
    dispatch(props.dropDownAction());
  }, [isOpen]);

  const dropdownField = (
    <StyledDropDownField className='inline-block w-full relative' onBlur={() => setOpen(false)}>
      <Popover
        containerClassName='z-50'
        isOpen={isOpen}
        positions={['bottom']}
        align='start'
        ref={buttonRef as any}
        content={() => (
          <StyledScrollable
            id='dropDownContent'
            style={{ minWidth: buttonRef.current?.clientWidth }}
            className='scrollable z-100 bg-white absolute shadow-lg py-1 rounded'
          >
            {(options || []).map(({ label, value }, i) => {
              return (
                <StyledDropDownContent
                  className={
                    (value === '' && 'reset mt-4') +
                    ' ox-dropdown-content cursor-pointer relative py-2 px-2 flex items-center w-full hover:bg-gray-200'
                  }
                  key={i}
                  onMouseDown={() => {
                    setOption({
                      label: value === '' ? emptyMessage || '' : label,
                      value
                    });
                    setFieldValue(fieldName, value);
                    setOpen(false);
                  }}
                >
                  <span className='checkmark border border-gray-300' />
                  <OxTypography size={textSize} weight={TextWeight.Regular} color={TextColor.Light}>
                    {label}
                  </OxTypography>
                </StyledDropDownContent>
              );
            })}
          </StyledScrollable>
        )}
      >
        <StyledDropDownField className={groupForm ? 'group-form-style' : ''}>
          <label htmlFor=''>{text}</label>
          {(emptyMessage && options && !options.length) || disabled ? (
            <StyledFieldErrorMessage
              className={
                disabled ? 'text-sm flex mt-2 disabled-error' : 'field-error-message text-sm flex'
              }
            >
              {emptyMessage}
            </StyledFieldErrorMessage>
          ) : (
            <button
              type='button'
              className={`${
                isOpen && 'field-border'
              } ox-${buttonType.toString()} ${sizeClasses} relative cursor-pointer inline-flex items-center w-full rounded shadow-sm border-gray-300 min-h-[32px] ${classes} ${
                !disabled && ' border'
              }`}
              onClick={handleToggle}
              {...rest}
            >
              <OxTypography size={textSize} weight={TextWeight.Regular}>
                {option.label}
              </OxTypography>
              {disabled ? null : (
                <ChevronDownSvg
                  width={iconWidth}
                  height={iconHeight}
                  className={`absolute select-icon ml-[93%] ${isOpen && 'transform rotate-180'}`}
                />
              )}
            </button>
          )}
        </StyledDropDownField>
      </Popover>
      {errors?.touched && errors?.error && (
        <StyledDropDownFieldError className='mt-[1px] dropdown-field-error text-sm text-red-500 flex'>
          <ExclamationCircleSvg height={16} width={16} className='mr-1' />
          {errors?.error}
        </StyledDropDownFieldError>
      )}
    </StyledDropDownField>
  );

  return <ThemeProvider theme={themes.lightTheme}>{dropdownField}</ThemeProvider>;
};
