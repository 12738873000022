import styled from 'styled-components';

export const StyledDateCalendar = styled.div`
  &.calendar {
    z-index: 100;
  }

  .rdrEndEdge,
  .rdrDayEndPreview,
  .rdrDayEndOfWeek,
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge,
  .rdrSelected,
  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .rdrStartEdge,
  .rdrDayStartPreview,
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrSelected,
  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background-color: ${(props) => props.theme.calendarRangeEdges} !important;
  }

  .rdrInRange {
    background-color: ${(props) => props.theme.calendarInRange} !important;
  }

  .rdrInRange ~ .rdrDayNumber span {
    color: ${(props) => props.theme.contrast_65} !important;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background-color: ${(props) => props.theme.primaryButtonBackground} !important;
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview {
    border: 1px solid ${(props) => props.theme.primaryButtonBackground} !important;
  }

  .rdrSelected {
    background: ${(props) => props.theme.primaryButtonBackground};
  }

  .rdrDayNumber:after {
    border-radius: 2px !important;
    color: ${(props) => props.theme.primaryButtonBackground};
  }

  .rdrDayHovered {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .rdrPprevButton {
    background-image: url('/images/chevron-left.svg') !important;
    background-repeat: no-repeat;
    transform: translate(3px, 0px);
    i {
      display: none !important;
    }
  }

  .rdrNextButton {
    background-image: url('/images/chevron-right.svg') !important;
    background-repeat: no-repeat;
    transform: translate(6px, 0px);
    i {
      display: none !important;
    }
  }

  .rdrNextPrevButton:hover {
    background-repeat: no-repeat !important;
    background: transparent;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }
`;
