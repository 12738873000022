import { colorWheel } from '@core/theme/colorWheel';
import {
  CanvasColor,
  CanvasColorRange,
  ColorRange,
  ColorScheme,
  CssColor,
  SchemeColor
} from '@core/theme/typings';
import chroma, { Color } from 'chroma-js';
import _ from 'lodash';

const toColor = (base: CssColor, dest?: CssColor | number, fallback?: number): CssColor => {
  let ret: Color = chroma(base);

  if (_.isString(dest)) ret = chroma(dest);
  if (_.isFinite(dest)) ret = ret.set('hsl.l', dest as number);
  if (_.isFinite(fallback)) ret = ret.set('hsl.l', fallback as number);

  return ret.css('hsl') as CssColor;
};

type CompleteScheme = {
  [Property in keyof ColorScheme]-?: ColorScheme[Property];
};

export type NormalizedScheme = {
  [Propery in keyof Omit<CompleteScheme, 'canvas' | 'component' | 'text' | 'heading'>]: ColorRange;
} & {
  canvas: CanvasColorRange;
  component: CanvasColorRange;
  text: CanvasColorRange;
  heading: CanvasColorRange;
};

const lightFallback = 0.92;
const darkFallback = 0.08;

const normalizeStdColor = (color: SchemeColor): ColorRange => {
  let light;
  let mid;
  let dark;

  if (_.isArray(color)) {
    const [clr, lightest, darkest] = color;
    light = toColor(clr, lightest, lightFallback);
    mid = toColor(clr);
    dark = toColor(clr, darkest, darkFallback);
  } else {
    light = toColor(color, lightFallback);
    mid = toColor(color);
    dark = toColor(color, darkFallback);
  }
  return [light, mid, dark];
};

const normalizeCanvColor = (color: CanvasColor, maxOffset: number): CanvasColorRange => {
  if (_.isArray(color)) return color;
  const baseLum = chroma(color).luminance();
  const isDark = baseLum < 0.5;
  const dest = baseLum + baseLum * maxOffset * (isDark ? 1 : -1);
  return [color, toColor(color, dest)];
};

const colorReducer = (val: SchemeColor | CanvasColor, key: string) => {
  switch (key) {
    case 'text':
    case 'heading':
      return normalizeCanvColor(val as CssColor, 0.2);
    case 'canvas':
    case 'component':
      return normalizeCanvColor(val as CanvasColor, 0.15);
    default:
      return normalizeStdColor(val as SchemeColor);
  }
};

export const normalizeColors = (scheme: ColorScheme, isDark = false): NormalizedScheme => {
  const completeScheme: CompleteScheme = _.defaults<
    ColorScheme,
    Omit<CompleteScheme, 'primary' | 'secondary'>
  >(scheme, {
    tertiary: scheme.secondary,
    accent: scheme.secondary,
    highlight: scheme.secondary,
    success: colorWheel.green[isDark ? 200 : 600],
    info: colorWheel.lightBlue[isDark ? 200 : 700],
    warning: colorWheel.yellow[isDark ? 200 : 700],
    danger: colorWheel.red[isDark ? 200 : 500],
    canvas: isDark ? colorWheel.gray[900] : colorWheel.common.white,
    component: colorWheel.gray[isDark ? 700 : 200],
    text: colorWheel.common[isDark ? 'white' : 'black'],
    heading: colorWheel.blueGray[isDark ? 50 : 900]
  });

  return _.mapValues(completeScheme, colorReducer) as NormalizedScheme;
};
