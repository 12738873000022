import { themes } from '@core/theme';
import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  StyledSwitchButton,
  StyledSwitchButtonBox,
  StyledSwitchButtonHead,
  StyledSwitchInputButton
} from './OxSwitchButton.styles';

export enum SwitchSize {
  Small = 'sm',
  Large = 'lg'
}

export const OxSwitchButton = ({
  checked,
  size,
  handleCheckedChange,
  ...props
}: any): JSX.Element => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const classes = ((isChecked && 'switch-button-checked ') || '') + 'checked-size-' + size;

  const checkedText = isChecked ? 'On' : 'Off';

  const handleChecked = () => {
    setIsChecked(!isChecked);
    handleCheckedChange && handleCheckedChange(!isChecked);
  };

  const switchButton = (
    <StyledSwitchButton
      data-testid='OxSwitchButton'
      className={'switch-button flex items-center content-center relative text-[14px] ' + classes}
      onClick={() => handleChecked()}
    >
      <StyledSwitchInputButton
        type='checkbox'
        checked={isChecked}
        className='hidden switch-button-input'
        {...props}
        onChange={() => {
          return;
        }}
      />
      <StyledSwitchButtonBox htmlFor={props.id} className='switch-button-box bg-gray-200 mr-2'>
        <StyledSwitchButtonHead className='switch-button-head absolute' />
      </StyledSwitchButtonBox>
      {checkedText}
    </StyledSwitchButton>
  );

  return <ThemeProvider theme={themes.lightTheme}>{switchButton}</ThemeProvider>;
};
