export enum Status {
  Processing = 'Processing',
  ProcessAlternative = 'ProcessAlternative',
  Done = 'Done',
  DoneAlternative = 'DoneAlternative',
  Pending = 'Pending',
  Warning = 'Warning',
  Completing = 'Completing',
  Completed = 'Completed',
  InProgress = 'InProgress',
  Success = 'Success',
  Uploaded = 'Uploaded',
  Empty = ''
}

export enum StatusColor {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Pending = 'pending',
  WarningAlt = 'warning-alt',
  PrimaryAlt = 'primary-alt',
  DangerAlt = 'danger-alt',
  InfoAlt = 'info-alt',
  Uploaded = 'warning-alt'
}

export enum InvoiceStatus {
  Approved = 'Approved',
  NotStarted = 'NotStarted',
  Completed = 'Completed',
  PendingPipeline = 'PendingPipeline',
  PendingInternal = 'PendingInternal',
  BlockedMissingInternalData = 'BlockedMissingInternalData',
  BlockedMissingPipelineData = 'BlockedMissingPipelineData',
  NeedsInternalReview = 'NeedsInternalReview',
  NeedsSchedulerReview = 'NeedsSchedulerReview',
  NeedsPipelineReview = 'NeedsPipelineReview',
  Empty = ''
}
