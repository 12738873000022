import { ButtonSize, ButtonType } from '@core/components';
import { OxMultiSelect, SelectOption } from '@core/form';
import { useValueMemo } from '@core/hooks';
import { CheckmarkCircleFilledSvg } from '@core/images';
import { useAppSelector } from '@core/redux';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useEffect, useRef, useState } from 'react';
import { PopoverAlign } from 'react-tiny-popover';
import { RootState } from 'redux/store';

export interface SearchableMultiSelectProps<T> {
  /**
   * Invoked whenever entries are selected or de-selected
   */
  onChange: (entries: T[]) => void;
  /**
   * Pass in selector to get slice of data
   */
  dataSelector?: (state: RootState) => T[];
  /**
   * Configures the label of select options
   */
  labelFn: (entry: T) => string;
  /**
   * Configures the key of select options
   */
  keyFn: (entry: T) => string;
  /**
   * Configures which are selected when first rendered
   */
  defaultSelected?: T[];

  selected?: T[];
  /**
   * Displays typeahead search bar
   */
  allowTypeAhead?: boolean;
  /**
   * Placeholder text when no options are selected
   */
  placeholder?: string;
  /**
   * Alignment of popover menu; passed into PopoverAlign
   */
  menuAlign?: PopoverAlign;
  /**
   * Number of options to display in collapsed menu before showing remainder as
   * a numeric count
   */
  maxSelectedOptionsShown?: number;
  /**
   * CSS classes to pass into OxMultiSelect
   */
  classes?: string;

  dropdownValues?: T[];

  singleSelect?: boolean;

  gridFields?: undefined | ColDef[];
}
const defaultDataSelector = () => [];

export default function SearchableMultiSelect<T>(props: SearchableMultiSelectProps<T>) {
  const {
    onChange,
    dataSelector = defaultDataSelector,
    labelFn,
    keyFn,
    defaultSelected = [],
    placeholder = '',
    allowTypeAhead = false,
    menuAlign = 'start',
    maxSelectedOptionsShown = 1,
    classes = '',
    dropdownValues,
    singleSelect,
    selected,
    gridFields
  } = props;

  const isFirstUpdate = useRef(true);

  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(
    selected
      ? selected.map((entry) => ({
          value: keyFn(entry),
          label: labelFn(entry)
        }))
      : defaultSelected.map((entry) => ({
          value: keyFn(entry),
          label: labelFn(entry)
        }))
  );

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }

    if (!selected?.length) {
      setSelectedOptions([]);
    }
  }, [selected?.length]);

  const stateEntries = useAppSelector(dataSelector);

  const entries = useValueMemo<T[]>(
    () => (dropdownValues?.length ? dropdownValues : stateEntries),
    [dropdownValues, stateEntries]
  );

  /* TODO: MEMOIZE */
  const options = entries.map((entry) => ({
    value: keyFn(entry),
    label: labelFn(entry)
  }));

  const handleOptionToggle = (option: SelectOption) => {
    let newSelected = [...selectedOptions];
    const existingIndex = newSelected.findIndex(({ value }) => value === option.value);
    // If it's already in the list, splice it out
    if (existingIndex > -1) {
      newSelected.splice(existingIndex, 1);
    } else if (singleSelect) {
      newSelected = [option];
    }
    // Otherwise, add it in
    else {
      newSelected.push(option);
    }

    setSelectedOptions(newSelected);
    onChange(
      newSelected.flatMap((option) => {
        return entries.find((entity) => keyFn(entity) === option.value) ?? [];
      })
    );
  };

  const handleSelectAll = () => {
    setSelectedOptions(options);
    onChange(entries);
  };

  const handleClearAll = () => {
    setSelectedOptions([]);
    onChange([]);
  };

  const appendedGridFields = gridFields
    ? [
        {
          field: '',
          cellRenderer: (params: ICellRendererParams) => {
            const isSelected = selectedOptions.find((option) => option.value === params.data.value);
            return isSelected ? <CheckmarkCircleFilledSvg width={20} height={20} /> : '';
          },
          cellClass: 'ml-3',
          width: 50
        },
        ...gridFields
      ]
    : undefined;

  return (
    <OxMultiSelect
      allowTypeAhead={allowTypeAhead}
      menuAlign={menuAlign}
      options={options}
      text={placeholder}
      isShowLabel={false}
      onSelectAll={handleSelectAll}
      onClearAll={handleClearAll}
      handleChange={handleOptionToggle}
      selectedOptions={selectedOptions}
      showSelectedOptionsText
      dropDownClassName={`mt-1 overflow-scroll ${gridFields ? '' : ' max-h-1/4'}`}
      buttonClassName='mr-2'
      containerClassName='mr-3'
      classes={`cursor-pointer duration-500 ease-in-out transition-colors transition-background-color font-normal text-gray-500 truncate w-full pt-1 pb-1 mr-2 text-sm rounded ${classes}`}
      dropDownWidth={350}
      placeholder={placeholder}
      buttonType={ButtonType.secondary}
      buttonSize={ButtonSize.compact}
      maxSelectedOptionsShown={maxSelectedOptionsShown}
      gridFields={appendedGridFields}
    />
  );
}
