import { useSetState, useValueMemo } from '@core/hooks';
import { withReset } from '@core/reset';
import { ErrorInfo, useMemo } from 'react';
import { ErrorObserver, ErrorOverlay } from './components';
import {
  ErrorBoundaryContextValue,
  ErrorBoundaryProps,
  ErrorBoundaryState
} from './error-boundary.types';
import { ErrorBoundaryContext } from './ErrorBoundaryContext';

const initialState: ErrorBoundaryState = {
  hasError: false,
  error: null,
  errorInfo: null,
  detailsOpen: false,
  hasCopiedStack: false
};

export const ErrorBoundary = withReset(function ErrorBoundary(props: ErrorBoundaryProps) {
  const { children, overlay: Overlay = ErrorOverlay, ...rest } = props;
  const [state, setState] = useSetState(initialState);

  const { handleError, toggleDetailsOpen, handleHasCopiedStack } = useMemo(
    () => ({
      handleError: (error: Error, errorInfo: ErrorInfo) => {
        setState({ error, errorInfo, hasError: true });
      },
      toggleDetailsOpen: () => {
        setState(({ detailsOpen }) => ({ detailsOpen: !detailsOpen }));
      },
      handleHasCopiedStack: () => {
        setState({ hasCopiedStack: true });
      }
    }),
    []
  );

  const value = useValueMemo<ErrorBoundaryContextValue>(
    () => ({
      ...state,
      toggleDetailsOpen,
      handleHasCopiedStack
    }),
    [state]
  );

  return (
    <ErrorBoundaryContext.Provider value={value}>
      {state.hasError ? (
        <Overlay {...rest} />
      ) : (
        <ErrorObserver {...{ handleError }}>{children}</ErrorObserver>
      )}
    </ErrorBoundaryContext.Provider>
  );
});
