import { useUserAppSettings } from '@core/user-configs';
import { ValueFormatterFunc } from 'ag-grid-community';
import { useMemo } from 'react';
import { formatters } from '../formatters';

export type UsePreferredDatetimeFormatterParams = {
  defaultFormatterType: 'datetime' | 'datetimeTimeFirst' | 'time';
};

export function usePreferredDatetimeFormatter<TData extends object = any>(
  { defaultFormatterType }: UsePreferredDatetimeFormatterParams = {
    defaultFormatterType: 'datetime'
  }
) {
  const Config = useUserAppSettings();

  return useMemo<ValueFormatterFunc<TData>>(() => {
    if (!Config.isHydrated) return formatters[defaultFormatterType];
    const {
      current: { isPreferCentralTime }
    } = Config;

    if (isPreferCentralTime) {
      if (defaultFormatterType === 'datetime') return formatters.centralTimeDatetime;
      if (defaultFormatterType === 'datetimeTimeFirst') {
        return formatters.centralTimeDatetimeTimeFirst;
      }
      if (defaultFormatterType === 'time') return formatters.centralTime;
    }
    return formatters[defaultFormatterType];
  }, [defaultFormatterType, Config.current.isPreferCentralTime, Config.isHydrated]);
}
