import { colorWheel } from '@core/theme/colorWheel';
import { ColorRef, ThemeLib, ThemePalette } from '@core/theme/typings';
import { flatten } from 'flat';
import _ from 'lodash';

import { getAmt } from '@core/theme/util';
import chroma, { InterpolationMode } from 'chroma-js';
import { customColors } from '../customColors';

const flatColorWheel = flatten(colorWheel);

interface TextColors {
  light?: ColorRef;
  dark?: ColorRef;
}

export function getThemeLib(colors: ThemePalette): ThemeLib {
  const flatColors = flatten(colors);
  const getColor = (
    color: ColorRef,
    fallback: ColorRef = colors.text.primary as ColorRef
  ): ColorRef => {
    let clrString: string = _.isArray(color) ? _.join(color, '.') : color;
    /* append shorthand color refs */
    if (!_.includes(clrString, '.')) {
      if (_.has(colors, [clrString])) clrString += '.primary';
      else if (_.has(colorWheel, [clrString])) clrString += '.500';
    }
    if (_.has(flatColors, [clrString])) return _.get(flatColors, [clrString]);
    if (_.has(flatColorWheel, [clrString])) return _.get(flatColorWheel, clrString);
    if (_.includes(['inherit', 'unset', 'initial', 'revert', 'currentColor'], color as string)) {
      return color as ColorRef;
    }
    if (_.includes(['clear', 'transparent'], color as string)) {
      return colorWheel.common.transparent as ColorRef;
    }
    if (chroma.valid(color)) return color as ColorRef;
    return fallback as ColorRef;
  };

  const getTextColor = (bgColor: ColorRef, textColors: TextColors = {}): ColorRef => {
    const { light = 'text.inverse', dark = 'text.primary' } = textColors;
    const lightClr = getColor(light);
    if (chroma.contrast(getColor(darken(bgColor, 10)) as string, lightClr as string) < 2.5) {
      return getColor(dark);
    }
    return lightClr;
  };

  const darken = (clr: ColorRef, amt = 50, mode: InterpolationMode = 'lrgb'): ColorRef => {
    const color = getColor(clr) as string;
    return chroma.mix(color, chroma(color).set('hsl.l', 0), getAmt(amt), mode).hex() as ColorRef;
  };

  const desaturate = (clr: ColorRef, amt = 50): ColorRef =>
    chroma(getColor(clr) as string)
      .set('hsl.s', `*${1 - getAmt(amt)}`)
      .hex() as ColorRef;

  const fade = (clr: ColorRef, amt = 50): ColorRef =>
    chroma(getColor(clr) as string)
      .alpha(1 - getAmt(amt))
      .css() as ColorRef;

  const opacify = (clr: ColorRef, amt = 50): ColorRef =>
    chroma(getColor(clr) as string)
      .alpha(getAmt(amt))
      .css() as ColorRef;

  const invert = (clr: ColorRef): ColorRef => {
    const color = chroma(getColor(clr) as string);
    const hsl = color.hsl();
    if (_.isNaN(hsl[0])) return color.set('hsl.l', 1 - hsl[2]).hex() as ColorRef;
    return color.set('hsl.h', '-180').hex() as ColorRef;
  };

  const lighten = (clr: ColorRef, amt = 50, mode: InterpolationMode = 'lrgb'): ColorRef => {
    const color = getColor(clr) as string;
    return chroma.mix(color, chroma(color).set('hsl.l', 1), getAmt(amt), mode).hex() as ColorRef;
  };

  const saturate = (clr: ColorRef, amt = 50): ColorRef =>
    chroma(getColor(clr) as string)
      .set('hsl.s', `*${1 + getAmt(amt)}`)
      .hex() as ColorRef;

  const toGray = (clr: ColorRef): ColorRef =>
    chroma(getColor(clr) as string)
      .desaturate(10)
      .hex() as ColorRef;

  const toGtr = (...vals: (string | number)[]) =>
    _.reduce(
      vals,
      (memo, v, ind) => {
        memo += `${ind ? ' ' : ''}${v}${_.isString(v) ? '' : 'px'}`;
        return memo;
      },
      ''
    );

  return {
    colorWheel,
    customColors,
    darken,
    desaturate,
    fade,
    getColor,
    getTextColor,
    invert,
    lighten,
    opacify,
    saturate,
    toGray,
    toGtr,

    /* consts */
    gutter: 8
  };
}
