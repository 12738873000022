import { useAuth0 } from '@auth0/auth0-react';
import { useValueMemo } from '@core/hooks';
import { RootState, useAppSelector } from '@core/redux';
import { setTokens } from '@core/redux-actions';

export function useLogout(): () => void {
  const { logout } = useAuth0();
  const { config } = useAppSelector((state: RootState) => state.config);
  /* memoize callback so implementations can use it as a hook dependency. */
  return useValueMemo(
    () => () => {
      const nomScheduleFilters = window.localStorage.getItem('nom-schedule-filters');
      if (nomScheduleFilters) {
        const parsedFilters = JSON.parse(nomScheduleFilters);
        if (parsedFilters?.dateRange) parsedFilters.dateRange = undefined;
        window.localStorage.setItem('nom-schedule-filters', JSON.stringify(parsedFilters));
      }
      setTokens(config, '');
      logout();
    },
    [config]
  );
}
