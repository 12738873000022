import { IUpload, UploadStatus } from '@core/typings';
import { Signals } from '@core/util';
import { createSlice } from '@reduxjs/toolkit';
import { UploadThunks } from 'redux/thunks/upload.thunks';
import { IUploadRepository } from 'shared/api/interfaces/IUploadRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { GenericPayload } from '../../utils/array.slice';

interface IUploadAction extends GenericPayload {
  meta?: any;
}

type InitialStateType = {
  uploads: IUpload[];
};

const uploads = [] as IUpload[];

const initialState: InitialStateType = {
  uploads
};

const uploadRepository = container.get<IUploadRepository>(SERVICE_KEYS.UPLOAD_REPOSITORY);
const uploadThunks = new UploadThunks(uploadRepository);

export const createUploadFile = uploadThunks.upload();

const handleUploadStatus = (state: any, arg: { id: string }, status: UploadStatus) => {
  const { id } = arg;
  const toUpdate = state.uploads.findIndex((file: any) => file.id == id);
  if (toUpdate > -1) {
    state.uploads[toUpdate].status = status;
    Signals.Instance.remove(id);
  }
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    cancelUploadFile(state, action) {
      const { id } = action.payload;
      const toCancel = state.uploads.findIndex((file) => file.id == id);
      if (toCancel > -1) {
        Signals.Instance.cancel(id);
      }
    },
    clearUploadFiles(state) {
      state.uploads.forEach(({ id }) => {
        Signals.Instance.cancel(id);
      });
      return initialState;
    },
    getUploadFiles(state) {
      state.uploads;
    }
  },
  extraReducers: (builder: any) => {
    const uploadThunk = uploadThunks.upload();
    builder
      .addCase(uploadThunk.pending, (state: any, { meta: { arg } }: IUploadAction) => {
        const { file, id, type } = arg;
        const uploadFile: IUpload = {
          ...file,
          name: file.name,
          id: id,
          status: UploadStatus.Uploading,
          type: type
        };
        state.uploads.push(uploadFile);
      })
      .addCase(uploadThunk.fulfilled, (state: any, { meta: { arg } }: IUploadAction) => {
        handleUploadStatus(state, arg, UploadStatus.Complete);
      })
      .addCase(uploadThunk.rejected, (state: any, { meta: { arg } }: IUploadAction) => {
        handleUploadStatus(state, arg, UploadStatus.Failed);
      });
  }
});

export const { cancelUploadFile, clearUploadFiles, getUploadFiles } = uploadSlice.actions;
export const uploadReducer = uploadSlice.reducer;
