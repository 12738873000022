import { themes } from '@core/theme';
import 'ag-grid-community';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine-no-font.css';
import 'ag-grid-enterprise/styles/agGridQuartzFont.css';
import 'agGridGlobalVars.scss';
import 'index.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import 'reflect-metadata';
import 'Resources/strings';
import { ThemeProvider } from 'styled-components';
import 'styles/styles.scss';

import App from './App';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-059591}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Eleox_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{OX}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{OX}_need_to_be_licensed___{OX}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{6_July_2025}____[v3]_[01]_MTc1MTc1NjQwMDAwMA==c325f2c69f0f7f34a252337ddf10a5a1'
);

const root = createRoot(document.getElementById('root') as HTMLElement);

/* NOTE: StrictMode has temporarily been removed, as it causes an issue with AG Grid and a few other
 * high-level packages in React 18. It should be re-enabled when React and/or AG Grid is upgraded
 * in the future.
 */
root.render(
  <Provider store={store}>
    <ThemeProvider theme={themes.lightTheme}>
      <App />
    </ThemeProvider>
  </Provider>
);
