import {
  BrandColors,
  CanvasColorRange,
  ColorRange,
  ColorScale,
  ColorScheme,
  Theme,
  ThemePalette,
  ThemeScheme
} from '@core/theme/typings';
import _ from 'lodash';

import {
  getCanvasColors,
  getStandardColors,
  getTextColors,
  nonStandardSchemeKeys,
  normalizeColors,
  NormalizedScheme
} from './util';

/* build scales for each palette color */

/* build color scale for each item in the palette config */
const getPalette = (scheme: ColorScheme, isDark = false): ThemePalette => {
  const normalized: NormalizedScheme = normalizeColors(scheme, isDark);
  const palette = _.mapValues(normalized, (val: any, key: string) => {
    switch (key) {
      case 'canvas':
      case 'component':
        return getCanvasColors(val as CanvasColorRange, isDark);
      case 'text':
      case 'heading':
        return getTextColors(val as CanvasColorRange, isDark);
      default:
        return getStandardColors(val as ColorRange);
    }
  }) as Omit<ThemePalette, 'brand'>;

  const brand = _.mapValues(
    _.omit(palette, nonStandardSchemeKeys),
    (v: ColorScale): string => v.primary
  ) as unknown as BrandColors;

  return { ...palette, brand };
};

export const buildTheme = (scheme: ThemeScheme): Theme => ({
  light: getPalette(scheme.light),
  dark: getPalette(scheme.dark, true)
});
