import { DeepEqual, Nullable } from '@core/typings';
import { diff } from 'deep-object-diff';
import isEq from 'fast-deep-equal';
import _ from 'lodash';
import Emitter from 'wolfy87-eventemitter';

export type InternalStateBaseOnChangeFn<State extends object = object> = (
  current: State,
  prev: State,
  errors: Nullable<unknown[]>
) => void;

export type ValidateStateFn<State extends object> = (state: State) => true | unknown[];

export type TransformStateFn<State extends object> = (state: State) => State;

type InternalStateBaseConstructor<
  State extends object = object,
  TInitState extends object = State
> = {
  initialState: TInitState;
  comparator?: DeepEqual<State>;
  emitOnChange?: boolean;
  emitOnValidationFailed?: boolean;
  emitDetailedChanges?: boolean;
  // validate?: ValidateFn<State>;
};

export class InternalStateBase<
  State extends object = object,
  TInitState extends object = State
> extends Emitter {
  /** internal state; access via .state or getState() */
  private _state: State;
  /** state equality check customizer; run deep-equal check by default */
  private _isEqual: DeepEqual<State>;

  private _emitOnChange: boolean;
  private _emitOnValidationFailed: boolean;
  private _emitDetailed: boolean;
  protected validate: ValidateStateFn<State> = () => true;
  protected transform: TransformStateFn<State> = (state) => state as unknown as State;

  constructor({
    emitOnValidationFailed = true,
    emitOnChange = true,
    emitDetailedChanges = false,
    initialState,
    comparator = isEq
  }: InternalStateBaseConstructor<State, TInitState>) {
    super();
    this._state = initialState as unknown as State;
    this._isEqual = comparator;
    this._emitOnChange = emitOnChange;
    this._emitDetailed = emitDetailedChanges;
    this._emitOnValidationFailed = emitOnValidationFailed;
  }
  /** internal state getter */
  get state() {
    return this._state;
  }
  /** internal state getter as function */
  public getState = (): State => this._state;
  /** retrieve a value from state. dot/bracket notation is allowed. */
  public get = (key: keyof State | string | (string | number)[]) => _.get(this._state, key);
  /**
   * @desc updates internal state
   * @returns Promise<State>
   */
  protected setStateAsync = (
    update: Partial<State>,
    options: { throwOnValidationFailed?: boolean } = { throwOnValidationFailed: true }
  ): Promise<State> =>
    new Promise((res, rej) => {
      try {
        const { current, isValid, errors } = this._handleStateChange(update, {
          emitOnValidationFailed: !options.throwOnValidationFailed
        });
        /* reject Promise if configured to throw */
        if (!isValid && options.throwOnValidationFailed) throw errors;
        return res(current);
      } catch (e) {
        return rej(e);
      }
    });

  /**
   * @desc updates internal state
   * @returns State
   */
  protected setState = (update: Partial<State>, cb?: InternalStateBaseOnChangeFn<State>): State => {
    const { current, prev, errors } = this._handleStateChange(update);
    if (cb) {
      cb(current, prev, errors);
      return current;
    }
    return current;
  };

  private _handleStateChange = (
    update: Partial<State>,
    options: { emitOnValidationFailed: boolean } = {
      emitOnValidationFailed: this._emitOnValidationFailed
    }
  ): { prev: State; current: State; isValid: boolean; errors: Nullable<unknown[]> } => {
    const pState = _.cloneDeep<State>({ ...this._state });
    const nState: State = this.transform({ ...this._state, ...update });
    const hasChange = !this._isEqual(pState, nState);
    /* because only valid changes are stored, we can assume unchanged state is still valid */
    const validOrErrors = hasChange ? this.validate(nState) : true;

    const isValid = validOrErrors === true;
    const errors = isValid ? null : validOrErrors;
    if (hasChange) {
      if (isValid) {
        this._state = nState;
        if (this._emitOnChange) {
          this.emit('change', nState, pState);
          if (this._emitDetailed) {
            /* emit event for each changed key */
            _.each(_.keys(diff(pState, nState)), (key) => {
              this.emit(`change:${key}`, _.get(nState, [key]), _.get(pState, [key]));
            });
          }
        }
        /* validation has failed; update will not proceed */
      } else if (options.emitOnValidationFailed) {
        /* will be array of errors */
        this.emit('validation-failed', errors);
      }
    }
    return { prev: pState, current: isValid ? nState : pState, isValid, errors };
  };
}
