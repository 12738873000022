import { useValueMemo } from '@core/hooks';
import { useReset } from '@core/reset';
import { useContext } from 'react';
import { ErrorBoundaryContext } from './ErrorBoundaryContext';

export function useErrorBoundary() {
  const state = useContext(ErrorBoundaryContext);
  const reset = useReset();

  /* prettier-ignore */
  return useValueMemo(() => ({ ...state, reset }), [state]);
}
