import { withReset } from '@core/reset';
import { ComposedSfc } from '@core/typings';
import { compose } from '@reduxjs/toolkit';

import { forwardRef, useRef } from 'react';
import { GridRefContext } from '../Grid.contexts';
import { GridInstance } from '../typings';

const hocs = compose(withReset, forwardRef);

export function withGridRef<TData extends object = any>(Composed: ComposedSfc) {
  type Instance = GridInstance<TData>;
  return hocs(function WithAgGrid(p: any, ref: any): JSX.Element {
    const value = useRef<Instance>(null as unknown as Instance);

    return (
      <GridRefContext.Provider value={value}>
        <Composed {...{ ...p, ref }} />
      </GridRefContext.Provider>
    );
  });
}
