import { StyledTableContainer } from './OxTableContainer.styles';

interface TableContainerProps {
  children: JSX.Element | JSX.Element[];
  classes?: string;
}

export function OxTableContainer({ children, classes }: TableContainerProps) {
  return (
    <StyledTableContainer
      className={'flex-1 flex flex-col mb-2 px-2.5 pb-8 rounded-lg ' + classes}
      data-testid='tableContainer'
    >
      {children}
    </StyledTableContainer>
  );
}
