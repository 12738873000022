import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IChargeTypeRepository } from 'shared/api/interfaces/IChargeTypeRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IChargeType, IChargeTypeSpec } from 'shared/models/ChargeType';

@injectable()
export class ChargeTypeRepository implements IChargeTypeRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/charge-types/');

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, {
      data: [{ name: ids[1], pipelineId: ids[0] }]
    });
  }

  async add(broker: IChargeType): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...broker });
  }

  async update(broker: IChargeType): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...broker });
  }

  async getAll(): Promise<AxiosResponse<IChargeType[]>> {
    return await client.get(this.apiUrl);
  }

  async getSpecs(): Promise<AxiosResponse<IChargeTypeSpec[]>> {
    return await client.get(this.apiUrl + 'spec-charge-types');
  }
}
