import { useValueMemo } from '@core/hooks';
import { ComposedSfc } from '@core/typings';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { SidebarContext, SidebarLib } from '../SidebarContext';
import { sidebarIsInitiallyOpen } from '../sidebarIsInitiallyOpen';

type HocProps = { children?: ReactNode };

const updateStored = (v: boolean) =>
  window.localStorage && window.localStorage.setItem('sidebar-expanded', JSON.stringify(v));

export function withSidebar(Composed: ComposedSfc): ComposedSfc {
  return function WithSidebar(props: HocProps) {
    const [isOpen, setIsOpen] = useState<boolean>(sidebarIsInitiallyOpen);
    const [preventNavigation, setPreventNavigation] = useState<boolean>(false);
    const [runRouteAction, setRunRouteAction] = useState({ action: () => null });
    const [showPreventNavigationModal, setShowPreventNavigationModal] = useState<boolean>(false);

    useEffect(
      () => () => {
        setPreventNavigation(false); /* etc */
        setRunRouteAction({ action: () => null });
      },
      [location.pathname]
    );

    const close = () => {
      setIsOpen(false);
    };

    const lib = useMemo<Omit<SidebarLib, 'toggle'>>(
      () => ({
        close,
        open: () => setIsOpen(true),
        setIsOpen: (v: boolean) => setIsOpen(v),
        setPreventNavigation: (v: boolean) => setPreventNavigation(v),
        setRunRouteAction: (v: any) => setRunRouteAction(v),
        setShowPreventNavigationModal: (v: boolean) => setShowPreventNavigationModal(v)
      }),
      []
    );

    const value = useValueMemo(() => {
      updateStored(isOpen);
      return {
        ...lib,
        isOpen,
        preventNavigation,
        runRouteAction,
        showPreventNavigationModal,
        toggle: () => setIsOpen(!isOpen)
      };
    }, [isOpen, preventNavigation, showPreventNavigationModal]);

    /* NOTE: this mimics the current behavior; remove if sidebar state should be preserved */
    useEffect(() => {
      setIsOpen(true);
      return () => updateStored(true);
    }, []);

    return (
      <SidebarContext.Provider value={value}>
        <Composed {...props} />
      </SidebarContext.Provider>
    );
  };
}
