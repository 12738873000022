export enum COLORS {
  '$red_1' = '#fff1f0',
  '$red_2' = '#ffbfc5',
  '$red_3' = '#ff9fa8',
  '$red_4' = '#ff7f8b',
  '$red_5' = '#ff5f6e',
  '$red_6' = '#f5222d',
  '$red_7' = '#cf1322',
  '$red_8' = '#a8071a',
  '$red_9' = '#820014',
  '$red_10' = '#5c0011',
  '$orange_1' = '#fff7e6',
  '$orange_2' = '#ffe7ba',
  '$orange_3' = '#ffd591',
  '$orange_4' = '#ffc069',
  '$orange_5' = '#ffa940',
  '$orange_6' = '#ff8100',
  '$orange_7' = '#d46b08',
  '$orange_8' = '#ad4e00',
  '$orange_9' = '#873800',
  '$orange_10' = '#612500',
  '$orange_11' = '#ffbd97',
  '$gold_1' = '#fffbe6',
  '$gold_2' = '#fff1b8',
  '$gold_3' = '#ffe58f',
  '$gold_4' = '#ffd666',
  '$gold_5' = '#ffc53d',
  '$gold_6' = '#ffbd19',
  '$gold_7' = '#d48806',
  '$gold_8' = '#ad6800',
  '$gold_9' = '#874d00',
  '$gold_10' = '#613400',
  '$gold_11' = '#fadc0d',
  '$green_1' = '#f6ffed',
  '$green_2' = '#d9f7be',
  '$green_3' = '#b7eb8f',
  '$green_4' = '#95de64',
  '$green_5' = '#73d13d',
  '$green_6' = '#1cdb0d',
  '$green_7' = '#389e0d',
  '$green_8' = '#237804',
  '$green_9' = '#135200',
  '$green_10' = '#092b00',
  '$green_11' = '#b8eb90',
  '$cyan_1' = '#e6fffb',
  '$cyan_2' = '#b5f5ec',
  '$cyan_3' = '#87e8de',
  '$cyan_4' = '#5cdbd3',
  '$cyan_5' = '#36cfc9',
  '$cyan_6' = '#13a8a8',
  '$cyan_7' = '#08979c',
  '$cyan_8' = '#006d75',
  '$cyan_9' = '#006d75',
  '$cyan_10' = '#002329',
  '$aqua_1' = '#e5fff8',
  '$aqua_2' = '#b4fdea',
  '$aqua_3' = '#93fce1',
  '$aqua_4' = '#7defd2',
  '$aqua_5' = '#52eac3',
  '$aqua_6' = '#26e4b4',
  '$aqua_7' = '#26c4a6',
  '$aqua_8' = '#266c7f',
  '$aqua_9' = '#1c4052',
  '$aqua_10' = '#132e3b',
  '$persian_blue_1' = '#f0f5ff',
  '$persian_blue_2' = '#d6e4ff',
  '$persian_blue_3' = '#adc6ff',
  '$persian_blue_4' = '#85a5ff',
  '$persian_blue_5' = '#597ef7',
  '$persian_blue_6' = '#2f54eb',
  '$persian_blue_7' = '#1d39c4',
  '$persian_blue_8' = '#10239e',
  '$persian_blue_9' = '#061178',
  '$persian_blue_10' = '#030852',
  '$persian_blue_11' = '#afc8fe',
  '$purple_1' = '#f1efff',
  '$purple_2' = '#c7bfff',
  '$purple_3' = '#aa9fff',
  '$purple_4' = '#8e7fff',
  '$purple_5' = '#715fff',
  '$purple_6' = '#553fe5',
  '$purple_7' = '#4631b5',
  '$purple_8' = '#301989',
  '$purple_9' = '#22075e',
  '$purple_10' = '#120338',
  '$purple_11' = '#8f80ff',
  '$magenta_1' = '#fff0f6',
  '$magenta_2' = '#ffd6e7',
  '$magenta_3' = '#ffadd2',
  '$magenta_4' = '#ff85c0',
  '$magenta_5' = '#f759ab',
  '$magenta_6' = '#eb2f96',
  '$magenta_7' = '#c41d7f',
  '$magenta_8' = '#9e1068',
  '$magenta_9' = '#780650',
  '$magenta_10' = '#520339',
  '$volcano_3' = '#ffbb96',
  '$volcano_5' = '#ff7a45',
  '$rich_black' = '#111825',
  '$black_85' = '#030408d9',
  '$black_65' = '#030408a6',
  '$black_45' = '#03040873',
  '$black_40' = '#03040866',
  '$black_25' = '#03040840',
  '$black_15' = '#03040826',
  '$black_6' = '#0304080f',
  '$black_4' = '#0304080a',
  '$black_2' = '#03040805',
  '$white' = '#ffffff',
  '$white_65' = '#ffffffa6',
  '$white_20' = '#ffffff33',
  '$white_8' = '#ffffff14',
  '$title' = '#262626',
  '$primary_text' = '#595959',
  '$secondary_text' = '#8c8c8c',
  '$disable' = '#bfbfbf',
  '$border' = '#d9d9d9',
  '$dividers' = '#f0f0f0',
  '$background' = '#f5f5f5',
  '$table_header' = '#fafafa',
  '$sidebar_background' = '#22075e',
  '$sidebar_text' = '#b3a9c3',
  '$border_search' = '#e4e7eb',
  'transparent' = 'transparent'
}
