import styled from 'styled-components';

export const StyledSwitchInputButton = styled.input`
  opacity: 0;
`;

export const StyledSwitchButtonBox = styled.label`
  display: inline-block;
  width: 30px;
  height: 16px;
  transition: color 150ms;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) => props.theme.contrast15};
`;

export const StyledSwitchButtonHead = styled.div`
  left: 1px;
  top: 1px;
  width: 14px;
  height: 14px;
  border-radius: 8px;
  transition: transform 0.3s ease-out;
  background-color: ${(props) => props.theme.secondaryButtonBackground};
`;

export const StyledSwitchButton = styled.div`
  cursor: pointer;

  &.switch-button-checked {
    ${StyledSwitchButtonBox} {
      background-color: ${(props) => props.theme.primaryButtonBackground};
    }

    ${StyledSwitchButtonHead} {
      transform: translateX(100%);
    }
  }

  &.checked-size-lg {
    ${StyledSwitchButtonBox} {
      width: 44px;
      height: 22px;
      border-radius: 12px;
    }

    ${StyledSwitchButtonHead} {
      width: 20px;
      height: 20px;
      border-radius: 12px;
    }
  }

  &.checked-size-lg.switch-button-checked {
    ${StyledSwitchButtonHead} {
      left: 3px;
    }
  }
`;
