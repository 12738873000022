import { useValueMemo } from '@core/hooks';
import isEq from 'fast-deep-equal';
import _ from 'lodash';
import { useCallback } from 'react';
import { RouteMatchParams } from '../OxSidebar.types';
import { useRouteInfo } from './useRouteInfo';
export type IsLinkActiveGetter = (params: RouteMatchParams) => boolean;

const doParamsMatch = (cParams: RouteMatchParams, routeParams: RouteMatchParams): boolean =>
  isEq(_.pick(cParams, _.keys(routeParams)), routeParams);

export function useGetIsRouteActive(): IsLinkActiveGetter {
  const { params, pathname, hash } = useRouteInfo();
  return useCallback<IsLinkActiveGetter>(
    (routeParams) => {
      return doParamsMatch({ ...params, hash }, routeParams);
    },
    [pathname, hash]
  );
}

export const useIsRouteActive: IsLinkActiveGetter = (routeParams: RouteMatchParams) => {
  const { params, pathname, hash } = useRouteInfo();

  return useValueMemo(() => doParamsMatch(params, routeParams), [routeParams, pathname, hash]);
};
