import { useAgGridRef, withGridRef } from '@core/grid';
import { useGetSetState, useUpdate, useValueMemo } from '@core/hooks';
import { ComposedSfc, Nullable } from '@core/typings';
import { ensureReadyConfig } from '@core/user-configs/hoc';
import { useConfig } from '@core/user-configs/hooks/useConfig';
import { compose } from '@reduxjs/toolkit';
import { GridReadyEvent, GridState, StateUpdatedEvent } from 'ag-grid-community';
import isEq from 'fast-deep-equal';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  GridViewsConfigTVal,
  GridViewsProviderCV,
  GridViewsProviderLib,
  GridViewsProviderParams,
  GridViewsProviderState,
  IGridViewsConfig
} from '../GridStateConfig.types';
import { GridViewsProviderContext } from '../GridViewsProvider.context';

/* TODO: check on why row grouping doesn't persis */
function parseState(state: Nullable): GridState | undefined {
  try {
    if (!state) return;
    return JSON.parse(state) as GridState;
  } catch (e) {
    return;
  }
}

export function withGridViewsIntegration(params: GridViewsProviderParams) {
  const { debug } = params;
  return compose(
    ensureReadyConfig(params.configId),
    function _withGridViewsIntegration(Composed: ComposedSfc) {
      return function WithGridViewsIntegration(props: any) {
        // const [isGridReady, setGridReady] = useState(false);
        const update = useUpdate();
        const Config = useConfig<GridViewsConfigTVal>(params.configId);
        const _GridRef = useAgGridRef();
        const Grid = _GridRef?.current;

        const getInitialState = useCallback(
          ({ isInitial }: { isInitial?: boolean } = {}): GridState | undefined => {
            const { customGridViews, activeGridView, defaultGridView } = Config.getCurrent();
            const viewKey = isInitial ? defaultGridView || activeGridView : activeGridView;
            // if (isInitial && viewKey !== activeGridView) Config.update({ activeGridView: viewKey });
            if (!viewKey) return;
            const view = _.find(customGridViews, { key: viewKey });
            if (!view) return;
            try {
              return parseState(view.gridState) as GridState;

              /* this should never happen (value is JSON.stringify'd on the way in) */
            } catch (e) {
              debug && console.error('error parsing grid state', e, view.gridState);
              return;
            }
          },
          []
        );

        const getAvailableViews = useCallback(
          () =>
            _.orderBy(
              _.map(Config.getCurrent().customGridViews, (val) => ({
                ...val,
                label: val.label || _.startCase(val.key),
                isCustom: true
              })),
              ['label'],
              ['asc']
            ),
          []
        );
        const initialProviderState = useMemo<GridViewsProviderState>(
          () => ({
            availableViews: getAvailableViews(),
            debug: !!debug,
            fallbackState: undefined,
            hasActiveViewStateMeaningfullyChanged: false,
            initialState: getInitialState({ isInitial: true }),
            isInitialized: false,
            reloadCount: 0
          }),
          []
        );
        const [getState, setState] = useGetSetState<GridViewsProviderState>(initialProviderState);
        const debouncedResizeHandler = useRef<NodeJS.Timeout | null>(null);
        useEffect(() => {
          if (!Grid?.api) return;
          const stateChangeListener = (event: StateUpdatedEvent) => {
            if (event.api.isDestroyed()) return;
            const nState = event.api.getState();
            const hasActiveView = !!Config.getCurrent().activeGridView;
            switch (true) {
              /* on first event (will have gridInitializing as a source), set init (fallback) state */
              case _.includes(event.sources, 'gridInitializing'):
                debug && console.log('setting initial state from gridInitializing', nState);
                setState({ isInitialized: true });
                break;

              /* handle manual column resize (don't update refs until timeout clears) */
              case !!hasActiveView && _.isEqual(event.sources, ['columnSizing']):
                if (debouncedResizeHandler.current) clearTimeout(debouncedResizeHandler.current);
                debouncedResizeHandler.current = setTimeout(() => {
                  debug && console.log('executing resize handler cb');
                  // activeStateRef.current = nState;
                  debouncedResizeHandler.current = null;
                  setState({ hasActiveViewStateMeaningfullyChanged: true });
                }, 250);
                break;
              /* handle a normal-ass event */
              case !!hasActiveView:
                debug && console.log(`state updated (via ${_.join(event.sources, ', ')}):`, nState);
                // activeStateRef.current = nState;
                if (
                  /* don't re-set flag when already set */
                  !getState().hasActiveViewStateMeaningfullyChanged &&
                  /* don't log sidebar events as meaningful */
                  !_.includes(event.sources, 'sideBar') &&
                  /* user events won't change any more than 3 things on state at once */
                  event.sources.length < 4
                ) {
                  setState({ hasActiveViewStateMeaningfullyChanged: true });
                }

                break;
              default:
                break;
            }
          };

          Grid?.api.addEventListener('stateUpdated', stateChangeListener);
          const colDefsUpdated = () => Config.emit('colDefs updated');
          Grid?.api.addEventListener('newColumnsLoaded', colDefsUpdated);
          return () => {
            debug && Grid.api.isDestroyed() && console.log('recycling listeners');
            if (!Grid.api.isDestroyed()) {
              Grid.api.removeEventListener('stateUpdated', stateChangeListener);
              Grid.api.removeEventListener('newColumnsLoaded', colDefsUpdated);
              update();
            }
          };
        }, [!!Grid?.api, getState().isInitialized]);

        const [canListen, setCanListen] = useState<boolean>(false);

        /* ******************************************************* HANDLE CHANGES TO CONFIG VALUE */
        useEffect(() => {
          /* on first render, determine what activeGrid view should be */
          if (!canListen) {
            const { activeGridView, defaultGridView } = Config.getCurrent();
            if (activeGridView !== defaultGridView) {
              Config.update({ activeGridView: defaultGridView || activeGridView });
            }
            setCanListen(true);
            /* don't start listeners until next render */
            return;
          }
          const handleConfChange = (nVal: IGridViewsConfig, pVal: IGridViewsConfig) => {
            const payload: Partial<GridViewsProviderState> = {};
            /* handle active view change */
            const nView = nVal.current.activeGridView;
            const pView = pVal.current.activeGridView;
            /* setting a new active view, so prep the "initialState" value passed to the grid */
            if (nView !== pView) {
              /* active view is changed, so grid is going to reload */
              payload.isInitialized = false;
              payload.hasActiveViewStateMeaningfullyChanged = false;
              if (nView) payload.initialState = getInitialState();
              else {
                debug && console.log('removing initialState');
                payload.initialState = undefined;
              }
            }
            /* handle options change */
            /* TODO: this should be an explicit handler */
            if (!isEq(nVal.current.customGridViews, pVal.current.customGridViews)) {
              payload.availableViews = getAvailableViews();
              payload.hasActiveViewStateMeaningfullyChanged = false;
            }

            /* drop update */
            if (!_.isEmpty(payload)) setState(payload);
          };
          Config.on('change', handleConfChange);

          const handleReload = () => {
            debug && console.log('ticking reload count to:', getState().reloadCount + 1);
            setState((pState) => ({ ...pState, reloadCount: ++pState.reloadCount }));
          };
          /* FIRED @ <GridViewsMontor /> */
          Config.on('grid reloading', handleReload);
          return () => {
            Config.off('change', handleConfChange);
            Config.off('grid reloading', handleReload);
          };
        }, [canListen]);

        const lib = useMemo<GridViewsProviderLib>(
          () => ({
            get providerParams() {
              return {
                debug: false,
                unshiftToolPanel: true,
                ...params
              };
            },
            onGridReady: (grid: GridReadyEvent) => {
              /* HANDLED @ <GridViewsMonitor /> */
              Config.emit('grid ready', { grid, providerState: getState() });

              update();
            }
          }),
          []
        );

        const value = useValueMemo<GridViewsProviderCV>(() => {
          Config.emit('withGridViewsProviderStateChange', getState());
          return _.extend(Config, getState(), { getProviderState: getState }, lib);
        }, [getState()]);

        return (
          <GridViewsProviderContext.Provider value={value}>
            <Composed {...props} />
          </GridViewsProviderContext.Provider>
        );
      };
    }
  );
}

export const withAdHocGridViewsIntegration = (params: GridViewsProviderParams) =>
  compose(withGridRef, withGridViewsIntegration(params));
