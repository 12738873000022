import { resolveClassName } from '@core/grid/util';
import { UseGetSetStateGetter } from '@core/hooks';
import { splitJoinedArray } from '@core/util';
import { CellClassParams, ColDef, ColGroupDef, HeaderClassParams } from 'ag-grid-community';
import { ColDefField } from 'ag-grid-community/dist/lib/entities/colDef';
import _ from 'lodash';
import {
  ColDefResult,
  ColGroupDefMap,
  ColGroupDefResult,
  FieldDef,
  FieldMap,
  GridViewConfig,
  RowGroupFieldMap,
  RowGroupTypeMap,
  WithGridFieldsState
} from '../../../typings';

type ReducedColDefs<TData extends object = any> = Record<string, ColGroupDefResult<TData>> & {
  addl: ColDefResult<TData>[];
};
type CreateColDefsConfig<
  TData extends object = any,
  FieldName extends string = any
> = GridViewConfig<TData, FieldName> & {
  rowGroupFieldMap: RowGroupFieldMap<FieldName>;
};

export function createColDefs<TData extends object = any, FieldName extends string = any>(
  conf: CreateColDefsConfig<TData, FieldName> = {
    key: 'default',
    rowGroupFieldMap: {} as RowGroupFieldMap<FieldName>
  },
  getState: UseGetSetStateGetter<WithGridFieldsState<TData, FieldName>>
) {
  const state = getState();
  const {
    agGridProps: { columnTypes, dataTypeDefinitions },
    fieldConfigMap: _fieldConf,
    columnGroupMap,
    dataPathMap
  } = state;
  const {
    availableFields = _.keys(_fieldConf),
    initialColumns = availableFields,
    fieldOverrides,
    sortFields
  } = conf;
  const sortMap = _.reduce(
    sortFields,
    (memo, _fld, index) => {
      const fld = _.isArray(_fld) ? _fld[0] : _fld;
      const dir = _.isArray(_fld) ? _fld[1] ?? 'asc' : 'asc';
      memo[fld] = {
        initialSort: dir,
        initialSortIndex: index
      };
      return memo;
    },
    {} as Record<
      string,
      {
        initialSort: 'asc' | 'desc';
        initialSortIndex: number;
      }
    >
  );
  const rowGroupTypeByColId = _.mapValues(_fieldConf, 'rowGroupType') as RowGroupTypeMap<FieldName>;

  const colGroups = _.mapValues<ColGroupDefMap<TData>, ColGroupDef<TData>>(
    columnGroupMap,
    (colGroup, groupId): ColGroupDef => ({
      groupId,
      headerName: _.startCase(groupId),
      ..._.omit(colGroup, ['columnDefaults', 'color']),
      headerClass: (params) =>
        resolveClassName({
          params,
          type: 'header',
          align: 'left',
          additionalClassNames: [
            `${groupId}-col`,
            colGroup.color && `core-${colGroup.color}-col`,
            colGroup.headerClass
          ]
        }),
      children: []
    })
  );

  const fieldMap = _.pick<FieldMap<TData, FieldName>>(_fieldConf, availableFields);

  const { addl, ...rest } = _.reduce<Partial<FieldMap<TData, FieldName>>, ReducedColDefs<TData>>(
    fieldMap,
    (memo, dftFieldDef = {} as FieldDef, fieldName) => {
      const fieldDef: FieldDef<TData, FieldName> = _.defaults(
        {},
        _.get(fieldOverrides, [fieldName]),
        dftFieldDef
      );
      if (_.isEmpty(fieldDef)) return memo;
      const {
        colDef,
        colDef: {
          cellDataType: _defCellDataType = undefined,
          headerName,
          type: _defType = []
        } = {},
        colGroup,
        align,
        ...restFieldDef
      } = fieldDef;

      /* attempt to infer cell data type from config'd column types */
      let cellDataType = _defCellDataType;
      if (!_defCellDataType) {
        const defTypeArr = splitJoinedArray(_defType);
        if (_.includes(defTypeArr, 'month')) cellDataType = 'monthString';
        else if (_.includes(defTypeArr, 'date')) cellDataType = 'dateString';
        else if (_.includes(defTypeArr, 'datetime')) cellDataType = 'datetimeString';
        else if (_.includes(defTypeArr, 'time')) cellDataType = 'timeString';
        else if (_.includes(defTypeArr, 'number')) cellDataType = 'numberString';
      }
      /* set text as the fallback (system behavior) */
      if (!cellDataType) cellDataType = 'text';

      let cellDataColTypes: string | string[] = [];
      let identicalColType: string | undefined;
      if (_.isString(cellDataType)) {
        cellDataColTypes = dataTypeDefinitions?.[cellDataType]?.columnTypes ?? [];
        if (_.has(columnTypes, [cellDataType])) identicalColType = cellDataType;
      }
      const colTypes = _.compact(
        _.concat(
          [],
          splitJoinedArray(cellDataColTypes),
          identicalColType,
          splitJoinedArray(_defType)
        )
      );

      const groupConf = _.get(state, ['columnGroupMap', colGroup ?? '']) ?? {};
      const { color: groupColorClassRoot, columnDefaults: groupDft = {} } = groupConf;
      const colorClassName = groupColorClassRoot && `core-${groupColorClassRoot}-col`;
      const groupClassName = colGroup && `${_.kebabCase(colGroup)}-col`;
      const fldDefHide = fieldDef.colDef.hide ?? fieldDef.colDef.initialHide;
      const hide = _.isBoolean(fldDefHide) ? fldDefHide : !_.includes(initialColumns, fieldName);
      const ret = {
        ...groupDft,
        ...(_.has(dataPathMap, [fieldName])
          ? { field: _.get(dataPathMap, [fieldName]) as ColDefField<TData> }
          : {}),
        colId: fieldName,
        ...colDef,
        cellClass: (params: CellClassParams) =>
          resolveClassName({
            additionalClassNames: [
              colorClassName,
              groupClassName,
              groupDft.cellClass,
              colDef.cellClass
            ],
            align,
            params,
            type: 'cell'
          }),
        headerClass: (params: HeaderClassParams) =>
          resolveClassName({
            additionalClassNames: [
              colorClassName,
              groupClassName,
              groupDft.headerClass,
              colDef.headerClass
            ],
            align,
            params,
            type: 'header'
          }),
        headerName: _.isString(headerName) ? headerName : _.startCase(fieldName),
        filterParams: {
          ...colDef.filterParams,
          className: colDef.headerClass
        },
        initialHide: hide,
        hide,

        type: colTypes,

        /* row group config; NOTE: defaults are set so switching activeView works */
        initialRowGroup: false,
        initialRowGroupIndex: -1,
        rowGroup: false,
        rowGroupIndex: -1,
        ...(conf.rowGroupFieldMap[fieldName as FieldName] as
          | RowGroupFieldMap<FieldName>
          | undefined),

        /* sort stuff */
        ..._.get(sortMap, fieldName),

        /* aggregation metadata */
        __metadata__: { ...restFieldDef, rowGroupTypeByColId }
      };

      if (colGroup && _.has(memo, [colGroup])) {
        (memo[colGroup] as ColGroupDef).children.push(ret);
      } else memo.addl.push(ret);
      return memo;
    },
    { ...colGroups, addl: [] as ColDef<TData>[] } as ReducedColDefs<TData>
  );
  return [...addl, ..._.values(rest)];
}
