import { css } from './Tabs.style';

import { useDidUpdate, useSetState, useValueMemo } from '@core/hooks';
import { addNotification } from '@core/notification';
import { useIsRouteHidden } from '@core/routing';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import _ from 'lodash';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useTransition
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { renderTab } from './Tab';
import { TabsContext } from './Tabs.context';
import { TabsCV, TabsLib, TabsMap, TabsProps, TabsState } from './Tabs.types';
import { TabsHeader } from './TabsHeader';

/* TODO: need to add Suspense for unloaded components */
export const Tabs = forwardRef(function Tabs(props: TabsProps, ref: ForwardedRef<TabsLib>) {
  const {
    activeTab: _ctrlActiveTab,
    autoHideTabs = true,
    className,
    headerAlign,
    headerPrefix,
    headerSuffix,
    indicatorType = 'inkbar',
    initiallyActiveTab,
    isHashed,
    color = 'primary',
    duration = 500,
    tabs: _tabs,
    lazyLoad = false,
    unmountOnExit = true
  } = props;
  const isTabHidden = useIsRouteHidden();
  const navigate = useNavigate();
  const [isPending, startTransistion] = useTransition();

  const Location = useLocation();
  const hash = useValueMemo(() => _.trim(Location.hash, '#'), [Location.hash]);

  const cls = useCss(css, { duration });

  const getVisibleTabs = useCallback(
    () =>
      _.omitBy(_tabs, ({ isHidden }, key) => {
        if (isTabHidden(isHidden)) return true;
        if (isHashed && autoHideTabs) {
          return isTabHidden(({ userCanVisit }) => !userCanVisit(Location.pathname, `#${key}`));
        }
        return false;
      }),
    [_tabs]
  );
  const [tabs, setTabs] = useState<TabsMap>(getVisibleTabs);
  const tabKeys = useValueMemo(() => _.keys(tabs), [tabs]);

  useEffect(() => {
    setTabs(getVisibleTabs());
  }, [getVisibleTabs]);

  const rootTabKey = useMemo(() => {
    const enabledTabs = _.omitBy(tabs, { isDisabled: true });
    const firstEnabled = _.keys(enabledTabs)[0];
    if (isHashed) return _.findKey(enabledTabs, { isDefaultRoute: true }) || firstEnabled;
    return firstEnabled;
  }, []);

  const initialActiveTab = useMemo(() => {
    if (isHashed) {
      let hashTab = hash;
      if (!hashTab) hashTab = rootTabKey;
      if (_.has(tabs, [hashTab])) return hashTab;
    }

    if (_ctrlActiveTab) return _ctrlActiveTab;
    if (initiallyActiveTab) return initiallyActiveTab;
    return rootTabKey;
  }, []);

  const initialState = useMemo<TabsState>(() => {
    const isCtrl = _.isString(_ctrlActiveTab);
    return {
      activeTab: initialActiveTab as string,
      defaultColor: color,
      indicatorType,
      isCtrl,
      isPending,
      duration,
      lazyLoad,
      unmountOnExit
    };
  }, []);

  const [state, setState] = useSetState<TabsState>(initialState);

  useDidUpdate(() => setState({ isPending }), [isPending]);

  /* update pass-through props */
  useDidUpdate(() => {
    setState({
      defaultColor: color,
      duration,
      indicatorType,
      lazyLoad,
      unmountOnExit
    });
  }, [color, indicatorType, duration, lazyLoad, unmountOnExit]);

  const validateTabKey = useCallback(
    (key: string) => _.includes(tabKeys, key) && tabs[key].isDisabled !== true,
    [tabKeys]
  );

  const setActiveTab = useCallback(
    (activeTab: string) => {
      if (validateTabKey(activeTab)) {
        startTransistion(() => setState({ activeTab }));
      }
    },
    [validateTabKey]
  );

  /* ensure route hash and activeTab are N'SYNC */
  useDidUpdate(
    (wasHashed, pHash, pActive) => {
      if (isHashed) {
        const nActive = state.activeTab;
        /* active tab has changed; update hash */
        if (nActive !== pActive && nActive !== hash) {
          navigate(`#${nActive}`, { replace: true });

          /* hash has changed prior to state updating */
        } else if (hash !== pHash && hash !== nActive) {
          if (hash === '') setActiveTab(rootTabKey); /* route has been reset */
          else if (validateTabKey(hash)) setActiveTab(hash); /* tab is valid, so update state */
          else if (!_.has(_tabs, [hash])) {
            /* tab is invalid; re-navigate to previous hash */
            addNotification({
              message: `Could not find tab for "#${hash}"`,
              type: 'error'
            });
            navigate(`#${pHash}`, { replace: true });
          }
        }
      }
    },
    [isHashed, hash, state.activeTab]
  );

  const lib = useMemo<TabsLib>(
    () => ({
      _addEndListener: (node: HTMLElement, done: () => void) => {
        node.addEventListener('transitionend', done, false);
      },
      setActive: setActiveTab
    }),
    [setActiveTab]
  );

  /* on control */
  useDidUpdate(() => {
    const { isCtrl } = state;
    if (_ctrlActiveTab) {
      if (!isCtrl) setState({ isCtrl: true });
      lib.setActive(_ctrlActiveTab);
    } else if (isCtrl) {
      setState({ isCtrl: false });
    }
  }, [_ctrlActiveTab]);

  useImperativeHandle(ref, () => lib, [lib]);

  const value = useValueMemo<TabsCV>(() => ({ ...state, ...lib }), [state]);

  return (
    <TabsContext.Provider value={value}>
      <div className={cn('core-tabs', className, cls.wpr)}>
        <TabsHeader {...{ headerAlign, headerPrefix, headerSuffix, tabs }} />
        <div className='tabs-content-wpr'>{_.map(tabs, renderTab)}</div>
      </div>
    </TabsContext.Provider>
  );
});
