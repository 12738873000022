import { UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { QMapped } from './useOrchestratedData.types';

export function getResponseMap<QMap, Err>(
  responses: UseQueryResult[],
  returnKeys: (keyof QMap)[]
): QMapped<QMap, UseQueryResult<unknown, Err>> {
  return _.reduce(
    responses,
    (memo, resp, index) => {
      memo[returnKeys[index]] = resp as any;
      return memo;
    },
    {} as QMapped<QMap, UseQueryResult<unknown, Err>>
  );
}
