import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal/Modal';
import { ButtonType, OxButton } from '../OxButton';
import { Text } from '../Text';

export function ModalUnsavedChanges({
  handleCancel,
  handleSave,
  handleProceed
}: {
  handleCancel: () => void;
  handleSave: () => void;
  handleProceed: () => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal
      headerText='Save Changes'
      buttonConfirmText='Yes'
      displayModalHandler={handleCancel}
      onConfirm={handleSave}
      footerContent={
        <OxButton className='ml-auto mx-4' onClick={handleProceed} buttonType={ButtonType.standard}>
          No
        </OxButton>
      }
    >
      <Text text={t('notifications.unsavedChanges')} />
    </Modal>
  );
}
