import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParse from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import { CreateFormatterConfig } from './createFormatter.types';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(customParse);
dayjs.extend(isBetween);
dayjs.extend(utc);

type CreateDateFormatterConfig = {
  formatString?: CreateFormatterConfig['formatString'];
  parseFormat?: CreateFormatterConfig['parseFormat'];
  isStrictParse?: CreateFormatterConfig['isStrictParse'];
  formatType?: 'date' | 'datetime';
  useCentralTime?: boolean;
};

export const createDateFormatter = (conf: CreateDateFormatterConfig) => (v: any) => {
  if (!v) return v;
  let val = v;
  if (_.isDate(val)) val = dayjs(val).toISOString();
  else if (!_.isString(val)) val = _.toString(val);
  if (!val) return val;

  const ret = dayjs(
    ..._.compact([val, conf.parseFormat || null, conf.isStrictParse || null])
  ).format(conf.formatString);

  if (!ret || ret === 'Invalid Date') return v;
  return ret;
};

export const createDatetimeFormatter = (conf: CreateDateFormatterConfig) => {
  function coerceValue(val: any) {
    if (_.isDate(val)) val = dayjs.utc(val).toISOString();
    else if (!_.isString(val)) val = _.toString(val);
    return val;
  }

  return (v: any) => {
    if (!v) return v;
    const val = coerceValue(v);
    if (!val) return val;

    const ret = dayjs
      .utc(..._.compact([val, conf.parseFormat || null, conf.isStrictParse || null]))
      .tz(conf.useCentralTime ? 'America/Chicago' : undefined)
      .format(conf.formatString);

    if (!ret || ret === 'Invalid Date') return v;
    return ret;
  };
};
