import styled from 'styled-components';

export const StyledLoader = styled.div`
  height: 100vh;
  background: white;
  color: black;
  z-index: 9999;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  animation: fadeIn 500ms ease-in-out forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.app-level {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }

  &.page-level {
    width: 100%;
  }

  .loading-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .loading-title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;
  }
`;
