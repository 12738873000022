import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { TransportationAgreementThunks } from 'redux/thunks/transportationAgreement.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { ITransportationAgreementRepository } from 'shared/api/interfaces/ITransportationAgreementRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import {
  IPipelineTransportationAgreement,
  ITransportationAgreement
} from 'shared/models/TransportationAgreement';

import { buildGenericSlice } from '../../helpers/slice.helper';

type InitialStateType = {
  transportationAgreements: ITransportationAgreement[];
  count?: number;
  changeId: number;
  pipelineTransportationAgreements: IPipelineTransportationAgreement[];
};

const transportationAgreements: ITransportationAgreement[] = [];
const pipelineTransportationAgreements: IPipelineTransportationAgreement[] = [];
const count = undefined;
const changeId = 0;

export const initialState: InitialStateType = {
  count,
  changeId,
  transportationAgreements,
  pipelineTransportationAgreements
};

const transportationAgreementRepository = container.get<ITransportationAgreementRepository>(
  SERVICE_KEYS.TRANSPORTATION_AGREEMENT_REPOSITORY
);
const transportationAgreementThunks = new TransportationAgreementThunks(
  transportationAgreementRepository
);
export const getAllTransportationAgreements = transportationAgreementThunks.getAll();

export const deleteTransportationAgreement = transportationAgreementThunks.deleteAll();

export const addTransportationAgreement = transportationAgreementThunks.add();

export const addPipelineTransportationAgreement = transportationAgreementThunks.addPipelineTA();

export const updateTransportationAgreement = transportationAgreementThunks.update();

export const getAllPipelineTransportationAgreements =
  transportationAgreementThunks.getAllPipelineTAs();

export const deletePipelineTransportationAgreement =
  transportationAgreementThunks.deletePipelineTA();

const slice = buildGenericSlice<InitialStateType>('transportationAgreement', initialState);
export const transportationAgreementSlice = createSlice({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllTransportationAgreements.fulfilled,
      (state: any, { payload }: GenericPayload) => {
        if (payload) {
          const { data } = payload;
          state.transportationAgreements = data as ITransportationAgreement[];
          state.changeId++;
          state.count = data.length;
        }
      }
    );
    builder.addCase(
      getAllPipelineTransportationAgreements.fulfilled,
      (state: any, { payload }: GenericPayload) => {
        if (payload) {
          const { data } = payload;
          state.pipelineTransportationAgreements = data as ITransportationAgreement[];
          state.changeId++;
          state.count = data.length;
        }
      }
    );
  }
});

export const selectTransportationAgreements = (state: RootState) =>
  state.transportationAgreement.transportationAgreements;
export const selectPipelineTAs = (state: RootState) =>
  state.transportationAgreement.pipelineTransportationAgreements;
export const selectPipelines = (state: RootState) => state.pipeline.pipelines;

export const selectSpecPipelines = (state: RootState) => state.pipeline.pipelineSpecs;

export const selectTAMap = createSelectorMap(selectTransportationAgreements, 'specChargeTypeId');
export const selectPipelinesMapEtrmId = createSelectorMap(selectPipelines, 'etrmId');

export const selectPipelinesNameMap = createSelectorMap(selectPipelines, 'name');
export const selectPipelineTANameMap = createSelectorMap<IPipelineTransportationAgreement>(
  selectPipelineTAs,
  'name'
);

export const selectSpecPipelinesNameMap = createSelectorMap(selectSpecPipelines, 'name');
export const selectSpecPipelinesSpecIdMap = createSelectorMap(selectSpecPipelines, 'specId');

export const selectPipelineTAIDMap = createSelectorMap<IPipelineTransportationAgreement>(
  selectPipelineTAs,
  'id'
);

export const selectFQTransportationAgreements = createSelector(
  [selectTransportationAgreements, selectPipelineTAIDMap, selectPipelinesMapEtrmId],
  (transportationAgreements, pipelineTAMap, pipelineMap) => {
    return transportationAgreements.map((transportationAgreement: ITransportationAgreement) => {
      return {
        ...transportationAgreement,
        pipelineTAName: pipelineTAMap.get(transportationAgreement.pipelineTAId)?.name,
        pipelineName: pipelineMap.get(transportationAgreement.etrmPipelineId)?.name
      };
    });
  }
);

export const selectFQPipelineTransportationAgreements = createSelector(
  [selectPipelineTAs, selectSpecPipelinesSpecIdMap],
  (transportationAgreements, pipelineTAMap) => {
    return transportationAgreements.map(
      (transportationAgreement: IPipelineTransportationAgreement) => {
        return {
          ...transportationAgreement,
          specPipelineName: pipelineTAMap.get(transportationAgreement.specPipelineId)?.name
        };
      }
    );
  }
);

export const getCounterparties = (state: RootState) =>
  state.transportationAgreement.transportationAgreements;
export const transportationAgreementReducer = transportationAgreementSlice.reducer;
