import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { CommentType, IDealCommentInput } from 'shared/models/DealComment';
import { IDealActivityRepository } from '../../interfaces/IDealActivityRepository';

@injectable()
export class DealActivityRepository implements IDealActivityRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('v1/communication/');

  getAllCommentsByDealId = async (dealId: string): Promise<AxiosResponse<any>> => {
    const response = await client.get(
      `${this.apiUrl}comments?itemId=${dealId}&itemType=${CommentType.Deal}&isExternal=false`
    );
    return response.status === 200 ? response.data : [];
  };

  getAllExternalCommentsByDealId = async (dealId: string): Promise<AxiosResponse<any>> => {
    const response = await client.get(
      `${this.apiUrl}comments?itemId=${dealId}&itemType=${CommentType.Deal}&isExternal=true`
    );
    return response.status === 200 ? response.data : [];
  };

  getAllActionsByDealId = async (dealId: string): Promise<AxiosResponse<any>> => {
    const response = await client.get(`${this.apiUrl}${dealId}/action-history`);
    return response.status === 200 ? response.data : [];
  };

  createComment = async (comment: IDealCommentInput): Promise<AxiosResponse<any, any>> => {
    const response = await client.post(`${this.apiUrl}comment`, comment);
    return response;
  };

  getAll = async (): Promise<AxiosResponse<any>> => {
    throw new Error('Method not implemented.');
  };

  add = async (/* item: any */): Promise<AxiosResponse<any, any>> => {
    throw new Error('Method not implemented.');
  };

  update = async (/* item: any */): Promise<AxiosResponse<any, any>> => {
    throw new Error('Method not implemented.');
  };

  delete = async (/* id: string */): Promise<AxiosResponse<any, any>> => {
    throw new Error('Method not implemented.');
  };
}
