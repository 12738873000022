import { DealStatus, GridRange, InfiniteFilterParams, ProductKeys } from '@core/typings';
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { recordLimit } from 'Sections/Pair/utils/deal-helpers.helper';

export { ProductKeys };

export interface TableFilterType {
  searchText: string;
  productsFilter: ProductKeys[];
  dateFilterRange: GridRange;
  isDownloadDisabled: boolean;
  infiniteFilterParams?: InfiniteFilterParams;
  ignoreDateFilter: boolean;
  dealStatus: DealStatus[];
  isExternal: string;
}

const initialDateRange = { startDate: '', endDate: '' };

export const initialState: TableFilterType = {
  searchText: '',
  productsFilter: [ProductKeys.AllProducts],
  dateFilterRange: initialDateRange,
  isDownloadDisabled: false,
  infiniteFilterParams: {
    paging: {
      offset: 0,
      limit: recordLimit
    },
    filters: {},
    dateRange: {
      startDate: '',
      endDate: ''
    }
  },
  ignoreDateFilter: true,
  dealStatus: [DealStatus.Default],
  isExternal: ''
};

const slice = buildGenericSlice<TableFilterType>('tableFilters', initialState);
export const tableFiltersSlice = createSlice<TableFilterType, SliceCaseReducers<TableFilterType>>({
  ...slice,
  reducers: {
    setSearchText(state: TableFilterType, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setProductFilters(state: TableFilterType, action: PayloadAction<ProductKeys[]>) {
      state.productsFilter = action.payload;
    },
    setDateFilterRange(state: TableFilterType, action: PayloadAction<GridRange>) {
      state.dateFilterRange = action.payload;
      state.ignoreDateFilter = false;

      if (state.infiniteFilterParams) {
        state.infiniteFilterParams.dateRange = action.payload;
      }
    },
    clearDateFilterRange(state: TableFilterType) {
      state.dateFilterRange = initialDateRange;
      state.ignoreDateFilter = true;

      if (state.infiniteFilterParams) {
        state.infiniteFilterParams.dateRange = {
          startDate: '',
          endDate: ''
        };
      }
    },
    setIsDownloadDisabled(state: TableFilterType, action: PayloadAction<boolean>) {
      state.isDownloadDisabled = action.payload;
    },
    setDealStatus(state: TableFilterType, action: PayloadAction<DealStatus[]>) {
      state.dealStatus = action.payload;
    },
    setIsExternal(state: TableFilterType, action: PayloadAction<string>) {
      state.isExternal = action.payload;
    }
  }
});

export const {
  setSearchText,
  setProductFilters,
  setDateFilterRange,
  clearDateFilterRange,
  setIsDownloadDisabled,
  setDealStatus,
  setIsExternal
} = tableFiltersSlice.actions;
export const tableFiltersReducer = tableFiltersSlice.reducer;
