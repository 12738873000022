import {
  InfiniteFilterModel,
  InfiniteFilterParams,
  SearchFilters,
  SortOrder,
  SortParams
} from '@core/typings';
import { SortModelItem } from 'ag-grid-community';

const getSort = (sortModel: SortModelItem[], infiniteFilterParams?: InfiniteFilterParams) => {
  let sort = infiniteFilterParams?.sort;

  if (sortModel.length) {
    const [sortValue] = sortModel;
    sort = {
      key: sortValue.colId,
      order: sortValue.sort === 'asc' ? SortOrder.ASC : SortOrder.DESC
    };
  }

  return sort;
};

const getSearchFilters = (filterModel: any) => {
  const searchFilters: SearchFilters = {};

  if (filterModel && Object.keys(filterModel).length) {
    const infiniteFilterModel = filterModel as InfiniteFilterModel;
    Object.entries(infiniteFilterModel).map((value) => {
      const [key, data] = value;
      searchFilters[key] = data.values;
    });
  }

  return searchFilters;
};

const getInfiniteSearchParams = (
  searchFilters: SearchFilters,
  sort?: SortParams,
  startRow?: number,
  infiniteFilterParams?: InfiniteFilterParams
) => {
  return {
    ...infiniteFilterParams,
    filters: {
      ...infiniteFilterParams?.filters
    },
    searchFilters,
    sort,
    paging: {
      ...infiniteFilterParams?.paging,
      offset: startRow || 0
    }
  };
};

export const getInfiniteFilterParams = (
  sortModel: SortModelItem[],
  filterModel: any,
  infiniteFilterParams?: InfiniteFilterParams,
  startRow?: number
) => {
  const sort = getSort(sortModel, infiniteFilterParams);
  const searchFilters = getSearchFilters(filterModel);
  return getInfiniteSearchParams(searchFilters, sort, startRow, infiniteFilterParams);
};
