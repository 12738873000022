import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IZoneRepository } from 'shared/api/interfaces/IZoneRepository';
import { IZone, IZoneSpec, IZoneSpecRequest } from 'shared/models/Zone';
import { IBaseThunk } from './base.thunks';

export class ZoneThunks implements IBaseThunk<IZone> {
  private zoneRepository!: IZoneRepository;

  constructor(_zoneRepository: IZoneRepository) {
    this.zoneRepository = _zoneRepository;

    if (this.zoneRepository === null) {
      throw new Error('zoneRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'zones';

  add(): AsyncThunk<HttpResponse<IZone>, IZone, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addZone`;

    return createAsyncThunk(action, async (zone: IZone, { rejectWithValue }) => {
      try {
        return await this.zoneRepository.add(zone);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IZone>, IZone, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateZone`;

    return createAsyncThunk(action, async (zone: IZone, { rejectWithValue }) => {
      try {
        return await this.zoneRepository.update(zone);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IZone, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteZone`;

    return createAsyncThunk(action, (id: string) => this.zoneRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<IZone, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteZones`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.zoneRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<IZone[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchZones`;

    return createAsyncThunk(action, () => this.zoneRepository.getAll());
  }

  getSpecs(): AsyncThunk<AxiosResponse<IZoneSpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchZoneSpecs`;

    return createAsyncThunk(action, () => this.zoneRepository.getSpecs());
  }

  getById(): AsyncThunk<AxiosResponse<IZone, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchZoneById`;

    return createAsyncThunk(action, (id: string) => this.zoneRepository.getById(id));
  }

  createSpecRequest(): AsyncThunk<
    HttpResponse<IZoneSpecRequest>,
    IZoneSpecRequest,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/createZoneSpecRequest`;

    return createAsyncThunk(action, async (specRequest: IZoneSpecRequest, { rejectWithValue }) => {
      try {
        return await this.zoneRepository.createSpecRequest(specRequest);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }
}
