import { ArrowRightSvg, CalendarSvg } from '@core/images';
import { StyledDateText } from './StyledDateText';

interface Props {
  handleShow: () => void;
  startDateString: string | undefined;
  endDateString: string | undefined;
  disabled: boolean;
}

export function OxDatePickerInputs({
  handleShow,
  startDateString,
  endDateString,
  disabled
}: Props): JSX.Element {
  const getDateText = (dateString: string | undefined, name: string) => {
    return (
      <>
        <StyledDateText className='date-text text-sm font-normal mt-2 ml-1' data-testid={name}>
          {dateString}
        </StyledDateText>
        <CalendarSvg className='ml-auto mt-1.5' height={18} width={18} />
      </>
    );
  };

  const getDateRangeSide = (name: string, dateString: string | undefined, side = 'left') => {
    const margin = side === 'left' ? 'mr-2' : 'ml-2';

    return (
      <div
        className={`inline-flex border-[1px] rounded h-8 w-28 pr-2 ${
          disabled ? 'opacity-60' : 'cursor-pointer'
        } ${margin}`}
        onClick={() => !disabled && handleShow()}
        data-testid={`${name}Field`}
      >
        {getDateText(dateString, `${name}Text`)}
      </div>
    );
  };

  return (
    <div className='inline-flex static'>
      <div className='flex h-full'>
        {getDateRangeSide('startDate', startDateString, 'left')}
        <ArrowRightSvg className='mt-2.5' height={16} width={16} data-testid='arrowSvg' />
        {getDateRangeSide('endDate', endDateString, 'right')}
      </div>
    </div>
  );
}
