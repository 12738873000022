// import { VCssGetter } from '@core/theme/typings';
import { StyleGetter } from '@core/theme/typings';

export const css: StyleGetter = (theme) => {
  const { themePaletteMap } = theme;
  const darkCanvas = themePaletteMap.modern.dark.canvas;

  return {
    screenWpr: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      overflow: 'hidden',
      position: 'relative',

      '.screen-ctr': { display: 'flex', flex: '1 1' },

      '.page-view-wpr': {
        /* onboarding */
        padding: '2.5rem',
        width: '100vw',
        /* same transition as in OxSidebar.style.ts to match animations */
        transition: 'margin 200ms ease-in-out',

        '&:not(.--is-onboarding)': {
          height: '100vh',
          padding: '0',
          overflowX: 'hidden',
          overflowY: 'auto',
          width: '100%',

          '& > :not(.scheduling-page)': {
            padding: '0.75rem'
          }
        }
      },

      '&.--is-dark': {
        backgroundColor: darkCanvas.primary
      },

      '&.--is-alert-bar-enabled': {
        transform: 'translateY(24px)'
      }
    }
  };
};
