import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { StyledDateCalendar } from './StyledDateCalendar';

interface Props {
  selectedDate: Range;
  handleSelect: (ranges: RangeKeyDict) => void;
  minDate?: Date;
  maxDate?: Date;
  handleShownDateChange?: (date: Date) => void;
}

export function OxDateRange({
  selectedDate,
  handleSelect,
  minDate,
  maxDate,
  handleShownDateChange
}: Props): JSX.Element {
  return (
    <StyledDateCalendar className='border-2 drop-shadow-md z-10'>
      <DateRange
        ranges={[selectedDate]}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleSelect}
        color='#26e4b4'
        rangeColors={['#26e4b4']}
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        data-testid='datePicker'
        onShownDateChange={handleShownDateChange}
      />
    </StyledDateCalendar>
  );
}
