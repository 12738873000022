import { DataMngrEndpoint } from '@core/data-manager/typings';
import { Nullable } from '@core/typings';
import _ from 'lodash';
import { Endpoints } from '../../EndpointManager';
import { getRouteParams } from './getRouteParams';

type GetValidatedEndpointConfig = {
  key: string;
  routeParams?: Record<string, unknown>;
  shouldBeMutation?: boolean;
};

export interface ValidatedDataMngrEndpoint {
  exists: boolean;
  isValid: boolean;
  endpoint: Nullable<DataMngrEndpoint>;
  routeParams: Record<string, unknown>;
}

export function getValidatedEndpoint(conf: GetValidatedEndpointConfig): ValidatedDataMngrEndpoint {
  const { key, routeParams: prams, shouldBeMutation = false } = conf;
  const routeParams = getRouteParams(prams);
  const exists = Endpoints.Map.has(key);
  if (!exists) console.warn(`${conf.key} has not been registered`);

  const endpoint: Nullable<DataMngrEndpoint> = exists
    ? (Endpoints.Map.get(key) as DataMngrEndpoint)
    : null;

  if (endpoint && endpoint.isMutation !== shouldBeMutation) {
    console.warn(`${key} is not a fetch-type query; use useDataMutation instead`);
  }

  let isValid = false;
  if (endpoint) {
    const { hasDynamicUrl, dynamicRouteKeys } = endpoint;
    isValid = hasDynamicUrl ? _.isEmpty(_.without(dynamicRouteKeys, ..._.keys(routeParams))) : true;
  }

  return {
    endpoint,
    exists,
    isValid,
    routeParams
  };
}
