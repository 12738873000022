import styled from 'styled-components';

export const StyledBanner = styled.div`
  &.ox-banner-primary {
    background-color: ${(props) => props.theme.bannerContentBg};
  }

  .ox-banner-icon {
    color: ${(props) => props.theme.bannerIcon};
  }
`;

export const StyledBannerTitle = styled.h1`
  font-family: 'GibsonRegular';
  color: ${(props) => props.theme.bannerTitle};
`;

export const StyledBannerText = styled.p`
  font-family: 'GibsonRegular';
  color: ${(props) => props.theme.bannerText};
`;
