import { faker } from '@faker-js/faker';
import { INomination } from 'shared/models/Nomination';

const createNomination = (): INomination => ({
  id: faker.datatype.uuid(),
  pathed: faker.datatype.boolean(),
  seq: faker.datatype.number({ min: 100000 }),
  hold: faker.datatype.boolean(),
  greenGas: faker.datatype.boolean(),
  transportationAgreement: faker.datatype.number({ min: 100000 }).toString(),
  transactionType: faker.datatype.number({ max: 10 }).toString(),
  packageId: faker.datatype.number({ min: 100000 }).toString(),
  acctCode: faker.datatype.number({ min: 100000 }).toString(),
  rank: faker.datatype.number({ max: 9 }),
  supplyRank: faker.datatype.number({ max: 9 }),
  supplyPackageId: faker.datatype.number({ min: 100000 }).toString(),
  supplyCounterparty: {
    value: faker.datatype.number({ min: 100000 }).toString(),
    isValid: true
  },
  supplyDuns: faker.datatype.number({ min: 1000 }).toString(),
  supplyUsk: faker.datatype.number({ min: 100000 }).toString(),
  supplyTransactionType: faker.datatype.number({ max: 10 }).toString(),
  supplyMeter: faker.datatype.number({ min: 10000 }).toString(),
  supplyZone: faker.address.stateAbbr(),
  supplyLocation: faker.address.city(),
  supplyNommedVolume: faker.datatype.number({ max: 1000 }),
  supplyRemainingVolume: faker.datatype.number({ max: 1000 }),
  supplyScheduledVolume: faker.datatype.number({ max: 1000 }),
  loss: faker.datatype.number({ max: 1000 }),
  demandScheduledVolume: faker.datatype.number({ max: 1000 }),
  demandRemainingVolume: faker.datatype.number({ max: 1000 }),
  demandPackageId: faker.datatype.number({ min: 100000 }).toString(),
  demandCounterparty: {
    value: faker.datatype.number({ min: 100000 }).toString(),
    isValid: true
  },
  demandDuns: faker.datatype.number({ min: 1000 }).toString(),
  demandDsk: faker.datatype.number({ min: 100000 }).toString(),
  demandTransactionType: faker.datatype.number({ max: 10 }).toString(),
  demandMeter: faker.datatype.number({ min: 10000 }).toString(),
  demandZone: faker.address.stateAbbr(),
  demandLocation: faker.address.city()
});

export function mockNomGenerator(numberofNoms: number): INomination[] {
  const noms = [];
  for (let i = 0; i < numberofNoms; i++) {
    noms.push(createNomination());
  }
  return noms;
}
