import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IRateScheduleRepository } from 'shared/api/interfaces/IRateScheduleRepository';
import { IRateSchedule } from 'shared/models/RateSchedule';
import { IBaseThunk } from './base.thunks';

export class RateScheduleThunks implements IBaseThunk<IRateSchedule> {
  private rateScheduleRepository!: IRateScheduleRepository;

  constructor(_rateScheduleRepository: IRateScheduleRepository) {
    this.rateScheduleRepository = _rateScheduleRepository;

    if (this.rateScheduleRepository === null) {
      throw new Error('rateScheduleRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'rate-schedule';

  add(): AsyncThunk<HttpResponse<IRateSchedule>, IRateSchedule, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addRate`;

    return createAsyncThunk(action, (rate: IRateSchedule) => this.rateScheduleRepository.add(rate));
  }

  update(): AsyncThunk<HttpResponse<IRateSchedule>, IRateSchedule, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateRate`;

    return createAsyncThunk(action, async (rateSchedule: IRateSchedule, { rejectWithValue }) => {
      try {
        return await this.rateScheduleRepository.update(rateSchedule);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IRateSchedule, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteRate`;

    return createAsyncThunk(action, (id: string) => this.rateScheduleRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<IRateSchedule, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteRates`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.rateScheduleRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<IRateSchedule[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchRateSchedules`;

    return createAsyncThunk(action, () => this.rateScheduleRepository.getAll());
  }

  getById(): AsyncThunk<AxiosResponse<IRateSchedule, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchRatesById`;

    return createAsyncThunk(action, (id: string) => this.rateScheduleRepository.getById(id));
  }
}
