import './Collapse.style.scss';

import {
  ForwardedRef,
  forwardRef,
  LegacyRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  useDidUpdate,
  useGetSetState,
  useMeasure,
  useMemoizeValue,
  useValueMemo
} from '@core/hooks';
import { cn } from '@core/util';
import { animated, config, EasingFunction, easings, useSpring } from '@react-spring/web';
import _ from 'lodash';

import { Nullable } from '@core/typings';
import { CollapseContextValue, CollapseProps, CollapseState } from './Collapse.types';
import { CollapseContext } from './CollapseContext';

export const Collapse = forwardRef(function Collapse(
  {
    children,
    className,
    closedHeight = 0,
    containerClassName,
    delay = 0,
    destroyOnClose,
    duration = 250,
    easing = 'easeInCubic',
    initiallyOpen = true,
    isOpen: isOpenCtrl,
    lazyLoad,
    maxHeight,
    openHeight: _openHeight,
    springConfig,
    springConfigType,
    onToggle,
    onTransitionEnd,
    onTransitionStart
  }: CollapseProps,
  ref: ForwardedRef<Nullable<CollapseContextValue> | undefined>
): JSX.Element {
  /* determines whether state should be defined by prop value */
  const isCtrl = useValueMemo(() => _.isBoolean(isOpenCtrl), [isOpenCtrl]);

  /* prettier-ignore */
  const initialState = useMemo<CollapseState>(() => {
    const iIsOpen = isCtrl ? Boolean(isOpenCtrl) : initiallyOpen;
    return {
      hasPendingTransition: false,
      hasOpened: iIsOpen,
      isOpen: iIsOpen,
      isTransitioning: false,
    };
  }, []);
  /* add css flags */
  const [classNameFlags, setClassNameFlags] = useState<any>(() => ({
    '--is-open': initialState.isOpen,
    '--is-closed': !initialState.isOpen
  }));

  const [getState, setState] = useGetSetState<CollapseState>(initialState, (nState) => {
    const visState = {
      isClosed: !nState.isOpen && !nState.isTransitioning && !nState.hasPendingTransition,
      isClosing: !nState.isOpen && nState.isTransitioning,
      isOpen: nState.isOpen && !nState.isTransitioning && !nState.hasPendingTransition,
      isOpening: nState.isOpen && nState.isTransitioning,
      isTransitioning: nState.isTransitioning
    };
    setClassNameFlags(_.mapKeys(visState, (v, key) => `--${_.kebabCase(key)}`));
  });
  const state = useMemoizeValue<CollapseState>(getState());

  /* tracks container height */
  const [innerRef, innerDimens] = useMeasure();
  /* determines actual open height */
  const pHeight = useRef(0);
  const openHeight = useValueMemo(() => {
    // if (state.isTransitioning) return pHeight.current;
    const custOpenHeight = _.isFunction(_openHeight)
      ? _openHeight(innerDimens?.height)
      : _openHeight;
    let nHeight = custOpenHeight ?? innerDimens?.height;
    if (nHeight && maxHeight) {
      nHeight = Math.min(maxHeight, nHeight);
    }
    /* NOTE: height is only 'auto' for the first render */
    pHeight.current = nHeight;
    return nHeight || 'auto';
  }, [_.omitBy({ _openHeight, maxHeight }, _.isFunction), innerDimens?.height]);

  /* watch for isOpen prop change and react when in controlled mode */
  useDidUpdate(() => {
    if (isCtrl) {
      /* if collapse is already transitioning, callback won't fire, so check it first */
      if (!getState().isTransitioning) setState({ hasPendingTransition: true });
      setState({ isOpen: isOpenCtrl });
    }
  }, [isCtrl, isOpenCtrl]);

  /* *** CONTEXT/HANDLE *** */
  const ctxValue = useValueMemo<CollapseContextValue>(
    () => ({
      close: (cb) => setState({ isOpen: false, hasPendingTransition: true }, cb),
      getState,
      get state() {
        return getState();
      },
      open: (cb) => setState({ isOpen: true, hasPendingTransition: true }, cb),
      toggle: (cb) => setState((prev) => ({ isOpen: !prev.isOpen, hasPendingTransition: true }), cb)
    }),
    []
  );

  useImperativeHandle(ref, (): CollapseContextValue => ctxValue, []);

  /* *** SPRING *** */
  const onStart = useCallback(() => {
    setState({ isTransitioning: true, hasPendingTransition: false, hasOpened: true });
    onToggle?.(getState());
    onTransitionStart?.(getState());
  }, []);
  const onRest = useCallback(() => {
    setState({ isTransitioning: false });
    onTransitionEnd?.(getState());
  }, []);

  const [style] = useSpring(
    () => ({
      config: springConfigType
        ? config[springConfigType]
        : {
            clamp: true,
            duration,
            easing: (_.isString(easing)
              ? easings[easing as keyof typeof easings]
              : easings.easeInOutCubic) as EasingFunction,
            ...springConfig
          },
      delay: delay,
      /* prevents react-spring from complaining about NaN height */
      ...(state.isOpen && !_.isFinite(openHeight)
        ? {}
        : { height: state.isOpen ? openHeight : closedHeight }),
      onRest,
      onStart
    }),
    [state.isOpen, easing, openHeight, closedHeight, springConfig]
  );

  return (
    <animated.div
      style={{ height: style.height }}
      className={cn('collapse-wpr', className, classNameFlags)}
      data-testid='Collapse'
    >
      <CollapseContext.Provider value={ctxValue}>
        <div
          ref={innerRef as LegacyRef<HTMLDivElement>}
          className={cn('collapse-ctr', containerClassName)}
        >
          {(lazyLoad && !state.hasOpened) ||
          (destroyOnClose && !state.isOpen && !state.isTransitioning && !state.hasPendingTransition)
            ? null
            : children}
        </div>
      </CollapseContext.Provider>
    </animated.div>
  );
});
