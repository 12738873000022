import { SetterParams } from 'shared/types/grid';
import { handleAddDispatch, handleNewValueObjects } from './util';
export const dropdownValueSetter = (
  params: SetterParams,
  addRow: any,
  key = '',
  colOverwrite = '',
  getAllRows: any,
  uniqueFieldPath?: string
) => {
  const { newValue, oldValue, column, data } = params;
  const newData = { ...data };
  const col = colOverwrite ? colOverwrite : column.getColId();

  const newUniqueValue = handleNewValueObjects(newValue, key);

  if (uniqueFieldPath) {
    const oldUniqueValue = uniqueFieldPath.split('.').reduce((o, i) => {
      if (typeof o === 'object' && i in o) {
        return o[i];
      }
    }, data);

    if (newUniqueValue === oldUniqueValue) {
      return;
    }
  } else if (newUniqueValue === oldValue) {
    return;
  }

  newData[col] = newUniqueValue;
  return handleAddDispatch(newData, addRow, getAllRows);
};
