import { DataTemplateType } from './DataTemplateType';

export interface UploadData {
  file: File;
  type: DataTemplateType;
  id: string;
}

export interface IUpload extends File {
  id: string;
  status: UploadStatus;
  type: DataTemplateType;
}

export enum UploadStatus {
  Uploading = 'Uploading',
  Complete = 'Complete',
  Failed = 'Failed'
}

export class ChunkedFile {
  private chunkSize = 1024 * 1023 * 5; // < 5 MB
  readonly file: File;
  readonly name: string;
  startChunkBytes: number;
  endChunkBytes: number;
  size: number;
  chunkCount: number;
  shouldChunk: boolean;

  constructor(file: File, name: string) {
    this.file = file;
    this.name = name;

    this.size = file.size;

    this.chunkCount =
      this.size % this.chunkSize === 0
        ? this.size / this.chunkSize
        : Math.floor(this.size / this.chunkSize) + 1;

    this.startChunkBytes = 0;
    this.endChunkBytes = this.chunkSize > this.file.size ? this.file.size : this.chunkSize;

    this.shouldChunk = this.chunkCount > 1;
  }

  public createChunks(): Blob[] {
    const chunks: Blob[] = [];

    for (let chunkIndex = 0; chunkIndex < this.chunkCount; chunkIndex++) {
      chunks.push(this.blob);

      const remainingBytes = this.file.size - this.endChunkBytes;

      if (remainingBytes < this.chunkSize) {
        this.startChunkBytes = this.endChunkBytes;
        this.endChunkBytes = this.startChunkBytes + remainingBytes;
      } else {
        this.startChunkBytes = this.endChunkBytes;
        this.endChunkBytes = this.startChunkBytes + this.chunkSize;
      }
    }

    return chunks;
  }

  get blob(): Blob {
    return this.file.slice(this.startChunkBytes, this.endChunkBytes);
  }

  get header(): string {
    const byteCalculation = (this.startChunkBytes - this.endChunkBytes) / this.file.size;
    return `bytes ${byteCalculation}`;
  }
}
