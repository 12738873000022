import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { ReactNode, useState } from 'react';
import { StyledLoader } from './OxLoadingV2.styles';

type LoaderProps = {
  title?: ReactNode;
  pageLevel?: boolean;
};

export const OxLoadingV2 = (props: LoaderProps) => {
  const { title = 'Loading...', pageLevel } = props;
  const [animationLoaded, setAnimationLoaded] = useState(false);

  return (
    <StyledLoader data-testid='OxLoading' className={pageLevel ? 'page-level' : 'app-level'}>
      <div className='loading-container'>
        <div className='loading-lottie'>
          <Player
            autoplay
            loop
            src='https://lottie.host/3f195790-a465-42bd-9144-a1d8dadd2411/zRrojpHiqE.json'
            style={{ height: '140px', width: '140px' }}
            onEvent={(event) => {
              if (event === 'load') setAnimationLoaded(true);
            }}
          >
            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
        </div>
        {animationLoaded && <div className='loading-title'>{title}</div>}
      </div>
    </StyledLoader>
  );
};
