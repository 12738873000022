import { titleCaseWord } from './string.helper';

export const upperCaseFirstLetters = (firstName: string[]) => {
  const transformedName = firstName.map((word) => titleCaseWord(word));
  return transformedName.join(' ');
};

export const getFullName = (firstName: string, lastName: string) => {
  if (!firstName) return;
  const firstNameParts = firstName.split(' ');
  const first =
    firstNameParts.length > 1 ? upperCaseFirstLetters(firstNameParts) : titleCaseWord(firstName);
  const last = titleCaseWord(lastName);
  return `${first} ${last}`;
};
