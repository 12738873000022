import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IProductRepository } from 'shared/api/interfaces/IProductRepository';
import { ICounterparty } from 'shared/models/Counterparty';
import { IBaseThunk } from './base.thunks';

export class ProductThunks implements IBaseThunk<any> {
  private productRepository!: IProductRepository;

  constructor(_productRepository: IProductRepository) {
    this.productRepository = _productRepository;

    if (this.productRepository === null) {
      throw new Error('productRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'product';

  add(): AsyncThunk<AxiosResponse<any, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<
    AxiosResponse<any, any>,
    { id: string; item: any },
    Record<string, unknown>
  > {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<any, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getAll(): AsyncThunk<AxiosResponse<ICounterparty[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchProducts`;

    return createAsyncThunk(action, () => this.productRepository.getAll());
  }

  getAllEnabled(): AsyncThunk<AxiosResponse<ICounterparty[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchEnabledProducts`;

    return createAsyncThunk(action, () => this.productRepository.getAllEnabled());
  }
}
