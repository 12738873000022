import { StyleGetter } from '@core/theme/typings';

const gtrVarName = '--toolbar-gutter-size';
const gtrVar = 'var(--toolbar-gutter-size)';
function multiplyGtrVar(amt: string | number) {
  return `calc(${gtrVar} * ${amt})`;
}
export const css: StyleGetter = (theme) => {
  const {
    colors: { component },
    darken,
    gutter: gtr,
    toGtr
  } = theme;

  return {
    wpr: {
      [gtrVarName]: gtr,
      display: 'flex',
      alignItems: 'center',

      // Divider
      '.divider': {
        alignSelf: 'center',
        width: 1.5,
        height: 22,
        borderRadius: 2,
        background: darken(component.offset6, 15),

        '&.--size-large': { margin: toGtr(0, multiplyGtrVar(3), 0, multiplyGtrVar(2)) }
      },

      '.spacer': {
        height: 0,
        width: multiplyGtrVar(2),

        '&.--size-large': { width: multiplyGtrVar(4) },
        '&.--size-small': { width: multiplyGtrVar(0) }
      },

      '.divider, .spacer': {
        '&:last-child': { display: 'none' }
      },

      '.core-switch': { marginTop: 0 },
      '.core-btn': {
        minWidth: 'auto',
        padding: toGtr(gtrVar, multiplyGtrVar(1.5)),
        minHeight: 34 /* NOTE: should match height of Selects */,
        '&.core-dropdown-trigger-btn': {
          width: 28,
          minHeight: 28,
          height: 28,
          padding: 0
        }
      },

      '> :not(:last-child)': {
        marginRight: 'var(--toolbar-gutter-size)'
      },

      '&.--force-show-last-item': {
        '.divider:last-child, .spacer:last-child': {
          display: 'block'
        }
      },

      '&.toolbar-layout-compact': {
        [gtrVarName]: gtr / 2,

        '.core-btn.core-dropdown-trigger-btn': {
          borderRadius: gtrVar,
          width: 16
        }
      },
      '&.toolbar-layout-wide': {
        [gtrVarName]: gtr * 2,

        /* overwrite width of large divider on wide layout. It's... too wide. */
        '.divider.--size-large': { margin: toGtr(0, multiplyGtrVar(2), 0, multiplyGtrVar(1)) }
      }
    }
  };
};
