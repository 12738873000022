import { ButtonSize, ButtonType, OxButton, OxSearchV2, SearchSize } from '@core/components';
import { ColDef, SortDirection } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { cn } from '@core/util';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import './ComplicatedNomSelector.scss';

type ComplicatedSelectorParams = {
  onSelect: (v: any) => void; //Used for setting the useState value
  options: any[]; //Array of objects to appear in the table that are available to be selected
  fields: (string | ColDef)[]; //The columns that show up in the table. These are properties on the objects you used in your options
  close: (propagate?: boolean) => void; //The thing that closes/hides the whole component. The boolean is to tell aggrid whether it should move to the next cell when this is closed
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  fieldValue?: string;
  onSelectAll?: () => void;
  onClearAll?: () => void;
  clearAllText?: string;
  isWide: boolean;
};

/**
 * GSS Features:
 * Would appear the whole object is presented as a row in the table
 * Every column appears to be filterable
 * However, typing into the select box for type ahead only filters on one column
 * OnClick performs the selection and that one thing is returned
 */
/**
 * A more complex Selector for AgGrid. Instead of just a select box, this opens into a select box and table. The table columns are filterable and the search box will filter the entire table as well
 * @param ComplicatedSelectorParams Props
 * @returns
 */
const ComplicatedNomSelector = ({
  onSelect,
  options = [],
  fields = [],
  close,
  searchValue,
  setSearchValue,
  fieldValue,
  onSelectAll,
  onClearAll,
  clearAllText,
  isWide
}: ComplicatedSelectorParams) => {
  const columns = fields.map((field) => {
    if (field === 'name' || field === 'displayName') {
      return {
        field,
        filter: 'agTextColumnFilter',
        sortable: true,
        initialSort: 'asc' as SortDirection
      };
    } else if (typeof field === 'string') {
      return { field, filter: 'agTextColumnFilter', sortable: true };
    } else {
      if (field.field === 'name' || field.field === 'displayName') {
        return {
          ...field,
          sortable: true,
          initialSort: 'asc' as SortDirection
        };
      }
      return field;
    }
  });

  const grid = useRef<any>(null);

  useEffect(() => {
    if (grid?.current) {
      // TODO: did all this so when you click out of the selector it closes.
      // This is all still pretty shaky
      const input: HTMLElement = document.querySelector(
        '[data-testid="OxDropdownSearchInput"]'
      ) as HTMLElement;

      if (input) {
        input.addEventListener('keydown', handleSearchInputKeyDown);
        input.focus();
      }
    }
    return () => {
      // Remove the event listener when the component unmounts
      const input: HTMLElement = document.querySelector(
        '[data-testid="OxDropdownSearchInput"]'
      ) as HTMLElement;

      if (input) {
        input.removeEventListener('keydown', handleSearchInputKeyDown);
      }
    };
  }, [grid]);

  const handleSearchInputKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();

      // Get the ag-Grid API
      if (grid.current) {
        const agGridApi = grid.current.api;

        // scrolls to the first column
        const firstCol = agGridApi.getColumns()[0];
        agGridApi.ensureColumnVisible(firstCol);

        // sets focus into the first grid cell
        agGridApi.setFocusedCell(0, firstCol);
      }
    }
  };

  useEffect(() => {
    if (grid.current?.api) {
      grid.current?.api.setGridOption('quickFilterText', searchValue);
    }
  }, [searchValue, grid]);

  return (
    <div
      className={cn('complicated-nom-selector', { 'wide-selector': isWide })}
      onBlur={(e) => {
        // Ignore blur when used outside of a grid
        if (onClearAll || onSelectAll) {
          return;
        }

        // Most of the time catches when a user has clicked outside the select. Messes up when changing column settings
        if (e.relatedTarget === null) close(true);
      }}
    >
      <div className='search-box'>
        <OxSearchV2
          searchText={searchValue}
          searchSize={SearchSize.compact}
          setSearchValue={setSearchValue}
          uniqueDataId='OxDropdownSearchInput'
        />
        {onClearAll && (
          <OxButton
            className='my-2'
            buttonType={ButtonType.secondary}
            buttonSize={ButtonSize.compact}
            text={`${clearAllText ?? 'Clear All'}`}
            onClick={onClearAll}
          />
        )}
        {onSelectAll && (
          <OxButton
            className='my-2 ml-2'
            buttonType={ButtonType.secondary}
            buttonSize={ButtonSize.compact}
            text='Select All'
            onClick={onSelectAll}
          />
        )}
      </div>
      <div className='teh-grid' data-testid='cellEditGrid'>
        <AgGridReact
          ref={grid}
          columnDefs={columns}
          rowHeight={25}
          headerHeight={30}
          rowData={options}
          rowSelection='single'
          quickFilterText={searchValue}
          onSelectionChanged={({ api }) => {
            if (fieldValue) {
              onSelect(api.getSelectedRows()[0][fieldValue]);
            } else {
              onSelect(api.getSelectedRows()[0].id);
            }
          }}
          onFirstDataRendered={(event) => {
            if (columns.length < 4) {
              event.api.sizeColumnsToFit();
            }
          }}
          defaultColDef={{
            width: 150,
            resizable: true
          }}
        />
      </div>
    </div>
  );
};

export default ComplicatedNomSelector;
