import { TextSize, TextWeight } from '@core/components';

interface TypographyProps {
  size: TextSize;
  weight: TextWeight;
}

export interface ButtonSizeStyle {
  sizeClasses: string;
  iconWidth: number;
  iconHeight: number;
  buttonHeight: string;
  typography: TypographyProps;
}

export const defaultButtonSizeStyle: ButtonSizeStyle = {
  sizeClasses: 'pt-[7px] pr-2 pb-[5px] pl-2',
  iconWidth: 18,
  iconHeight: 30,
  typography: { size: TextSize.Medium, weight: TextWeight.Regular },
  buttonHeight: '38px'
};
