import { getGlobalConfig, GlobalConfig } from '@core/global-config';
import { authToken } from '@core/redux-actions';
import { StatusCode, TokenRequestBody } from '@core/typings';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  failOnBadRequest?: boolean;
}

function headers(): Readonly<Record<string, string | boolean>> {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': window.location.origin,
    'X-Requested-With': 'XMLHttpRequest'
  };
}
export abstract class Http {
  private instance: Promise<AxiosInstance> | null = null;

  public get http(): Promise<AxiosInstance> {
    return this.instance || (this.instance = this.initHttp());
  }

  private async initHttp() {
    const config = await getGlobalConfig();
    const http = axios.create({
      baseURL: config.backendUrl.replace('/api', ''),
      headers: headers()
    });

    http.interceptors.response.use(
      (response) => response,
      ({ response, config }) => {
        const failOnBadRequest = config?.failOnBadRequest;
        return this.handleError(response, failOnBadRequest);
      }
    );

    return http;
  }

  private async addToken(axiosConfig?: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const config = await getGlobalConfig();
    return {
      ...axiosConfig,
      headers: {
        /* NOTE: don't overwrite pre-existing Authorization header */
        Authorization: `Bearer ${authToken(config)?.id_token}`,
        ...axiosConfig?.headers
      }
    };
  }

  private async refreshTokens() {
    const config = await getGlobalConfig();
    const url: string = config.oauth.authority + 'oauth/token';

    const requestBody: TokenRequestBody = {
      client_id: config.oauth.clientId,
      grant_type: 'refresh_token',
      refresh_token: authToken(config)?.refresh_token
    };

    const requestHeaders = {
      headers: {
        'content-type': 'application/json'
      }
    };

    return await axios
      .post(url, requestBody, requestHeaders)
      .catch(() => {
        return false;
      })
      .finally(() => {
        return true;
      });
  }

  private async retryRequest(config: GlobalConfig, error: any) {
    const originalRequest = error.config;
    originalRequest.headers['Authorization'] = `Bearer ${authToken(config)?.id_token}`;

    return (await this.http)(originalRequest);
  }

  async request<T = any, R = AxiosResponse<T>>(config: ExtendedAxiosRequestConfig): Promise<R> {
    const configWithToken = await this.addToken(config);
    return (await this.http).request<T, R>(configWithToken);
  }

  async get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    const configWithToken = await this.addToken(config);
    return (await this.http).get<T, R>(url, configWithToken);
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const configWithToken = await this.addToken(config);
    return (await this.http).post<T, R>(url, data, configWithToken);
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const configWithToken = await this.addToken(config);
    return (await this.http).put<T, R>(url, data, configWithToken);
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const configWithToken = await this.addToken(config);
    return (await this.http).delete<T, R>(url, configWithToken);
  }

  protected async handleError(error: any, failOnBadRequest = false) {
    const config = await getGlobalConfig();
    const status = error?.status;

    switch (status) {
      case StatusCode.InternalServerError: {
        if (error.data === 'jwt expired' || error.data === 'jwt malformed') {
          const refreshed = await this.refreshTokens();
          if (refreshed) {
            return this.retryRequest(config, error);
          } else {
            // const redirect = `${location.origin}`;
            // manager.signinRedirect({
            //   state: {
            //     url: redirect
            //   },
            //   extraQueryParams: {
            //     max_age: 0
            //   }
            // });
          }
        }
        break;
      }
      case StatusCode.Forbidden: {
        break;
      }
      case StatusCode.Unauthorized: {
        const refreshed = await this.refreshTokens();
        if (refreshed) {
          return this.retryRequest(config, error);
        }
        break;
      }
      case StatusCode.TooManyRequests: {
        break;
      }
      case StatusCode.BadRequest: {
        if (!failOnBadRequest) {
          Promise.reject(error);
          return error;
        }
      }
    }

    return Promise.reject(error);
  }
}
