import styled from 'styled-components';

export const StyledHeader = styled.header`
  color: ${(props) => props.theme.fontColor} !important;
  background-color: ${(props) => props.theme.headerBackground} !important;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition-type: opacity;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0, 0, 0.63, -0.37);
`;

export const StyledHeaderSupportButton = styled.a`
  .support-icon {
    color: ${(props) => props.theme.contrast_65};
  }
`;

export const StyledHeaderConfigButton = styled.span`
  .config-icon {
    fill: ${(props) => props.theme.contrast_65};
  }
`;
