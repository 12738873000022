import { GlobalConfig } from '@core/global-config';
import { v4 } from 'uuid';
import { ReconnectingWebSocket } from './ReconnectingWebsocket';

interface OxSubscriber {
  /**
   * Invoked for every message received
   */
  onMessage: (message: string) => void;
  /**
   * Invoked whenever the web socket connection is established
   */
  onReconnect: () => void;
}

export class OxSocket {
  private static instance: ReconnectingWebSocket | null = null;
  static invalidJwt = false;

  static start(config: GlobalConfig, endpoint = ''): ReconnectingWebSocket {
    if (OxSocket.instance) {
      OxSocket.close();
    }
    return (OxSocket.instance = OxSocket.init(config, endpoint));
  }

  static init(config: GlobalConfig, endpoint: string): ReconnectingWebSocket {
    const socketUrl = config.webSocketUrl;
    const endpointUrl = `${socketUrl}` + (endpoint.length ? `/${endpoint}` : '');

    const socket = new ReconnectingWebSocket(async () => new WebSocket(endpointUrl), {
      maxBackoff: 10,
      minStableTime: 5,
      onOpen: async () => {
        const key = sessionStorage.key(0);
        if (key) {
          const storageItem: any = sessionStorage.getItem(key);
          const parsedStorageItem = JSON.parse(storageItem);
          const jwt = parsedStorageItem['id_token'];

          socket.send(
            JSON.stringify({
              event: 'auth',
              data: jwt
            })
          );
        }

        OxSocket.publishReconnect();
      },
      onMessage: async (message: string) => {
        if (message.toLowerCase() === 'invalid jwt') {
          OxSocket.invalidJwt = true;
        } else if (message.toLowerCase() === 'jwt authenticated.') {
          OxSocket.invalidJwt = false;
        } else if (message === 'Listeners restarting') {
          //clear your deal contract data
        }

        OxSocket.publishMessage(message);
      }
    });

    socket.start();
    return socket;
  }

  static subscribers = new Map<string, OxSubscriber>();

  static publishReconnect = (): void => {
    OxSocket.subscribers.forEach((subscriber) => {
      try {
        subscriber.onReconnect();
      } catch (error) {
        console.warn(`Caught error while publishing 'onReconnect': ${error}`);
      }
    });
  };

  static publishMessage = (message: string): void => {
    OxSocket.subscribers.forEach((subscriber) => {
      try {
        subscriber.onMessage(message);
      } catch (error) {
        console.warn(`Caught error while publishing message '${message}': ${error}`);
      }
    });
  };

  static subscribe = (
    onMessage: OxSubscriber['onMessage'],
    onReconnect?: OxSubscriber['onReconnect']
  ) => {
    const subscriber: OxSubscriber = {
      onMessage,
      // eslint-disable-next-line
      onReconnect: onReconnect ?? (() => {})
    };

    const id = v4();
    OxSocket.subscribers.set(id, subscriber as OxSubscriber);

    return {
      unsubscribe: () => {
        OxSocket.subscribers.delete(id);
      }
    };
  };

  static close = (): void => {
    OxSocket.instance?.stop();
  };
}
