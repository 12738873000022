import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import routeStrings from 'Containers/Routing/routeStrings';
import { User } from 'oidc-client-ts';
import { AuthThunks } from 'redux/thunks/auth.thunks';
import { IAuthRepository } from 'shared/api/interfaces/IAuthRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';

type InitialStateType = {
  isAuthenticated: boolean;
  isOnboarding: boolean;
  resetPasswordMessage: string;
  user: User | null;
};

export const initialState: InitialStateType = {
  isAuthenticated: false,
  isOnboarding: false,
  resetPasswordMessage: 'Please change your password',
  user: null
};

export interface ChangePasswordData {
  client_id: string | undefined;
  email: string | undefined;
  connection: string;
}

const authRepository = container.get<IAuthRepository>(SERVICE_KEYS.AUTH_REPOSITORY);
const authThunks = new AuthThunks(authRepository);

export const createChangePasswordEmail = authThunks.changePassword();

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    changePassword(state, action) {
      state.resetPasswordMessage = action.payload.message;
    },
    loginSuccess(state) {
      state.isAuthenticated = true;
    },
    loginFailure(state) {
      state.isAuthenticated = false;
    },
    setIsOnboarding(state, action) {
      state.isOnboarding = action.payload.isOnboarding;
    },
    getIsOnboarding(state) {
      const { pathname } = location;
      state.isOnboarding =
        pathname === routeStrings.productSelection ||
        pathname === '/' ||
        pathname === routeStrings.login ||
        pathname === routeStrings.changePassword;
    },
    setUser(state, action) {
      state.user = action.payload.user;
    },
    getUser(state) {
      state.user;
    }
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      createChangePasswordEmail.fulfilled,
      (state: any, { payload }: PayloadAction<string[]>) => {
        state.resetPasswordMessage = payload;
      }
    );
  }
});

export const {
  changePassword,
  loginSuccess,
  loginFailure,
  setIsOnboarding,
  getIsOnboarding,
  setUser,
  getUser
} = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
