import { GlobalConfig } from './GlobalConfig';

let config: GlobalConfig | undefined;

export const getGlobalConfig = async () => {
  if (!config) {
    config = await fetch(`/config/${location.hostname.split('.')[0]}.json`).then(
      (response) => response.json() as Promise<GlobalConfig>
    );
  }
  return config as GlobalConfig;
};
