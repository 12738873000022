import _ from 'lodash';

import deepEq from 'fast-deep-equal';
import { flattenCss } from './flattenCss';
import { Css, CSSOMRule, ICssOmRule, ICSSOMSheet, RealNanoRenderer } from './virtualCssOm.types';

let counter = 0;
export function virtualCssOm(renderer: RealNanoRenderer) {
  if (!renderer.client) return;
  const sheetId = `core-vcss-${(counter++).toString(36)}`;
  document.head.appendChild((renderer.msh = document.createElement('style')));
  let el: HTMLStyleElement;
  document.head.appendChild((el = document.createElement('style')));
  // console.log(el);
  el.setAttribute('type', 'text/css');
  el.setAttribute('id', sheetId);

  class CssOmRule implements ICssOmRule {
    _Rule: CSSOMRule;
    decl: Css = {};
    constructor(selector: string, parent: string, initialDecl?: Css) {
      this._Rule = this.createRule(renderer.selector(selector, parent), parent);
      if (initialDecl) this.diff(initialDecl);
    }

    createRule = function (selector: string, prelude?: string) {
      let rawCss = selector + '{}';
      if (prelude) rawCss = prelude + '{' + rawCss + '}';

      const sheet = prelude ? renderer.msh.sheet : el.sheet;
      const index = sheet!.insertRule(rawCss, sheet!.cssRules.length);
      const rule: any = (sheet!.cssRules || sheet!.rules)[index] as CSSOMRule;
      rule.index = index;

      // Keep track of `index` where rule was inserted in the sheet. This is
      // needed for rule deletion.

      if (prelude) {
        // If rule has media query (it has prelude), move style (CSSStyleDeclaration)
        // object to the "top" to normalize it with a rule without the media
        // query, so that both rules have `.style` property available.
        const selectorRule = rule.cssRules[0];
        rule.style = selectorRule.style;
        rule.styleMap = selectorRule.styleMap;
      }

      return rule;
    };

    diff = (newDecl: Css) => {
      // console.log({ newDecl });
      const oldDecl = this.decl;

      _.each(_.keys(oldDecl), (property) => {
        if (!_.has(newDecl, [property])) this._Rule.style.removeProperty(property);
      });

      _.each(_.keys(newDecl), (property) => {
        if (newDecl[property] !== oldDecl[property]) {
          // const kProp = renderer.kebab(property);
          // console.log({ oldDecl, newDecl, property, kProp, style });
          const declarations = renderer.decl(property, newDecl[property]).split(';');
          _.each(_.compact(declarations), (declaration) => {
            const [key, value] = declaration.split(':');
            const isImportant = _.endsWith(value, '!important');
            const setArgs: [string, string, string?] = [
              renderer.kebab(key),
              isImportant ? _.trim(_.replace(value, '!important', '')) : value
            ];
            if (isImportant) setArgs.push('important');
            // console.log(setArgs);

            this._Rule.style.setProperty(...setArgs);
            // console.log({ orig: newDecl[property], value, st: this.rule.style });
          });
        }
      });
      this.decl = newDecl;
    };

    remove = () => {
      const sh = this._Rule.parentStyleSheet;
      const rules = sh?.cssRules || sh?.rules || [];
      let maxIndex = _.max([this._Rule.index, rules.length - 1]) || -1;
      while (maxIndex >= 0) {
        if (rules[maxIndex] === this._Rule) {
          sh?.deleteRule(maxIndex);
          break;
        }
        maxIndex--;
      }
    };
  }

  class CSSOMSheet implements ICSSOMSheet {
    private _className: string;
    private _block: Record<string, Record<string, Record<string, CssOmRule>>> = {};
    private _valueCache: Record<string, Css> = {};

    constructor(className: string) {
      this._className = `.${className}`;
    }

    diff = (css: Css, blockKey: string = '__single_main') => {
      const pCss = this._valueCache[blockKey] || {};
      /* if no change is detected in CSS, don't run updates */
      if (pCss && deepEq(pCss, css)) return;
      /* store new value on cache */
      this._valueCache[blockKey] = css;

      const newTree = flattenCss(
        css,
        `${this._className}${blockKey === '__single_main' ? '' : '-' + blockKey}`
      );
      // const nBlock = { ..._.cloneDeep(newTree) };

      const oldTree = this._block[blockKey] || {};

      // Remove media queries not present in new tree.
      _.each(oldTree, (rules, key) => {
        if (!_.has(newTree, [key])) _.each(oldTree[key], (rule) => rule.remove());
      });
      _.each(newTree, (rules, key) => {
        if (!_.has(oldTree, [key])) {
          _.each(newTree[key], (rule, selector) =>
            _.set(newTree, [key, selector], new CssOmRule(selector, key, rule))
          );
        } else {
          // Old tree already has rules with this media query.
          const oldRules = _.get(oldTree, [key]);
          const newRules = _.get(newTree, [key]);

          // Remove rules not present in new tree.
          _.each(oldRules, (rule, selector) => {
            if (!_.get(newRules, [selector])) rule.remove();
          });
          // Apply new rules.
          _.each(newRules, (rule, selector) => {
            let nRule = _.get(oldRules, [selector]);
            if (!nRule) nRule = new CssOmRule(selector, key, rule);
            else nRule.diff(rule);
            _.set(newTree, [key, selector], nRule);
          });
        }
      });
      const _block = this._block;
      this._block[blockKey] = newTree as (typeof _block)[string];
    };

    diffBlock = (newBlock: Record<string, Css>) => {
      _.each(newBlock, (block, key) => this.diff(block, key));
    };
  }

  renderer.CSSOMSheet = CSSOMSheet;
}
