import './FileTransferStatusWidget.style.scss';

import { ConfirmModal, ConfirmModalType } from '@core/components';
import { useDidUpdate, useSetState } from '@core/hooks';
import {
  CheckmarkCircleFilledSvg,
  ChevronDownSvg,
  CloseCircleFilledSvg,
  CloseOutlineSvg,
  FileCsvFillSvg
} from '@core/images';
import { useAppSelector } from '@core/redux';
import { IUpload, UploadStatus } from '@core/typings';
import { cn } from '@core/util';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface TransferStatusProps {
  uploadActions: {
    cancelUploadFile: any;
    clearUploadFiles: any;
  };
}

interface TransferFileData {
  id: string;
  name: string;
  status: UploadStatus;
}
interface CountState {
  complete: number;
  failed: number;
  uploading: number;
}
interface State {
  label: string;
  counts: CountState;
  isVisible: boolean;
  isCollapsed: boolean;
  isModalDisplayed: boolean;
}

interface CancelModalProps {
  uploadingCount: number;
  handleClear: () => void;
  setIsModalDisplayed: (v: boolean) => void;
}

function CancelModal(props: CancelModalProps) {
  const { uploadingCount, handleClear, setIsModalDisplayed } = props;
  const { t } = useTranslation();
  return (
    <ConfirmModal
      modalType={ConfirmModalType.confirm}
      headerText={
        uploadingCount > 1
          ? t('oxModal.confirmCancel.titlePlural')
          : t('oxModal.confirmCancel.titleSingular')
      }
      bodyText={
        uploadingCount > 1
          ? t('oxModal.confirmCancel.bodyPlural')
          : t('oxModal.confirmCancel.bodySingular')
      }
      buttonCancelText={
        uploadingCount > 1
          ? t('oxModal.confirmCancel.continuePlural')
          : t('oxModal.confirmCancel.continueSingular')
      }
      buttonConfirmText={
        uploadingCount > 1
          ? t('oxModal.confirmCancel.cancelPlural')
          : t('oxModal.confirmCancel.cancelSingular')
      }
      buttonConfirmHandler={handleClear}
      displayModalHandler={setIsModalDisplayed as Dispatch<SetStateAction<boolean>>}
    />
  );
}

const getNumOfUploadsByStatus = (uploadStatus: UploadStatus, uploads: IUpload[]) => {
  return uploads.filter(({ status }) => status === uploadStatus).length;
};

export function FileTransferStatusWidget(props: TransferStatusProps): JSX.Element {
  const {
    uploadActions: { clearUploadFiles, cancelUploadFile }
  } = props;
  const { t } = useTranslation();
  const getText = useCallback((path: string, ...args: any[]): string => {
    return t(`fileTransferStatusWidget.${path}`, ...args);
  }, []);
  const clearLabel = useMemo(() => getText('clearLabel'), []);
  const dispatch = useDispatch();
  const { uploads = [] } = useAppSelector((state: any) => state.upload);

  const getCounts = useCallback(
    (files: IUpload[]): CountState => ({
      complete: getNumOfUploadsByStatus(UploadStatus.Complete, files),
      failed: getNumOfUploadsByStatus(UploadStatus.Failed, files),
      uploading: getNumOfUploadsByStatus(UploadStatus.Uploading, files)
    }),
    []
  );

  const getLabel = useCallback((counts: CountState): string => {
    if (counts.uploading > 1) return getText('inProgressPlural', { fileCount: counts.uploading });
    if (counts.uploading === 1) return getText('inProgressSingular');
    if (counts.failed > 1) return getText('failedPlural', { fileCount: counts.failed });
    if (counts.failed === 1) return getText('failedSingular');
    if (counts.complete > 1) return getText('completePlural', { fileCount: counts.complete });
    if (counts.complete === 1) return getText('completeSingular');

    return getText('idle');
  }, []);

  const initCounts = useMemo<CountState>(() => getCounts(uploads), []);
  const initLabel = useMemo<string>(() => getLabel(initCounts), []);
  const [state, setState] = useSetState<State>({
    counts: initCounts,
    label: initLabel,
    isModalDisplayed: false,
    isCollapsed: true,
    isVisible: !!uploads.length
  });
  const { counts, label, isModalDisplayed, isCollapsed, isVisible } = state;

  useDidUpdate(() => {
    if (uploads.length) {
      const nCounts = getCounts(uploads);
      const shouldClear = nCounts.complete === uploads.length;
      /* prettier-ignore */
      setState({
        counts: nCounts,
        label: getLabel(nCounts),
        isVisible: true,
        isCollapsed: !shouldClear
      });
    } else if (isVisible) setState({ isVisible: false });
  }, [uploads]);

  const handleCancel = useCallback((id: string) => {
    dispatch(cancelUploadFile({ id }));
  }, []);

  const handleClear = useCallback(() => {
    setState({ isVisible: false });
    dispatch(clearUploadFiles());
  }, []);

  const toggleCollapsed = useCallback(() => {
    setState({ isCollapsed: !isCollapsed });
  }, [isCollapsed]);

  const renderListItem = useCallback((file: TransferFileData) => {
    return (
      <div
        title={file.status}
        data-testid={file.id}
        key={file.id}
        className={cn('list-itm-wpr', `status--${file.status?.toLowerCase()}`)}
      >
        <div className='file-label-wpr'>
          <FileCsvFillSvg className='label-icon' />
          <div className='label-text'>{file.name}</div>
        </div>
        <div className='status-icon-wpr'>
          {file.status === UploadStatus.Complete ? (
            <CheckmarkCircleFilledSvg title={file.status} />
          ) : file.status === UploadStatus.Failed ? (
            <CloseCircleFilledSvg title={file.status} />
          ) : (
            <div
              className='status-icon-ctr'
              onClick={() => {
                handleCancel(file.id);
              }}
            >
              <div className='cancel-text'>{t('general.cancel')}</div>
              <CloseOutlineSvg id='loading-spinner' title={file.status} />
            </div>
          )}
        </div>
      </div>
    );
  }, []);

  if (!isVisible) return <></>;
  return (
    <>
      {isModalDisplayed && (
        <CancelModal
          {...{ handleClear }}
          uploadingCount={counts.uploading}
          setIsModalDisplayed={(v: boolean) => {
            setState({ isModalDisplayed: v });
          }}
        />
      )}
      <div data-testid='FileTransferStatusWidget' className='file-transfer-status-widget'>
        <div className={cn('header-wpr', { 'widget-collapsed': isCollapsed })}>
          <div className='label-wpr'>
            <div className='label'>{label}</div>
          </div>
          <div className='buttons-wpr'>
            <div
              className='clear-btn-wpr'
              onClick={() => {
                counts.uploading ? setState({ isModalDisplayed: true }) : handleClear();
              }}
            >
              <div className='clear-btn-label'>{clearLabel}</div>
            </div>
            <div className='collapse-btn-wpr' onClick={toggleCollapsed}>
              <ChevronDownSvg
                className='collapse-btn'
                title={t(`general.${isCollapsed ? 'maximize' : 'minimize'}`)}
              />
            </div>
          </div>
        </div>
        <div className={cn('list-wpr', { 'widget-collapsed': isCollapsed })}>
          {uploads.map(renderListItem)}
        </div>
      </div>
    </>
  );
}
