/* NOTE:
 * this hook is somewhat expensive; use only when dimensions cannot be
 * ascertained by other means and must be tracked continuously.
 */
import _ from 'lodash';
import { LegacyRef, useLayoutEffect, useMemo, useState } from 'react';
import { StateMediator, useMediatedState } from './useMediatedState';

export type UseMeasureDimens = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;
export type UseMeasureRef<E extends Element = Element> = (element: E) => void;
export type UseMeasureResult<E extends Element = HTMLDivElement> = [LegacyRef<E>, UseMeasureDimens];

const defaultState: UseMeasureDimens = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
};

const getMediator = (
  round: boolean | number | undefined = false
): StateMediator<UseMeasureDimens> =>
  round === false
    ? (v) => v
    : (val) => _.mapValues(val, (v) => _.round(v, round === true ? 0 : round)) as UseMeasureDimens;

export function useMeasure<E extends Element = HTMLDivElement>(
  { round }: { round?: boolean | number } = { round: 3 }
): UseMeasureResult<E> {
  const [element, ref] = useState<E | null>(null);
  const mediator = useMemo(() => getMediator(round), []);
  const [dimens, setDimens] = useMediatedState<UseMeasureDimens>(mediator, defaultState);

  const observer = useMemo(
    () =>
      new (window as any).ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect;
          setDimens({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return [ref, dimens];
}
