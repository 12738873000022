import { IOxRole } from '@core/typings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IRoleRepository } from '../../../shared/api/interfaces/IRoleRepository';
import { IRole } from '../../../shared/models/Role';
import { RootState } from '../../store';
import { RoleThunks } from '../../thunks/role.thunks';

type InitialStateType = {
  roles: IOxRole[];
};

const roles: IOxRole[] = [];

export const initialState: InitialStateType = {
  roles: roles
};

const roleRepository = container.get<IRoleRepository>(SERVICE_KEYS.ROLE_REPOSITORY);
const roleThunks = new RoleThunks(roleRepository);

export const fetchRoles = roleThunks.getAll();
export const fetchRoleById = roleThunks.getOne();
export const fetchActiveUserCountByRole = roleThunks.getActiveUserCountByRole();
export const addRole = roleThunks.add();
export const updateRole = roleThunks.update();
export const removeRole = roleThunks.delete();

const slice = {
  name: 'role',
  initialState,
  extraReducers: (builder: any) => {
    builder.addCase(fetchRoles.fulfilled, (state: any, { payload }: PayloadAction<IRole[]>) => {
      state.roles = payload;
    });

    builder.addCase(
      addRole.fulfilled,
      (state: any, { payload }: PayloadAction<{ data: IRole }>) => {
        const role = payload.data;
        state.roles.push(role);
      }
    );
  }
};

export const roleSlice = createSlice({
  ...slice,
  reducers: {}
});

export const roleReducer = roleSlice.reducer;
export const getRole = (state: RootState) => state.role;
