import { OxField } from '@core/form';
import { CalendarSvg } from '@core/images';
import { CalendarTypeProps } from '@core/typings';
import { cn } from '@core/util';
import { ChangeEvent } from 'react';
import { StyledDateText } from '../StyledDateText';
import { CalendarInputContainer } from './OxCalendarInput.styles';

interface Props {
  handleShow: () => void;
  dateString?: string | undefined;
  setSelectedDate: any;
  setDateString: any;
  typeData?: CalendarTypeProps;
  hasBorder?: boolean;
  isEditable?: boolean;
}

export const OxCalendarInput = ({
  handleShow,
  dateString,
  setSelectedDate,
  setDateString,
  typeData,
  hasBorder = false,
  isEditable = true
}: Props): JSX.Element => {
  let field;
  let calendarClasses = 'ml-auto mt-1.5 cursor-pointer';

  if (typeData) {
    const { label, handleChange, inputError } = typeData;
    calendarClasses = hasBorder
      ? 'absolute no-bdr-top right-2 cursor-pointer'
      : 'absolute top-10 right-3 cursor-pointer';

    const onChangeHandler = (evt: ChangeEvent) => handleChange(evt, setSelectedDate, setDateString);

    field = (
      <OxField
        label={label}
        name='expirationDate'
        type='calendar'
        fieldType='calendar'
        value={dateString}
        maxLength='10'
        disabled={!isEditable}
        readOnly={!isEditable}
        classes={`${
          isEditable &&
          'editable hover:cursor-default focus:cursor-text font-normal truncate w-full pt-1 pb-1 rounded'
        } duration-500 ease-in-out transition-all ${hasBorder ? 'text-gray-500' : ''}`}
        onChange={onChangeHandler}
        errors={{ touched: inputError, error: inputError }}
        showLabel
      />
    );
  } else {
    field = (
      <StyledDateText
        className='date-text text-sm font-normal mt-2 ml-1 w-full'
        data-testid='calendarDateText'
        onClick={handleShow}
      >
        {dateString}
      </StyledDateText>
    );
  }

  const calendarInput = (
    <>
      {field}
      {isEditable && (
        <CalendarSvg
          className={calendarClasses}
          height={24}
          width={24}
          viewBox={hasBorder ? undefined : '0 0 20 20'}
          onClick={handleShow}
        />
      )}
    </>
  );

  let container = (
    <CalendarInputContainer
      className={cn('calendar-container inline-flex rounded h-8 w-32 pr-2 cursor-pointer', {
        'border-[1px]': !typeData
      })}
      data-testid='calendarDateField'
    >
      {calendarInput}
    </CalendarInputContainer>
  );

  if (!hasBorder) {
    container = calendarInput;
  }

  return (
    <div
      className='relative flex'
      style={{
        minWidth: 100
      }}
    >
      {container}
    </div>
  );
};
