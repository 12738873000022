import { UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { DataMngrOrchRespDetails } from './useOrchestratedData.types';

export function getResponseDetails<QMap>(responseMap: any): DataMngrOrchRespDetails<QMap> {
  return _.reduce(
    _.keys(_.values(responseMap)[0]),
    (memo, key) => {
      memo[key as keyof UseQueryResult] = _.mapValues(
        responseMap,
        (resp: UseQueryResult) => resp[key as keyof UseQueryResult]
      ) as any;
      return memo;
    },
    {} as DataMngrOrchRespDetails<QMap>
  );
}
