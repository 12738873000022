import { themes } from '@core/theme';
import { ITooltipParams } from 'ag-grid-enterprise';
import { ThemeProvider } from 'styled-components';
import { StyledGridToolTipContainer } from './OxGridTooltip.styles';

export const OxGridTooltip = ({ value }: ITooltipParams): JSX.Element => {
  const tooltip = (
    <StyledGridToolTipContainer className='grid-tooltip-container px-2 pt-2.5 pb-2.5 rounded-md'>
      <div>{value}</div>
    </StyledGridToolTipContainer>
  );
  return <ThemeProvider theme={themes.lightTheme}>{tooltip}</ThemeProvider>;
};

export const OxRejectionTooltip = ({ value }: ITooltipParams): JSX.Element => {
  const tooltip = (
    <StyledGridToolTipContainer className='grid-tooltip-container px-2 pt-2.5 pb-2.5 rounded-md'>
      {value.map((reason: any, index: number) => {
        return <div key={index}>{reason.reason}</div>;
      })}
    </StyledGridToolTipContainer>
  );
  return <ThemeProvider theme={themes.lightTheme}>{tooltip}</ThemeProvider>;
};
