import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IRateDefinitionRepository } from 'shared/api/interfaces/IRateDefinitionRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IRateDefinition } from 'shared/models/RateDefinition';

@injectable()
export class RateDefinitionRepository implements IRateDefinitionRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/rate-definitions/');

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, { params: { ids: ids } });
  }

  async filter(startDate: Date, endDate: Date): Promise<AxiosResponse<IRateDefinition[]>> {
    return await client.get(this.apiUrl + 'filter/' + { ...startDate, ...endDate });
  }

  async add(rateDefinition: IRateDefinition): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...rateDefinition });
  }

  async update(rateDefinition: IRateDefinition): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...rateDefinition });
  }

  async getAll(): Promise<AxiosResponse<IRateDefinition[]>> {
    return await client.get(this.apiUrl);
  }

  async getById(id: string): Promise<AxiosResponse<IRateDefinition>> {
    return await client.get(this.apiUrl + id);
  }
}
