import styled from 'styled-components';

export const BtnsWpr = styled.div((props: any) => {
  const { gutter = 8 } = props;
  return {
    display: 'flex',

    button: {
      marginLeft: gutter / 2,
      marginRight: gutter / 2,

      '&:first-child': { marginLeft: 0 },
      '&:last-child': { marginRight: 0 }
    }
  };
});
