import { css, TabHeadStyleProps } from './TabHead.style';

import { useMeasure } from '@core/hooks';
import { useCss } from '@core/theme';
import _ from 'lodash';
import { renderTabLabel } from '../TabLabel';
import { TabsProps } from '../Tabs.types';

type TabHeadProps = Pick<TabsProps, 'headerPrefix' | 'headerSuffix' | 'tabs' | 'headerAlign'>;

export function TabsHeader({ headerAlign, headerPrefix, headerSuffix, tabs }: TabHeadProps) {
  const [lblRef, { width: lblWidth }] = useMeasure({ round: true });
  const [pfxRef, { width: pfxWidth }] = useMeasure({ round: true });
  const [sfxRef, { width: sfxWidth }] = useMeasure({ round: true });
  const [wprRef, { width: wprWidth }] = useMeasure({ round: true });
  const cls = useCss<TabHeadStyleProps>(css, {
    headerAlign,
    lblWidth,
    pfxWidth,
    sfxWidth,
    wprWidth
  });
  return (
    <div className={cls.tabsHead} ref={wprRef}>
      <div className='pfx-wpr' ref={pfxRef}>
        {headerPrefix}
      </div>
      <div className='labels-wpr'>
        <div className='labels-ctr' ref={lblRef}>
          {_.map(tabs, renderTabLabel)}
        </div>
      </div>
      <div className='sfx-wpr' ref={sfxRef}>
        {headerSuffix}
      </div>
    </div>
  );
}
