import { ColumnValues, DealStatus, HttpResponse, InfiniteFilterParams } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IDealRepository } from 'shared/api/interfaces/IDealRepository';
import { MetaSearchParams } from 'shared/constants/Search';
import { buildSearchThunk } from 'shared/helpers/search.helper';
import { DealRollups, IDeal, IFlattenRejectedDeal, WithdrawReason } from 'shared/models/Deal';
import { IBaseThunk } from './base.thunks';

type PotentialMatchArgs = {
  matchIds: string[];
  dealId: string;
};

export class DealThunks implements IBaseThunk<IDeal> {
  private readonly dealRepository!: IDealRepository;

  constructor(_dealRepository: IDealRepository) {
    this.dealRepository = _dealRepository;

    if (this.dealRepository === null) {
      throw new Error('dealRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'deal';

  get(): AsyncThunk<AxiosResponse<IDeal, any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchDeals`;

    return createAsyncThunk(action, () => this.dealRepository.getAll());
  }

  search(): AsyncThunk<
    { result: IDeal[]; meta: MetaSearchParams },
    InfiniteFilterParams,
    Record<string, unknown>
  > {
    return buildSearchThunk(`${this.baseIdentifier}/searchDeals`, this.dealRepository.search);
  }

  searchRejected(): AsyncThunk<
    { result: IFlattenRejectedDeal[]; meta: MetaSearchParams },
    InfiniteFilterParams,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/searchRejectedDeals`;

    return createAsyncThunk(action, async (filterParams: InfiniteFilterParams) => {
      const response = await this.dealRepository.searchRejected(filterParams);

      if (response.data) {
        const { data } = response;
        const result = (data.data || []).map((deal) => {
          const dealLegs =
            deal?.attemptedDeal?.dealLegs.length > 1 ? {} : deal?.attemptedDeal?.dealLegs[0];

          return {
            ...deal,
            ...deal?.attemptedDeal,
            ...dealLegs,
            dealStatus: DealStatus.Rejected,
            etrmId: deal.etrmId
          };
        });
        const meta = data.meta || {};

        return {
          result,
          meta
        };
      }

      return {
        result: [],
        meta: response
      };
    });
  }

  rollup(): AsyncThunk<
    { result: DealRollups; meta: MetaSearchParams },
    InfiniteFilterParams,
    Record<string, unknown>
  > {
    return buildSearchThunk(`${this.baseIdentifier}/rollupDeals`, this.dealRepository.getRollups);
  }

  getPotentialMatches(): AsyncThunk<AxiosResponse<IDeal[], any>, PotentialMatchArgs, any> {
    const action = `${this.baseIdentifier}/fetchDeal`;

    return createAsyncThunk(action, ({ matchIds }: PotentialMatchArgs) =>
      this.dealRepository.getPotentialMatches(matchIds)
    );
  }

  getColumnValues(): AsyncThunk<
    AxiosResponse<ColumnValues[], any> | undefined,
    { field: string; status: string },
    any
  > {
    const action = `${this.baseIdentifier}/getColumnValues`;

    return createAsyncThunk(action, ({ field, status }) =>
      this.dealRepository.getColumnValues(field, status)
    );
  }

  getRejectedColumnValues(): AsyncThunk<
    AxiosResponse<ColumnValues[], any> | undefined,
    { field: string },
    any
  > {
    const action = `${this.baseIdentifier}/getRejectedColumnValues`;

    return createAsyncThunk(action, ({ field }) =>
      this.dealRepository.getRejectedColumnValues(field)
    );
  }

  add(): AsyncThunk<AxiosResponse<any, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<
    AxiosResponse<any, any>,
    { id: string; item: any },
    Record<string, unknown>
  > {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<any, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getAll(): AsyncThunk<AxiosResponse<any[], any>, void, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  clickToPair(): AsyncThunk<
    AxiosResponse<IDeal, any>,
    {
      companyDealEtrmId: string | undefined;
      counterpartyDealEtrmId: string | undefined;
      comment: string;
    },
    any
  > {
    const action = `${this.baseIdentifier}/click-to-pair`;

    return createAsyncThunk(
      action,
      async ({ companyDealEtrmId, counterpartyDealEtrmId, comment }, { rejectWithValue }) => {
        try {
          return await this.dealRepository.clickToPair(
            companyDealEtrmId,
            counterpartyDealEtrmId,
            comment
          );
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }

  withdraw(): AsyncThunk<
    AxiosResponse<IDeal, any>,
    { etrmId: string | undefined; comment: string; reason: WithdrawReason },
    any
  > {
    const action = `${this.baseIdentifier}/withdraw`;

    return createAsyncThunk(action, async ({ etrmId, comment, reason }, { rejectWithValue }) => {
      try {
        return await this.dealRepository.withdraw(etrmId, comment, reason);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  downloadDeals(): AsyncThunk<HttpResponse<any>, InfiniteFilterParams, any> {
    const action = `${this.baseIdentifier}/downloadDeals`;

    return createAsyncThunk(action, (filterParams: InfiniteFilterParams) =>
      this.dealRepository.downloadDeals(filterParams)
    );
  }

  deleteDealReplica(): AsyncThunk<AxiosResponse<IDeal, any>, { date: string }, any> {
    const action = `${this.baseIdentifier}/deleteDealReplica`;

    return createAsyncThunk(action, async ({ date }, { rejectWithValue }) => {
      try {
        return await this.dealRepository.deleteDealReplica(date);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  deleteRejectedDeals(): AsyncThunk<AxiosResponse<any>, { ids: string[] }, any> {
    const action = `${this.baseIdentifier}/deleteRejectedDeals`;

    return createAsyncThunk(action, async ({ ids }, { rejectWithValue }) => {
      try {
        return await this.dealRepository.deleteRejectedDeals(ids);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }
}
