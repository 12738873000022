import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RejectedEtrmInvoicesThunks } from 'redux/thunks/rejectedEtrmInvoice.thunks';
import { HttpSlicePayload } from 'redux/types/payload';
import { IRejectedEtrmInvoicesRepository } from 'shared/api/interfaces/IRejectedEtrmInvoicesRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IRejectedEtrmInvoice } from 'shared/models/RejectedEtrmInvoice';

type InitialStateType = {
  rejectedInvoices: IRejectedEtrmInvoice[];
};

const rejectedInvoices: IRejectedEtrmInvoice[] = [];

export const initialState: InitialStateType = {
  rejectedInvoices
};

const rejectedEtrmInvoicesRepository = container.get<IRejectedEtrmInvoicesRepository>(
  SERVICE_KEYS.REJECTED_ETRM_INVOICES_REPOSITORY
);
const thunks = new RejectedEtrmInvoicesThunks(rejectedEtrmInvoicesRepository);

export const fetchRejectedEtrmInvoices = thunks.getAll();
export const deleteRejectedEtrmInvoice = thunks.delete();
export const resubmitRejectedEtrmInvoice = thunks.resubmit();

const slice = buildGenericSlice<InitialStateType>('rejectedEtrmInvoices', initialState);

export const rISlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchRejectedEtrmInvoices.fulfilled,
      (state: InitialStateType, result: HttpSlicePayload<IRejectedEtrmInvoice>) => {
        if (result && result.payload) {
          const { data } = result.payload;
          state.rejectedInvoices = data;
        }
      }
    );
  }
});

export const RejectedEtrmInvoiceReducer = rISlice.reducer;
