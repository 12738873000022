import { ComposedSfc } from '@core/typings';
import { compose } from '@reduxjs/toolkit';
import _ from 'lodash';
import { FC } from 'react';
import { FiltersConfigMap, WithGridConfig } from '../typings';
import { withGridFields } from './withGridFields';
import { withGridFilters } from './withGridFilters';
import { withGridRef } from './withGridRef';

export const withGrid = <
  TData extends object = any,
  FieldName extends string = any,
  Filters extends FiltersConfigMap = FiltersConfigMap
>({ fields, filters }: WithGridConfig<TData, FieldName> = {}): ((Composed: FC) => ComposedSfc) => {
  const hocs = [(Composed: ComposedSfc) => withGridRef<TData>(Composed)];
  if (filters) hocs.push(withGridFilters<Filters>(_.isBoolean(filters) ? {} : filters));
  if (fields) hocs.push(withGridFields<TData, FieldName>(fields));
  return compose(...hocs);
};
