import { ReactComponent as EleoxIconSvg } from './eleox-icon.svg';
import { ReactComponent as OxAdminIconInverseSvg } from './oxadmin-icon-inverse.svg';
import { ReactComponent as OxAdminIconSvg } from './oxadmin-icon.svg';
import { ReactComponent as OxAdminLogomarkInverseSvg } from './oxadmin-logomark-inverse.svg';
import { ReactComponent as OxAdminLogomarkSvg } from './oxadmin-logomark.svg';
import { ReactComponent as OxAdminWordmarkInverseSvg } from './oxadmin-wordmark-inverse.svg';
import { ReactComponent as OxAdminWordmarkSvg } from './oxadmin-wordmark.svg';
import { ReactComponent as OxNomIconInverseSvg } from './oxnom-icon-inverse.svg';
import { ReactComponent as OxNomIconSvg } from './oxnom-icon.svg';
import { ReactComponent as OxNomLogomarkInverseSvg } from './oxnom-logomark-inverse.svg';
import { ReactComponent as OxNomLogomarkSvg } from './oxnom-logomark.svg';
import { ReactComponent as OxNomWordmarkInverseSvg } from './oxnom-wordmark-inverse.svg';
import { ReactComponent as OxNomWordmarkSvg } from './oxnom-wordmark.svg';
import { ReactComponent as OxOracleIconInverseSvg } from './oxoracle-icon-inverse.svg';
import { ReactComponent as OxOracleIconSvg } from './oxoracle-icon.svg';
import { ReactComponent as OxOracleLogomarkInverseSvg } from './oxoracle-logomark-inverse.svg';
import { ReactComponent as OxOracleLogomarkSvg } from './oxoracle-logomark.svg';
import { ReactComponent as OxOracleWordmarkInverseSvg } from './oxoracle-wordmark-inverse.svg';
import { ReactComponent as OxOracleWordmarkSvg } from './oxoracle-wordmark.svg';
import { ReactComponent as OxPairIconInverseSvg } from './oxpair-icon-inverse.svg';
import { ReactComponent as OxPairIconSvg } from './oxpair-icon.svg';
import { ReactComponent as OxPairLogomarkInverseSvg } from './oxpair-logomark-inverse.svg';
import { ReactComponent as OxPairLogomarkSvg } from './oxpair-logomark.svg';
import { ReactComponent as OxPairWordmarkInverseSvg } from './oxpair-wordmark-inverse.svg';
import { ReactComponent as OxPairWordmarkSvg } from './oxpair-wordmark.svg';
import { ReactComponent as OxSettleIconInverseSvg } from './oxsettle-icon-inverse.svg';
import { ReactComponent as OxSettleIconSvg } from './oxsettle-icon.svg';
import { ReactComponent as OxSettleLogomarkInverseSvg } from './oxsettle-logomark-inverse.svg';
import { ReactComponent as OxSettleLogomarkSvg } from './oxsettle-logomark.svg';
import { ReactComponent as OxSettleWordmarkInverseSvg } from './oxsettle-wordmark-inverse.svg';
import { ReactComponent as OxSettleWordmarkSvg } from './oxsettle-wordmark.svg';

export {
  EleoxIconSvg,
  OxAdminIconSvg,
  OxAdminIconInverseSvg,
  OxAdminLogomarkInverseSvg,
  OxAdminLogomarkSvg,
  OxAdminWordmarkInverseSvg,
  OxAdminWordmarkSvg,
  OxNomIconInverseSvg,
  OxNomIconSvg,
  OxNomLogomarkInverseSvg,
  OxNomLogomarkSvg,
  OxNomWordmarkInverseSvg,
  OxNomWordmarkSvg,
  OxOracleIconInverseSvg,
  OxOracleIconSvg,
  OxOracleLogomarkInverseSvg,
  OxOracleLogomarkSvg,
  OxOracleWordmarkInverseSvg,
  OxOracleWordmarkSvg,
  OxPairIconInverseSvg,
  OxPairIconSvg,
  OxPairLogomarkInverseSvg,
  OxPairLogomarkSvg,
  OxPairWordmarkInverseSvg,
  OxPairWordmarkSvg,
  OxSettleIconInverseSvg,
  OxSettleIconSvg,
  OxSettleLogomarkInverseSvg,
  OxSettleLogomarkSvg,
  OxSettleWordmarkInverseSvg,
  OxSettleWordmarkSvg
};

export const brandingImgMap = {
  admin: {
    icon: {
      light: OxAdminIconSvg,
      dark: OxAdminIconInverseSvg
    },
    logomark: {
      light: OxAdminLogomarkSvg,
      dark: OxAdminLogomarkInverseSvg
    },
    wordmark: {
      light: OxAdminWordmarkSvg,
      dark: OxAdminWordmarkInverseSvg
    }
  },
  nom: {
    icon: {
      light: OxNomIconSvg,
      dark: OxNomIconInverseSvg
    },
    logomark: {
      light: OxNomLogomarkSvg,
      dark: OxNomLogomarkInverseSvg
    },
    wordmark: {
      light: OxNomWordmarkSvg,
      dark: OxNomWordmarkInverseSvg
    }
  },
  oracle: {
    icon: {
      light: OxOracleIconSvg,
      dark: OxOracleIconInverseSvg
    },
    logomark: {
      light: OxOracleLogomarkSvg,
      dark: OxOracleLogomarkInverseSvg
    },
    wordmark: {
      light: OxOracleWordmarkSvg,
      dark: OxOracleWordmarkInverseSvg
    }
  },
  pair: {
    icon: {
      light: OxPairIconSvg,
      dark: OxPairIconInverseSvg
    },
    logomark: {
      light: OxPairLogomarkSvg,
      dark: OxPairLogomarkInverseSvg
    },
    wordmark: {
      light: OxPairWordmarkSvg,
      dark: OxPairWordmarkInverseSvg
    }
  },
  settle: {
    icon: {
      light: OxSettleIconSvg,
      dark: OxSettleIconInverseSvg
    },
    logomark: {
      light: OxSettleLogomarkSvg,
      dark: OxSettleLogomarkInverseSvg
    },
    wordmark: {
      light: OxSettleWordmarkSvg,
      dark: OxSettleWordmarkInverseSvg
    }
  }
} as const;
