import { dateFormatStringMap } from '@core/util';
import { ValueFormatterFunc } from 'ag-grid-community';
import _ from 'lodash';
import { createFormatter, CreateFormatterConfig } from './createFormatter';

export const formatterConfig = {
  currency: {
    format: 'currency',
    nullValue: '',
    emptyValue: '',
    formatString: '($0,0.00)',
    decimalPrecision: 2
  },
  date: {
    format: 'date',
    formatString: dateFormatStringMap.date,
    nullValue: ''
  },
  dateLong: {
    format: 'date',
    formatString: dateFormatStringMap.datetime,
    nullValue: ''
  },
  dateShort: {
    format: 'date',
    formatString: dateFormatStringMap.dateShort,
    nullValue: ''
  },
  datetime: {
    format: 'datetime',
    formatString: dateFormatStringMap.datetime,
    nullValue: ''
  },
  datetimeTimeFirst: {
    format: 'datetime',
    formatString: dateFormatStringMap.datetimeTimeFirst,
    nullValue: ''
  },
  unitPrice: {
    format: 'currency',
    formatString: '($0,0.0000[00])',
    emptyValue: '',
    nullValue: ''
  },
  integer: {
    format: 'integer',
    emptyValue: '',
    nullValue: '',
    formatString: '(0,0[.][0000])'
  },
  string: { format: 'string' },
  month: {
    format: 'date',
    formatString: dateFormatStringMap.month,
    parseFormat: ['MM/YYYY', 'YYYY/MM', 'MM-DD-YYYY', 'YYYY MMM', 'YYYY MMMM'],
    nullValue: ''
  },
  monthLong: {
    format: 'date',
    formatString: dateFormatStringMap.monthLong,
    parseFormat: ['MM/YYYY', 'YYYY/MM', 'MM-DD-YYYY', 'YYYY MMM', 'YYYY MMMM'],
    nullValue: ''
  },
  number: {
    format: 'number',
    formatString: '(0,0[.]0000)',
    emptyValue: '',
    nullValue: ''
  },
  text: {
    format: 'string',
    emptyValue: '',
    nullValue: ''
  },
  time: {
    format: 'datetime',
    formatString: dateFormatStringMap.time,
    nullValue: ''
  },
  get centralTimeDatetime() {
    return {
      ...this.datetime,
      useCentralTime: true
    };
  },
  get centralTimeDatetimeTimeFirst() {
    return {
      ...this.datetimeTimeFirst,
      useCentralTime: true
    };
  },
  get centralTime() {
    return {
      ...this.time,
      useCentralTime: true
    };
  }
} as const;

type FConfMap = Record<keyof typeof formatterConfig, CreateFormatterConfig>;

export const formatters = _.mapValues<FConfMap, ValueFormatterFunc>(
  formatterConfig as unknown as FConfMap,
  createFormatter
) as { [K in keyof typeof formatterConfig]: ValueFormatterFunc };
