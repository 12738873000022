/* NOTE: ******** WHEN MODIFYING, ACCOUNT FOR THE FACT THAT THIS IS RENDERED RECURSIVELY ******** */

import { Text } from '@core/components';
import { useValueMemo } from '@core/hooks';
import { useSidebar } from '@core/sidebar';
import { IsRouteHiddenParams } from '@core/typings';
import { cn, shallowEqual } from '@core/util';
import { useGetIsRouteActive } from 'Containers/OxSidebar/hooks';
import { PopulatedLinkInfo } from 'Containers/OxSidebar/OxSidebar.types';
import isEq from 'fast-deep-equal';
import _ from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = PopulatedLinkInfo & {
  isRouteHiddenParams: IsRouteHiddenParams;
  isSubmenuItem?: boolean;
  parentIsActive?: boolean;
};

const watchedDeps: (keyof Props)[] = [
  'isRouteHiddenParams',
  'parentIsActive',
  'linkText',
  'preventNavigation'
];

export const PageLink = memo(
  function PageLink(props: Props) {
    const {
      isSubmenuItem = false,
      matchParams,
      linkText,
      LinkIcon,
      SelectedLinkIcon,
      route,
      children = [],
      isExternal,
      isHidden: isHiddenFn,
      isRouteHiddenParams,
      parentIsActive,
      preventNavigation
    } = props;
    const isHidden = useValueMemo<boolean>(
      () => isHiddenFn?.(isRouteHiddenParams) ?? false,
      [isRouteHiddenParams]
    );
    const getIsActive = useGetIsRouteActive();
    const isSubmenuParent = useMemo<boolean>(() => !isSubmenuItem && !!children.length, []);
    const { setShowPreventNavigationModal, setRunRouteAction } = useSidebar();

    const isActive = useValueMemo<boolean>(
      () =>
        isExternal
          ? false
          : isSubmenuParent
            ? _.some(children, (child) => getIsActive(child.matchParams))
            : isSubmenuItem
              ? (parentIsActive && getIsActive(matchParams)) || false
              : getIsActive(matchParams),
      [getIsActive, parentIsActive],
      shallowEqual
    );

    const [submenuIsOpen, setSubmenuIsOpen] = useState<boolean>(() => isSubmenuParent && isActive);
    useEffect(() => {
      if (isSubmenuParent && isActive && !submenuIsOpen) setSubmenuIsOpen(true);
    }, [isActive]);

    const navigate = useNavigate();

    const renderSubmenuItem = useCallback(
      (item: PopulatedLinkInfo, key: number) => {
        return (
          <PageLink
            {...{ ...item, key, isRouteHiddenParams, preventNavigation }}
            isSubmenuItem
            parentIsActive={isActive}
            preventNavigation={preventNavigation}
          />
        );
      },
      [isRouteHiddenParams, isActive, preventNavigation]
    );

    if (isHidden) return null;
    return (
      <li
        className={cn({
          'menu-item': !isSubmenuItem,
          'submenu-item': isSubmenuItem,
          active: isActive,
          '--has-children': !!children?.length
        })}
      >
        <div
          role='button'
          className={cn({
            'menu-item-content-wpr': !isSubmenuItem,
            'submenu-item-link': isSubmenuItem
          })}
          onClick={() => {
            // This is for clicking on the layer of options under each product.

            if (isSubmenuParent) {
              setSubmenuIsOpen(!submenuIsOpen);
            } else {
              if (isExternal) {
                window.open(route, '_blank')?.focus();
              } else {
                if (preventNavigation) {
                  setShowPreventNavigationModal(true);
                  setRunRouteAction({ action: () => navigate(route) });
                } else {
                  navigate(route);
                }
              }
            }
          }}
        >
          {!isSubmenuItem &&
            (isActive ? (
              <SelectedLinkIcon width={14} height={14} className='selected-link-icon' />
            ) : (
              <LinkIcon width={14} height={14} className='link-icon' />
            ))}
          <Text
            className={cn({
              'submenu-item-link-text': isSubmenuItem,
              'menu-item-link-text': !isSubmenuItem,
              'link-selected': !isSubmenuItem && isActive
            })}
            t={linkText}
          />
        </div>

        {!!children?.length && (
          <div className={cn('submenu', { show: submenuIsOpen })}>
            <ul className='submenu-list'>{_.map(children, renderSubmenuItem)}</ul>
          </div>
        )}
      </li>
    );
  },
  (prev, current) => isEq(_.pick(prev, watchedDeps), _.pick(current, watchedDeps))
);
