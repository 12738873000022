import { createSelector, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RootState } from 'redux/store';
import { BrokerThunks } from 'redux/thunks/broker.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { IBrokerRepository } from 'shared/api/interfaces/IBrokerRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IBroker, IBrokerSpec } from 'shared/models/Broker';
import { DeleteResponse } from 'shared/models/DeleteResponse';

type InitialStateType = {
  count?: number;
  changeId: number;
  brokers: IBroker[];
  brokerSpecs: IBrokerSpec[];
};

const count = undefined;
const changeId = 0;
const brokers: IBroker[] = [];
const brokerSpecs: IBrokerSpec[] = [];

export const initialState: InitialStateType = {
  count,
  changeId,
  brokers,
  brokerSpecs
};

const brokerRepository = container.get<IBrokerRepository>(SERVICE_KEYS.BROKER_REPOSITORY);
const brokerThunks = new BrokerThunks(brokerRepository);
export const getAllBrokers = brokerThunks.getAll();
export const addBroker = brokerThunks.add();
export const deleteAllBrokers = brokerThunks.deleteAll();
export const getAllBrokerSpecs = brokerThunks.getSpecs();
export const updateBroker = brokerThunks.update();
export const forceUpdatepdateBroker = brokerThunks.forceUpdate();
export const createSpecRequest = brokerThunks.createSpecRequest();

const slice = buildGenericSlice<InitialStateType>('broker', initialState);
export const brokerSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllBrokers.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload) {
          if (payload && payload.data?.length) {
            const { data } = payload;
            state.brokers = data as IBroker[];
            state.changeId++;
            state.count = data.length;
          }
        }
      }
    );
    builder.addCase(addBroker.fulfilled, (state: InitialStateType, { payload }: GenericPayload) => {
      if (payload) {
        if (payload) {
          const brokers = [...state.brokers, payload.data];
          state.brokers = brokers;
          state.changeId++;
          state.count = brokers.length;
        }
      }
    });
    builder.addCase(
      deleteAllBrokers.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload.status === 200) {
          const { data } = payload;
          state.changeId++;
          state.brokers = state.brokers.filter(
            (broker: IBroker) =>
              !data.find((response: DeleteResponse) => response.id === broker.etrmId)?.isSuccess
          );
        }
      }
    );
    builder.addCase(
      getAllBrokerSpecs.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data && payload.data.length > 0) {
          state.brokerSpecs = payload.data as IBrokerSpec[];
          state.changeId++;
        }
      }
    );
  }
});

export const brokerReducer = brokerSlice.reducer;

export const selectBrokers = (state: RootState) => state.broker.brokers;
export const selectBrokerSpecs = (state: RootState) => state.broker.brokerSpecs;

export const selectBrokerMap = createSelectorMap(selectBrokers, 'etrmId');
export const selectBrokerSpecMap = createSelectorMap(selectBrokerSpecs, 'specId');

export interface IFQBroker extends IBroker {
  spec?: IBrokerSpec;
}

export const selectFQBrokerRefs = createSelector(
  [selectBrokers, selectBrokerSpecMap],
  (brokers, specMap) => {
    return brokers.map<IFQBroker>((broker) => {
      return {
        ...broker,
        spec: specMap.get(broker.specId)
      };
    });
  }
);
