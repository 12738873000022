import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IProductRepository } from 'shared/api/interfaces/IProductRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IProduct } from '../../../models/Role';

@injectable()
export class ProductRepository implements IProductRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('v1/products');

  getAll = async (): Promise<AxiosResponse<IProduct>> => {
    const response = await client.get(this.apiUrl);
    return response.data;
  };

  getAllEnabled = async (): Promise<AxiosResponse<IProduct>> => {
    const response = await client.get(`${this.apiUrl}/enabled`);
    return response.data;
  };
}
