import { createSelector, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { PayIndexThunks } from 'redux/thunks/payIndex.thunk';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { IPayIndexRepository } from 'shared/api/interfaces/IPayIndexRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { DeleteResponse } from 'shared/models/DeleteResponse';
import { IPayIndex, IPayIndexSpec } from 'shared/models/PayIndex';
import { RootState } from '../../store';

type InitialStateType = {
  count?: number;
  changeId: number;
  payIndexes: IPayIndex[];
  payIndexSpecs: IPayIndexSpec[];
};

const count = undefined;
const changeId = 0;
const payIndexes: IPayIndex[] = [];
const payIndexSpecs: IPayIndexSpec[] = [];

export const initialState: InitialStateType = {
  count,
  changeId,
  payIndexes,
  payIndexSpecs
};

const payIndexRepository = container.get<IPayIndexRepository>(
  SERVICE_KEYS.PAY_INDEX_TYPE_REPOSITORY
);
const payIndexThunks = new PayIndexThunks(payIndexRepository);
export const getAllPayIndexes = payIndexThunks.getAll();
export const getAllPayIndexSpecs = payIndexThunks.getSpecs();
export const addPayIndex = payIndexThunks.add();
export const deleteAllPayIndexes = payIndexThunks.deleteAll();
export const updatePayIndex = payIndexThunks.update();
export const forceUpdatePayIndex = payIndexThunks.forceUpdate();
export const createSpecRequest = payIndexThunks.createSpecRequest();

const slice = buildGenericSlice<InitialStateType>('payIndex', initialState);
export const payIndexSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllPayIndexes.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data?.length) {
          const { data } = payload;
          state.payIndexes = data;
          state.changeId++;
          state.count = data.length;
        }
      }
    );
    builder.addCase(
      addPayIndex.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload) {
          if (payload) {
            const payIndexes = [...state.payIndexes, payload.data];
            state.payIndexes = payIndexes;
            state.changeId++;
            state.count = payIndexes.length;
          }
        }
      }
    );
    builder.addCase(
      getAllPayIndexSpecs.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data && payload.data.length > 0) {
          state.changeId++;
          state.payIndexSpecs = payload.data as IPayIndexSpec[];
        }
      }
    );
    builder.addCase(
      deleteAllPayIndexes.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload.status === 200) {
          const { data } = payload;
          state.changeId++;
          state.payIndexes = state.payIndexes.filter(
            (payIndex: IPayIndex) =>
              !data.find((response: DeleteResponse) => response.id === payIndex.etrmId)?.isSuccess
          );
        }
      }
    );
  }
});

export const payIndexReducer = payIndexSlice.reducer;

export const selectPayIndexes = (state: RootState) => state.payIndex.payIndexes;
export const selectPayIndexSpecs = (state: RootState) => state.payIndex.payIndexSpecs;

export const selectPayIndexMap = createSelectorMap(selectPayIndexes, 'etrmId');
export const selectPayIndexSpecMap = createSelectorMap(selectPayIndexSpecs, 'specId');

export interface IFQPayIndex extends IPayIndex {
  spec?: IPayIndexSpec;
}

export const selectFQPayIndexRefs = createSelector(
  [selectPayIndexes, selectPayIndexSpecMap],
  (payIndexes, specMap) => {
    return payIndexes.map<IFQPayIndex>((payIndex) => {
      return {
        ...payIndex,
        spec: specMap.get(payIndex.specId)
      };
    });
  }
);
