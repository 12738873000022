import { UploadEmptySvg } from '@core/images';
import { themes } from '@core/theme';
import { isCamelCase, splitCamelCase, titleCaseWord } from '@core/util';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { StyledEmptyText, StyledEmptyTitle } from './TableEmptyState.styles';

export interface TableEmptyStateProps {
  templateType: string;
  text?: string;
  hasBody?: boolean;
  hasBtn?: boolean;
  uploadBtn?: JSX.Element;
}

export const TableEmptyState = ({
  templateType,
  text,
  hasBody = true,
  hasBtn = true,
  uploadBtn
}: TableEmptyStateProps): JSX.Element => {
  const { t } = useTranslation();
  const templateTitle = isCamelCase(templateType)
    ? splitCamelCase(templateType)
    : titleCaseWord(templateType);

  const tes = (
    <div
      data-testid='emptyUploadComponent'
      className='template-empty-state flex flex-col items-center content-center h-96 mt-30'
    >
      <UploadEmptySvg data-testid='uploadEmptySvg' height={150} width={210.5} />
      <StyledEmptyTitle data-testid='uploadEmptyTitle' className='empty-title text-xl  mt-8'>
        {text ? text : t('oracle.emptyTitle', { templateName: templateTitle })}
      </StyledEmptyTitle>
      {hasBody && (
        <StyledEmptyText
          data-testid='uploadEmptyText'
          className='empty-text text-center mt-3 mx-64'
        >
          {t('oracle.emptyBody')}
        </StyledEmptyText>
      )}
      {hasBtn && uploadBtn}
    </div>
  );

  return <ThemeProvider theme={themes.lightTheme}>{tes}</ThemeProvider>;
};
