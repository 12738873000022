export const buildFutureDate = (months: number, days: number, years: number) => {
  const futureDate = new Date();
  const futureMonth = futureDate.getMonth() + months;
  const futureDays = futureDate.getDate() + days;
  const futureYears = futureDate.getFullYear() + years;

  futureDate.setMonth(futureMonth);
  futureDate.setDate(futureDays);
  futureDate.setFullYear(futureYears);

  return futureDate;
};
