import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IPipelineRepository } from 'shared/api/interfaces/IPipelineRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IPipeline, IPipelineSpec, IPipelineSpecRequest } from 'shared/models/Pipeline';

@injectable()
export class PipelineRepository implements IPipelineRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/pipelines/');

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, { params: { ids: ids } });
  }

  async filter(startDate: Date, endDate: Date): Promise<AxiosResponse<IPipeline[]>> {
    return await client.get(this.apiUrl + 'filter/' + { ...startDate, ...endDate });
  }

  async add(pipeline: IPipeline): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...pipeline });
  }

  async update(pipeline: IPipeline): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...pipeline });
  }

  async getAll(): Promise<AxiosResponse<IPipeline[]>> {
    return await client.get(this.apiUrl);
  }

  async getSpecs(): Promise<AxiosResponse<IPipelineSpec[]>> {
    return await client.get(this.apiUrl + 'spec-pipelines');
  }

  async getById(id: string): Promise<AxiosResponse<IPipeline>> {
    return await client.get(this.apiUrl + id);
  }

  async forceUpdate(pipeline: IPipeline): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'update-deal', { ...pipeline });
  }

  async createSpecRequest(specRequest: IPipelineSpecRequest): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'spec-request', specRequest);
  }
}
