import { useAuth0 } from '@auth0/auth0-react';
import { useValueMemo } from '@core/hooks';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { UseDataOptions } from '../typings';
import { getNormalizedQuery } from './util';

export function useData<Resp = unknown, Formatted = Resp, Err = Error>(
  conf: UseDataOptions<Resp, Formatted, Err>
): UseQueryResult<Formatted, Err> {
  const authState = useAuth0();
  const options = useValueMemo(() => {
    return getNormalizedQuery(conf, authState);
  }, [conf, authState.isAuthenticated, authState.isLoading]);
  const Qry = useQuery<Resp, Err, Formatted, any>(options);

  /* watch queries where filters aren't included in cache key; refetch when filters change */
  /* NOTE: this runs on first render on purpose so partial reloads (resets) also trigger refetch */
  useValueMemo(() => {
    /* NOTE: if refetch isn't delayed by 1 tick (via setTimeout), old params will be used */
    if (conf.includeFiltersInQueryKey === false) setTimeout(Qry.refetch);
  }, [(options.meta?.requestOptions as AxiosRequestConfig)?.params, conf.includeFiltersInQueryKey]);

  return Qry;
}
