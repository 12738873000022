import { addNotification } from '@core/notification';
import { Query, QueryClient, QueryFunction, QueryKey } from '@tanstack/react-query';
import { handleRequest } from './lib';
import { DataMngrQueryFn, DataMngrQueryFnContext } from './typings';

const queryFn: DataMngrQueryFn = async ({ meta }: DataMngrQueryFnContext) => {
  return handleRequest(meta);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { queryFn: queryFn as QueryFunction }
  }
  // TODO: Enable this after testing. This will show a toast for any error that occurs.
  // queryCache: new QueryCache({
  //   onError: queryCacheOnError
  // })
});

export const SchedulingErrorMessages: Record<string, string> = {
  getLogicalNomIds: 'Error getting logical nom IDs'
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function queryCacheOnError(err: Error, query: Query<unknown, unknown, unknown, QueryKey>) {
  if (typeof query.meta?.name === 'string' && query.meta.name.startsWith('schedulingKeys.')) {
    const errorMessage = SchedulingErrorMessages[query.meta?.key as string];
    if (errorMessage) {
      return addNotification({
        id: `${query.meta.name}Error`,
        title: 'Scheduling Error',
        message: errorMessage
      });
    }
  }

  addNotification({
    id: `queryCacheUnhandledErr`,
    message: 'Something went wrong'
  });
}
