import { useValueMemo } from '@core/hooks';
import {
  CogSvg,
  DoubleCheckCircleSvg,
  IDBadgeOutlineSvg,
  InfoCircleOutlineSvg,
  LinkOutlineSvg,
  OracleOutlineSvg,
  OxAdminLogomarkInverseSvg,
  OxNomLogomarkInverseSvg,
  OxNomSvg,
  OxOracleLogomarkInverseSvg,
  OxPairLogomarkInverseSvg,
  OxSettleLogomarkInverseSvg
} from '@core/images';
import { Product, ProductKey } from '@core/typings';
import { getRouteMatchParams } from 'Containers/OxSidebar/util';
import _ from 'lodash';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
import { ILinkInfo, PopulatedLinkInfo, ProductLinkConfig } from '../OxSidebar.types';
import {
  getOxAdminPageLinks,
  getOxNomPageLinks,
  getOxOraclePageLinks,
  getOxPairPageLinks,
  getOxSettlePageLinks,
  getSettingsPageLinks,
  getSupportPageLinks
} from '../productLinks';

export type ProductLinkMap = Record<ProductKey, Partial<ProductLinkConfig>>;
export type ProductLinkSet = ProductLinkConfig[];

type UseProductLinksConfig = { items: boolean };

type LinkInfoMap = Record<ProductKey, PopulatedLinkInfo[]>;

/* build links for all products; add matchParams at top and 2nd level */
const makeAllTheLinks = ({
  rejectedInvoicesCount
}: {
  rejectedInvoicesCount: number;
}): LinkInfoMap =>
  _.mapValues(
    {
      oxAdmin: getOxAdminPageLinks(),
      oxOracle: getOxOraclePageLinks(),
      oxPair: getOxPairPageLinks(),
      oxNom: getOxNomPageLinks(),
      oxSettle: getOxSettlePageLinks({ rejectedInvoicesCount }),
      settings: getSettingsPageLinks(),
      support: getSupportPageLinks()
    } as Record<ProductKey, ILinkInfo[]>,
    (items) =>
      _.map(items, (item) => {
        return {
          ...item,
          matchParams: getRouteMatchParams(item.route, { only: ['product', 'page'], slim: true }),
          children: _.map(item.children, (child) => ({
            ...child,
            matchParams: getRouteMatchParams(child.route, { slim: true })
          }))
        };
      })
  ) as LinkInfoMap;

export function useProductLinks({ items: getItems }: UseProductLinksConfig = { items: true }) {
  const stateFrag = useAppSelector((state: RootState) => ({
    rejectedInvoicesCount: state.rejectedEtrmInvoices.rejectedInvoices.length,
    enabledProducts: state.product.enabledProducts,
    featureFlags: state.featureFlags,
    currentUser: state.access.currentUser
  }));

  return useValueMemo<ProductLinkSet>(() => {
    const { rejectedInvoicesCount, enabledProducts, featureFlags, currentUser } = stateFrag;
    const itemMap = getItems ? makeAllTheLinks({ rejectedInvoicesCount }) : ({} as LinkInfoMap);
    const productGroupMap: ProductLinkMap = {
      oxAdmin: {
        Icon: IDBadgeOutlineSvg,
        Logomark: OxAdminLogomarkInverseSvg,
        route: '/products/admin/user-permission/profiles',
        styleClass: 'product-admin-route',
        isPrimaryMenuItem: true
      },
      oxOracle: {
        Icon: OracleOutlineSvg,
        Logomark: OxOracleLogomarkInverseSvg,
        route: '/products/oracle/static-data',
        styleClass: 'product-oracle-route',
        isPrimaryMenuItem: true
      },
      oxPair: {
        Icon: LinkOutlineSvg,
        Logomark: OxPairLogomarkInverseSvg,
        iconClassName: 'white',
        route: '/products/pair/deals',
        styleClass: 'product-pair-route',
        isHidden: featureFlags.hideQaFeatures,
        isPrimaryMenuItem: true
      },
      oxNom: {
        Icon: OxNomSvg,
        Logomark: OxNomLogomarkInverseSvg,
        route: '/products/nom/scheduling',
        styleClass: 'product-nom-route',
        isHidden: !featureFlags.nom,
        isPrimaryMenuItem: true
      },
      oxSettle: {
        Icon: DoubleCheckCircleSvg,
        Logomark: OxSettleLogomarkInverseSvg,
        route: '/products/settle/invoice-reconciliation',
        styleClass: 'product-settle-route',
        isHidden: featureFlags.hideQaFeatures,
        isPrimaryMenuItem: true
      },
      settings: {
        Icon: CogSvg,
        iconClassName: 'fill-white',
        route: '/products/settings/company',
        styleClass: 'settings-route',
        hideProductLink: true,
        isHidden: !currentUser.settings?.read
      },
      support: {
        Icon: InfoCircleOutlineSvg,
        route: '/products/support/eleox-support',
        hideProductLink: true
      }
    };

    return _.filter(
      _.map(
        productGroupMap,
        (conf: ProductLinkConfig, id: ProductKey): ProductLinkConfig => ({
          ...conf,
          items: itemMap[id] ?? [],
          id,
          title: Product[id],
          altTag: Product[id],
          matchParams: getRouteMatchParams(conf.route as string, { only: ['product'] })
        })
      ),
      (product: ProductLinkConfig) =>
        product.id === 'support' ||
        (!product.isHidden && !!_.find(enabledProducts, { key: product.id, enabled: true }))
    ) as unknown as ProductLinkSet;
  }, [stateFrag]);
}
