const home = {
  home: {
    title: 'Sign in to your account',
    email: 'Email address',
    password: 'Password',
    signIn: 'Sign In',
    forgotPassword: 'Forgot Password?',
    forgotPasswordTitle: 'Forgot your password?',
    forgotPasswordBody:
      "Enter your email address and we'll send you instructions to reset your password.",
    sendResetInstructions: 'Send Reset Instructions',
    cancel: 'Cancel, go back',
    goBack: 'Go Back to Sign In',
    emailSentTitle: 'Voila, done!',
    emailSentBody: 'Check your email and follow the instructions.',
    welcome: 'Welcome to the Eleox Platform.',
    changePassword: 'Please change your password',
    productSelectTitle: 'Select a product',
    productSelectBody: 'Get started by choosing a product to work within.'
  }
} as const;

export default home;
