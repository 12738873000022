import _ from 'lodash';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { CSSGetter, ThemeCV } from '../typings';
import { nano } from './useNanoCss';
import { useStyles, UseStylesConfig } from './useStyles';

const isDev = _.includes(window.location.hostname, 'localtest');
const stringArgs = isDev ? [] : [36];
let counter = 0;

export type UseCssConfig = UseStylesConfig & {
  prefix?: string;
};

export function useCss<Props extends object = any, Theme extends ThemeCV = ThemeCV>(
  getStyles: CSSGetter<Props, Theme>,
  props: Props = {} as Props,
  { prefix, ...styleConf }: UseCssConfig = {}
): Record<string, string> {
  /* set defaults once */
  // const defaults = useMemo(() => (_.isPlainObject(defaultProps) ? defaultProps : {}), []);

  /* set prefix once on load */
  const pfx = useMemo(() => {
    let _pfx = prefix;
    if (!prefix) _pfx = '';
    if (prefix?.length) _pfx = `${prefix}-`;
    return _pfx;
  }, []);
  const sty = useStyles<Props, Theme>(getStyles, props, styleConf);
  const classNameRoot = useMemo(() => `${pfx}ox-css-${(counter++).toString(...stringArgs)}`, []);
  const classNames = useMemo(() => _.mapValues(sty, (v, k) => `${classNameRoot}-${k}`), []);
  const sheet = useMemo(() => new nano.CSSOMSheet(classNameRoot), []);

  useLayoutEffect(() => {
    sheet.diffBlock(sty);
  }, [sty]);
  useEffect(() => () => sheet.diffBlock({}), []);
  return classNames;
}
