import { StyleGetter } from '@core/theme/typings';

export const css: StyleGetter = (theme) => {
  const {
    colors: { canvas, /* component, */ info, success, text },
    fade,
    gutter: gtr,
    toGtr
  } = theme;
  const iptBdrRad = gtr / 2;
  return {
    wpr: {
      display: 'flex',
      padding: toGtr(gtr / 2, gtr / 1.5),
      // border: `0.5px solid ${fade(text.primary, 80)}`,
      flex: '1 0 100%',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',

      '.label': {
        color: text.hint,
        marginBottom: gtr / 4
      },

      '.input-wpr': {
        display: 'flex',
        flexDirection: 'column',
        margin: toGtr(gtr, 0),
        paddingRight: gtr * 1.5 /* align with list (scrollbar) */,

        '.input-with-btn-ctr': {
          display: 'flex',
          flex: '1 0',
          alignItems: 'center',

          '.core-select': {
            flex: 1,
            '.___control, &': {
              borderRadius: toGtr(iptBdrRad, 0, 0, iptBdrRad)
              // borderRightWidth: 0
            }
          },

          '.core-btn': {
            height: 34,
            minHeight: 34,
            /* TODO: REMOVE HARDCODED COLOR (MATCHES SELECT BORDER) */
            borderColor: 'hsl(0, 0%, 80%)',
            borderLeftWidth: 0,
            borderRadius: toGtr(0, iptBdrRad, iptBdrRad, 0),

            '&.bordered:not(:hover):not(disabled)': {
              backgroundColor: canvas.primary
            },

            '.btn-icon': {
              height: 20,
              width: 20
            }
          }
        },

        '.hint': {
          marginTop: gtr / 4,
          fontSize: 11,
          color: text.hint,
          fontStyle: 'italic'
        },

        '.create-option-label-wpr': {
          display: 'flex',
          alignItems: 'center',
          color: success.primary,
          fontSize: '0.8rem',
          '.create-icn': {
            color: 'inherit',
            marginRight: gtr / 2
          },
          '.core-text': { color: 'inherit' }
        },
        '.option-wpr': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          '.tag-wpr': {
            padding: toGtr(gtr / 4, gtr / 2),
            borderRadius: gtr / 2,
            border: `1px solid ${info.saturated}`,
            marginLeft: gtr,
            '.core-text': {
              color: info.saturated,
              lineHeight: 1
            }
          },

          '&.--is-selected': {
            '.core-text': { color: text.inverse },
            '.tag-wpr': { borderColor: text.inverse, '.core-text': { color: text.inverse } }
          }
        }
      },
      '.custom-views-list-wpr': {
        display: 'flex',
        flexDirection: 'column',
        margin: toGtr(gtr, 0),
        overflow: 'hidden',
        flex: '1 1',

        '.custom-views-title': {
          borderBottom: `0.5px solid ${fade(text.hint, 80)}`,
          marginRight: gtr * 1.5 /* scrollbar pad */
        },

        '.custom-views-list-ctr': {
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1',
          overflowY: 'scroll',
          paddingRight: gtr * 1.5 /* scrollbar pad */
        },

        '.custom-view-wpr': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: toGtr(gtr / 1.5, 0),
          borderBottom: `0.5px solid ${fade(text.primary, 80)}`,

          '&:last-child': { borderBottom: 'none' },
          '.custom-view-actions-wpr': {
            marginLeft: gtr,

            '.core-btn': {
              borderWidth: 1,
              padding: gtr / 2,
              minHeight: 20,
              height: 20
            }
          }
        }
      }
    }
  };
};
