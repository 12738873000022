import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ICounterparty } from 'shared/models/Counterparty';
import Storage from '../../../shared/utils/Storage';
import { RootState } from '../../store';

type InitialStateType = {
  darkMode: boolean;
  selectedLegalEntities: ICounterparty[];
};

export const initialState: InitialStateType = {
  darkMode: Storage.get('dark-mode') || false,
  selectedLegalEntities: []
};

export const userPreferencesSlice = createSlice<
  InitialStateType,
  SliceCaseReducers<InitialStateType>
>({
  name: 'userPreferences',
  initialState,
  reducers: {
    updateDarkMode: (state, { payload }: PayloadAction<boolean>) => {
      state.darkMode = payload;
      Storage.add<boolean>('dark-mode', payload);
    },
    updateSelectedLegalEntities: (state, { payload }: PayloadAction<ICounterparty[]>) => {
      state.selectedLegalEntities = payload;
    }
  }
});

export const { updateDarkMode, updateSelectedLegalEntities } = userPreferencesSlice.actions;

export const selectUserPreferences = (state: RootState) => state.userPreferences;
export const selectUserSelectedLegalEntities = (state: RootState) =>
  state.userPreferences.selectedLegalEntities;

export const userPreferencesReducer = userPreferencesSlice.reducer;
