import _ from 'lodash';
import {
  FormatSelectionOptionsConfig,
  FormattedSelectOption,
  SelectOptions
} from '../Select.types';

export function createOptionsFormatter(conf: FormatSelectionOptionsConfig) {
  const { valueKey = 'value', labelKey = 'label', sortOptions } = conf;
  return function formatSelectOptions(options: SelectOptions): FormattedSelectOption[] {
    if (!Array.isArray(options)) return [];
    const formattedOptions = _.map(options, (option) => {
      if (!!option && _.isObject(option)) return option;
      return { [labelKey]: _.toString(option), [valueKey]: option };
    });
    return sortOptions ? _.sortBy(formattedOptions, labelKey) : formattedOptions;
  };
}
