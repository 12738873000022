import { OxProfileImage, Text } from '@core/components';
import { getInitials } from '@core/util';
import _ from 'lodash';
import { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';

export function UserProfileV2() {
  const { currentUser } = useAppSelector((state) => state.access);
  const initials = getInitials(currentUser?.firstName, currentUser?.lastName);
  const entityName = useMemo(() => {
    return _.startCase(window.location.hostname.split('.')[0]);
  }, []);

  return (
    <div className='user-profile'>
      <OxProfileImage initials={initials} />
      <div className='text-wpr'>
        <Text m className='full-name' text={`${currentUser?.firstName} ${currentUser.lastName}`} />
        <div className='role-wpr'>
          <Text text={`${currentUser?.role?.name}`} />
          <Text m className='sep' text='|' />
          <Text text={`${entityName}`} />
        </div>
      </div>
    </div>
  );
}
