import { ChunkedFile, DataTemplateType, HttpResponse, UploadData } from '@core/typings';
import { timeComparator } from '@core/util';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createFormData } from 'redux/utils/form.util';
import { IUploadRepository } from 'shared/api/interfaces/IUploadRepository';
import { IDataTemplate } from 'shared/models/DataTemplate';
import { UploadResponse, UploadRow } from 'shared/models/UploadResponse';
import { IBaseThunk } from './base.thunks';

interface SuccessAndFailuresProps {
  id: string;
  type: string;
  templateType: string;
}

export class UploadThunks implements IBaseThunk<IDataTemplate> {
  private readonly uploadRepository!: IUploadRepository;

  constructor(_uploadRepository: IUploadRepository) {
    this.uploadRepository = _uploadRepository;

    if (this.uploadRepository === null) {
      throw new Error('uploadRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'upload';

  getAll(): AsyncThunk<AxiosResponse<IDataTemplate[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchFiles`;

    return createAsyncThunk(action, () => this.uploadRepository.getAll());
  }

  getAllByType(): AsyncThunk<{ data: IDataTemplate[] }, DataTemplateType, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/dataTemplates/fetchDataTemplate`;

    return createAsyncThunk(action, async (type: DataTemplateType) => {
      const result = await this.uploadRepository.getAllByType(type);

      const sortedResult: IDataTemplate[] = result.data.sort(
        (a: UploadResponse, b: UploadResponse) => {
          return timeComparator(b.createdAt || '', a.createdAt || '');
        }
      );

      return { data: sortedResult };
    });
  }

  add(): AsyncThunk<HttpResponse<IDataTemplate>, IDataTemplate, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addFile`;

    return createAsyncThunk(action, (file: IDataTemplate) => this.uploadRepository.add(file));
  }

  upload(): AsyncThunk<AxiosResponse<FormData, any>, UploadData, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/uploadFile`;

    return createAsyncThunk(action, async ({ file, type, id }: UploadData, { rejectWithValue }) => {
      const { name } = file;

      try {
        const chunkedFile = new ChunkedFile(file, name);
        if (chunkedFile.shouldChunk) {
          const responses: any[] = [];
          const chunks = chunkedFile.createChunks();
          for (let chunkIndex = 0; chunkIndex < chunkedFile.chunkCount; chunkIndex++) {
            await (async () => {
              const isEnd = chunkIndex === chunkedFile.chunkCount - 1;
              const form = createFormData(
                { file, type, id },
                name,
                chunks[chunkIndex],
                chunkIndex,
                chunkedFile.chunkCount,
                isEnd
              );

              const uploadData = this.uploadRepository.upload(form, id);
              responses.push(uploadData);
            })();
          }

          const allResponses = await Promise.all(responses);

          return allResponses.filter((d) => d.data.rows)[0];
        } else {
          const form = createFormData({ file, type, id }, name);

          const response = this.uploadRepository.upload(form, id);

          return (await response).data;
        }
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IDataTemplate>, IDataTemplate, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateFile`;

    return createAsyncThunk(action, async (file: IDataTemplate, { rejectWithValue }) => {
      try {
        return await this.uploadRepository.update(file);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IDataTemplate, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteFile`;

    return createAsyncThunk(action, (id: string) => this.uploadRepository.delete(id));
  }

  getSuccessesAndFailures(): AsyncThunk<any, SuccessAndFailuresProps, Record<any, any>> {
    const action = `${this.baseIdentifier}/getSuccessesAndFailures`;

    return createAsyncThunk(action, async ({ id, type }) => {
      const result = (await this.uploadRepository.getSuccessesAndFailures(
        id,
        type
      )) as unknown as UploadRow[];

      const mapper = (result: UploadRow[]) => {
        const flatResults = result.map((r: UploadRow) => {
          const flatData: { [key: string]: any } = {};
          Object.entries(r.data).map(([key, val]) => {
            const { value, isValid } = val as { value: any; isValid: boolean };

            flatData[key] = value;
            flatData[`${key}IsValid`] = isValid;
          });

          return { ...r, ...flatData };
        });

        return flatResults;
      };

      return { data: mapper(result) };
    });
  }
}
