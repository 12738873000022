import _ from 'lodash';
import { matchPath, PathPattern } from 'react-router-dom';
import { RouteMatchParamKey, RouteMatchParams } from '../OxSidebar.types';
import {
  pageMatchPattern,
  productMatchPattern,
  rogueMatchPattern,
  subPageMatchPattern
} from './routeMatchPattern';

const emptyMatch: RouteMatchParams = {
  product: undefined,
  page: undefined,
  subPage: undefined,
  '*': undefined
};

const confByLen = {
  [2]: productMatchPattern,
  [3]: pageMatchPattern,
  [4]: subPageMatchPattern
};

export function getRouteMatchParams(
  _pathname: string,
  {
    only,
    slim = true,
    pattern: _pattern
  }: { only?: RouteMatchParamKey[]; slim?: boolean; pattern?: string | PathPattern } = {}
): RouteMatchParams {
  const [pathname, hash] = _.split(_.trim(_pathname, '/'), '#');
  const pathLen = _.split(pathname, '/').length;
  /* find pattern by length, with fallbacks for over/under defined lengths */
  const pattern: string | PathPattern =
    _pattern ||
    (confByLen[pathLen as keyof typeof confByLen] ??
      (pathLen > 4 ? subPageMatchPattern : rogueMatchPattern));
  /* run match with given params  */
  const { params } = matchPath(pattern, `/${pathname}`) ?? { params: {} as RouteMatchParams };

  let ret = { ...params };
  if (hash) ret.hash = hash;
  /* remove params that are undefined/'' */
  if (slim) ret = _.omitBy(ret, _.isEmpty);
  else ret = _.defaults<object, RouteMatchParams, RouteMatchParams>({}, params, emptyMatch);

  /* select keys */
  if (only) ret = _.pick(ret, only);

  return ret;
}
