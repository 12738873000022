import { StyleGetter } from '@core/theme/typings';
import { SwitchStyleProps } from './Switch.types';

const transitionDur = 200;
const leftTransition = `left ${transitionDur}ms ease-in-out`;

export const css: StyleGetter<SwitchStyleProps> = (
  theme,
  { color = 'primary', gutter = theme.gutter }
) => {
  const {
    colors: { canvas, component, text },
    darken,
    desaturate,
    getColor,
    getTextColor
  } = theme;
  const clr = getColor(color);
  const uncheckedClr = component.offset4;
  return {
    wpr: {
      display: 'flex',
      alignItems: 'center',

      button: { flex: '0 0 auto' },
      '.rc-switch': {
        background: uncheckedClr,
        borderColor: uncheckedClr,
        borderWidth: 0,
        transition: 'background 250ms ease-in-out',
        lineHeight: '23px',
        outline: '1px solid transparent',

        '&:after': {
          top: 3,
          height: 16,
          width: 16,
          background: canvas.primary,
          boxShadow: `1px 1px 4px -2px ${darken(uncheckedClr, 60)}`,
          transform: 'none',
          animation: 'unset',
          transition: leftTransition
        },
        '.rc-switch-inner-checked, .rc-switch-inner-unchecked': {
          transition: leftTransition,
          fontSize: 14
        },
        '.rc-switch-inner-checked': { color: getTextColor(clr) },

        '&.rc-switch-disabled': {
          borderWidth: 0,
          background: canvas.disabled,
          boxShadow: 'none',
          '.rc-switch-inner-unchecked': { color: text.disabled },
          '.rc-switch-inner-checked': { color: canvas.disabled },

          '&:after': { background: darken(canvas.disabled, 33) },
          '&.rc-switch-checked': {
            background: desaturate(clr, 60),

            '&:after': { background: canvas.disabled }
          }
        },

        '&.rc-switch-checked': {
          borderWidth: 0,
          background: clr,
          '&:after': { left: 24.5 },
          '.rc-switch-inner-checked': { left: 8 }
        },

        '&:focus': { boxShadow: 'none' },

        '&:hover:not(.rc-switch-disabled)': {
          background: darken(uncheckedClr, 20),

          '&.rc-switch-checked': { background: darken(clr, 33) }
        }
      },
      '.switch-label': {
        marginLeft: gutter
      }
    }
  };
};
