import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IRejectedEtrmInvoicesRepository } from 'shared/api/interfaces/IRejectedEtrmInvoicesRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IRejectedEtrmInvoice } from 'shared/models/RejectedEtrmInvoice';

@injectable()
export class RejectedEtrmInvoicesRepository implements IRejectedEtrmInvoicesRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('settle/v1/invoices/etrm/rejected');

  async add(): Promise<AxiosResponse> {
    throw new Error('Method not implemented.');
  }

  async update(/* invoice: IRejectedEtrmInvoice */): Promise<AxiosResponse> {
    throw new Error('Method not implemented.');
  }

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + '/' + id);
  }

  async resubmit(id: string): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + '/' + id);
  }

  async getAll(): Promise<AxiosResponse<IRejectedEtrmInvoice[]>> {
    return await client.get(this.apiUrl);
  }

  async getById(id: string): Promise<AxiosResponse<IRejectedEtrmInvoice>> {
    return await client.get(this.apiUrl + '/' + id);
  }
}
