import {
  DoubleCheckCircleFillSvg,
  DoubleCheckCircleSvg,
  TachometerFastOutlineSvg,
  TachometerFastSvg,
  TimesCircleOutlineSvg,
  TimesCircleSvg
} from '@core/images';
import { ILinkInfo } from '../OxSidebar.types';

type OxSettlePageLinkParams = { rejectedInvoicesCount: number };

export const getOxSettlePageLinks: (params: OxSettlePageLinkParams) => ILinkInfo[] = (params) => [
  {
    LinkIcon: DoubleCheckCircleSvg,
    SelectedLinkIcon: DoubleCheckCircleFillSvg,
    linkText: 'oxSidebar.oxSettle.invoiceReconciliation',
    route: 'products/settle/invoice-reconciliation'
  },
  {
    LinkIcon: TimesCircleOutlineSvg,
    SelectedLinkIcon: TimesCircleSvg,
    linkText: ['oxSidebar.oxSettle.rejectedEtrmInvoices', { count: params.rejectedInvoicesCount }],
    route: 'products/settle/rejected-etrm-invoices'
  },
  {
    LinkIcon: TachometerFastOutlineSvg,
    SelectedLinkIcon: TachometerFastSvg,
    linkText: 'oxSidebar.oxSettle.sqtsDailyLevels',
    route: 'products/settle/daily-level-quantities'
  }
];
