import { EndpointSections, EndpointSectionUriMap } from './EndpointMap';

export const indexSection = ({ key, uriRoot }: { key: string; uriRoot: string }): void => {
  /* check for */
  if (EndpointSections.has(key)) {
    console.warn(`\
[Data Manager]: Registering duplicate section ${key}; \
please consider consolidating registries to prevent naming collisions`);
  }
  /* check for duplicate section uri */
  if (EndpointSectionUriMap.has(uriRoot)) {
    console.warn(`\
[Data Manager]: registry ${EndpointSectionUriMap.get(uriRoot)} also uses the api root \
${uriRoot}; please consolidate registries for clarity and ease-of-use`);
  }

  EndpointSections.add(key);
};
