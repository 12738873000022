import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IRejectedEtrmInvoicesRepository } from 'shared/api/interfaces/IRejectedEtrmInvoicesRepository';
import { IRejectedEtrmInvoice } from 'shared/models/RejectedEtrmInvoice';
import { IBaseThunk } from './base.thunks';

export class RejectedEtrmInvoicesThunks implements IBaseThunk<IRejectedEtrmInvoice> {
  private rejectedEtrmInvoicesRepository!: IRejectedEtrmInvoicesRepository;

  constructor(_rejectedEtrmInvoicesRepository: IRejectedEtrmInvoicesRepository) {
    this.rejectedEtrmInvoicesRepository = _rejectedEtrmInvoicesRepository;

    if (this.rejectedEtrmInvoicesRepository === null) {
      throw new Error('RejectedEtrmInvoicesRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'rejectedEtrmInvoices';

  add(): AsyncThunk<
    HttpResponse<IRejectedEtrmInvoice>,
    IRejectedEtrmInvoice,
    Record<string, unknown>
  > {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<
    HttpResponse<IRejectedEtrmInvoice>,
    IRejectedEtrmInvoice,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/updateRejectedEtrmInvoice`;
    return createAsyncThunk(
      action,
      async (rejectedEtrmInvoice: IRejectedEtrmInvoice, { rejectWithValue }) => {
        try {
          return await this.rejectedEtrmInvoicesRepository.update(rejectedEtrmInvoice);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }

  delete(): AsyncThunk<AxiosResponse<IRejectedEtrmInvoice, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteRejectedEtrmInvoice`;

    return createAsyncThunk(action, async (id: string, { rejectWithValue }) => {
      try {
        return await this.rejectedEtrmInvoicesRepository.delete(id);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  resubmit(): AsyncThunk<
    AxiosResponse<IRejectedEtrmInvoice, any>,
    string,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/resubmitRejectedEtrmInvoice`;

    return createAsyncThunk(action, async (id: string, { rejectWithValue }) => {
      try {
        return await this.rejectedEtrmInvoicesRepository.resubmit(id);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  getAll(): AsyncThunk<AxiosResponse<IRejectedEtrmInvoice[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchRejectedEtrmInvoices`;
    return createAsyncThunk(action, () => this.rejectedEtrmInvoicesRepository.getAll());
  }
}
