import { OxTypography, TextSize, TextWeight } from '@core/components';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import { StyledDateCalendar } from './StyledDateCalendar';

interface Props {
  selectedDate: Range;
  handleSelect: (ranges: RangeKeyDict) => void;
  months: number;
  maxDate?: Date;
  minDate?: Date;
  clearDateRange: () => void;
  handleShow: () => void;
}

export function OxMultiMonthRange({
  selectedDate,
  handleSelect,
  months,
  maxDate,
  minDate
}: // clearDateRange,
// handleShow
Props): JSX.Element {
  // const handleClear = () => {
  //   clearDateRange();
  //   handleShow();
  // };

  return (
    <StyledDateCalendar className='border-2 drop-shadow-md z-10'>
      <div className='flex p-4 bg-white'>
        <OxTypography size={TextSize.Large} weight={TextWeight.Medium}>
          Date Range
        </OxTypography>
        {/* <OxButton
          buttonSize={ButtonSize.default}
          buttonType={ButtonType.text}
          className='ml-auto'
          onClick={handleClear}
        >
          Clear Filter
        </OxButton> */}
      </div>
      <DateRangePicker
        ranges={[selectedDate]}
        retainEndDateOnFirstSelection
        onChange={handleSelect}
        months={months}
        data-testid='datePicker'
        direction='horizontal'
        maxDate={maxDate}
        minDate={minDate}
        // staticRanges={[]}
        // inputRanges={[]}
      />
    </StyledDateCalendar>
  );
}
