import { UserConfigFormatFn } from '@core/user-configs/typings';
import { GridViewsConfigTVal } from './GridStateConfig.types';

export const formatGridViewsConfig: UserConfigFormatFn<GridViewsConfigTVal> = (raw) => {
  return {
    activeGridView: null,
    customGridViews: [],
    ...(raw as Partial<GridViewsConfigTVal>)
  };
};
