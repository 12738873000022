import { HttpResponse, IOxUser } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ProductKeys } from 'redux/slices/tableFilter/tableFilters.slice';
import { IUserRepository } from 'shared/api/interfaces/IUserRepository';
import { IBaseThunk } from './base.thunks';

export class UserThunks implements IBaseThunk<IOxUser> {
  private readonly userRepository!: IUserRepository;

  constructor(_userRepository: IUserRepository) {
    this.userRepository = _userRepository;

    if (this.userRepository === null) {
      throw new Error('userRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'user';

  getAll(): AsyncThunk<AxiosResponse<IOxUser[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchUsers`;

    return createAsyncThunk(action, () => this.userRepository.getAll());
  }

  getAllAuth0(): AsyncThunk<AxiosResponse<any[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchAuth0Users`;

    return createAsyncThunk(action, () => this.userRepository.getAllAuth0());
  }

  getOne(): AsyncThunk<AxiosResponse<IOxUser, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchUser`;

    return createAsyncThunk(action, (id: string) => this.userRepository.getOne(id));
  }

  getAllForRole(): AsyncThunk<any, string, Record<any, any>> {
    const action = `${this.baseIdentifier}/getAllForRole`;

    return createAsyncThunk(action, async (roleId: string) => {
      const result = await this.userRepository.getAllForRole(roleId);
      return { [roleId]: result };
    });
  }

  getCurrentUser(): AsyncThunk<AxiosResponse<IOxUser, any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/currentUser`;

    return createAsyncThunk(action, () => this.userRepository.getCurrentUser());
  }

  add(): AsyncThunk<HttpResponse<IOxUser>, any, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addUser`;

    return createAsyncThunk(action, async (user: any, { rejectWithValue }) => {
      try {
        return await this.userRepository.add(user);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IOxUser>, any, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateUser`;

    return createAsyncThunk(action, async (payload: IOxUser, { rejectWithValue }) => {
      try {
        return await this.userRepository.update(payload);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IOxUser, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteUser`;

    return createAsyncThunk(action, async (id: string, { rejectWithValue }) => {
      try {
        return await this.userRepository.delete(id);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  getChangeLogs(): AsyncThunk<
    AxiosResponse<IOxUser, any>,
    { start: string; end: string },
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/getChangeLogs`;

    return createAsyncThunk(action, ({ start, end }) =>
      this.userRepository.getChangeLogs(start, end)
    );
  }

  logUserActivity(): AsyncThunk<
    AxiosResponse,
    { product: Omit<ProductKeys, ProductKeys.AllProducts> },
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/currentUser/activity`;

    return createAsyncThunk(action, ({ product }) => this.userRepository.logUserActivity(product));
  }
}
