import { useValueMemo } from '@core/hooks';
import { useReset } from '@core/reset';
import _ from 'lodash';
import { useMemo } from 'react';
import { UseGridLib, UseGridReturn } from '../typings';
import { useAgGridRef } from './useAgGridRef';
import { useGridFields } from './useGridFields';
import { useGridFilters } from './useGridFilters';

export function useGrid<
  TData extends object = any,
  FieldName extends string = any
>(): UseGridReturn<TData, FieldName> {
  const Grid = useAgGridRef<TData>();
  const filters = useGridFilters();
  const fields = useGridFields<TData, FieldName>();
  const reload = useReset();

  const lib = useMemo<UseGridLib<TData>>(
    () =>
      ({
        get Grid() {
          return Grid.current;
        },
        get gridHasRendered() {
          return Boolean(this.Grid?.api);
        },
        /* TODO: once useGridData is integrated, invalidate queries */
        reload
      }) as UseGridLib<TData>,
    []
  );

  return useValueMemo(
    () => ({
      ...lib,
      filters,
      fields
    }),
    [_.omitBy({ ...filters, ...fields }, _.isFunction), !!Grid.current?.api]
  );
}
