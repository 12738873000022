import {
  AggNodeValue,
  AggValueAggFunc,
  AggValueAggFuncParams,
  AggValueConfig,
  IAggValue,
  RowGroupTypeMap,
  TransformValuesFunc
} from '@core/grid/typings';
import _ from 'lodash';
import { AggValuePreset, aggValuePresetMap } from './aggValuePresetMap';

const pass = <T = unknown>(vals: T[]) => vals;
export class AggValue<
  TData extends object = any,
  FieldName extends string = any,
  TValue = any,
  TResult = TValue
> implements IAggValue<TData, FieldName, TValue, TResult>
{
  protected _compact: boolean = true;
  protected _isHidden: boolean = false;
  protected _uniq: boolean = false;
  protected _valuePath: string | undefined = undefined;
  protected _value: TResult = null as TResult;
  protected _values: TValue[] = [];
  protected _rowGroupTypeMap: RowGroupTypeMap = {};

  calculate: () => TResult = () => '' as TResult;
  rowGroupType: IAggValue['rowGroupType'] = undefined;
  transformValues: TransformValuesFunc = pass;
  countLabel: string;
  params: AggValueAggFuncParams<TData, FieldName>;
  isExcludingNoLoc = false;
  isExcludingPairedRecInd = false;
  isExcludingDupeFees = false;

  constructor(
    params: AggValueAggFuncParams<TData, FieldName>,
    config: AggValueConfig<TData, FieldName, TValue, TResult> = {}
  ) {
    this.params = params;
    const { colDef, column, rowNode } = params;
    const {
      headerName,
      __metadata__: { valuePath, countLabel, groupLevelDisplay, rowGroupTypeByColId } = {}
    } = colDef;
    this.countLabel = countLabel || headerName || 'Value';

    const aggFunc = column.getAggFunc();
    const preset = aggValuePresetMap[aggFunc as AggValuePreset] ?? {};
    const { field: rowGroupField } = rowNode;
    const {
      compactValues = true,
      calculate,
      transformValues,
      uniqueValues = false
    } = _.defaults({}, config, preset);

    if (!compactValues) this._compact = false;
    if (_.isFunction(transformValues)) this.transformValues = transformValues;
    if (_.isFunction(calculate)) this.calculate = calculate.bind(this);
    if (valuePath) this._valuePath = valuePath as string | undefined;

    const rowGroupType = (this.rowGroupType = rowGroupTypeByColId?.[rowGroupField as FieldName]);

    this._isHidden =
      (!!groupLevelDisplay?.showAt &&
        !_.includes(groupLevelDisplay.showAt, rowGroupType) &&
        !_.includes(groupLevelDisplay.showAt, rowGroupField)) ||
      (!!groupLevelDisplay?.hideAt &&
        (_.includes(groupLevelDisplay.hideAt, rowGroupType) ||
          _.includes(groupLevelDisplay?.hideAt, rowGroupField))) ||
      false;

    if (uniqueValues) this._uniq = _.isFunction(uniqueValues) ? uniqueValues(this) : true;

    this.values = params.values;
  }

  /** concats raw leaf/group values to standardized array of values  */
  set values(vals: TValue[]) {
    let out = _.reduce<AggNodeValue, TValue[]>(
      vals,
      (memo, val) => {
        /* handle a group node */
        if (!this.params.rowNode.leafGroup) {
          const { values } = val as IAggValue;
          if (_.isArray(values)) memo.push(...values);
          else memo.push(values);
          /* handle leaf node */
        } else {
          memo.push(
            this._valuePath && _.isPlainObject(val) ? _.get(val, this._valuePath) : _.toString(val)
          );
        }
        return memo;
      },
      []
    );
    if (this._compact) out = _.compact(out);
    if (this._uniq) out = _.uniq(out);
    this._values = this.transformValues(out);
  }
  /** called by calcuate() when converting value(s) to a string */
  get values() {
    return this._values;
  }

  get length() {
    return this.values.length;
  }

  get value() {
    if (!this._value) this._value = this.calculate();
    return this._value;
  }

  toString = () => {
    if (this._isHidden) return '';
    return _.toString(this.value);
  };

  toNumber = () => {
    return _.toFinite(this.value);
  };
}

export const createAggFunc =
  <TData extends object = any, FieldName extends string = any, TValue = any, TResult = TValue>(
    conf?: AggValueConfig
  ): AggValueAggFunc<TData, FieldName, TValue, TResult> =>
  (params) =>
    new AggValue<TData, FieldName, TValue, TResult>(params, conf);
