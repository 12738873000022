import styled from 'styled-components';

export const StyledButton = styled.button`
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;

  &.ox-button-compact {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 2px 13px;
  }

  &.ox-button-default {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 5px 14px;
  }

  &.ox-button-large {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 14px;
  }

  &.ox-button-primary {
    border: none;
    background-color: ${(props) => props.theme.primaryButtonBackground};
    color: ${(props) => props.theme.primaryButtonText};
  }

  &.ox-button-secondary {
    background-color: ${(props) => props.theme.secondaryButtonBackground};
    color: ${(props) => props.theme.secondaryButtonText};
    border: 1px ${(props) => props.theme.secondaryButtonBorder} solid;
  }

  &.ox-button-standard {
    border: none;
    background-color: ${(props) => props.theme.standardButtonBackground};
    color: ${(props) => props.theme.standardButtonText};
  }

  &.ox-button-danger {
    border: none;
    background-color: ${(props) => props.theme.dangerButtonBackground};
    color: ${(props) => props.theme.dangerButtonText};
  }

  &.ox-button-icon {
    background-color: ${(props) => props.theme.iconButtonBackground};
    color: ${(props) => props.theme.iconButtonText};
    border: 1px ${(props) => props.theme.iconButtonBorder} solid;
  }

  &.ox-button-ghost {
    background-color: ${(props) => props.theme.ghostButtonBackground};
    color: ${(props) => props.theme.ghostButtonText};
    border: 1px ${(props) => props.theme.ghostButtonBorder} solid;
  }

  &.ox-button-text {
    border: none;
    background-color: ${(props) => props.theme.textButtonBackground};
    color: ${(props) => props.theme.textButtonText};
  }

  &.ox-button-centered {
    div {
      justify-content: center;
    }
  }

  &.ox-button-settings {
    border: none;
    background-color: ${(props) => props.theme.primaryButtonBackground};
    color: ${(props) => props.theme.base};

    width: 32px !important;
    height: 32px !important;
    padding-left: 5.3px !important;
    padding-right: 5.1px !important;
    border-radius: 9999px !important;
  }

  // Define last in order to override other styles
  &.ox-button-disable {
    border: none;
    background-color: ${(props) => props.theme.disableButtonBackground};
    color: ${(props) => props.theme.disableButtonText};
  }

  // Adding temporary Black&Gray button styles for old OxButton component for "Clear All" button
  &.ox-button-beta {
    height: 27px;
    min-height: auto;
    padding: 8px 12px;
    background: #363a45;
    color: white;
    border: none;
    border-radius: 5px;
    line-height: 1;

    > div {
      color: white;
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .btn-label {
      font-size: 10px;
    }
    &:hover {
      background: #45506d;
    }

    &[disabled] {
      opacity: 0.5;

      &:hover {
        background: #363a45;
      }
    }

    &.core-dropdown-trigger-btn {
      background: none;
    }
  }
`;
