import { EndpointUrlMap } from './EndpointMap';

export const indexRoute = (method: string, url: string, name: string): string => {
  const indexKey = `${method}@${url}`;

  if (EndpointUrlMap.has(indexKey)) {
    throw new Error(`\
[Data Manager]: ignoring duplicate ${method} request configuration for ${url}; \
use ${EndpointUrlMap.get(indexKey)} instead\
`);
  }
  EndpointUrlMap.set(indexKey, name);
  return indexKey;
};
