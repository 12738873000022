import _isFinite from 'lodash/isFinite';
import { ReactChild, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Nullable } from '..';
import { ResetContext, ResetContextValue } from './ResetContext';

export interface ResetBoundaryProps {
  children: Nullable<ReactChild | ReactChild[]>;
  component?: ReactNode;
  defaultDelay?: number;
}

export function ResetBoundary(props: ResetBoundaryProps) {
  const { children, component = null, defaultDelay = 0 } = props;
  const delay = useRef<number>(defaultDelay);

  const [resetting, setResetting] = useState(false);

  const value = useMemo<ResetContextValue>(
    () => ({
      handleReset: (_delay?: number) => {
        if (_isFinite(_delay)) delay.current = _delay as number;
        setResetting(true);
      }
    }),
    []
  );

  useEffect(() => {
    if (resetting) {
      const unset = () => {
        setResetting(false);
      };
      if (!delay.current) unset();
      else setTimeout(unset, delay.current);
    } else {
      /* reset delay when cycle completes */
      delay.current = defaultDelay;
    }
  }, [resetting]);

  return (
    <ResetContext.Provider value={value}>{resetting ? component : children}</ResetContext.Provider>
  );
}
