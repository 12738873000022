/* add filteredLeafChildren to RowNode */
import { Nullable } from '@core/typings';
import { IRowNode, RowNode } from 'ag-grid-community';
import _ from 'lodash';

function getFilteredLeafChildren(nodes?: Nullable<IRowNode[]>): IRowNode[] {
  return _.flatten(
    _.map(nodes, (node) => {
      if (node.group) {
        /* check if filter has been applied to this node before recursing */
        if ((node.allChildrenCount ?? 0) < node.allLeafChildren.length) {
          return getFilteredLeafChildren(node.childrenAfterFilter);
        }
        /* return all children if there's no difference  */
        return node.allLeafChildren;
      }
      return node;
    })
  );
}

/*
  adds a property to the RowNode class that gets all the root-level children
  of that node after filters are applied.
 */
Object.defineProperty(RowNode.prototype, 'filteredLeafChildren', {
  get: function () {
    if (!this.group) return this.childrenAfterFilter;
    return getFilteredLeafChildren(this.childrenAfterFilter);
  }
});

declare module 'ag-grid-community' {
  /** flattened array of all leaf children under a RowNode, after filters are applied */
  interface IRowNode<TData = any> {
    filteredLeafChildren: IRowNode<TData>[] | undefined;
  }

  interface ColDef {
    __metadata__?: Record<string, unknown>;
  }
}
