import { HttpResponse } from '@core/typings';
import { IOxResource } from '@core/typings/Resource';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IResourceRepository } from '../../shared/api/interfaces/IResourceRepository';
import { IBaseThunk } from './base.thunks';

export class ResourceThunks implements IBaseThunk<IOxResource> {
  private readonly resourceRepository!: IResourceRepository;

  constructor(_resourceRepository: IResourceRepository) {
    this.resourceRepository = _resourceRepository;

    if (this.resourceRepository === null) {
      throw new Error('resourceRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'resource';

  getAll(): AsyncThunk<AxiosResponse<IOxResource[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchResources`;

    return createAsyncThunk(action, () => this.resourceRepository.getAll());
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getAllByProductId(): AsyncThunk<AxiosResponse<any, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchResourcesByProductId`;

    return createAsyncThunk(action, (id: string) => this.resourceRepository.getAllByProductId(id));
  }

  getOne(): AsyncThunk<AxiosResponse<IOxResource, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchResource`;

    return createAsyncThunk(action, (id: string) => this.resourceRepository.getOne(id));
  }

  add(): AsyncThunk<HttpResponse<IOxResource>, any, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addResource`;

    return createAsyncThunk(action, async (resource: any, { rejectWithValue }) => {
      try {
        return await this.resourceRepository.add(resource);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IOxResource>, any, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateResource`;

    return createAsyncThunk(action, async (payload, { rejectWithValue }) => {
      try {
        return await this.resourceRepository.update(payload.item);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IOxResource, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteResource`;

    return createAsyncThunk(action, async (id: string, { rejectWithValue }) => {
      try {
        return await this.resourceRepository.delete(id);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }
}
