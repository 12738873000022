import { DataTemplateType, InfiniteFilterParams } from '@core/typings';
import { createSlice } from '@reduxjs/toolkit';
import { UploadThunks } from 'redux/thunks/upload.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { IUploadRepository } from 'shared/api/interfaces/IUploadRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { UploadRow, UploadRows } from 'shared/models/UploadResponse';
import { buildGenericSlice } from '../../helpers/slice.helper';

interface GetRowsAction extends GenericPayload {
  meta?: {
    arg: {
      type: 'success' | 'failure';
      templateType: DataTemplateType;
      id: string;
      filterParams: InfiniteFilterParams;
    };
  };
  payload: {
    data: UploadRow[];
  };
}

type ValidationRowsType = {
  [key in DataTemplateType]: UploadRows;
};

export const initialState: ValidationRowsType = {
  chargeType: {},
  counterparty: {},
  interconnect: {},
  location: {},
  pipeline: {},
  zone: {},
  rateDefinition: {},
  rateSchedule: {},
  locationGroup: {},
  payIndex: {},
  pipelineInvoice: {},
  transactionType: {},
  pipelineTransportationAgreement: {},
  transportationAgreement: {},
  broker: {},
  brokerSpecRequest: {},
  counterpartySpecRequest: {},
  locationSpecRequest: {},
  payIndexSpecRequest: {},
  pipelineSpecRequest: {},
  zoneSpecRequest: {}
};

const slice = buildGenericSlice<ValidationRowsType>('validationRows', initialState);

const uploadRepository = container.get<IUploadRepository>(SERVICE_KEYS.UPLOAD_REPOSITORY);

const uploadThunks = new UploadThunks(uploadRepository);

export const getSuccessesAndFailures = uploadThunks.getSuccessesAndFailures();

export const validationRowsSlice = createSlice({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getSuccessesAndFailures.fulfilled,
      (state: ValidationRowsType, { payload, meta }: GetRowsAction) => {
        if (meta && payload?.data?.length) {
          const { arg } = meta;
          const { templateType = DataTemplateType.counterparty, id = '', type } = arg;

          if (templateType.length && id.length) {
            const { data } = payload;

            const sortNumbers = (a: UploadRow, b: UploadRow) => a.rowNumber - b.rowNumber;

            const newState = {
              ...state[templateType],
              [id]: {
                ...state[templateType][id],
                [type]: data.sort(sortNumbers)
              }
            };

            state[templateType] = newState;
          }
        }
      }
    );
  }
});

export const { addValidationRows, updateValidationRows, removeValidationRows } =
  validationRowsSlice.actions;

export const validationRowsReducer = validationRowsSlice.reducer;
