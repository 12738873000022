import { createSelector, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RootState } from 'redux/store';
import { CounterpartyThunks } from 'redux/thunks/counterparty.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { ICounterpartyRepository } from 'shared/api/interfaces/ICounterpartyRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { ICounterparty, ICounterpartySpec } from 'shared/models/Counterparty';
import { DeleteResponse } from 'shared/models/DeleteResponse';

type InitialStateType = {
  count?: number;
  changeId: number;
  counterparties: ICounterparty[];
  counterpartySpecs: ICounterpartySpec[];
};

const count = undefined;
const changeId = 0;
const counterparties: ICounterparty[] = [];
const counterpartySpecs: ICounterpartySpec[] = [];

export const initialState: InitialStateType = {
  count,
  changeId,
  counterparties,
  counterpartySpecs
};

const cpRepository = container.get<ICounterpartyRepository>(SERVICE_KEYS.COUNTERPARTY_REPOSITORY);
const counterpartyThunks = new CounterpartyThunks(cpRepository);
export const getAllCounterparties = counterpartyThunks.getAll();
export const addCounterparty = counterpartyThunks.add();
export const deleteAllCounterparty = counterpartyThunks.deleteAll();
export const getAllCounterpartySpecs = counterpartyThunks.getSpecs();
export const updateCounterparty = counterpartyThunks.update();
export const forceUpdateCounterparty = counterpartyThunks.forceUpdate();
export const createSpecRequest = counterpartyThunks.createSpecRequest();

const slice = buildGenericSlice<InitialStateType>('counterparty', initialState);
export const counterpartySlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>(
  {
    ...slice,
    extraReducers: (builder: any) => {
      builder.addCase(
        getAllCounterparties.fulfilled,
        (state: InitialStateType, { payload }: GenericPayload) => {
          if (payload && payload.data?.length) {
            const { data } = payload;
            state.counterparties = data;
            state.changeId++;
            state.count = data.length;
          }
        }
      );
      builder.addCase(
        addCounterparty.fulfilled,
        (state: InitialStateType, { payload }: GenericPayload) => {
          if (payload) {
            if (payload) {
              const counterparties = [...state.counterparties, payload.data];
              state.counterparties = counterparties;
              state.changeId++;
              state.count = counterparties.length;
            }
          }
        }
      );
      builder.addCase(
        getAllCounterpartySpecs.fulfilled,
        (state: InitialStateType, { payload }: GenericPayload) => {
          if (payload && payload.data && payload.data.length > 0) {
            state.counterpartySpecs = payload.data as ICounterpartySpec[];
            state.changeId++;
          }
        }
      );
      builder.addCase(
        deleteAllCounterparty.fulfilled,
        (state: InitialStateType, { payload }: GenericPayload) => {
          if (payload.status === 200) {
            const { data } = payload;
            state.changeId++;
            state.counterparties = state.counterparties.filter(
              (counterparty: ICounterparty) =>
                !data.find((response: DeleteResponse) => response.id === counterparty.etrmId)
                  ?.isSuccess
            );
          }
        }
      );
    }
  }
);

export const counterpartyReducer = counterpartySlice.reducer;

export const selectCounterparties = (state: RootState) => state.counterparty.counterparties;
export const selectCounterpartySpecs = (state: RootState) => state.counterparty.counterpartySpecs;

export const selectCounterpartyMap = createSelectorMap(selectCounterparties, 'etrmId');
export const selectCounterpartySpecMap = createSelectorMap(selectCounterpartySpecs, 'specId');

export interface IFQCounterparty extends ICounterparty {
  spec?: ICounterpartySpec;
}

export const selectFQCounterpartyRefs = createSelector(
  [selectCounterparties, selectCounterpartySpecMap],
  (counterparties, specMap) => {
    return counterparties.map<IFQCounterparty>((counterparty) => {
      return {
        ...counterparty,
        spec: specMap.get(counterparty.specId)
      };
    });
  }
);

export const selectFQCounterpartyMappedRefs = createSelector(
  [selectCounterparties, selectCounterpartySpecMap],
  (counterparties, specMap) => {
    return counterparties
      .map<IFQCounterparty | undefined>((counterparty) => {
        const spec = specMap.get(counterparty.specId);
        if (!spec || !spec.companyParty) {
          return;
        }

        return {
          ...counterparty,
          spec: specMap.get(counterparty.specId)
        };
      })
      .filter((counterparty): counterparty is IFQCounterparty => !!counterparty)
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  }
);
