import { GridViewsProviderParams } from '../GridStateConfig.types';
import { GridViewsToolPanel } from './GridViewsToolPanel';

export const gridViewsToolPanelId = 'gridViews';

export const getGridViewsToolPanel = ({
  configId,
  debug,
  presetMap,
  ...rest
}: GridViewsProviderParams) => ({
  id: 'gridViews',
  labelDefault: 'Views',
  labelKey: gridViewsToolPanelId,
  iconKey: 'menuPin',
  toolPanel: GridViewsToolPanel,
  minWidth: 200,
  width: 350,
  ...rest,
  toolPanelParams: { ...rest.toolPanelParams, debug, presetMap, configId }
});
