import { css } from './ResizableList.style';

import { useMeasure } from '@core/hooks';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import _ from 'lodash';
import { Resizable } from 're-resizable';
import { ComponentType, LegacyRef, useEffect, useState } from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { Text } from '../Text';
import { ResizableListProps, ResizeableListVirtualListProps } from './ResizableList.types';

export function ResizableList(props: ResizableListProps) {
  const {
    children,
    color,
    ListItem,
    itemCount: _itemCount,
    resizerProps,
    title,
    theme = {},
    listProps: {
      headerHeight = _.isEmpty(title) ? 0 : 57,
      itemCount = _itemCount,
      itemSize = 64,
      ...listProps
    } = {} as ResizeableListVirtualListProps
  } = props;

  const [wprRef, { height, width: wprWidth }] = useMeasure();
  const [width, setWidth] = useState<number>(0);
  useEffect(() => {
    if (wprWidth && !width) {
      const initialWidth = wprWidth / 4;
      setWidth(initialWidth);
    }
  }, [wprWidth]);

  /* baseWidth is the width the list was at the time resizing started */
  const [baseWidth, setBaseWidth] = useState<number>(0);
  useEffect(() => {
    if (!baseWidth) setBaseWidth(width);
  }, [width]);

  const cls = useCss(css, { color, headerHeight, theme });
  return (
    <div className={cn(cls.wpr)} ref={wprRef as LegacyRef<HTMLDivElement>}>
      <div className='list-wpr'>
        <Resizable
          className='list-resizer'
          size={{ width, height }}
          minWidth={200}
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          {...resizerProps}
          onResizeStart={(...args) => {
            setBaseWidth(width);
            resizerProps?.onResizeStart?.(...args);
          }}
          onResize={(e, dir, ref, dlt) => {
            setWidth(baseWidth + dlt.width);
            resizerProps?.onResize?.(e, dir, ref, dlt);
          }}
          handleClasses={{
            ...resizerProps?.handleClasses,
            right: cn('resize-handle', resizerProps?.handleClasses?.right)
          }}
        >
          <div className='list-hdr' style={{ width }}>
            {_.isString(title) ? <Text h2 t={title} className='list-title' /> : title}
          </div>
          <List
            /* eslint-disable react/no-children-prop */
            children={ListItem as ComponentType<ListChildComponentProps>}
            {...{ itemCount, itemSize, ...listProps, width }}
            className={cn('list', listProps.className)}
            height={height - headerHeight}
          />
        </Resizable>
      </div>
      <div className='detail-wpr' style={{ width: `calc(100% - ${width}px)` }}>
        {children}
      </div>
    </div>
  );
}
