import { DeepEqual } from '@core/typings';
import isEqual from 'fast-deep-equal';
import { useRef } from 'react';
import { useIsFirstRender } from './useIsFirstRender';

export function usePreviousValue<X>(value: X, compare: DeepEqual = isEqual): X | undefined {
  const prevRef = useRef<X>();
  const curRef = useRef<X>(value);
  const isFirstRender = useIsFirstRender();

  if (!isFirstRender && !compare(curRef.current, value)) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
}
