export default {
  table: {
    filename: 'File Name',
    status: 'Status',
    rows: 'Rows',
    successes: 'Successes',
    failures: 'Failures',
    date: 'Date',
    createdAt: 'Created At',
    uploadedBy: 'Uploaded By'
  }
} as const;
