import styled from 'styled-components';

export const StyledFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;

  &.--is-inline {
    flex-direction: column;
  }

  .ox-field {
    width: 100%;
    border-radius: 4px;
    padding: 6px 8px;
    margin-bottom: 12px;
    line-height: 1.25;
    border: 1px solid ${(props) => props.theme.contrast_15};
    transition: all 200ms ease-in-out;
  }

  .field-error {
    color: ${(props) => props.theme.fieldError};
    outline: 1px solid ${(props) => props.theme.fieldError};
    border-color: ${(props) => props.theme.fieldError};
    background-color: transparent !important;
  }

  input:focus {
    outline: 1px solid ${(props) => props.theme.fieldFocus};
    border-color: ${(props) => props.theme.fieldFocus} !important;
  }

  input[readonly] {
    border: none;
    padding-left: 0px;
  }

  input[disabled] {
    background-color: #d9dada;
  }
`;

export const StyledFieldLabel = styled.label`
  color: ${(props) => props.theme.contrast - 85};
`;
