import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { ChangePasswordData } from 'redux/slices/authentication/authentication.slice';
import 'reflect-metadata';
import { IAuthRepository } from 'shared/api/interfaces/IAuthRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';

@injectable()
export class AuthRepository implements IAuthRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('dbconnections/');

  changePassword = async (data: ChangePasswordData): Promise<AxiosResponse> => {
    return await client.post(this.apiUrl + 'change_password', data);
  };
}
