import { ButtonSize, ButtonType, OxButton, Text } from '@core/components';
import { useDidUpdate } from '@core/hooks';
import { DateFormatOptions, DatePickerProps, GridRange } from '@core/typings';
import {
  createCurrentMonthDateRange,
  localizeDateString,
  maxNumberOfDaysInCalendarRange
} from '@core/util';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Range, RangeKeyDict } from 'react-date-range';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
import {
  OxDatePickerInputs,
  OxDatePickerWrapper,
  OxDateRange,
  OxMultiMonthRange
} from './components';

interface Props extends DatePickerProps {
  setGridRange?: Dispatch<SetStateAction<GridRange>> | ((dateRange: GridRange) => void);
  endDateOverride?: Date;
  months?: number;
  maxDate?: Date;
  minDate?: Date;
  ignoreDateFilter?: boolean;
  clearDateRange?: () => void;
  disabled?: boolean;
}

export interface DateRangePropsV2 extends Props {
  allowExternalControl?: boolean; // lets startDateOverride and endDateOverride control the widget
  disableMultiMonthRange?: boolean; // restricts date range selection to within a single month
  label?: string;
}

export function OxDateRangePicker({
  setGridRange,
  startDateOverride,
  endDateOverride,
  setCalendarDate,
  months,
  maxDate,
  minDate,
  ignoreDateFilter,
  clearDateRange,
  disabled = false,
  dateOptions = {
    year: DateFormatOptions.NUMERIC,
    month: DateFormatOptions.TWO_DIGIT,
    day: DateFormatOptions.TWO_DIGIT
  }
}: Props): JSX.Element {
  const featureFlags = useAppSelector((state: RootState) => state.featureFlags);
  const validStartDate = startDateOverride?.getTime() === startDateOverride?.getTime();
  const validEndDate = endDateOverride?.getTime() === endDateOverride?.getTime();
  const [selectedDate, setSelectedDate] = useState<Range>({
    startDate: validStartDate ? startDateOverride : new Date(),
    endDate: validEndDate ? endDateOverride : new Date(),
    key: 'selection'
  });

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [hasCleared, setHasCleared] = useState<boolean>(false);
  const handleShow = () => setShowCalendar(!showCalendar);

  const [startDateString, setStartDateString] = useState<string | undefined>(
    ignoreDateFilter ? '' : localizeDateString(selectedDate.startDate, dateOptions)
  );
  const [endDateString, setEndDateString] = useState<string | undefined>(
    ignoreDateFilter ? '' : localizeDateString(selectedDate.endDate, dateOptions)
  );

  useEffect(() => {
    setSelectedDate({
      startDate: startDateOverride,
      endDate: endDateOverride,
      key: 'selection'
    });
    setStartDateString(localizeDateString(startDateOverride, dateOptions));
    setEndDateString(localizeDateString(endDateOverride, dateOptions));
  }, [startDateOverride, endDateOverride]);

  const handleSelect = (ranges: RangeKeyDict) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    const maxDays = featureFlags.adjustableDateRange.enabled
      ? featureFlags.adjustableDateRange.value
      : maxNumberOfDaysInCalendarRange;

    const diffInDays = dayjs(endDate).diff(dayjs(startDate), 'day');
    if (diffInDays <= maxDays) {
      setStartDateString(localizeDateString(ranges.selection.startDate, dateOptions));
      setEndDateString(localizeDateString(ranges.selection.endDate, dateOptions));
    } else {
      setStartDateString(localizeDateString(ranges.selection.startDate, dateOptions));
      setEndDateString(
        localizeDateString(dayjs(startDate).add(maxDays, 'day').toDate(), dateOptions)
      );
      ranges.selection.endDate = dayjs(startDate).add(maxDays, 'day').toDate();
    }
    setSelectedDate(ranges.selection);
  };

  useDidUpdate(() => {
    if (selectedDate) {
      let start = '';
      let end = '';
      if (selectedDate.startDate) {
        const selectedStart = new Date(selectedDate.startDate);
        start = selectedStart.toLocaleDateString('en-US');
      }
      if (selectedDate.endDate) {
        const selectedEnd = new Date(selectedDate.endDate);
        end = selectedEnd.toLocaleDateString('en-US');
      }
      if (start.length && end.length && !hasCleared) {
        setGridRange &&
          setGridRange({
            startDate: start,
            endDate: end
          });
      } else if (start.length) {
        setCalendarDate && setCalendarDate(selectedDate.startDate);
      }
      setHasCleared(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (ignoreDateFilter) {
      setStartDateString('');
      setEndDateString('');
    }
  }, [ignoreDateFilter]);

  const handleClear = () => {
    if (clearDateRange) {
      setHasCleared(true);
      clearDateRange();
    }

    const { startDate, endDate } = createCurrentMonthDateRange();
    setSelectedDate({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: 'selection'
    });
  };

  const datePicker =
    months && clearDateRange ? (
      <OxMultiMonthRange
        selectedDate={selectedDate}
        handleSelect={handleSelect}
        months={months}
        maxDate={maxDate}
        minDate={minDate}
        clearDateRange={handleClear}
        handleShow={handleShow}
      />
    ) : (
      <OxDateRange selectedDate={selectedDate} handleSelect={handleSelect} />
    );

  const dateRangePicker = (
    <OxDatePickerWrapper
      popoverContent={datePicker}
      setShowCalendar={setShowCalendar}
      showCalendar={showCalendar}
    >
      <OxDatePickerInputs
        handleShow={handleShow}
        startDateString={startDateString}
        endDateString={endDateString}
        disabled={disabled}
      />
    </OxDatePickerWrapper>
  );

  return dateRangePicker;
}

export function OxDateRangePickerV2({
  setGridRange,
  startDateOverride,
  endDateOverride,
  setCalendarDate,
  months,
  maxDate,
  minDate,
  ignoreDateFilter,
  clearDateRange,
  disabled = false,
  label = '',
  dateOptions = {
    year: DateFormatOptions.NUMERIC,
    month: DateFormatOptions.TWO_DIGIT,
    day: DateFormatOptions.TWO_DIGIT
  },
  allowExternalControl = false,
  disableMultiMonthRange
}: DateRangePropsV2): JSX.Element {
  const featureFlags = useAppSelector((state: RootState) => state.featureFlags);
  const validStartDate = startDateOverride?.getTime() === startDateOverride?.getTime();
  const validEndDate = endDateOverride?.getTime() === endDateOverride?.getTime();
  const [selectedDate, setSelectedDate] = useState<Range>({
    startDate: validStartDate ? startDateOverride : new Date(),
    endDate: validEndDate ? endDateOverride : new Date(),
    key: 'selection'
  });

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [hasCleared, setHasCleared] = useState<boolean>(false);
  const handleShow = () => setShowCalendar(!showCalendar);

  const [startDateString, setStartDateString] = useState<string | undefined>(
    ignoreDateFilter ? '' : localizeDateString(selectedDate.startDate, dateOptions)
  );
  const [endDateString, setEndDateString] = useState<string | undefined>(
    ignoreDateFilter ? '' : localizeDateString(selectedDate.endDate, dateOptions)
  );

  useEffect(() => {
    if (!allowExternalControl) return;
    setSelectedDate({
      startDate: startDateOverride,
      endDate: endDateOverride,
      key: 'selection'
    });
    setStartDateString(localizeDateString(startDateOverride, dateOptions));
    setEndDateString(localizeDateString(endDateOverride, dateOptions));
  }, [startDateOverride, endDateOverride, allowExternalControl]);

  const handleSelect = (ranges: RangeKeyDict) => {
    if (ranges.selection) {
      const startDate = ranges.selection.startDate;
      const endDate = ranges.selection.endDate;

      const maxDays = featureFlags.adjustableDateRange.enabled
        ? featureFlags.adjustableDateRange.value
        : maxNumberOfDaysInCalendarRange;

      const diffInDays = dayjs(endDate).diff(dayjs(startDate), 'day');
      if (diffInDays <= maxDays) {
        setStartDateString(localizeDateString(ranges.selection.startDate, dateOptions));
        setEndDateString(localizeDateString(ranges.selection.endDate, dateOptions));
      } else {
        setStartDateString(localizeDateString(ranges.selection.startDate, dateOptions));
        setEndDateString(
          localizeDateString(dayjs(startDate).add(maxDays, 'day').toDate(), dateOptions)
        );
        ranges.selection.endDate = dayjs(startDate).add(maxDays, 'day').toDate();
      }
      setSelectedDate(ranges.selection);
    } else {
      const startDate = ranges.range1.startDate;
      const endDate = ranges.range1.endDate;

      const maxDays = featureFlags.adjustableDateRange.enabled
        ? featureFlags.adjustableDateRange.value
        : maxNumberOfDaysInCalendarRange;

      const diffInDays = dayjs(endDate).diff(dayjs(startDate), 'day');
      if (diffInDays <= maxDays) {
        setStartDateString(localizeDateString(ranges.range1.startDate, dateOptions));
        setEndDateString(localizeDateString(ranges.range1.endDate, dateOptions));
      } else {
        setStartDateString(localizeDateString(ranges.range1.startDate, dateOptions));
        setEndDateString(
          localizeDateString(dayjs(startDate).add(maxDays, 'day').toDate(), dateOptions)
        );
        ranges.range1.endDate = dayjs(startDate).add(maxDays, 'day').toDate();
      }
      setSelectedDate(ranges.range1);
    }
  };

  const handleShownDateChange = (date: Date) => {
    setStartDateString(localizeDateString(date, dateOptions));
    setEndDateString(localizeDateString(date, dateOptions));
    setSelectedDate({
      startDate: new Date(date),
      endDate: new Date(date),
      key: 'selection'
    });
  };

  useDidUpdate(() => {
    if (selectedDate) {
      let start = '';
      if (selectedDate.startDate) {
        const selectedStart = new Date(selectedDate.startDate);
        start = selectedStart.toLocaleDateString('en-US');
      }
      if (start.length) {
        setCalendarDate && setCalendarDate(selectedDate.startDate);
      }
      setHasCleared(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (ignoreDateFilter) {
      setStartDateString('');
      setEndDateString('');
    }
  }, [ignoreDateFilter]);

  const handleClear = () => {
    if (clearDateRange) {
      setHasCleared(true);
      clearDateRange();
    }

    const { startDate, endDate } = createCurrentMonthDateRange();
    setSelectedDate({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: 'selection'
    });
  };

  const datePicker =
    months && clearDateRange ? (
      <OxMultiMonthRange
        selectedDate={selectedDate}
        handleSelect={handleSelect}
        months={months}
        maxDate={maxDate}
        minDate={minDate}
        clearDateRange={handleClear}
        handleShow={handleShow}
      />
    ) : (
      <>
        <OxDateRange
          selectedDate={selectedDate}
          handleSelect={handleSelect}
          maxDate={maxDate}
          minDate={minDate}
          handleShownDateChange={disableMultiMonthRange ? handleShownDateChange : undefined}
        />

        <OxButton
          style={{ position: 'absolute', bottom: '0px', right: '0px' }}
          buttonType={ButtonType.primary}
          buttonSize={ButtonSize.compact}
          onClick={() => {
            if (selectedDate) {
              let start = '';
              let end = '';
              if (selectedDate.startDate) {
                const selectedStart = new Date(selectedDate.startDate);
                start = selectedStart.toLocaleDateString('en-US');
              }
              if (selectedDate.endDate) {
                const selectedEnd = new Date(selectedDate.endDate);
                end = selectedEnd.toLocaleDateString('en-US');
              }
              if (start.length && end.length && !hasCleared) {
                setGridRange &&
                  setGridRange({
                    startDate: start,
                    endDate: end
                  });
              } else if (start.length) {
                setCalendarDate && setCalendarDate(selectedDate.startDate);
              }
              setHasCleared(false);
            }
            setShowCalendar(false);
          }}
        >
          Submit
        </OxButton>
      </>
    );

  const closeCalendar = () => {
    setShowCalendar(false);
    setSelectedDate({ startDate: startDateOverride, endDate: endDateOverride });
    setStartDateString(localizeDateString(startDateOverride, dateOptions));
    setEndDateString(localizeDateString(endDateOverride, dateOptions));
  };

  const dateRangePicker = (
    <OxDatePickerWrapper
      popoverContent={datePicker}
      setShowCalendar={closeCalendar}
      showCalendar={showCalendar}
    >
      <div className='flex items-center'>
        {label && (
          <Text medium className='mr-2 inline-block'>
            {label}
          </Text>
        )}
        <OxDatePickerInputs
          handleShow={handleShow}
          startDateString={startDateString}
          endDateString={endDateString}
          disabled={disabled}
        />
      </div>
    </OxDatePickerWrapper>
  );

  return dateRangePicker;
}
