import { JsonMap } from '@core/typings';
import { InternalStateBase } from '@core/util';
import { ErrorObject } from 'ajv';
import isEq from 'fast-deep-equal';
import _ from 'lodash';
import {
  AdHocUserConfigRegistryParams,
  AtomicChangeEvent,
  DefinedUserConfigRegistryParams,
  IUserConfig,
  UserConfigPostSaveParams,
  UserConfigRemoteSaveOptions,
  UserConfigState,
  UserConfigStateNotReady,
  UserConfigStoredData,
  UserConfigType
} from '../typings';
import { userConfigDefaultValueMap } from '../util';
import { Ajv } from './Ajv';
import { getIntegration } from './integrations';
import { UserConfigIntegration } from './integrations/UserConfigIntegration';
import { UserConfigManager } from './UserConfigManager';

export class UserConfig<TVal extends JsonMap = JsonMap>
  extends InternalStateBase<UserConfigState<TVal>, UserConfigStateNotReady<TVal>>
  implements IUserConfig<TVal>
{
  private _integration: UserConfigIntegration<TVal>;
  private _registryParams:
    | DefinedUserConfigRegistryParams<TVal>
    | AdHocUserConfigRegistryParams<TVal>;
  constructor({ key, type }: { key: string; type: UserConfigType }) {
    /* TODO:
     * instead of using type inference here, we should pass the config type as a generic to the class.
     * this will create strong overloads.
     */
    type TType = typeof type;
    super({
      emitDetailedChanges: true,
      emitOnValidationFailed: true,

      initialState: {
        current: null,
        hydrated: null,
        isHydrated: false,
        isHydrating: false,
        metadata: null,
        saved: null,
        savedAt: null,
        updatedAt: null
      }
    });

    /* NOTE: lookup is done here (instead of passing to constructor) to ensure registration */
    this._registryParams = UserConfigManager.getRegistryParams<TType, TVal>(key)!;
    if (!this._registryParams) throw `Failed to create unregistered User Config ${key}`;

    this._integration = (
      type === 'custom' || type === 'filterSet'
        ? getIntegration<TVal>({
            type,
            key,
            schema: (this._registryParams as AdHocUserConfigRegistryParams<TVal>).schema
          })
        : getIntegration<TVal>({ type })
    ) as UserConfigIntegration<TVal>;

    /* update persisted cache when  */
    const watchedStateKeys = ['_t', 'current', 'metadata'];
    this.on('change', async (nState: UserConfigState<TVal>, pState: UserConfigState<TVal>) => {
      /* when state.current changes (after hydrate), update local cache automatically */
      if (
        nState.isHydrated &&
        !isEq(_.pick(nState, watchedStateKeys), _.pick(pState, watchedStateKeys))
      ) {
        await UserConfigManager.updateLocal(this.key, {
          value: nState.current,
          metadata: nState.metadata,
          _t: nState.updatedAt,
          type: this.type
        });

        /* push to remote if auto-saving */
        if (this._registryParams.autoSave) this.saveRemote();
      }
    });

    this.on('validation-failed', (errors: ErrorObject[]) => {
      console.error(Ajv.errorsText(errors));
    });

    /* listen for changes to remote state for this config */
    UserConfigManager.on(`save:${this.key}`, (nVal: AtomicChangeEvent<TVal>) => {
      const { value, _t, metadata } = nVal;
      this.setState({ saved: value, savedAt: _t, metadata }, () => {
        this.emit('save', nVal);
      });
    });
  }

  public override transform = (state: UserConfigState<TVal>) => {
    const { current, isHydrated, isHydrating } = state;
    if (isHydrating || !isHydrated) return state;
    return {
      ...state,
      current: this._integration.format(current)
    } as UserConfigState<TVal>;
  };

  public override validate = ({ current, isHydrated, isHydrating }: UserConfigState<TVal>) => {
    /* setState gets called once during `.hydrate` while state is still null and is ignored. */
    if (isHydrating || !isHydrated) return true;
    const isValid = this._integration.validate(current);
    /* these conditions should be tied together, but just in case... */
    if (isValid && !this._integration.validate.errors) return isValid;
    /* return errors */
    return this._integration.validate.errors as ErrorObject[];
  };

  public hydrate = async ({ isBatched = false }: { isBatched?: boolean } = {}) => {
    if (this.state.isHydrated) {
      console.warn(`cannot rehydrate User Config ${this.key}`);
      return undefined;
    }

    if (this.state.isHydrating) return undefined;
    await this.setStateAsync({ isHydrating: true });

    const pLocal = (await UserConfigManager.getLocalConfig(this.key)) ?? undefined;
    const pRemote = UserConfigManager.getSavedConfig(this.key) as UserConfigStoredData<TVal>;
    let local = (pLocal ? { ..._.cloneDeep(pLocal) } : null) as UserConfigStoredData<TVal>;
    let remote = { ..._.cloneDeep(pRemote) };
    let current: UserConfigStoredData<TVal>;
    const isNew = _.isNull(local) && _.isNull(remote);
    const typeHasChanged =
      (!_.isEmpty(local) && local.type !== this.type) ||
      (!_.isEmpty(remote) && remote.type !== this.type);

    /* config doesn't exist or has a new type; create a new config */
    const { initialValue: _initialValue } = this._registryParams;
    const initialValue = _.isEmpty(_initialValue)
      ? userConfigDefaultValueMap[this.type]
      : _initialValue || {};

    // console.log({ key: this.key, local, remote, isNew, typeHasChanged });

    if (isNew || typeHasChanged) {
      const newItem = {
        type: this.type,
        value: initialValue,
        _t: 0
      } as UserConfigStoredData<TVal>;
      current = remote = local = newItem;

      /* local exists but remote does not; use local config and then update remote */
    } else if (_.isNull(remote)) {
      current = remote = local;
      /* remote exists but local does not OR config should revert on load; use remote */
    } else if (_.isNull(local)) {
      current = local = remote;
      /* both current and remote exist — populate based on preference */
    } else {
      switch (this.preferredHydrationValue) {
        case 'local':
          current = local;
          break;
        case 'remote':
          current = remote;
          await this._hardSetCacheValue(current);
          break;
        case 'latest':
          current = _.maxBy<UserConfigStoredData<TVal>>(
            [remote, local],
            '_t'
          ) as UserConfigStoredData<TVal>;
          // console.log({ remote, local, current });
          break;
      }
    }
    const iKeys = _.keys(initialValue);
    const cKeys = _.keys(current.value);

    /* if new keys have been added to the model,  */
    if (iKeys.length > cKeys.length) {
      current = { ...current, value: { ...initialValue, ...current.value }, _t: Date.now() };
    }
    if (this._registryParams.resolve) {
      current.value = this._registryParams.resolve({
        local,
        remote,
        current: current.value,
        isNew,
        typeHasChanged
      });
    }

    /* local was modified, so update the cache */
    const shouldUpdateRemote = !isEq(pRemote, remote);

    const { value, _t, metadata } = current;

    const payload: UserConfigState<TVal> = {
      updatedAt: _t,
      metadata,
      current: value,
      hydrated: value,
      isHydrated: true,
      isHydrating: false,
      saved: shouldUpdateRemote ? value : remote.value,
      savedAt: shouldUpdateRemote ? _t : remote._t
    };
    try {
      await this.setStateAsync(payload);
      if (shouldUpdateRemote) {
        if (!isBatched) this.saveRemote({ silent: true });
        else return local;
      }
      return undefined;
    } catch (e) {
      console.error(
        `${this.label} hydration failed with: "${_.isArray(e) ? Ajv.errorsText(e) : _.toString(e)}". Resetting local config to initial value.`
      );
      this.setState({
        ...payload,
        updatedAt: Date.now(),
        current: initialValue as TVal
      });
    }
  };

  /* internal — force-update the cache to the value provided (full store value) */
  private _hardSetCacheValue = async (data: UserConfigStoredData<TVal>) => {
    return UserConfigManager.updateLocal(this.key, data);
  };

  /** update remote value of config */
  public saveRemote = (options?: UserConfigRemoteSaveOptions) => {
    UserConfigManager.updateRemote({ key: this.key, data: this.toJSON(), options });
  };

  /* *********************************************************************************** UTIL FNS */
  public getCurrent = () => this.getState().current;

  private _toStoredValue = (value = this.current): UserConfigStoredData<TVal> => ({
    _t: this.savedAt,
    type: this.type,
    metadata: this.metadata,
    value
  });

  public toJSON = (value = this.current) => {
    if (!this.state.isHydrated) throw 'Cannot deserialize non-initialized instance';
    try {
      return JSON.parse(JSON.stringify(this._toStoredValue(value)));
    } catch (e) {
      console.error(`failed to convert config ${this.key} to JSON Object`);
      throw e;
    }
  };

  public toObject = () => _.toPlainObject(this._toStoredValue());
  public toString = () => _.toString(this._toStoredValue());

  private _check = () => {
    if (!this.state.isHydrated) throw `Config has not been hydrated`;
    return true;
  };
  /* ********************************************************************************** MUTATIONS */
  public update = (payload: Partial<TVal>, params?: UserConfigPostSaveParams): TVal => {
    try {
      const { current } = this.setState({
        current: { ...this.current, ...payload },
        updatedAt: Date.now()
      });
      return current as TVal;
    } finally {
      if (params) {
        if (params.saveRemote === true) this.save();
        else if (params.saveRemote !== false) this.saveRemote(params.saveRemote);
      }
    }
  };

  public updateMetadata = (payload: JsonMap): JsonMap => {
    this._check();
    const { metadata } = this.setState({
      metadata: {
        ...this.state.metadata,
        ...payload
      }
    });
    return metadata;
  };

  public revertToSaved = () => {
    const { current } = this.setState({ current: this.saved, updatedAt: this.savedAt });
    return current;
  };

  public reset = () => {
    const { autoSave, initialValue } = this._registryParams;
    const resetVal = _.isEmpty(initialValue) ? userConfigDefaultValueMap[this.type] : initialValue;
    const nLocal = this.update(resetVal as TVal);
    if (autoSave) this.saveRemote();
    return nLocal;
  };

  public save = () => {
    this.saveRemote();
  };
  /* ******************************************************************************* DATA GETTERS */
  get current() {
    this._check();
    return this.state.current as TVal;
  }
  get isDirty() {
    return !isEq(this.saved, this.current);
  }
  get isHydrated() {
    return this.state.isHydrated;
  }
  get isInitial() {
    return isEq(this.current, this._registryParams.initialValue);
  }
  get key() {
    return this._registryParams.key;
  }
  get label() {
    return this._registryParams.label || this.key;
  }
  get metadata() {
    this._check();
    return this.state.metadata;
  }
  get pathname() {
    return this._registryParams.pathname;
  }
  get saved() {
    this._check();
    return this.state.saved as TVal;
  }
  get savedAt() {
    this._check();
    return this.state.savedAt;
  }
  get preferredHydrationValue() {
    return this._registryParams.preferredHydrationValue;
  }
  get type() {
    return this._registryParams.type;
  }
  get updatedAt() {
    return this.state.updatedAt;
  }
  get value() {
    return this.current;
  }
}
