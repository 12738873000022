const pair = {
  pair: {
    drawer: {
      internalComments: 'Company Comments',
      externalComments: 'Counterparty Comments',
      shareComment: 'Share with counterparty',
      history: 'History',
      allActivity: 'All',
      eleoxSystem: 'Eleox System'
    },
    exceptions: {
      title: 'Ox Pair Exceptions',
      viewBy: 'View By',
      viewAs: 'Legal Entity',
      dateRange: 'Date Range',
      clearFilter: 'Clear Filter',
      team: 'Team',
      tradeId: 'Trade',
      cp: 'CP',
      assignee: 'Assignee',
      details: 'Details',
      transactionType: 'Transaction Type',
      dealAttributes: 'Deal Attributes',
      activity: 'Activity',
      accordion: {
        potentialMatch:
          'Eleox found potential matches for this deal. Review potential inbound deal matches for your outbound deal.',
        pairException:
          'This deal has been amended by the counterparty. Please review the discrepancies and select whether this deal should be reconciled with counterparty.',
        rejectedDeals: 'Associated deal legs'
      },
      myData: 'My ETRM Data',
      counterpartyData: 'Counterparty Spec Data',
      mySpecData: 'My Spec Data',
      potentialMatch: 'Potential Match'
    },
    deals: {
      title: 'Ox Pair Deals',
      counterpartyFilterPlaceholder: 'Filter by counterparty',
      rollupDealsError: 'Error getting deal counts.',
      dealCountExceeded:
        'The number of deals exceeds the maximum allowed. Please refine your search.',
      noLegalEntitiesAssigned:
        'Unable to search for deals. No legal entities are assigned to the current user.'
    },
    configurations: {
      title: 'Ox Pair Configurations',
      performanceMetrics: {
        title: 'Customize performance metrics to display',
        description: 'Choose which metrics are visible on your working area',
        inboundDeals: 'Inbound Deals',
        outboundDeals: 'Outbound Deals',
        unpairedDeals: 'Unpaired Deals',
        pairedX: 'PairedX',
        terminatedDeals: 'Terminated Deals'
      },
      tradeIndicators: {
        title: 'Trade date indicators',
        description:
          'Tailor your desired threshold for urgency within each status type for trade deals that have past',
        onTrack: 'On Track',
        atRisk: 'At Risk',
        offTrack: 'Off Track'
      }
    },
    table: {
      allDeals: {
        checkbox: '',
        clickToPair: 'Click to Pair',
        withdrawDeal: 'Withdraw',
        withdrawAgreement: 'Are you sure you want to withdraw deal {{deal}}?',
        withdrawSuccess: 'Deal successfully withdrawn.',
        withdrawError: 'Error withdrawing deal.',
        ctpError: 'Error pairing deal.',
        clickToPairAgreement:
          'Please confirm you wish to change your delivery location to {{field}}.',
        'confirmLocationId.name': 'Confirm Location',
        clickToPairReason: 'Reason required',
        etrmId: 'ETRM ID',
        tradeId: 'Trade',
        tradeType: 'Trade Type',
        tradeDateDurationStatus: 'State',
        dealStatus: 'Status',
        'buyerCounterpartyId.name': 'Buyer',
        'sellerCounterpartyId.name': 'Seller',
        buyerTraderName: 'Buyer Trader Name',
        confirmationId: 'Confirmation ID',
        sellerTraderName: 'Seller Trader Name',
        'brokerId.name': 'Broker',
        'buyerPayIndexId.name': 'Buyer Pay Index',
        buyerIndexAveragingMethod: 'Buyer Index Averaging Method',
        buyerIndexPricingCalendar: 'Buyer Index Pricing Calendar',
        tradeDate: 'Trade Date',
        deliveryStartDate: 'Delivery Start Date',
        deliveryEndDate: 'Delivery End Date',
        deliveryType: 'Delivery Type',
        'pipelineId.name': 'Pipeline',
        'deliveryLocationId.name': 'Delivery Location',
        totalQuantity: 'Total Quantity',
        quantity: 'Quantity',
        quantityFrequency: 'Quantity Frequency',
        quantityUnit: 'Quantity Unit',
        fixedPrice: 'Fixed Price',
        pricingFrequency: 'Pricing Frequency',
        pricePrecision: 'Price Precision',
        priceCurrency: 'Price Currency',
        priceUnit: 'Price Unit',
        settlementCurrency: 'Settlement Currency',
        settlementFrequency: 'Settlement Frequency',
        indexDifferential: 'Index Differential',
        contractDate: 'Contract Date',
        masterContractType: 'Master Contract Type',
        potentialMatchIds: '',
        version: 'Version',
        counterpartyR: ''
      },

      rejectedDeals: {
        checkbox: '',
        etrmId: 'ETRM ID',
        tradeType: 'Trade Type',
        dealStatus: 'Status',
        etrmBuyerCounterpartyId: 'Buyer',
        etrmSellerCounterpartyId: 'Seller',
        buyerTraderName: 'Buyer Trader Name',
        confirmationId: 'Confirmation ID',
        sellerTraderName: 'Seller Trader Name',
        etrmBrokerId: 'Broker',
        etrmBuyerPayIndexId: 'Buyer Pay Index ID',
        buyerIndexAveragingMethod: 'Buyer Index Averaging Method',
        buyerIndexPricingCalendar: 'Buyer Index Pricing Calendar',
        tradeDate: 'Trade Date',
        deliveryStartDate: 'Delivery Start Date',
        deliveryEndDate: 'Delivery End Date',
        deliveryType: 'Delivery Type',
        etrmPipelineId: 'Pipeline ID',
        etrmDeliveryLocationId: 'Delivery Location ID',
        totalQuantity: 'Total Quantity',
        quantity: 'Quantity',
        quantityUnit: 'Quantity Unit',
        quantityFrequency: 'Quantity Frequency',
        fixedPrice: 'Fixed Price',
        pricingFrequency: 'Pricing Frequency',
        pricePrecision: 'Price Precision',
        priceCurrency: 'Price Currency',
        priceUnit: 'Price Unit',
        settlementCurrency: 'Settlement Currency',
        settlementFrequency: 'Settlement Frequency',
        indexDifferential: 'Index Differential',
        contractDate: 'Contract Date',
        masterContractType: 'Master Contract Type',
        dealLegs: '',
        version: 'Version',
        counterpartyR: '',
        rejectedAt: 'Rejection Date',
        rejectionReason: 'Rejection Reason'
      }
    }
  }
} as const;

export default pair;
