export const elements = {
  fileTransferStatusWidget: {
    inProgressSingular: 'Transferring 1 file',
    inProgressPlural: 'Transferring {{fileCount}} files',
    completeSingular: '1 File Transfer Complete',
    completePlural: '{{fileCount}} File Transfers Complete',
    failedSingular: '1 File Transfer Failed',
    failedPlural: '{{fileCount}} File Transfers Failed',
    idle: 'File Transfers',
    clearLabel: 'Clear All'
  }
};
