import styled from 'styled-components';
export const StyledMultiSelectButton = styled.button<{ buttonHeight?: string }>`
  min-height: ${(props) => props.buttonHeight ?? '38px'};

  .select-icon {
    stroke: ${(props) => props.theme.secondaryDropDownText};
  }

  .selected-label {
    max-width: 10rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
