import { JsonMap } from '@core/typings';
import {
  IUserConfig,
  IUserConfigMap,
  IUserConfigRegistryMap,
  UserConfigRegistryParams
} from '../typings';

/* map of all registered configs */
export const UserConfigRegistryMap: IUserConfigRegistryMap = new Map<
  string,
  UserConfigRegistryParams<JsonMap>
>();

export const UserConfigMap: IUserConfigMap = new Map<string, IUserConfig>();
