import { DropdownMenu, IMenuEntry, IMenuItem, ISubMenu } from '@core/components';
import { cn } from '@core/util';
import _ from 'lodash';
import { ReactNode } from 'react';
import { useCreateRenderToolbarItem } from './createRenderToolbarItem';
import { ToolbarDropdownMenuItem, ToolbarItem, ToolbarProps } from './Toolbar.types';

type ItemCreatorFunc = (item: ToolbarItem, key: number) => JSX.Element | null;
type ToMenuItemFunc = (item: ToolbarDropdownMenuItem, index: number) => IMenuEntry;
type ToDropdownItemFunc = (item: ToolbarDropdownMenuItem, index: number) => IMenuItem;

function isDropdownItem(item: ToolbarDropdownMenuItem): boolean {
  return (
    _.has(item, ['title']) ||
    _.has(item, ['description']) ||
    _.has(item, ['children']) ||
    _.has(item, ['render'])
  );
}

function createToMenuItem(renderItem: ItemCreatorFunc): ToDropdownItemFunc {
  const convertItem: ToMenuItemFunc = (item, key) => {
    /* if item can be used directly, don't convert it */
    if (isDropdownItem(item)) return item as IMenuEntry;
    return {
      ...(item as IMenuEntry),
      children: renderItem(item as ToolbarItem, key) as ReactNode
    } as IMenuEntry;
  };

  return (item, index): IMenuItem => {
    if (item.isSubmenu) {
      return { ...item, items: _.map(item.items, convertItem) } as ISubMenu;
    }
    return convertItem(item, index) as IMenuEntry;
  };
}

export function ToolbarMenu(
  props: Pick<ToolbarProps, 'menuItems' | 'dropdownProps' | 'components' | 'menuProps'>
) {
  const {
    components,
    dropdownProps: { className, ...dropProps } = {},
    menuItems,
    menuProps
  } = props;
  const renderItem = useCreateRenderToolbarItem(components);
  const toMenuItem = createToMenuItem(renderItem);

  if (!menuItems?.length) return null;
  return (
    <DropdownMenu
      {...{ ...dropProps, menuProps }}
      className={cn('toolbar-menu', className)}
      items={_.map(menuItems, toMenuItem)}
    />
  );
}
