import { COLORS } from './colors';

export const lightTheme: Record<string, string> = {
  base: COLORS.$white,
  contrast: COLORS.$rich_black,
  contrast_85: COLORS.$black_85,
  contrast_65: COLORS.$black_65,
  contrast_45: COLORS.$black_45,
  contrast_40: COLORS.$black_40,
  contrast_25: COLORS.$black_25,
  contrast_15: COLORS.$black_15,
  contrast_4: COLORS.$black_4,
  primaryButtonBackground: COLORS.$aqua_6,
  primaryButtonText: COLORS.$white,
  secondaryButtonBackground: COLORS.$white,
  secondaryButtonText: COLORS.$black_65,
  secondaryButtonBorder: COLORS.$black_15,
  standardButtonBackground: COLORS.$aqua_9,
  standardButtonText: COLORS.$white,
  disableButtonBackground: COLORS.$background,
  disableButtonText: COLORS.$black_25,
  bottomborder: COLORS.$black_25,
  dangerButtonBackground: COLORS.$red_6,
  dangerButtonText: COLORS.$white,
  iconButtonBackground: COLORS.$white,
  iconButtonText: COLORS.$black_85,
  iconButtonBorder: COLORS.$black_15,
  ghostButtonBackground: COLORS.transparent,
  ghostButtonText: COLORS.$white_65,
  ghostButtonBorder: COLORS.$white_20,
  textButtonBackground: COLORS.transparent,
  textButtonText: COLORS.$aqua_7,
  backgroundColor: COLORS.$background,
  secondaryBackgroundColor: COLORS.$dividers,
  fieldDrawerBackgroundColor: COLORS.$background,
  rowfontColor: COLORS.$aqua_3,
  fontColor: COLORS.$primary_text,
  secondaryFontColor: COLORS.$secondary_text,
  borderColor: COLORS.$border,
  primaryText: COLORS.$aqua_6,
  listBackground: COLORS.$table_header,
  tooltipBackground: COLORS.$title,
  tooltipFont: COLORS.$white,
  accordionText: COLORS.$rich_black,
  accordionTitleBg: COLORS.$background,
  accordionContentBg: COLORS.$aqua_1,
  accordionContentBorder: COLORS.$aqua_6,
  referenceDataText: COLORS.$black_65,
  referenceDataTitle: COLORS.$black_85,
  referenceDataToggleBackground: COLORS.$black_4,
  referenceDataToggleActiveText: COLORS.$aqua_7,
  referenceDataToggleInactiveText: COLORS.$black_45,
  referenceDataToggleActiveBackground: COLORS.$white,
  referenceDataTemplateActive: COLORS.$aqua_5,
  referenceDataTemplateInactive: COLORS.$black_65,
  bannerText: COLORS.$black_65,
  bannerTitle: COLORS.$black_85,
  bannerIcon: COLORS.$black_65,
  exceptionTextVeryLight: COLORS.$black_45,
  exceptionTextLight: COLORS.$black_65,
  exceptionTextMedium: COLORS.$black_85,
  exceptionTextDark: COLORS.$rich_black,
  bannerContentBg: COLORS.$aqua_1,
  bannerButtonBorder: COLORS.$black_15,
  unpairedBackground: COLORS.$magenta_2,
  unpairedIconBackground: COLORS.$magenta_4,
  inboundBackground: COLORS.$orange_2,
  inboundIconBackground: COLORS.$orange_5,
  outboundBackground: COLORS.$purple_1,
  outboundIconBackground: '#5A5C60',
  pairedBackground: COLORS.$persian_blue_2,
  pairedIconBackground: COLORS.$persian_blue_5,
  oracleTagBackground: COLORS.$gold_11,
  oxpairTagBackground: COLORS.$orange_11,
  oxsettleTagBackground: COLORS.$green_11,
  oxnomTagBackground: COLORS.$purple_11,
  oxadminTagBackground: COLORS.$persian_blue_11,
  adminBackground: COLORS.$persian_blue_4,
  oracleBackground: COLORS.$gold_11,
  pairBackground: COLORS.$volcano_5,
  nomBackground: COLORS.$purple_3,
  settleBackground: COLORS.$green_4,
  primaryDropDownText: COLORS.$white,
  primaryDropDownBgColor: COLORS.$aqua_6,
  secondaryDropDownText: COLORS.$primary_text,
  secondaryDropDownBgColor: COLORS.$background,
  inverseDropDownBgColor: COLORS.$white,
  inverseDropDownText: COLORS.$aqua_7,
  inverseDropDownIconColor: COLORS.$rich_black,
  dropDownContentText: COLORS.$rich_black,
  sidebarBackground: COLORS.$sidebar_background,
  sidebarText: COLORS.$sidebar_text,
  sidebarProductText: COLORS.$rich_black,
  sidebarTextSelected: COLORS.$white,
  sidebarDivider: COLORS.$white,
  fieldFocus: COLORS.$aqua_5,
  fieldHover: COLORS.$black_4,
  fieldHighlight: COLORS.$aqua_2,
  fieldError: COLORS.$red_5,
  confirmationPairedBackground: '#1BDAAA',
  confirmationPairsBackground: '#588292',
  confirmationInboundBackground: '#B4B4B4',
  confirmationOutboundBackground: '#5A5C60',
  confirmationTerminatedBackground: COLORS.$red_5,
  confirmationExceptionBackground: COLORS.$magenta_6,
  statusOxPair: COLORS.$volcano_3,
  statusYellow: COLORS.$gold_6,
  drawerBackground: COLORS.$white,
  statusGreen: COLORS.$cyan_3,
  statusTextDark: COLORS.$black_85,
  headerBackground: COLORS.$white,
  datePickerText: COLORS.$black_65,
  tableContainer: COLORS.$black_6,
  tableLabel: COLORS.$black_45,
  tableFont: COLORS.$black_45,
  modalHeaderText: COLORS.$black_85,
  modalBodyText: COLORS.$black_65,
  modalCoverBackground: COLORS.$black_40,
  modalDefaultBackground: COLORS.$white,
  modalDefaultBorder: COLORS.$black_6,
  imagePlaceholder: COLORS.$black_40,
  checkmark: COLORS.$aqua_6,
  checkmarkCheck: COLORS.$white,
  modalConfirmBackground: COLORS.$white,
  modalConfirmBorder: COLORS.$black_6,
  modalConfirmIcon: COLORS.$red_6,
  modalConfirmWarningIcon: COLORS.$gold_6,
  uploadStatusHeaderColor: COLORS.$white_65,
  uploadStatusHeaderBackground: COLORS.$aqua_9,
  uploadStatusItemBackground: COLORS.$white,
  uploadStatusItemPrimary: COLORS.$black_45,
  uploadStatusItemSecondary: COLORS.$aqua_8,
  uploadStatusItemSuccess: COLORS.$aqua_7,
  uploadStatusItemFailed: COLORS.$red_6,
  searchiconborder: COLORS.$border_search,
  templateFileTableHeader: COLORS.$persian_blue_6,
  templateFileTableHeaderFont: COLORS.$white,
  oracleTableLabel: COLORS.$black_45,
  oracleTableHover: COLORS.$aqua_1,
  oracleTableFocus: COLORS.$aqua_5,
  oracleTableSelected: COLORS.$aqua_6,
  snackbarTextColor: COLORS.$white,
  snackbarBackgroundColor: COLORS.$purple_5,
  adminStatusBackgroundColor: COLORS.$persian_blue_3,
  referenceSuccessTable: COLORS.$aqua_7,
  referenceSuccessOddRow: COLORS.$aqua_1,
  referenceSuccessOddRowFocus: COLORS.$aqua_2,
  referenceFailureTable: COLORS.$red_7,
  referenceFailureOddRow: COLORS.$red_1,
  referenceFailureOddRowFocus: COLORS.$red_2,
  referenceSubTable: COLORS.$aqua_7,
  referenceOddRow: COLORS.$aqua_1,
  referenceOddRowFocus: COLORS.$aqua_2,
  profileImagePrimary: COLORS.$aqua_6,
  profileImageSecondary: COLORS.$aqua_9,
  profileImageWarning: COLORS.$gold_6,
  profileImageDanger: COLORS.$red_6,
  profileImageInfo: COLORS.$persian_blue_3,
  profileImageRedLight: COLORS.$red_3,
  profileImageRedDark: COLORS.$red_8,
  profileImageOrangeLight: COLORS.$orange_3,
  profileImageOrangeDark: COLORS.$orange_7,
  profileImageGoldLight: COLORS.$gold_3,
  profileImageGoldDark: COLORS.$gold_8,
  profileImageGreenLight: COLORS.$green_3,
  profileImageGreenDark: COLORS.$green_8,
  profileImageCyanLight: COLORS.$cyan_3,
  profileImageCyanDark: COLORS.$cyan_8,
  profileImageAquaLight: COLORS.$aqua_2,
  profileImageAquaDark: COLORS.$aqua_10,
  profileImagePersianLight: COLORS.$persian_blue_2,
  profileImagePersianDark: COLORS.$persian_blue_9,
  profileImagePurpleLight: COLORS.$red_6,
  profileImagePurpleDark: COLORS.$persian_blue_3,
  profileImageMagentaLight: COLORS.$magenta_3,
  profileImageMagentaDark: COLORS.$magenta_7,
  profileImageVolcanoLight: COLORS.$volcano_3,
  profileImageVolcanoDark: COLORS.$volcano_5,
  profileImageBlackLight: COLORS.$black_45,
  profileImageBlackDark: COLORS.$black_85,
  arrowToggleHoverColor: COLORS.$rich_black,
  accordionContainer: COLORS.$aqua_8,
  accordionHeaderText: COLORS.$white,
  accordionHeaderBackgroundColor: COLORS.$aqua_9,
  comparisonHeaderTarget: COLORS.$aqua_3,
  comparisonHeaderEleoxSpec: COLORS.$persian_blue_3,
  comparisonHeaderPotentialMatch: COLORS.$purple_2,
  comparisonFieldTaret: COLORS.$aqua_1,
  comparisonFieldEleoxSpec: COLORS.$persian_blue_1,
  comparisonFieldPotentialMatch: COLORS.$purple_1,
  comparisonHeaderError: COLORS.$red_5,
  comparisonFieldError: COLORS.$red_1,
  terminatedStatus: COLORS.$red_7,
  cancelledStatus: COLORS.$red_7,
  inProgressStatus: COLORS.$persian_blue_7,
  pairException: '#FF6969',
  overlayText: COLORS.$white,
  overlayBackground: COLORS.$black_85,
  calendarRangeEdges: COLORS.$aqua_6,
  calendarInRange: COLORS.$aqua_1
};

export const darkTheme: Record<string, string> = {
  contrast_40: COLORS.$black_40,
  primaryBtnBackground: COLORS.$aqua_6,
  primaryButtonBackground: COLORS.$aqua_6,
  primaryBtnText: COLORS.$background,
  secondaryBtnBackground: COLORS.$background,
  secondaryBtnText: COLORS.$rich_black,
  dangerBtnBackground: COLORS.$red_6,
  dangerBtnText: COLORS.$background,
  backgroundColor: COLORS.$rich_black,
  secondaryBackgroundColor: COLORS.$title,
  fontColor: COLORS.$background,
  secondaryFontColor: COLORS.$background,
  borderColor: COLORS.$table_header,
  primaryText: COLORS.$aqua_6,
  listBackground: COLORS.$rich_black,
  tooltipBackground: COLORS.$background,
  tooltipFont: COLORS.$title,
  accordionText: COLORS.$rich_black,
  accordionTitleBg: COLORS.$background,
  accordionContentBg: COLORS.$aqua_1,
  accordionContentBorder: COLORS.$aqua_6,
  exceptionTextVeryLight: COLORS.$black_45,
  exceptionTextLight: COLORS.$black_65,
  exceptionTextMedium: COLORS.$black_85,
  exceptionTextDark: COLORS.$rich_black,
  unpairedBackground: COLORS.$magenta_2,
  unpairedIconBackground: COLORS.$magenta_4,
  inboundBackground: COLORS.$orange_2,
  inboundIconBackground: COLORS.$orange_5,
  outboundBackground: COLORS.$purple_1,
  outboundIconBackground: COLORS.$purple_4,
  pairedBackground: COLORS.$persian_blue_2,
  pairedIconBackground: COLORS.$persian_blue_5,
  oracleTagBackground: COLORS.$gold_11,
  oxpairTagBackground: COLORS.$orange_11,
  oxsettleTagBackground: COLORS.$green_11,
  oxnomTagBackground: COLORS.$purple_11,
  oxadminTagBackground: COLORS.$persian_blue_11,
  adminBackground: COLORS.$persian_blue_4,
  oracleBackground: COLORS.$gold_3,
  pairBackground: COLORS.$orange_6,
  nomBackground: COLORS.$purple_3,
  settleBackground: COLORS.$green_4,
  primaryDropDownText: COLORS.$white,
  primaryDropDownBgColor: COLORS.$aqua_6,
  secondaryDropDownText: COLORS.$primary_text,
  secondaryDropDownBgColor: COLORS.$background,
  inverseDropDownBgColor: COLORS.$white,
  inverseDropDownText: COLORS.$aqua_7,
  inverseDropDownIconColor: COLORS.$rich_black,
  dropDownContentText: COLORS.$rich_black,
  sidebarBackground: COLORS.$sidebar_background,
  sidebarText: COLORS.$sidebar_text,
  sidebarProductText: COLORS.$rich_black,
  sidebarTextSelected: COLORS.$white,
  sidebarDivider: COLORS.$white,
  confirmationPairedBackground: COLORS.$green_5,
  confirmationPairsBackground: COLORS.$orange_5,
  confirmationInboundBackground: '#B4B4B4',
  confirmationOutboundBackground: '#898989',
  confirmationTerminatedBackground: COLORS.$red_5,
  confirmationExceptionBackground: COLORS.$magenta_6,
  statusOxPair: COLORS.$volcano_3,
  statusYellow: COLORS.$gold_6,
  statusGreen: COLORS.$cyan_3,
  statusTextDark: COLORS.$black_85,
  headerBackground: COLORS.$white,
  datePickerText: COLORS.$black_65,
  tableContainer: COLORS.$black_6,
  tableLabel: COLORS.$black_45,
  tableFont: COLORS.$black_45,
  imagePlaceholder: COLORS.$black_4,
  checkmark: COLORS.$aqua_6,
  checkmarkCheck: COLORS.$white,
  templateFileTableHeader: COLORS.$persian_blue_6,
  templateFileTableHeaderFont: COLORS.$white,
  snackbarTextColor: COLORS.$white,
  snackbarBackgroundColor: COLORS.$purple_5,
  adminStatusBackgroundColor: COLORS.$persian_blue_3,
  referenceSuccessTable: COLORS.$aqua_7,
  referenceSuccessOddRow: COLORS.$aqua_1,
  referenceSuccessOddRowFocus: COLORS.$aqua_2,
  referenceFailureTable: COLORS.$red_7,
  referenceFailureOddRow: COLORS.$red_1,
  referenceFailureOddRowFocus: COLORS.$red_2,
  referenceSubTable: COLORS.$aqua_7,
  referenceOddRow: COLORS.$aqua_1,
  referenceOddRowFocus: COLORS.$aqua_2,
  profileImagePrimary: COLORS.$aqua_6,
  profileImageSecondary: COLORS.$aqua_9,
  profileImageWarning: COLORS.$gold_6,
  profileImageDanger: COLORS.$red_6,
  profileImageInfo: COLORS.$persian_blue_3,
  profileImageRedLight: COLORS.$red_3,
  profileImageRedDark: COLORS.$red_8,
  profileImageOrangeLight: COLORS.$orange_3,
  profileImageOrangeDark: COLORS.$orange_7,
  profileImageGoldLight: COLORS.$gold_3,
  profileImageGoldDark: COLORS.$gold_8,
  profileImageGreenLight: COLORS.$green_3,
  profileImageGreenDark: COLORS.$green_8,
  profileImageCyanLight: COLORS.$cyan_3,
  profileImageCyanDark: COLORS.$cyan_8,
  profileImageAquaLight: COLORS.$aqua_2,
  profileImageAquaDark: COLORS.$aqua_10,
  profileImagePersianLight: COLORS.$persian_blue_2,
  profileImagePersianDark: COLORS.$persian_blue_9,
  profileImagePurpleLight: COLORS.$red_6,
  profileImagePurpleDark: COLORS.$persian_blue_3,
  profileImageMagentaLight: COLORS.$magenta_3,
  profileImageMagentaDark: COLORS.$magenta_7,
  profileImageVolcanoLight: COLORS.$volcano_3,
  profileImageVolcanoDark: COLORS.$volcano_5,
  profileImageBlackLight: COLORS.$black_45,
  profileImageBlackDark: COLORS.$black_85,
  arrowToggleHoverColor: COLORS.$white,
  accordionContainer: COLORS.$aqua_8,
  accordionHeaderText: COLORS.$white,
  accordionHeaderBackgroundColor: COLORS.$aqua_9,
  comparisonHeaderTarget: COLORS.$aqua_3,
  comparisonHeaderPotentialMatch: COLORS.$purple_2,
  comparisonFieldTaret: COLORS.$aqua_1,
  comparisonFieldPotentialMatch: COLORS.$purple_1,
  comparisonHeaderError: COLORS.$red_5,
  comparisonFieldError: COLORS.$red_1,
  inProgressStatus: COLORS.$persian_blue_7,
  terminatedStatus: COLORS.$red_7,
  calendarRangeEdges: COLORS.$aqua_6,
  calendarInRange: COLORS.$aqua_1
};

export const themes = {
  lightTheme,
  darkTheme
};
