import { Nullable } from '@core/typings';
import _ from 'lodash';
import { DataMngrOrchRespDetails, DataMngrOrchState } from './useOrchestratedData.types';

function consolidateByExpectedValue(prop: unknown, expected: unknown) {
  const invalidKey = _.findKey(prop, (v) => !_.isEqual(v, expected));
  if (invalidKey) return _.get(prop, [invalidKey]);
  return expected;
}
export function getConsolidatedState<QMap, Err>(responseDetails: DataMngrOrchRespDetails<QMap>) {
  return _.mapValues(responseDetails, (val: unknown, key: keyof DataMngrOrchState<QMap, Err>) => {
    switch (key) {
      case 'dataUpdatedAt':
      case 'errorUpdateCount':
      case 'errorUpdatedAt':
      case 'failureCount':
        return _.max(_.values(val));
      case 'failureReason':
      case 'error':
        return consolidateByExpectedValue(val, null) as Nullable;
      case 'fetchStatus':
        return consolidateByExpectedValue(val, 'idle') as string;
      case 'status':
        return consolidateByExpectedValue(val, 'success') as string;
      case 'isError':
      case 'isFetching':
      case 'isInitialLoading':
      case 'isLoading':
      case 'isLoadingError':
      case 'isPaused':
      case 'isPlaceholderData':
      case 'isRefetchError':
      case 'isRefetching':
      case 'isStale':
        return consolidateByExpectedValue(val, false) as boolean;
      case 'isFetched':
      case 'isFetchedAfterMount':
      case 'isSuccess':
        return consolidateByExpectedValue(val, true) as boolean;
      case 'data':
      case 'refetch':
      default:
        return val;
    }
  }) as unknown as DataMngrOrchState<QMap, Err>;
}
