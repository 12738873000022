import { useCallback } from 'react';
import { useAgGridRef } from './useAgGridRef';

/**
 * @description functionally the same as {@link useAgGridRef}, but returns a getter, so you can use
 * it inside memoized functions without causing unnecessary re-renders
 * @returns {() => AgGridReact} a getter function that returns the current AgGridReact instance
 */
export function useGetAgGridRef<TData extends object = any>() {
  const Ref = useAgGridRef<TData>();
  return useCallback(() => Ref.current, []);
}
