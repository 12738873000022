import { BoxArrowUpRightSvg, HelpFillSvg } from '@core/images';
import routeStrings from 'Containers/Routing/routeStrings';
import { ILinkInfo } from '../OxSidebar.types';

export const getSupportPageLinks: () => ILinkInfo[] = () => [
  {
    LinkIcon: HelpFillSvg,
    SelectedLinkIcon: HelpFillSvg,
    linkText: 'oxSidebar.support.eleoxSupport',
    route: 'products/support/eleox-support'
  },
  {
    LinkIcon: BoxArrowUpRightSvg,
    SelectedLinkIcon: BoxArrowUpRightSvg,
    linkText: 'oxSidebar.support.helpdesk',
    route: routeStrings.support.helpdesk,
    isExternal: true
  }
];
