import {
  deepGetter,
  formatNumberLocale,
  isNumeric,
  isValidTimestamp,
  utcToDisplayFormat
} from '@core/util';

const formatMatchingValue = (value: string) => {
  if (isNumeric(value)) {
    return formatNumberLocale(value);
  } else if (isValidTimestamp(value)) {
    return utcToDisplayFormat(value);
  }

  return value;
};
/* TODO: move -> Pair */
export const isDealValueMatching = (
  sourceData: Record<string, any>,
  targetData: Record<string, any>,
  field: string
): boolean => {
  const sourceDataValue = deepGetter(sourceData, field);
  const targetDataValue = deepGetter(targetData, field);

  if (
    sourceDataValue &&
    targetDataValue &&
    typeof sourceDataValue === 'object' &&
    typeof targetDataValue === 'object'
  ) {
    const sortEntries = (data: Record<string, any>) =>
      Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]));
    const sortedSourceData = sortEntries(sourceDataValue);
    const sortedTargetData = sortEntries(targetDataValue);

    return JSON.stringify(sortedSourceData) === JSON.stringify(sortedTargetData);
  }

  const sourceValue = formatMatchingValue(sourceDataValue);
  const targetValue = formatMatchingValue(targetDataValue);

  if (sourceData?.tradeType === 'IDX' && field === 'fixedPrice' && sourceValue !== targetValue) {
    return true;
  }

  if (['fixedPrice', 'totalQuantity', 'quantity', 'indexDifferential'].includes(field)) {
    return Number(sourceDataValue) === Number(targetDataValue);
  }

  if (
    sourceData?.tradeType === 'FP' &&
    (field === 'buyerPayIndexId.etrmId' || field === 'indexDifferential') &&
    sourceValue !== targetValue
  ) {
    return true;
  }

  return sourceValue === targetValue;
};
