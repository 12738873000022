import { useDidUpdate, useSetState, useValueMemo } from '@core/hooks';
import { useSidebar } from '@core/sidebar';
import { ComposedSfc } from '@core/typings';
import { useMemo } from 'react';
import { initialThemeState, ThemeContext } from '../Contexts';
import { defaultScheme } from '../defaultScheme';
import { useIsDark } from '../hooks/useIsDark';
import { buildTheme } from '../lib';
import { modernScheme } from '../modernScheme';
import { ThemeCV, ThemePaletteMap, ThemeScheme, ThemeState, ThemeStateLib } from '../typings';
import { getThemeLib } from '../util';

const themePaletteMap: ThemePaletteMap = {
  classic: buildTheme(defaultScheme),
  modern: buildTheme(modernScheme)
};

type Hoc = (C: ComposedSfc) => ComposedSfc;
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export function withTheme(scheme: ThemeScheme = defaultScheme): Hoc {
  return function _withTheme(Composed: ComposedSfc): ComposedSfc {
    return function WithTheme(p: any): JSX.Element {
      const [isDark] = useIsDark();
      const [state, setState] = useSetState<ThemeState>(initialThemeState);
      const colors = useValueMemo(
        () => themePaletteMap[state.activeThemeKey][isDark ? 'dark' : 'light'],
        [isDark, state.activeThemeKey]
      );

      const { isOpen: sidebarIsOpen } = useSidebar();

      useDidUpdate(() => {
        setState({
          colors,
          isDark,
          topNavHeight: 0,
          sidebarCurrentWidth: sidebarIsOpen ? state.sidebarOpenWidth : 40
        });
      }, [colors, sidebarIsOpen]);

      const lib = useMemo<ThemeStateLib>(
        () => ({
          themePaletteMap,
          setActiveThemeKey: (activeThemeKey: ThemeState['activeThemeKey']) =>
            setState({ activeThemeKey })
        }),
        []
      );

      /* prettier-ignore */
      const value = useValueMemo<ThemeCV>(() => ({
        ...lib,
        ...getThemeLib(state.colors),
        ...state,
      }), [state]);

      return (
        <ThemeContext.Provider value={value}>
          <Composed {...p} />
        </ThemeContext.Provider>
      );
    };
  };
}
