import { ChangeEvent, Dispatch, SetStateAction } from 'react';

export interface DatePickerProps {
  setCalendarDate?: Dispatch<SetStateAction<Date | null | undefined>>;
  minDate?: Date;
  startDateOverride?: Date;
  dateOptions?: Intl.DateTimeFormatOptions;
  hasBorder?: boolean;
  isEditable?: boolean;
}

export interface CalendarTypeProps {
  label: string;
  setDate?: Dispatch<SetStateAction<Range>>;
  handleChange: (evt: ChangeEvent, setSelectedDate: any, setDateString: any) => void;
  handleSelect?: (date: Date) => void;
  inputError: string;
  handleErrors?: (value: Date | string, validityStatement?: boolean) => boolean | undefined;
}

export enum DateFormatOptions {
  NUMERIC = 'numeric',
  TWO_DIGIT = '2-digit',
  LONG = 'long',
  SHORT = 'short',
  NARROW = 'narrow'
}
