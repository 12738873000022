import { createSlice } from '@reduxjs/toolkit';
import { INomination } from 'shared/models/Nomination';
import { mockNomGenerator } from 'shared/utils/mockNominationGenerator';
import { buildGenericSlice } from '../../helpers/slice.helper';
import { RootState } from '../../store';

type InitialStateType = {
  nominationList: INomination[];
};

const nominationList: INomination[] = mockNomGenerator(50);

export const initialState: InitialStateType = {
  nominationList
};

const slice = buildGenericSlice<InitialStateType>('nomination', initialState);

export const nominationSlice = createSlice({ ...slice });

export const { addNomination, updateNomination, removeNomination } = nominationSlice.actions;
export const getNominationList = (state: RootState) => state.nomination.nominationList;

export const nominationReducer = nominationSlice.reducer;
