import { OxRoute, ProductKey } from '@core/typings';
import { RootState } from 'redux/store';
/* TODO: this should be converted to a hook, and useAppSelector shouldn't be passed as a param (slop) */
export function getVerifiedRoutes(
  routes: OxRoute[],
  writeRequiredPaths: string[],
  product: ProductKey,
  selector: any
) {
  const { currentUser } = selector((state: RootState) => state.access);

  return routes.filter((route) => {
    const isWriteRequired = writeRequiredPaths.findIndex((path) => path === route.path) > -1;
    const isWritable = currentUser[product]?.write;

    return !isWriteRequired || isWritable;
  });
}
