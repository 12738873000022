import { ColDef, ICellRendererParams, IRichCellEditorParams } from 'ag-grid-community';

export const createSelectCell = <TData = any, TValue = string>(
  values: TValue[] | ((params: ICellRendererParams) => TValue[]) = [],
  rest?: Partial<ColDef<TData, TValue>>
): ColDef<TData, TValue> => {
  return {
    cellRenderer: 'selectCellRenderer',
    cellEditor: 'agRichSelectCellEditor',
    editable: true,
    ...rest,
    cellEditorParams: {
      ...rest?.cellEditorParams,
      values
    } as IRichCellEditorParams<TData, TValue>
  };
};
