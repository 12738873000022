import '../OxNotification.scss';

import {
  CheckmarkCircleFilledSvg,
  CloseCircleFilledSvg,
  CloseOutlineSvg,
  ExclamationTriangleFillSvg
} from '@core/images';
import { Text } from '@eleox/libs';
import _ from 'lodash';
import { toast, Toast, Toaster } from 'react-hot-toast';
import { IOxToast, NotificationType } from '../notification';

const renderIcon = (type: NotificationType) => {
  switch (type) {
    case 'success':
      return <CheckmarkCircleFilledSvg width={20} height={20} />;
    case 'warning':
      return <ExclamationTriangleFillSvg width={20} height={20} />;
    case 'error':
    default:
      return <CloseCircleFilledSvg width={20} height={20} />;
  }
};

const renderToast = ({
  visible,
  id,
  title,
  message,
  titleProps,
  messageProps,
  customType
}: IOxToast) => {
  return (
    <div
      className={`notification-container --type-${customType} ${visible ? '!opacity-100' : '!opacity-0'}`}
    >
      <div className='close-container' onClick={() => toast.dismiss(id)}>
        <CloseOutlineSvg viewBox='0 0 512 512' preserveAspectRatio='none' width={24} height={24} />
      </div>
      <div className='icon-container'>{renderIcon(customType)}</div>
      <div className='message-body'>
        <Text h3 color='inherit' t={title || `notifications.${customType}`} {...titleProps} />
        <Text color='inherit' t={_.toString(message)} {...messageProps} />
      </div>
    </div>
  );
};

export function OxToast() {
  return (
    <Toaster
      containerClassName='notifications-container'
      position='top-right'
      toastOptions={{ duration: 5000 }}
    >
      {renderToast as (toast: Toast) => JSX.Element}
    </Toaster>
  );
}
