import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ILegalEntityRepository } from 'shared/api/interfaces/ILegalEntityRepository';
import { ICounterparty } from 'shared/models/Counterparty';
import { IBaseThunk } from './base.thunks';

export class LegalEntityThunks implements IBaseThunk<ICounterparty> {
  private legalEntityRepository!: ILegalEntityRepository;

  constructor(_legalEntityRepository: ILegalEntityRepository) {
    this.legalEntityRepository = _legalEntityRepository;

    if (this.legalEntityRepository === null) {
      throw new Error('legalEntityRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'legal-entity';

  add(): AsyncThunk<HttpResponse<ICounterparty>, ICounterparty, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addLegalEntity`;

    return createAsyncThunk(action, (le: ICounterparty) => this.legalEntityRepository.add(le));
  }

  update(): AsyncThunk<HttpResponse<ICounterparty>, ICounterparty, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateLegalEntity`;

    return createAsyncThunk(action, async (legalEntity: ICounterparty, { rejectWithValue }) => {
      try {
        return await this.legalEntityRepository.update(legalEntity);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<ICounterparty, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteLegalEntity`;

    return createAsyncThunk(action, (id: string) => this.legalEntityRepository.delete(id));
  }

  getAll(): AsyncThunk<AxiosResponse<ICounterparty[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchLegalEntities`;

    return createAsyncThunk(action, () => this.legalEntityRepository.getAll());
  }
}
