import { Btn } from '@core/components';
import { ChevronRightSvg } from '@core/images';
import { ReactComponent as Logo } from '@core/images/eleox-logo-white-text.svg';
import { useSidebar } from '@core/sidebar';
import { useCss } from '@core/theme';
import { useLogout } from '@core/user';
import { cn } from '@core/util';
import _ from 'lodash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BusinessProfile, ProductMenu } from './components';
import { UserProfileV2 } from './components/UserProfileV2';
import { useProductLinks } from './hooks';
import { css } from './OxSidebar.style';
import { ProductLinkConfig } from './OxSidebar.types';

export function OxSidebar(): JSX.Element {
  const { isOpen, setIsOpen, preventNavigation, setShowPreventNavigationModal, setRunRouteAction } =
    useSidebar();
  const cls = useCss(css, {}, { watchSidebar: true });
  const productGroups = useProductLinks();
  const navigate = useNavigate();
  const logout = useLogout();
  const renderProductMenu = useCallback(
    (p: ProductLinkConfig, key: number) => <ProductMenu {...{ ...p, key }} className={cls.menu} />,
    [cls.menu]
  );

  return (
    <div className={cn('sidebar-wpr', cls.wpr, { '--is-open': isOpen })}>
      <div
        className={`hamburger-wpr ${isOpen ? 'open' : 'close'}`}
        onClick={() => setIsOpen(!isOpen)}
        role='button'
        style={{
          position: 'absolute',
          top: 44,
          right: 7,
          zIndex: 100
        }}
      >
        <ChevronRightSvg width={30} height={30} color='#1BDAAA' />
      </div>
      <div className='sidebar-ctr'>
        <div
          className='logo-wpr'
          role='button'
          onClick={() => {
            if (preventNavigation) {
              setShowPreventNavigationModal(true);
              setRunRouteAction({ action: () => navigate('/') });
            } else {
              navigate('/');
            }
          }}
        >
          <Logo className='logo' />
        </div>

        <div className='profile-wpr'>
          <UserProfileV2 />
          <BusinessProfile />
        </div>
        <div className='content-wpr'>
          <div className='primary-menu'>
            {_.map(
              _.filter(productGroups, (product) => product.isPrimaryMenuItem),
              renderProductMenu
            )}
          </div>
          <div className='secondary-menu'>
            {_.map(
              _.filter(productGroups, (product) => !product.isPrimaryMenuItem),
              renderProductMenu
            )}
            <Btn
              label='Logout'
              bordered
              color='primary.veryLight'
              borderWidth={0}
              onClick={logout}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
