import { CreateNotificationFn } from '@core/notification';
import { createStore, LocalForage } from '@core/storage';
import { IOxUserData, JsonMap, Nullable } from '@core/typings';
import { InternalStateBase } from '@core/util';
import { MutationFunction } from '@tanstack/react-query';
import { diff } from 'deep-object-diff';
import _ from 'lodash';
import Queue, { QueueWorker } from 'queue';

import {
  AdHocUserConfigRegistryParams,
  AdHocUserConfigType,
  AtomicChangeEvent,
  BatchUpdatePayload,
  IUserConfigManager,
  IUserConfigRegistryMap,
  IUserConfigStore,
  UserConfigManagerState,
  UserConfigRegistryParams,
  UserConfigRemoteSaveOptions,
  UserConfigStoredData,
  UserConfigType
} from '../typings';
import { UserConfigRegistryMap } from '../util';

const initialState: UserConfigManagerState = {
  committed: {},
  isReady: false,
  createdAt: '',
  updatedAt: ''
};

class _UserConfigManager
  extends InternalStateBase<UserConfigManagerState>
  implements IUserConfigManager
{
  private _localStore: LocalForage;
  private _ConfigRegistry: IUserConfigRegistryMap;

  public notify = (() => {
    throw 'Config Manager has not been initialized';
  }) as CreateNotificationFn;

  public commit = (async () => {
    throw 'Config Manager has not been initialized';
  }) as MutationFunction<IOxUserData>;

  private _Queue = new Queue({ concurrency: 1, timeout: 5000 });
  constructor() {
    super({
      initialState,
      emitDetailedChanges: true
    });
    /* ***************************************************************************** CREATE STORE */
    this._localStore = createStore({
      storeName: `UserConfigs`
    });

    /* ************************************************************************** QUEUE CALLBACKS */
    /* bypass timed out request */
    this._Queue.addEventListener('timeout', ({ detail }) => {
      console.log('job timed out:', detail.job.toString().replace(/\n/g, ''));
      detail.next();
    });
    /* on successful save, send notification if push wasn't marked as silen */
    this._Queue.addEventListener('success', ({ detail }) => {
      /* this is improperly typed in parent package; updating it here */
      const { job } = detail as unknown as {
        job?: QueueWorker & { payloadKeys?: string[]; isSilent?: boolean };
        result: unknown[];
      };
      if (job && !job.isSilent) {
        const { payloadKeys = [] } = job;
        const message =
          payloadKeys.length > 1
            ? `${payloadKeys.length} Configs saved.`
            : `${this._ConfigRegistry.get(payloadKeys[0])?.label || '1 Config'} has been saved.`;
        this.notify({
          type: 'success',
          message,
          title: 'Config Saved'
        });
      }
    });

    /* on failed request, notify user if push wasn't marked as silent */
    this._Queue.addEventListener('error', ({ detail }) => {
      const { error, job } = detail as {
        error: Error;
        job: QueueWorker & { payloadKeys?: string[]; isSilent?: boolean };
      };
      console.error('Config update failure:', _.toString(error));
      if (!job.isSilent) {
        this.notify({
          title: 'Config Save Failed',
          message: _.toString(error),
          type: 'error'
        });
      }
    });

    this._ConfigRegistry = UserConfigRegistryMap;

    /* ************************************************************************* CHANGE CALLBACKS */
    type DataState = UserConfigManagerState['committed'];
    /* emit atomic change events for each config value that gets updated */
    this.on('change:committed', (nCommitted: DataState, pCommitted: DataState) => {
      _.each(_.keys(diff(pCommitted, nCommitted)), (key) => {
        const { value, _t = 0, metadata } = _.get(nCommitted, [key]);
        const payload: AtomicChangeEvent = {
          value,
          _t,
          metadata
        };
        this.emit(`save:${key}`, payload);
      });
    });
  }
  private _handleUserData = (user: IOxUserData): Promise<UserConfigManagerState> => {
    const { createdAt, updatedAt, userConfig = {} } = user;
    return this.setStateAsync({
      createdAt: _.toString(createdAt),
      updatedAt: _.toString(updatedAt),
      committed: userConfig as IUserConfigStore,
      isReady: true
    });
  };

  public init: IUserConfigManager['init'] = async ({ payload, notify, commit }) => {
    this.notify = notify;
    this.commit = commit;

    await this._localStore.ready();
    await this._handleUserData(payload);
    this._Queue.start();
    this._Queue.autostart = true;
    this.emit('ready');
  };
  /** push changes for 1 config */
  public updateRemote = <TVal extends JsonMap>({
    key,
    data,
    options
  }: {
    key: string;
    data: UserConfigStoredData<TVal>;
    options?: UserConfigRemoteSaveOptions;
  }) => {
    this._push({
      payload: { [key]: data },
      ...options
    });
  };

  /** push changes for multiple configs — USED INTERNALLY ONLY */
  public batchUpdateRemote = (payload: Record<string, UserConfigStoredData>): void => {
    this._push({ payload, silent: true });
  };

  private _push = ({
    payload,
    silent = false
  }: {
    payload: BatchUpdatePayload;
    silent?: boolean;
  }) => {
    if (_.isEmpty(payload)) return;
    const job = () =>
      new Promise<void>((res, rej) => {
        try {
          const update = _.mapValues(payload, (item) => ({
            ...item,
            _t: Date.now()
          }));

          this.commit({ ...this.getState().committed, ...update })
            .then(this._handleUserData)
            .then(() => res());
        } catch (e) {
          const eString = _.toString(e);
          rej(new Error(eString));
        }
      });
    job.isSilent = silent;
    job.payloadKeys = _.keys(payload);

    this._Queue.push(job as QueueWorker);
  };

  public updateLocal = async <TVal extends JsonMap>(
    key: string,
    value: UserConfigStoredData<TVal>
  ) => {
    this._check();
    return this._localStore.setItem(key, { ...value, _t: Date.now() });
  };

  public getRegistryParams: IUserConfigManager['getRegistryParams'] = <
    TType extends UserConfigType,
    TVal extends JsonMap
  >(
    key: string
  ) => {
    const params = this._ConfigRegistry.get(key);
    if (!params) return undefined;
    return params as unknown as TType extends AdHocUserConfigType
      ? AdHocUserConfigRegistryParams<TVal>
      : UserConfigRegistryParams<TVal>;
  };

  /** get entire config object from local cache */
  public getLocalConfig = async <TVal extends JsonMap>(
    key: string
  ): Promise<Nullable<UserConfigStoredData<TVal>>> => {
    this._check();
    return this._localStore.getItem<UserConfigStoredData<TVal>>(key);
  };
  /** get config value from local cache */
  public getLocalValue = async <TVal extends JsonMap>(key: string): Promise<Nullable<TVal>> => {
    this._check();
    return ((await this.getLocalConfig<TVal>(key))?.value as TVal) ?? null;
  };

  /** get last remotely saved config */
  public getSavedConfig = <TVal extends JsonMap>(
    key: string
  ): Nullable<UserConfigStoredData<TVal>> => {
    this._check();
    return this.get(['committed', key]) ?? null;
  };
  /** get .value of last remotely saved config */
  public getSavedValue = <TVal extends JsonMap>(key: string): Nullable<TVal> => {
    this._check();
    return (this.getSavedConfig(key)?.value as TVal) ?? null;
  };

  private _check = () => {
    if (!this.state.isReady) throw 'Config Manager has not been initialized';
    return;
  };
}

const instance = new _UserConfigManager();
export const UserConfigManager = instance;
