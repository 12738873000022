/* TODO: convert this to use formatters from @core/table-util, MATTHEW */
import { CreateFormatterConfig, createFormatterLite } from '@core/table-util';
import { ValueFormatterLiteFunc } from 'ag-grid-community';
import _ from 'lodash';

export const formatterConf: Record<string, CreateFormatterConfig> = {
  number: {
    format: 'number',
    formatString: '(0,0[.]00[0000]'
  },
  date: {
    format: 'date',
    formatString: 'MM/DD/YYYY',
    parseFormat: ['MM-DD-YYYY', 'YYYY-MM-DD']
  },
  datetime: {
    format: 'date',
    formatString: 'MM/DD/YYYY h:mm A z'
  },
  time: {
    format: 'date',
    formatString: 'h:mm[:]ss A z'
  },
  month: {
    format: 'date',
    formatString: 'MMM YYYY',
    parseFormat: ['MM/YYYY', 'YYYY/MM', 'MM-DD-YYYY', 'YYYY MMM', 'YYYY MMMM']
  },
  text: {
    format: 'string'
  }
};

export const formatters = _.mapValues(formatterConf, createFormatterLite) as Record<
  keyof typeof formatterConf,
  ValueFormatterLiteFunc<any, any>
>;
