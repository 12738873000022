import { createSelector, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice, MergedReducers } from 'redux/helpers/slice.helper';
import { RootState } from 'redux/store';
import { LocationThunks } from 'redux/thunks/location.thunks';
import { GenericPayload, GenericUnknown } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { ILocationRepository } from 'shared/api/interfaces/ILocationRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { DeleteResponse } from 'shared/models/DeleteResponse';
import { ILocation, ILocationSpec } from 'shared/models/Location';
import { IPipelineSpec } from 'shared/models/Pipeline';
import { IZoneSpec } from 'shared/models/Zone';
import { selectPipelineSpecMap } from '../pipeline/pipeline.slice';
import { selectZoneSpecMap } from '../zone/zone.slice';

type InitialStateType = {
  count?: number;
  changeId: number;
  locations: ILocation[];
  locationSpecs: ILocationSpec[];
};

const count = undefined;
const changeId = 0;
const locations: ILocation[] = [];
const locationSpecs: ILocationSpec[] = [];

export const initialState: InitialStateType = {
  count,
  changeId,
  locations,
  locationSpecs
};

const locationRepository = container.get<ILocationRepository>(SERVICE_KEYS.LOCATION_REPOSITORY);
const locationThunks = new LocationThunks(locationRepository);
export const getAllLocations = locationThunks.getAll();
export const addLocation = locationThunks.add();
export const deleteAllLocation = locationThunks.deleteAll();
export const getAllLocationSpecs = locationThunks.getSpecs();
export const updateLocation = locationThunks.update();
export const forceUpdateLocation = locationThunks.forceUpdate();
export const deleteLocation = locationThunks.delete();
export const createSpecRequest = locationThunks.createSpecRequest();

const addLocationReducer = {
  reducer: (state: GenericUnknown, { payload }: PayloadAction<ILocation>) => {
    state.locationList.push(payload);
  },
  prepare: (payload: ILocation) => {
    return {
      payload: payload,
      meta: {
        offline: {
          effect: {
            url: '/api/oracle/v1/locations',
            method: 'POST',
            json: payload,
            headers: { 'content-type': 'application/json' }
          },
          commit: { type: 'FOLLOW_USER_COMMIT', meta: {} },
          rollback: { type: 'FOLLOW_USER_ROLLBACK', meta: {} }
        }
      }
    };
  }
};

const apiAddReducer: MergedReducers = { name: 'addLocation', reducer: addLocationReducer };

const slice = buildGenericSlice<InitialStateType>('location', initialState, 'List', [
  apiAddReducer
]);
export const locationSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllLocations.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data?.length) {
          const { data } = payload;
          state.locations = data;
          state.changeId++;
          state.count = data.length;
        }
      }
    );
    builder.addCase(
      addLocation.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload) {
          if (payload) {
            const locations = [...state.locations, payload.data];
            state.locations = locations;
            state.changeId++;
            state.count = locations.length;
          }
        }
      }
    );
    builder.addCase(
      getAllLocationSpecs.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data && payload.data.length > 0) {
          state.locationSpecs = payload.data as ILocationSpec[];
          state.changeId++;
        }
      }
    );
    builder.addCase(
      deleteAllLocation.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload.status === 200) {
          const { data } = payload;
          state.changeId++;
          state.locations = state.locations.filter(
            (location: ILocation) =>
              !data.find((response: DeleteResponse) => response.id === location.etrmId)?.isSuccess
          );
        }
      }
    );
  }
});

export const locationReducer = locationSlice.reducer;

export const selectLocations = (state: RootState) => state.location.locations;
export const selectLocationSpecs = (state: RootState) => state.location.locationSpecs;

export const selectLocationMap = createSelectorMap(selectLocations, 'etrmId');
export const selectLocationMapByName = createSelectorMap(selectLocations, 'name');
export const selectLocationSpecMap = createSelectorMap(selectLocationSpecs, 'specId');

export interface IFQLocationSpec extends ILocationSpec {
  specPipeline?: IPipelineSpec;
  specZone?: IZoneSpec;
}

export const selectFQLocationSpecRefs = createSelector(
  [selectLocationSpecs, selectPipelineSpecMap, selectZoneSpecMap, selectLocationSpecMap],
  (locations, pipelineSpecMap, zoneSpecMap, locationMap) => {
    return locations.map<IFQLocationSpec>((locationSpec) => {
      return {
        ...locationSpec,
        specPhysicalLocationName: locationMap.get(locationSpec.specId),
        specPipeline: pipelineSpecMap.get(locationSpec.specPipelineId),
        specZone: zoneSpecMap.get(locationSpec.specZoneId)
      };
    });
  }
);
