/* NOTE: this needs to remain a UI-only component; keep all functionality in HOCs. */
import { css } from './Grid.style';

import { useAgGridRef, useGrid } from '@core/grid/hooks';
import { useCss } from '@core/theme';
import { ClassName } from '@core/typings';
import { cn } from '@core/util';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

export type GridProps<TData extends object = any> = AgGridReactProps<TData> & {
  autoSizeRowGroupColumn?: boolean;
  autoSizeColumnsOnDataChange?: boolean | 'minimal';
  autoSizeColumnsOnLoad?: boolean | 'minimal';
  closeToolPanelOnLoad?: boolean;
  wrapperClassName?: ClassName;
};

export function Grid<TData extends object = any>(props: GridProps<TData>): JSX.Element {
  const {
    className,
    autoSizeRowGroupColumn,
    autoSizeColumnsOnDataChange,
    autoSizeColumnsOnLoad,
    closeToolPanelOnLoad = true,
    wrapperClassName,
    onFirstDataRendered,
    onRowDataUpdated,
    onGridReady,
    onRowGroupOpened,
    ...passedProps
  } = props;
  const {
    fields: { agGridProps }
  } = useGrid<TData>();
  const ref = useAgGridRef();
  const cls = useCss(css);

  return (
    <div className={cn(cls.wpr, 'core-grid-wpr', wrapperClassName, 'ag-theme-alpine')}>
      <AgGridReact<TData>
        alwaysMultiSort
        animateRows
        groupMaintainOrder
        stopEditingWhenCellsLoseFocus
        suppressAggFuncInHeader
        suppressCellFocus
        suppressMakeColumnVisibleAfterUnGroup
        onRowDataUpdated={(event) => {
          onRowDataUpdated?.(event);
          if (autoSizeColumnsOnDataChange) {
            setTimeout(() => {
              event.columnApi.autoSizeAllColumns(autoSizeColumnsOnDataChange === 'minimal');
            }, 50);
          }
        }}
        onRowGroupOpened={(event) => {
          onRowGroupOpened?.(event);
          if (autoSizeRowGroupColumn) event.columnApi.autoSizeColumn('ag-Grid-AutoColumn', true);
        }}
        onGridReady={(event) => {
          onGridReady?.(event);
          if (closeToolPanelOnLoad) event.api.closeToolPanel();
        }}
        onFirstDataRendered={(event) => {
          if (autoSizeColumnsOnLoad || autoSizeColumnsOnDataChange) {
            event.columnApi.autoSizeAllColumns(
              autoSizeColumnsOnLoad === 'minimal' || autoSizeColumnsOnDataChange === 'minimal'
            );
          }
          onFirstDataRendered?.(event);
        }}
        className={cn('core-grid', className)}
        {...{ ...agGridProps, ...passedProps, ref }}
      />
    </div>
  );
}
