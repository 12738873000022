import { StyleGetter } from '@core/theme/typings';
import { TabsStyleProps } from './Tabs.types';

const tX = (amt: number) => `translateX(${amt}vw)`;
export const css: StyleGetter<TabsStyleProps> = (theme, props) => {
  const { duration = 500 } = props;
  const transition = `transform ${duration}ms`;

  return {
    wpr: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0',
      alignSelf: 'stretch',
      alignItems: 'stretch',
      width: '100%',

      '.hdr-wpr': {},

      '.tabs-content-wpr': {
        display: 'inline-flex',
        width: '100%',
        height: '100%',
        alignItems: 'stretch',
        position: 'relative'
      },

      '.tab': {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        overflowX: 'hidden',
        transform: tX(100),
        alignSelf: 'stretch',
        alignItems: 'stretch',

        '&.--appear': { transform: tX(0) },

        '&.--enter-active': { transform: tX(0), transition },

        '&.--exit': { transform: tX(0) },
        '&.--exit-active': { transform: tX(-100), transition },

        '&.--exit-done': { transform: tX(-100) },
        '&.--enter-done': { transform: tX(0) }
      }
    }
  };
};
