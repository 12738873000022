import { useDidUpdate } from '@core/hooks';
import { ComposedSfc, Nullable } from '@core/typings';
import {
  ensureReadyConfig,
  userAppSettingsConfigKey,
  useUserAppSettings
} from '@core/user-configs';
import { compose } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useSidebar } from '../hooks';

function withAutoCloseSidebarHandler(Composed: ComposedSfc) {
  return function WithAutoCloseSidebar(props: any) {
    const Sidebar = useSidebar();
    const wasOpenOnMount = useMemo(() => Sidebar.isOpen, []);
    const {
      current: { isAutoHideSidebarOnWorkspacePages }
    } = useUserAppSettings();
    const lastActionWasAutoClose = useRef<Nullable<boolean>>(null);
    useDidUpdate(() => {
      if (_.isBoolean(lastActionWasAutoClose.current)) {
        lastActionWasAutoClose.current = false;
      }
    }, [Sidebar.isOpen]);

    useEffect(() => {
      /* on mount, check user config and initial state of sidebar */
      if (isAutoHideSidebarOnWorkspacePages && wasOpenOnMount) {
        /* give page a second to finish mounting loaders and stuff */
        setTimeout(() => {
          Sidebar.close();
          setTimeout(() => {
            lastActionWasAutoClose.current = true;
          }, 500);
        }, 1000);
      }
      return () => {
        if (lastActionWasAutoClose.current) Sidebar.open();
      };
    }, []);

    return <Composed {...props} />;
  };
}

export const withWorkspacePageSidebarHandler = compose(
  ensureReadyConfig(userAppSettingsConfigKey),
  withAutoCloseSidebarHandler
);
