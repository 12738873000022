import { BtnProps, Text } from '@core/components';
import _ from 'lodash';
import { FC, Fragment, useCallback } from 'react';
import { ToolbarItem, ToolbarProps } from './Toolbar.types';

export function useCreateRenderToolbarItem(components: ToolbarProps['components'] = {}) {
  return useCallback(
    (item: ToolbarItem, key: number) => {
      let El;
      /* *********************************************************************** string-type item */
      if (_.isString(item)) {
        if (_.has(components, [item])) El = (components as Record<string, FC>)[item];
        if (El) return <El {...{ key }} />;
        return null;
      }
      /* *********************************************************************** custom component */
      if (item.component || item.render) {
        if (item.render) {
          El = item.render;
          return <El {...{ key }} />;
        }
        const { component, params = {} } = item;
        const Component = components?.[component];
        if (Component) return <Component {...{ ...params, key }} />;
        return null;
      }

      /* ************************************************************************ predefined type */
      const { type: _type, hidden = false, ...elProps } = item;
      const type = _type || 'button';
      if (hidden) return null;

      let _props: any = { ...elProps };
      El = components[type];

      if (type === 'button') {
        if (_.includes((_props as BtnProps).className as string, 'dark')) {
          _props = { color: 'tertiary.darker', ..._props };
        } else if (_.includes((_props as BtnProps).className as string, 'light')) {
          _props = { bordered: true, color: 'tertiary.darker', ..._props };
        }
      }

      return (
        <Fragment {...{ key }}>
          {type === 'select' && elProps.label && <Text m t={elProps.label} />}
          <El {..._props} />
        </Fragment>
      );
    },
    [components]
  );
}
