import { useAuth0 } from '@auth0/auth0-react';
import { useValueMemo } from '@core/hooks';
import { RootState, useAppSelector } from '@core/redux';
import { useLogout } from './useLogout';

export type UseUserReturn = {
  isAuthenticated: boolean;
  data: RootState['access']['currentUser'];
  logout: ReturnType<typeof useLogout>;
};

export function useUser(): UseUserReturn {
  const { isAuthenticated } = useAuth0();
  const data = useAppSelector((state: RootState) => state.access.currentUser);
  const logout = useLogout();

  return useValueMemo<UseUserReturn>(
    () => ({ isAuthenticated, data, logout }),
    [isAuthenticated, data, logout]
  );
}
