export enum AccessType {
  None = 'None',
  Read = 'Read',
  Write = 'Read/Write'
}

export enum Role {
  SuperUser = 'Super User',
  Admin = 'Administrator',
  ConfirmAnalyst = 'Confirm Analyst',
  DataAnalyst = 'Data Analyst',
  SchedulerAnalyst = 'Scheduler Analyst',
  SettlementAnalyst = 'Settlement Analyst',
  SettlementManager = 'Settlement Manager',
  ITSupport = 'IT Support',
  EleoxSupport = 'Eleox Support',
  Etrm = 'ETRM'
}

export enum RoleValue {
  SuperUser = 'super-user',
  Admin = 'admin',
  ConfirmAnalyst = 'confirm-analyst',
  DataAnalyst = 'data-analyst',
  SchedulerAnalyst = 'scheduler-analyst',
  SettlementAnalyst = 'settlement-analyst',
  SettlementManager = 'settlement-manager',
  ITSupport = 'it-support',
  EleoxSupport = 'support',
  Etrm = 'etrm'
}

const permissions = {
  permissions: {
    accessTypes: AccessType,
    roles: Role,
    table: {
      title: 'Roles & Permissions',
      description: 'View permissions for each user role by product.'
    },
    addRole: 'Add Role',
    products: {
      table: {
        title: 'Products and Resources',
        description: 'View products and the resources managed by each one.'
      }
    }
  }
} as const;

export default permissions;
