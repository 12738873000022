import { OxColumnDefs } from '@core/typings';

export const generalValueFormatter = (params: OxColumnDefs): string => {
  const emptyField = '--';

  if (params && params.colDef && params.data) {
    const fieldName = params.colDef.field || '';

    const field = params.data[fieldName];

    return field?.trim().length ? field : emptyField;
  }

  return emptyField;
};
