import { TextProps } from '@eleox/libs';
import { Renderable, toast, Toast, ToastOptions, ValueOrFunction } from 'react-hot-toast';

export type NotificationType = 'success' | 'warning' | 'error' /* | 'info' | 'default' */;

// Custom props, not part of the react-hot-toast library
interface ICustomToast {
  title?: string;
  customType?: NotificationType;
  titleProps?: Partial<TextProps>;
  messageProps?: Partial<TextProps>;
}

export interface IOxToast extends Toast, ICustomToast {
  customType: NotificationType;
}

export interface IOxToastOptions extends ToastOptions, ICustomToast {
  message: ValueOrFunction<Renderable, Toast>;
  type?: NotificationType;
}

export const addNotification = ({
  message,
  type,
  customType = type || 'error',
  ...toastOptions
}: IOxToastOptions) => {
  toast(message, { customType, ...toastOptions } as IOxToastOptions);
};
