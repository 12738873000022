import { ButtonSize, TextSize, TextWeight } from '@core/components';
import { ButtonSizeStyle, defaultButtonSizeStyle } from './defaultButtonSizeStyle';

export const buttonSizeStylesMap = new Map<ButtonSize, ButtonSizeStyle>([
  [ButtonSize.default, defaultButtonSizeStyle],
  [
    ButtonSize.compact,
    {
      sizeClasses: 'text-md px-2 py-0',
      iconWidth: 15,
      iconHeight: 30,
      typography: { size: TextSize.Medium, weight: TextWeight.Regular },
      buttonHeight: '32px'
    }
  ],
  [
    ButtonSize.large,
    {
      sizeClasses: 'px-3 py-1',
      iconWidth: 20,
      iconHeight: 30,
      typography: { size: TextSize.Large, weight: TextWeight.Regular },
      buttonHeight: '38px'
    }
  ]
]);
