import { client } from '@core/rest-client';
import { IOxRole } from '@core/typings/Role';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IRoleRepository } from '../../interfaces/IRoleRepository';
import { BaseRepository } from '../BaseRepository';

@injectable()
export class RoleRepository implements IRoleRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('v1/roles');

  getAll = async (): Promise<AxiosResponse<IOxRole[]>> => {
    const response = await client.get(this.apiUrl);
    return response.data;
  };

  getOne = async (id: string): Promise<AxiosResponse<IOxRole[]>> => {
    return await client.get(`${this.apiUrl}/${id}`);
  };

  getActiveUserCountByRole = async (roleId: string): Promise<AxiosResponse<IOxRole[]>> => {
    return await client.get(`${this.apiUrl}/${roleId}/getActiveUsersCount`);
  };

  add = async (role: any): Promise<AxiosResponse> => {
    return await client.post(this.apiUrl, role);
  };

  update = async (role: any): Promise<AxiosResponse> => {
    return await client.put(this.apiUrl, role);
  };

  delete = async (id: string): Promise<any> => {
    return await client.delete(`${this.apiUrl}`, { data: { id: id } });
  };
}
