import { colorWheel } from '@core/theme/colorWheel';
import { CanvasColorRange, CssColor, TextColorScale } from '@core/theme/typings';
import chroma from 'chroma-js';

export const getTextColors = (range: CanvasColorRange, isDark = false): TextColorScale => {
  const [primary, offset1, offset2, offset3, offset4, offset5, offset6] = chroma
    .scale(range)
    .colors(7) as CssColor[];

  return {
    primary,
    offset1,
    offset2,
    offset3,
    offset4,
    offset5,
    offset6,
    faded: chroma(primary).alpha(0.6).hex() as CssColor,
    inverse: chroma(primary)
      .set('hsl.l', chroma(primary).get('hsl.l') <= 0.5 ? 0.96 : 0.04)
      .hex() as CssColor,
    disabled: chroma(primary)
      .set('hsl.s', 0)
      .set('hsl.l', isDark ? 0.5 : 0.55)
      .hex() as CssColor,
    hint: colorWheel.blueGray[isDark ? 100 : 500] as CssColor
  };
};
