import './ErrorDetails.style.scss';

import { Collapse, Text } from '@core/components';
import { useErrorBoundary } from '@core/error-boundary/useErrorBoundary';
import { cn } from '@core/util';
import _isString from 'lodash/isString';

export function ErrorDetails() {
  const { error, errorInfo, detailsOpen } = useErrorBoundary();

  return (
    <div className={cn('details-wpr', { '--details-open': detailsOpen })}>
      <Collapse isOpen={detailsOpen} duration={150} className='details-collapse-wpr' lazyLoad>
        {_isString(error?.message) && (
          <div className='detail-sec'>
            <Text t='Message' className='detail-sec-hdr' />
            <Text text={error?.message as string} em className='detail-data' />
          </div>
        )}
        {_isString(errorInfo?.componentStack) && (
          <div className='detail-sec'>
            <Text t='Stack' className='detail-sec-hdr' />
            <Text text={errorInfo?.componentStack} em className='detail-data stacktrace' />
          </div>
        )}
        <div className='detail-sec'>
          <Text t='Location' className='detail-sec-hdr' />
          <Text text={`${window.location.href}`} em className='detail-data location' />
        </div>
      </Collapse>
    </div>
  );
}
