import { createSlice } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RateScheduleThunks } from 'redux/thunks/rateSchedule.thunk';
import { GenericPayload } from 'redux/utils/array.slice';
import { IRateScheduleRepository } from 'shared/api/interfaces/IRateScheduleRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IRateSchedule } from 'shared/models/RateSchedule';
import { RootState } from '../../store';

type InitialStateType = {
  count?: number;
  rateSchedules: IRateSchedule[];
};

const count = undefined;
const rateSchedules: IRateSchedule[] = [];

export const initialState: InitialStateType = {
  count,
  rateSchedules
};

const rateScheduleRepository = container.get<IRateScheduleRepository>(
  SERVICE_KEYS.RATE_SCHEDULE_REPOSITORY
);
const rateScheduleThunks = new RateScheduleThunks(rateScheduleRepository);
export const getAllRateSchedules = rateScheduleThunks.getAll();
export const addRateSchedule = rateScheduleThunks.add();
export const deleteAllRateSchedules = rateScheduleThunks.deleteAll();

const slice = buildGenericSlice<InitialStateType>('rateSchedule', initialState);
export const rateScheduleSlice = createSlice({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllRateSchedules.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data?.length) {
          const { data } = payload;
          state.rateSchedules = data;
          state.count = data.length;
        }
      }
    );
    builder.addCase(deleteAllRateSchedules.fulfilled, (state: any, { payload }: GenericPayload) => {
      if (payload.status === 200) {
        const { ids } = payload.config.params;
        state.rateSchedules = state.rateSchedules.filter(
          (rate: IRateSchedule) => !ids.includes(rate.etrmId)
        );
      }
    });
  }
});

export const getRateSchedules = (state: RootState) => state.rateSchedule.rateSchedules;
export const rateScheduleReducer = rateScheduleSlice.reducer;
