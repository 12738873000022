import { InfiniteFilterParams } from '@core/typings';
import { handleSearchFilters } from '@core/util';
import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import { Dispatch } from 'react';
import { getInfiniteFilterParams } from './getInfiniteFilterParams';
interface CSRequest<T> {
  payload: {
    data: T[];
    status: number;
  };
}

const handleCSDataPayload = <T>(
  data: T[],
  params: IGetRowsParams,
  setRowCount?: Dispatch<any>,
  infiniteFilterParams?: InfiniteFilterParams
) => {
  if (data) {
    const { startRow, endRow, successCallback, sortModel, filterModel } = params;

    const updatedFilters = getInfiniteFilterParams(
      sortModel,
      filterModel,
      infiniteFilterParams,
      startRow
    );

    const results = handleSearchFilters(data, updatedFilters);

    const rowsThisPage = results.slice(startRow, endRow);
    setRowCount && setRowCount(results.length);
    let lastRow = -1;
    if (results.length <= endRow) {
      lastRow = results.length;
    }

    successCallback(rowsThisPage, lastRow);
  }
};

export const updateClientSideDataSource = <T>(
  promiseOrData: any,
  infiniteFilterParams?: InfiniteFilterParams,
  setRowCount?: Dispatch<any>
) => {
  const ds: IDatasource = {
    rowCount: undefined,
    getRows: (params: IGetRowsParams) => {
      if (Array.isArray(promiseOrData)) {
        handleCSDataPayload(promiseOrData, params, setRowCount, infiniteFilterParams);
      } else {
        promiseOrData.then((req: CSRequest<T>) =>
          handleCSDataPayload(req?.payload.data, params, setRowCount, infiniteFilterParams)
        );
      }
    }
  };

  return ds;
};
