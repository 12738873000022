import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { deepGetter } from './object.helper';

export const utcToDisplayFormat = (date: string) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString('en-US', { timeZone: 'UTC' });
};

export const compareDates = (value: string, param: string, operator: string) => {
  const valueDate = new Date(value);
  const paramDate = new Date(param);
  switch (operator) {
    case 'lessThan':
      return valueDate <= new Date(paramDate.setUTCHours(23, 59, 59, 999));
    case 'greaterThan':
      return valueDate >= paramDate;
    default:
      return false;
  }
};

export function timeComparator(date1: string, date2: string) {
  const date1Number = date1 ? new Date(date1).getTime() : null;
  const date2Number = date2 ? new Date(date2).getTime() : null;
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
}

export const getDaysElapsed = (date: string): number => {
  const currentDate = new Date();
  if (date) {
    const tradeDate = new Date(date);
    const timeElapsed = currentDate.getTime() - tradeDate.getTime();
    const daysElapsed = Math.ceil(timeElapsed / (1000 * 3600 * 24));

    return daysElapsed;
  }

  return 0;
};

export const localizeDateString = (
  date: Date | undefined | null,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
) => {
  return date?.toLocaleDateString('en-US', options);
};

export const localizeDateStringExpanded = (
  date: Date | undefined | null,
  options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    weekday: 'short'
  }
) => {
  return date?.toLocaleDateString('en-US', options);
};

export const localizeTimeString = (date: Date | undefined | null) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit'
  };

  return date?.toLocaleDateString('en-US', dateOptions);
};

export const getFullDate = (date: string) => {
  const cleanDate = date ? new Date(date) : null;

  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric'
  };
  const fullDate = localizeDateStringExpanded(cleanDate, dateOptions);

  return fullDate;
};

export function isValidTimestamp(timestamp: string): boolean {
  const newTimestamp = new Date(timestamp).getTime();
  return isNumeric(newTimestamp) && !Number.isInteger(Number(timestamp));
}

export function displayFormattedField(data: any, header: ColDef<any>, valueOnly?: boolean): string {
  const value = deepGetter(data, header.field || '');

  if (value) {
    if (valueOnly) {
      return value;
    }

    return isValidTimestamp(value) ? utcToDisplayFormat(value) : value;
  }

  return '';
}

const isNumeric = (num: number) => !isNaN(num) && isFinite(num);

export const formatDateToDayMonthYear = (date: Date) => utcToDisplayFormat(date.toISOString());

export const displayFormatToUtc = (date: string): Date => {
  const [month, day, year] = date.split('/').map((string) => parseInt(string));
  return new Date(year, month - 1, day);
};

interface DateRange {
  startDate: string;
  endDate: string;
}

export const createCurrentMonthDateRange = (): DateRange => {
  const todaysDate = new Date();
  const currentYear = todaysDate.getFullYear();
  const currentMonth = todaysDate.getMonth();

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
  return {
    startDate: formatDateToDayMonthYear(firstDayOfMonth),
    endDate: formatDateToDayMonthYear(lastDayOfMonth)
  };
};

const formatSingleDigitDateValue = (value: string) => (value.length === 1 ? `0${value}` : value);

export const reformatDateString = (date: string) => {
  if (!date) return date;
  const [month, day, year] = date.split('/');
  return `${year}-${formatSingleDigitDateValue(month)}-${formatSingleDigitDateValue(day)}`;
};

export function isPastDate(date: string): boolean {
  const now = new Date();
  const incomingDate = new Date(date);
  return now < incomingDate;
}

export const maxNumberOfDaysInCalendarRange = 365;

export const standardDateFormat = 'MM/DD/YYYY';

export const dateFormatStringMap: {
  date: string;
  dateShort: string;
  datetime: string;
  datetimeTimeFirst: string;
  month: string;
  monthLong: string;
  time: string;
} = {
  date: standardDateFormat,
  dateShort: 'M/D/YY',
  datetime: 'MM/DD/YYYY h:mm A z',
  datetimeTimeFirst: 'h:mm A z MM/DD/YYYY',
  month: 'MMM YYYY',
  monthLong: 'MMMM YYYY',
  time: 'h:mm[:]ss A z'
};

export const utcDatetimeToTimeZoneFormatted = (date: string, useCentralTime?: boolean) => {
  return useCentralTime
    ? dayjs.utc(date).tz('America/Chicago').format(dateFormatStringMap.datetime)
    : dayjs.utc(date).tz().format(dateFormatStringMap.datetime);
};

export const convertToUTC = (date: string | Date) => {
  return dayjs.utc(date).toDate();
};
