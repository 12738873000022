import { ComposedSfc } from '@core/typings';
import { useConfig } from '../hooks';

export function ensureReadyConfig(key: string) {
  return function withReadyConfig(Composed: ComposedSfc) {
    return function WithReadyConfig(p: any) {
      const Conf = useConfig(key);
      if (!Conf.isHydrated) return <></>;
      return <Composed {...p} />;
    };
  };
}
