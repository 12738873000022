import { client } from '@core/rest-client';
import { HttpResponse } from '@core/typings';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { ICounterpartyRepository } from 'shared/api/interfaces/ICounterpartyRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import {
  ICounterparty,
  ICounterpartySpec,
  ICounterpartySpecRequest
} from 'shared/models/Counterparty';

@injectable()
export class CounterpartyRepository implements ICounterpartyRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/counterparties/');

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, { params: { ids: ids } });
  }

  async filter(startDate: Date, endDate: Date): Promise<AxiosResponse<ICounterparty[]>> {
    return await client.get(this.apiUrl + 'filter/' + { ...startDate, ...endDate });
  }

  async add(counterparty: ICounterparty): Promise<HttpResponse<ICounterparty>> {
    const response = (await client.post(this.apiUrl, {
      ...counterparty
    })) as HttpResponse<ICounterparty>;
    if (response.status === 200 || response.status === 201) {
      return response;
    }

    if (response) {
      const { data } = response;
      const { message, error } = data;
      let stringMessage = message;
      if (Array.isArray(message)) {
        stringMessage = message.join(', ');
      }

      throw new Error(`${error}: ${stringMessage}`);
    }

    throw new Error('Malformed Response');
  }

  async update(counterparty: ICounterparty): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...counterparty });
  }

  async getAll(): Promise<AxiosResponse<ICounterparty[]>> {
    return await client.get(this.apiUrl);
  }

  async getSpecs(): Promise<AxiosResponse<ICounterpartySpec[]>> {
    return await client.get(this.apiUrl + 'spec-counterparties');
  }

  async getById(id: string): Promise<AxiosResponse<ICounterparty>> {
    return await client.get(this.apiUrl + id);
  }

  async forceUpdate(counterparty: ICounterparty): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'update-deal', { ...counterparty });
  }

  async createSpecRequest(specRequest: ICounterpartySpecRequest): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'spec-request', specRequest);
  }
}
