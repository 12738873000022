import { CalendarTypeProps, DateFormatOptions, DatePickerProps } from '@core/typings';
import { localizeDateString, utcToDisplayFormat } from '@core/util';
import { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import { OxCalendar, OxCalendarInput, OxDatePickerWrapper } from '../components';

interface Props extends DatePickerProps {
  typeData?: CalendarTypeProps;
}

export const OxDatePicker = ({
  minDate,
  startDateOverride,
  setCalendarDate,
  typeData,
  dateOptions = {
    year: DateFormatOptions.NUMERIC,
    month: DateFormatOptions.TWO_DIGIT,
    day: DateFormatOptions.TWO_DIGIT
  },
  hasBorder = false,
  isEditable = true
}: Props): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Range>({
    startDate: startDateOverride || new Date(),
    key: 'selection'
  });

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const handleShow = () => setShowCalendar(!showCalendar);

  const [startDateString, setStartDateString] = useState<string | undefined>(
    localizeDateString(selectedDate.startDate, dateOptions)
  );

  const handleCalendarSelect = (date: Date) => {
    setCalendarDate && setCalendarDate(date);
    setSelectedDate({ startDate: date });
    setStartDateString(localizeDateString(date, dateOptions));
    setShowCalendar(false);
    if (typeData && typeData.handleErrors) {
      typeData.handleErrors(date);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      let start = '';
      if (selectedDate.startDate && !isNaN(selectedDate.startDate.getTime())) {
        const selectedStart = new Date(selectedDate.startDate);
        start = utcToDisplayFormat(selectedStart.toISOString());
      }
      if (start.length) {
        setCalendarDate && setCalendarDate(selectedDate.startDate);
      }
    }
  }, [selectedDate]);

  const calendar = (
    <OxCalendar
      handleCalendarChange={handleCalendarSelect}
      date={selectedDate.startDate}
      minDate={minDate}
    />
  );

  return (
    <OxDatePickerWrapper
      popoverContent={calendar}
      setShowCalendar={setShowCalendar}
      showCalendar={showCalendar}
      // bordersEnabled
    >
      <OxCalendarInput
        handleShow={handleShow}
        setSelectedDate={setSelectedDate}
        setDateString={setStartDateString}
        dateString={startDateString}
        typeData={typeData}
        hasBorder={hasBorder}
        isEditable={isEditable}
      />
    </OxDatePickerWrapper>
  );
};
