import { StyleGetter } from '@core/theme/typings';
import { TabStyleProps } from '../Tabs.types';

const bgTransition = 'background-color 250ms ease-in-out, border-color 250ms ease-in-out';

export const css: StyleGetter<TabStyleProps> = (theme, { color = 'primary' }) => {
  const {
    colors: { text },
    darken,
    fade,
    getColor,
    getTextColor,
    gutter,
    lighten,
    toGtr
  } = theme;
  const clr = getColor(color);
  const activeClr = clr;
  const hoverBg = fade(clr, 88);
  const hoverClr = darken(clr, 10);
  return {
    lbl: {
      display: 'inline-flex',
      transition: bgTransition,
      cursor: 'pointer',
      margin: toGtr(0, gutter / 1.5),
      width: '100%',

      '.label-ctr': {
        display: 'flex',
        transition: 'inherit',
        backgroundColor: 'transparent',
        padding: toGtr(gutter, gutter * 1.5)
      },

      '.label, svg': { color: 'inherit', cursor: 'inherit' },
      svg: { width: 16, height: 16, marginRight: gutter },
      '.label': { lineHeight: 1, textWrap: 'nowrap' },

      '&.inkbar-indicator': {
        borderBottom: `2px solid transparent`,

        '&.--is-active': {
          color: activeClr,
          cursor: 'normal',
          borderColor: activeClr
        },

        '&.--is-disabled': {
          color: lighten(text.disabled, 33),
          cursor: 'not-allowed',
          borderColor: 'transparent',
          borderRadius: gutter
        },

        '&:not(.--is-active):not(.--is-disabled)': {
          '.label-ctr': {
            transition: `${bgTransition}, color 150ms ease-in-out`,

            '&:hover': {
              color: hoverClr,
              backgroundColor: hoverBg,
              borderRadius: gutter
            }
          }
        }
      },

      '&.pill-indicator': {
        border: '2px solid transparent',
        borderRadius: gutter,
        margin: toGtr(gutter / 1.5, gutter / 2),

        '.label-ctr': {
          padding: toGtr(gutter * 0.75, gutter * 1.5)
        },

        '&.--is-active': {
          borderColor: activeClr,
          backgroundColor: activeClr,
          color: getTextColor(clr)
        },

        '&.--is-disabled': {
          color: text.disabled
        },

        '&:not(.--is-active):not(.--is-disabled)': {
          transition: `${bgTransition}, color 150ms ease-in-out`,

          '&:hover': {
            color: hoverClr,
            backgroundColor: hoverBg,
            borderColor: fade(hoverBg, 99)
          }
        }
      },
      '&:first-child': { marginLeft: 0 },
      '&:last-child': { marginRight: 0 }
    }
  };
};
