import { css } from './Toolbar.style';

import { Btn, OxSearchV2, Text } from '@core/components';
import { useValueMemo } from '@core/hooks';
import { Select, Switch } from '@core/inputs';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import _ from 'lodash';
import { OxDateRangePickerV2 } from '../Datepickers';
import { ToolbarProps, ToolbarSpaceProps } from './Toolbar.types';
import { ToolbarItems } from './ToolbarItems';
import { ToolbarMenu } from './ToolbarMenu';

function Space({ size, isDivider }: ToolbarSpaceProps) {
  return <div className={cn(isDivider ? 'divider' : 'spacer', `--size-${size}`)} />;
}

const presetComponents = {
  button: Btn,
  select: Select,
  toggle: Switch,
  switch: Switch,
  daterange: OxDateRangePickerV2,
  search: OxSearchV2,
  divider: (p: ToolbarSpaceProps) => <Space {...p} isDivider />,
  text: Text,
  spacer: (p: ToolbarSpaceProps) => <Space {...p} />
};

export function Toolbar({
  className,
  components: customComponents,
  dropdownProps,
  items,
  layout = 'normal',
  menuItems,
  menuProps,
  showLayoutItemWhenLastChild,
  flexEnd = false
}: ToolbarProps): JSX.Element {
  const components = useValueMemo<ToolbarProps['components']>(
    () => {
      return {
        ...presetComponents,
        ...customComponents
      };
      /* run shallow-eq compare on passed components; assume they are memoized elsewhere */
    },
    [customComponents],
    ([pComps], [nComps]) => pComps === nComps
  );

  const cls = useCss(css);
  return (
    <div
      className={cn('core-toolbar', `toolbar-layout-${layout}`, className, cls.wpr, {
        '--force-show-last-item': showLayoutItemWhenLastChild,
        'justify-end': flexEnd
      })}
    >
      <ToolbarItems {...{ items, components }} />
      {!_.isEmpty(menuItems) && (
        <ToolbarMenu {...{ components, menuItems, menuProps, dropdownProps }} />
      )}
    </div>
  );
}
