import { DataTypeDefinition } from 'ag-grid-community';
import _ from 'lodash';
import { formatters } from './formatters';

export const standardDataTypeDefinitions: Record<string, DataTypeDefinition> = {
  text: {
    baseDataType: 'text',
    extendsDataType: 'text',
    valueFormatter: formatters.string
  },
  number: {
    baseDataType: 'number',
    extendsDataType: 'number',
    valueFormatter: formatters.number
  },
  dateString: {
    baseDataType: 'dateString',
    extendsDataType: 'dateString',
    valueFormatter: formatters.date
  },
  datetimeString: {
    baseDataType: 'dateString',
    extendsDataType: 'dateString',
    valueFormatter: formatters.datetime
  },
  monthString: {
    baseDataType: 'dateString',
    extendsDataType: 'dateString',
    valueFormatter: formatters.month
  },
  timeString: {
    baseDataType: 'dateString',
    extendsDataType: 'dateString',
    valueFormatter: formatters.time
  },
  object: {
    baseDataType: 'object',
    extendsDataType: 'object',
    valueFormatter: ({ value }) => _.toString(value)
  }
};
