import { OxGridTooltip } from '@core/components';
import { useDidUpdate, useValueMemo } from '@core/hooks';
import { RootState, useAppSelector } from '@core/redux';
import { getHighlightCellStyle } from '@core/table-util';
import { useIsDark } from '@core/theme';
import { debounce } from '@core/util';
import { ColDef, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import _ from 'lodash';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { StyledTable } from './OxTable.styles';

interface OxTableProps extends AgGridReactProps {
  setSelectedRows?: React.Dispatch<React.SetStateAction<IRowNode[]>>;
  className?: string;
  failureHighlightCellColors?: {
    even: string;
    odd: string;
  };
  targetData?: any;
  fieldsToIgnore?: string[];
  combinedSearchFields?: string[][];
  overwriteHighlighting?: boolean;
  customSearchText?: string;
  disableFilter?: boolean;
  onGridReady?: (event: GridReadyEvent) => void;
}

function withHighlightCellStyles(
  columnDefs: ColDef[],
  filterText: string,
  props: OxTableProps
): ColDef[] {
  return columnDefs.map((colDef) => ({
    ...colDef,
    cellStyle: getHighlightCellStyle(
      filterText || '',
      props?.failureHighlightCellColors,
      props?.targetData,
      props?.fieldsToIgnore,
      props?.combinedSearchFields
    )
  }));
}

function customComparator(a: string, b: string) {
  return _.toLower(a).localeCompare(_.toLower(b));
}

export const OxTable = forwardRef(function OxTable(props: OxTableProps, ref: any) {
  const {
    disableFilter,
    domLayout = 'normal',
    failureHighlightCellColors,
    targetData,
    fieldsToIgnore,
    customSearchText,
    combinedSearchFields,
    overwriteHighlighting,
    rowData,
    rowModelType = 'clientSide',
    setSelectedRows,
    ...rest
  } = props;
  const cellStyleConfig = useValueMemo(
    () => ({
      failureHighlightCellColors,
      targetData,
      fieldsToIgnore,
      combinedSearchFields
    }),
    [failureHighlightCellColors, targetData, fieldsToIgnore, combinedSearchFields]
  );

  const fltTxt = useAppSelector((state: RootState) => state.tableFilters.searchText);
  const [darkMode] = useIsDark();

  const [quickFilterText, setQuickFilterText] = useState<string>(() =>
    disableFilter ? '' : customSearchText || fltTxt || ''
  );
  useEffect(() => {
    if (disableFilter) {
      if (quickFilterText) setQuickFilterText('');
      return;
    }
    const nFilterText = _.toString(customSearchText || fltTxt);
    setQuickFilterText(nFilterText);
    ref?.current?.api?.setGridOption('quickFilterText', nFilterText);
  }, [fltTxt, customSearchText, disableFilter]);

  /* set column defs */
  const initialColDefs = useMemo(
    () =>
      overwriteHighlighting
        ? props.columnDefs
        : withHighlightCellStyles(props.columnDefs || [], quickFilterText, cellStyleConfig),
    []
  );

  /* TODO: colDefs shouldn't be completely reset every time filter text changes!! */
  useEffect(() => {
    if (ref?.current?.api && !overwriteHighlighting) {
      if (ref.current.api.isDestroyed()) return;
      ref.current?.api.setGridOption(
        'columnDefs',
        withHighlightCellStyles(props.columnDefs || [], quickFilterText, cellStyleConfig)
      );
    }
  }, [quickFilterText, cellStyleConfig, overwriteHighlighting]);

  const handleResize = useCallback(
    debounce(() => {
      if ((props.columnDefs?.length || 0) < 5) ref.current?.api?.sizeColumnsToFit();
    }, 100),
    [props.columnDefs?.length]
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.columnDefs?.length]);

  useDidUpdate(() => {
    if (ref?.current?.api && rowModelType === 'clientSide') {
      if (ref.current.api.isDestroyed()) return;
      ref?.current.api.setGridOption('rowData', rowData);
    }
  }, [rowData]);

  const overrideableGridOptions = useMemo<AgGridReactProps>(
    () => ({
      accentedSort: true,
      autoGroupColumnDef: { minWidth: 200 },
      rowGroupPanelShow: 'never',
      suppressDragLeaveHidesColumns: true,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent', align: 'center' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' }
        ]
      }
    }),
    []
  );

  const gridOptions = useMemo<AgGridReactProps>(
    () => ({
      columnDefs: initialColDefs,
      defaultColDef: {
        resizable: true,
        width: 100,
        tooltipComponent: OxGridTooltip,
        filter: true,
        sortable: true,
        comparator: customComparator,
        cellDataType: false
      },
      onGridReady: (params) => {
        props.onGridReady?.(params);
        handleResize();
      },
      onModelUpdated: (params) => {
        if (params.api.getDisplayedRowCount() === 0) params.api.showNoRowsOverlay();
        else params.api.hideOverlay();
      },
      onSelectionChanged: (params) => {
        setSelectedRows?.(params.api.getSelectedNodes() ?? []);
      },
      popupParent: document.querySelector('body'),
      quickFilterText,
      /* NOTE: initialRowData is just here so Jest doesn't lose its mind */
      rowData: rowModelType === 'clientSide' ? rowData : undefined
    }),
    []
  );

  return (
    <StyledTable
      data-testid='OxTable'
      className={`ag-theme-alpine${darkMode ? '-dark' : ''} shadow rounded-lg`}
      style={
        domLayout === 'normal'
          ? { width: '100%', height: '100%' }
          : { width: '100%', height: 'auto' }
      }
    >
      <AgGridReact
        ref={ref}
        {...overrideableGridOptions}
        {...rest}
        {...{
          domLayout,
          rowModelType
        }}
        className={`${props.className ? props.className : ' '}`}
        {...gridOptions}
      />
    </StyledTable>
  );
});
