import { ColorWheel } from '../typings';
import { amber } from './amber';
import { blue } from './blue';
import { blueGray } from './blueGray';
import { brown } from './brown';
import { common } from './common';
import { cyan } from './cyan';
import { deepOrange } from './deepOrange';
import { deepPurple } from './deepPurple';
import { gray } from './gray';
import { green } from './green';
import { indigo } from './indigo';
import { lightBlue } from './lightBlue';
import { lightGreen } from './lightGreen';
import { lime } from './lime';
import { orange } from './orange';
import { pink } from './pink';
import { purple } from './purple';
import { red } from './red';
import { teal } from './teal';
import { yellow } from './yellow';

export const colorWheel: ColorWheel = {
  amber,
  blue,
  blueGray,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  gray,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
};
