import {
  BusinessSvg,
  DoubleCheckCircleFillSvg,
  DoubleCheckCircleSvg,
  LinkOutlineSvg,
  LinkSvg,
  OxNomSvg,
  PersonGearOutlineSvg,
  PersonGearSvg,
  SlidersVOutlineSvg
} from '@core/images';
import _ from 'lodash';
import { ILinkInfo } from '../OxSidebar.types';

export const getSettingsPageLinks: () => ILinkInfo[] = () =>
  _.compact([
    {
      LinkIcon: BusinessSvg,
      SelectedLinkIcon: BusinessSvg,
      linkText: 'oxSidebar.settings.company',
      route: '#',
      children: [
        {
          LinkIcon: SlidersVOutlineSvg,
          linkText: 'Global',
          route: 'products/settings/company#global',
          SelectedLinkIcon: SlidersVOutlineSvg,
          matchParams: {
            product: 'settings',
            page: 'company',
            hash: 'global'
          }
        },
        {
          LinkIcon: LinkOutlineSvg,
          linkText: 'Pair',
          route: 'products/settings/company#pair',
          SelectedLinkIcon: LinkSvg,
          matchParams: {
            only: ['product', 'page', 'hash']
          }
        },
        {
          LinkIcon: OxNomSvg,
          linkText: 'Nom',
          route: 'products/settings/company#nom',
          SelectedLinkIcon: OxNomSvg,
          matchParams: {
            only: ['product', 'page', 'hash']
          }
          // isHidden: () => !isDev
        },
        {
          LinkIcon: DoubleCheckCircleSvg,
          linkText: 'Settle',
          route: 'products/settings/company#settle',
          SelectedLinkIcon: DoubleCheckCircleFillSvg,
          matchParams: {
            only: ['product', 'page', 'hash']
          }
        }
      ]
    },
    {
      LinkIcon: PersonGearOutlineSvg,
      SelectedLinkIcon: PersonGearSvg,
      linkText: 'oxSidebar.settings.user',
      route: 'products/settings/user'
    }
  ]);
