import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import {
  IPipelineTransportationAgreement,
  ITransportationAgreement
} from 'shared/models/TransportationAgreement';
import { ITransportationAgreementRepository } from '../../interfaces/ITransportationAgreementRepository';
import { BaseRepository } from '../BaseRepository';

@injectable()
export class TransportationAgreementRepository implements ITransportationAgreementRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/transportation-agreements/');

  async add(transportationAgreement: ITransportationAgreement): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...transportationAgreement });
  }

  async update(transportationAgreement: ITransportationAgreement): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...transportationAgreement });
  }

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, {
      data: [{ name: ids[1], pipelineId: ids[0] }]
    });
  }

  async getAll(): Promise<AxiosResponse<ITransportationAgreement[]>> {
    return await client.get(this.apiUrl);
  }

  async getAllPipelineTAs(): Promise<AxiosResponse<IPipelineTransportationAgreement[]>> {
    return await client.get(this.apiUrl + 'pipeline-tas');
  }

  async addPipelineTA(pipelineTA: IPipelineTransportationAgreement): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'pipeline-tas', { ...pipelineTA });
  }

  async deletePipelineTA(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + 'pipeline-tas', {
      data: [{ name: ids[1], pipelineId: ids[0] }]
    });
  }

  async getById(id: string): Promise<AxiosResponse<ITransportationAgreement>> {
    return await client.get(this.apiUrl + id);
  }
}
