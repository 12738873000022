import { StyleGetter } from '@core/theme/typings';
import { TagStyleProps } from './Tag.types';

export const css: StyleGetter<TagStyleProps> = (theme, { color }) => {
  const { fade, getColor, getTextColor, gutter: gtr, toGtr } = theme;
  const clr = getColor(color);
  const textClr = getTextColor(clr);

  const padSm = gtr / 2;
  const padLg = gtr * 1.5;
  const padXl = gtr * 2;
  return {
    tag: {
      backgroundColor: clr,
      borderRadius: gtr / 2,
      padding: toGtr(gtr / 1.5, gtr),

      '.core-text.tag-label': { color: textClr, lineHeight: 1 },

      '&.layout-compact': {
        paddingLeft: padSm,
        paddingRight: padSm
      },

      '&.layout-wide': {
        paddingLeft: padXl,
        paddingRight: padXl
      },

      '&.size-large': {
        paddingTop: gtr,
        paddingBottom: gtr,
        '&:not(.layout-compact):not(.layout-wide)': {
          paddingLeft: padLg,
          paddingRight: padLg
        },

        borderRadius: gtr,
        '.core-text.tag-label': { fontSize: 18 }
      },

      '&.size-small': {
        paddingTop: padSm,
        paddingBottom: padSm,

        '&:not(.layout-wide)': { paddingLeft: padSm, paddingRight: padSm },
        '.core-text.tag-label': { fontSize: 12 }
      },

      '&.--is-outline, &.--is-translucent': {
        backgroundColor: 'transparent',
        outline: `2px solid ${clr}`,
        '.core-text.tag-label': { color: clr }
      },
      '&.--is-translucent': {
        backgroundColor: fade(clr, 88)
      },
      '&.--is-clickable': {
        cursor: 'pointer',
        '.core-text.tag-label': { cursor: 'pointer' }
      }
    }
  };
};
