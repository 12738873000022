import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IPayIndexRepository } from 'shared/api/interfaces/IPayIndexRepository';
import { IPayIndex, IPayIndexSpec, IPayIndexSpecRequest } from 'shared/models/PayIndex';
import { IBaseThunk } from './base.thunks';

export class PayIndexThunks implements IBaseThunk<IPayIndex> {
  private payIndexRepository!: IPayIndexRepository;

  constructor(_payIndexRepository: IPayIndexRepository) {
    this.payIndexRepository = _payIndexRepository;

    if (this.payIndexRepository === null) {
      throw new Error('payIndexRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'pay-indexs';

  add(): AsyncThunk<HttpResponse<IPayIndex>, IPayIndex, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addPayIndex`;

    return createAsyncThunk(action, async (payIndex: IPayIndex, { rejectWithValue }) => {
      try {
        return await this.payIndexRepository.add(payIndex);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IPayIndex>, IPayIndex, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updatePayIndex`;

    return createAsyncThunk(action, async (payIndex: IPayIndex, { rejectWithValue }) => {
      try {
        return await this.payIndexRepository.update(payIndex);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IPayIndex, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deletePayIndex`;

    return createAsyncThunk(action, (id: string) => this.payIndexRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<IPayIndex, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deletePayIndexes`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.payIndexRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<IPayIndex[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchPayIndexes`;

    return createAsyncThunk(action, () => this.payIndexRepository.getAll());
  }

  getSpecs(): AsyncThunk<AxiosResponse<IPayIndexSpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchPayIndexesSpecs`;

    return createAsyncThunk(action, () => this.payIndexRepository.getSpecs());
  }

  getById(): AsyncThunk<AxiosResponse<IPayIndex, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchPayIndexById`;

    return createAsyncThunk(action, (id: string) => this.payIndexRepository.getById(id));
  }

  forceUpdate(): AsyncThunk<HttpResponse<IPayIndex>, IPayIndex, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/forceUpdatePayIndex`;

    return createAsyncThunk(action, async (payIndex: IPayIndex, { rejectWithValue }) => {
      try {
        return await this.payIndexRepository.forceUpdate(payIndex);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  createSpecRequest(): AsyncThunk<
    HttpResponse<IPayIndexSpecRequest>,
    IPayIndexSpecRequest,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/createPayIndexSpecRequest`;

    return createAsyncThunk(
      action,
      async (specRequest: IPayIndexSpecRequest, { rejectWithValue }) => {
        try {
          return await this.payIndexRepository.createSpecRequest(specRequest);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }
}
