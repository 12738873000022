const isBlob = (data: any) => typeof data === 'string' && data.search('blob:') !== -1;
const isPNG = (data: any) => typeof data === 'string' && data.charAt(0) === 'i';
const isJPEG = (data: any) => typeof data === 'string' && data.charAt(0) === '/';
const isBuffer = (data: any) => typeof data === 'object' && data?.type === 'Buffer';

export function formatImageData(data: any): any {
  if (!data) return data;

  if (data instanceof File) {
    return window.URL.createObjectURL(data);
  }

  if (isBlob(data)) {
    return data;
  }

  if (isPNG(data)) {
    return `data:png;base64,${data}`;
  }

  if (isJPEG(data)) {
    return `data:jpeg;base64,${data}`;
  }

  if (isBuffer(data)) {
    if (data && data?.data) {
      const base64 = btoa(
        new Uint8Array(data?.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      return formatImageData(base64);
    }
  }

  return data;
}
