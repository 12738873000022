import styled from 'styled-components';

export const StyledTable = styled.div`
  // font-family: 'GibsonRegular' !important;

  // .ag-header {
  //   font-family: 'GibsonMedium' !important;
  //   border-color: ${(props) => props.theme.$black_6} !important;
  // }

  // .ag-root-wrapper {
  //   border: none !important;
  // }

  // .ag-header-cell {
  //   border: none !important;
  // }

  // .ag-header-row {
  //   background-color: ${(props) => props.theme.$black_2} !important;
  //   color: ${(props) => props.theme.$black_85} !important;
  // }

  // .ag-row {
  //   background-color: ${(props) => props.theme.$white};
  //   color: ${(props) => props.theme.$primary_text} !important;
  //   border-width: 0px 0px 0.5px 0px !important;
  // }
  // .ag-row:hover {
  //   background-color: ${(props) => props.theme.$aqua_1} !important;
  // }
  // .ag-row-selected {
  //   background-color: ${(props) => props.theme.$aqua_3} !important;
  // }

  // .ag-watermark {
  //   display: none;
  // }

  // .rounded-corners {
  //   .ag-root-wrapper {
  //     border-radius: 8px;
  //   }
  // }

  // .font-exceptions {
  //   .ag-row {
  //     color: ${(props) => props.theme.$black_45} !important;
  //   }
  // }
  // .ag-cell-focus,
  // .ag-cell-no-focus {
  //   border: none !important;
  // }

  // .ag-cell-focus {
  //   background-color: ${(props) => props.theme.fieldHighlight} !important;
  // }

  // .ag-cell:focus {
  //   border: none !important;
  //   outline: none;
  // }

  // &.ag-theme-alpine-dark {
  //   .ag-header-row {
  //     background-color: ${(props) => props.theme.$title} !important;
  //     color: ${(props) => props.theme.$table_header} !important;
  //   }

  //   .ag-row {
  //     background-color: ${(props) => props.theme.$rich_black} !important;
  //     color: ${(props) => props.theme.$table_header} !important;
  //   }
  // }

  // .ag-overlay {
  //   pointer-events: auto;
  //   top: 47px;
  // }
`;
