import { createContext } from 'react';
import { GridFieldsCV, GridFiltersCV, GridRef } from './typings';

/* context for withGridRef */
export const GridRefContext = createContext<GridRef>(null as unknown as GridRef);

/* context for withGridFilters */
export const GridFiltersContext = createContext<GridFiltersCV>({} as GridFiltersCV);

/* context for withGridFields */
export const GridFieldsContext = createContext<GridFieldsCV>({} as GridFieldsCV);
