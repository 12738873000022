const components = {
  oxModal: {
    confirmCancel: {
      titleSingular: 'Cancel upload?',
      titlePlural: 'Cancel all uploads?',
      bodySingular: 'Your upload is not complete. Would you like to cancel the upload?',
      bodyPlural: 'Your uploads are not complete. Would you like to cancel all ongoing uploads?',
      continueSingular: 'Continue Upload',
      continuePlural: 'Continue Uploads',
      cancelSingular: 'Cancel Upload',
      cancelPlural: 'Cancel Uploads'
    }
  },
  oxSnackbar: {
    referenceBody: 'Drop files onto this page to upload them for reference data.'
  },
  oxSidebar: {
    configurations: 'Configurations',
    oxPair: {
      deals: 'Deals',
      withdrawnDeals: 'Withdrawn Deals',
      terminatedDeals: 'Terminated Deals',
      rejectedDeals: 'Rejected Deals'
    },
    oxSettle: {
      upload: 'Invoice Uploads',
      rejectedEtrmInvoices: 'Rejected Invoices ({{count}})',
      approvedInvoices: 'Approved Invoices',
      invoiceReconciliation: 'Invoice Reconciliation',
      sqtsDailyLevels: 'Daily Level Quantities'
    },
    oxOracle: {
      staticData: 'Spec Static Data',
      specReferenceData: 'Spec Reference Data',
      referenceData: 'Reference Data',
      tariffRates: 'Tariff Rates',
      tariffRatesMatrix: 'Tariff Rates Matrix'
    },
    oxAdmin: {
      userPermission: 'User Permission',
      userReports: 'User Reports',
      counterparty: 'Counterparty',
      broker: 'Broker',
      pipeline: 'Pipeline'
    },
    oxNom: {
      nomination: 'Nomination',
      scheduling: 'Scheduling',
      deal: 'Deal',
      reporting: 'Reporting',
      positionSummary: 'Position Summary',
      plannedVolumes: 'Planned Volumes',
      ediDashboard: 'Scheduled Quantities',
      fuelRateScenarios: 'Fuel Rate Scenarios',
      gasDaySummary: 'Gas Day Summary',
      integration: 'Integration',
      pipelineSubmission: 'Pipeline Submission',
      etrm: 'ETRM',
      referenceData: 'Reference Data',
      counterparty: 'Counterparties',
      agency: 'Agency Associations',
      crossReference: 'Cross Reference',
      location: 'Locations',
      locationGroup: 'Location Groups',
      pipelineZoneLocation: 'Pipeline Zone Location',
      transportationAgreements: 'Transportation Agreements',
      rates: 'Rates',
      support: 'Support',
      ediLogs: 'EDI Logs',
      ebbLogs: 'EBB Logs',
      processingLog: 'Processing Log',
      mdqSummary: 'MDQ Summary',
      storageReport: 'Storage Report',
      exportHistory: 'Export History',
      contract: 'Contracts',
      contractSegments: 'Contract Segments',
      epsqCalculator: 'EPSQ Calculator',
      columnConfiguration: 'NomPad Column Config'
    },
    settings: {
      company: 'Company',
      user: 'User'
    },
    support: {
      eleoxSupport: 'Eleox Support',
      helpdesk: 'Helpdesk (External)'
    }
  },
  oxTable: {
    download: 'Download Table',
    contextMenu: {
      separator: 'separator',
      copy: 'copy',
      copyWithHeaders: 'copyWithHeaders',
      copyWithGroupHeaders: 'copyWithGroupHeaders',
      export: 'export'
    }
  },
  oxRollupDisplay: {
    title: 'Deals'
  }
};

export default components;
