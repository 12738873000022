/* NOTE:
 * This is a list of all the higher-order components that wrap the app, divided by functionality.
 * Please do not remove or rearrange the order of these components; it'll break things.
 */

import { Auth0Provider } from '@auth0/auth0-react';
import { OxLoading } from '@core/components';
import { DataProvider } from '@core/data-manager';
import { ErrorBoundary } from '@core/error-boundary';
import { getGlobalConfig } from '@core/global-config';
import { useAppDispatch, useAppSelector } from '@core/redux';
import { setUserConfig } from '@core/redux-actions';
import { withSidebar } from '@core/sidebar';
import { withIsDark, withTheme } from '@core/theme';
import { withUserConfigs } from '@core/user-configs/hoc';
import { compose } from '@reduxjs/toolkit';
import trimEnd from 'lodash/trimEnd';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { getIsOnboarding } from './redux/slices/authentication/authentication.slice';

type HocProps = { children?: ReactNode };
type ComposedSfc = (props: HocProps) => JSX.Element;

const withRouter = (Composed: ComposedSfc): ComposedSfc => {
  return function withHocs(props: HocProps): JSX.Element {
    return (
      <ErrorBoundary>
        <div className='App'>
          <BrowserRouter>
            <Composed {...props} />
          </BrowserRouter>
        </div>
      </ErrorBoundary>
    );
  };
};

function withConfig(Composed: ComposedSfc): ComposedSfc {
  return function WithConfig(props: HocProps): JSX.Element {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      getGlobalConfig().then(async (config) => {
        await dispatch(setUserConfig(config));
        setIsLoading(false);
      });
      dispatch(getIsOnboarding());
    }, []);

    if (isLoading) {
      return (
        <div className='mt-4 text-center'>
          <OxLoading />
        </div>
      );
    }

    return <Composed {...props} />;
  };
}

function withAuth(Composed: ComposedSfc): ComposedSfc {
  return function WithAuth(props: HocProps): JSX.Element {
    const { config } = useAppSelector((state) => state.config);
    const domain = config.oauth.authority;
    const formattedDomain = trimEnd(domain, '/');
    const navigate = useNavigate();
    const onRedirectCallback = useCallback(() => {
      navigate(window.location.pathname);
    }, []);

    return (
      <Auth0Provider
        domain={formattedDomain}
        clientId={config.oauth.clientId}
        authorizationParams={{
          redirect_uri: location.origin
        }}
        {...{ onRedirectCallback }}
      >
        <Composed {...props} />
      </Auth0Provider>
    );
  };
}

function withDataProvider(Composed: ComposedSfc): ComposedSfc {
  return function WithDataProvider(props: HocProps): JSX.Element {
    return (
      <DataProvider>
        <Composed {...props} />
      </DataProvider>
    );
  };
}

function withThemeListener(Composed: ComposedSfc): ComposedSfc {
  return function WithThemeListener(props: HocProps): JSX.Element {
    const { darkMode } = useAppSelector((state) => state.userPreferences);
    useEffect(() => {
      document.body.classList.remove(darkMode ? 'theme-light' : 'theme-dark');
      document.body.classList.add(darkMode ? 'theme-dark' : 'theme-light');
    }, [darkMode]);
    return <Composed {...props} />;
  };
}

export const withAppWrappers = compose<ComposedSfc>(
  withRouter,
  withConfig,
  withAuth,
  withIsDark,
  withSidebar,
  withTheme(),
  withThemeListener,
  withDataProvider,
  withUserConfigs
);
