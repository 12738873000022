import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { ILegalEntityRepository } from 'shared/api/interfaces/ILegalEntityRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { ICounterparty } from 'shared/models/Counterparty';

// All the below are placeholders for api integration

@injectable()
export class LegalEntityRepository implements ILegalEntityRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/counterparties/legal-entities');

  async getAll(): Promise<AxiosResponse<ICounterparty[]>> {
    return await client.get(this.apiUrl);
  }

  add(/* item: any */): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }
  update(/* item: ICounterparty */): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }
  delete(/* id: string */): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }
}
