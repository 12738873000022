import { client } from '@core/rest-client';
import { DataTemplateType } from '@core/typings';
import { Signals } from '@core/util';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IUploadRepository } from 'shared/api/interfaces/IUploadRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IDataTemplate } from 'shared/models/DataTemplate';

export const controllers = new Map<string, AbortController>();

@injectable()
export class UploadRepository implements IUploadRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/uploads/');

  async getAll(): Promise<AxiosResponse<IDataTemplate[]>> {
    const response = await client.get(this.apiUrl);
    return response.data;
  }

  async add(file: IDataTemplate): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...file });
  }

  async upload(file: FormData, id: string): Promise<AxiosResponse<any, any>> {
    const { signal } = Signals.Instance.add(id);
    return await client.post(this.apiUrl, file, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async update(file: IDataTemplate): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...file });
  }

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async getAllByType(type: DataTemplateType): Promise<AxiosResponse<any, any>> {
    const response = await client.get(this.apiUrl + type.valueOf());
    return response;
  }

  async getSuccessesAndFailures(id: string, type: string): Promise<AxiosResponse<any, any>> {
    const response = await client.get(`${this.apiUrl}rows/${id}/${type.valueOf()}`);
    return response.data;
  }
}
