const Storage = {
  exists: window.localStorage !== undefined,

  get<T>(key: string): T | null {
    if (!this.exists) return null;

    const data: string | null = localStorage.getItem(key);

    if (data !== null) {
      return JSON.parse(data);
    }

    return null;
  },

  add<T>(key: string, data: T) {
    if (!this.exists) return;
    window.localStorage.setItem(key, JSON.stringify(data));
  },

  remove(key: string) {
    if (!this.exists) return;
    window.localStorage.removeItem(key);
  },

  wipe() {
    if (!this.exists) return;
    window.localStorage.clear();
  }
};

export default Storage;
