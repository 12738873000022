export enum NomPadColumnCategory {
  Unknown = 0,
  Supply = 1,
  Demand = 2,
  NomHeader = 3,
  Link = 4,
  UserOptional = 5,
  Housekeeping = 6,
  EndurInfo = 7,
  Fixed = 8,
  EbbInfo = 9
}
