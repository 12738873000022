import { useValueMemo } from '@core/hooks';
import { PersonFillSvg } from '@core/images';
import { useAppSelector } from '@core/redux';
import { useLogout } from '@core/user';
import { getInitials } from '@core/util';
import _ from 'lodash';
import { useCallback } from 'react';
import { ButtonSize } from '../OxButton';
import { OxDropDown } from '../OxDropDown';
import { OxProfileImage } from '../OxProfileImage';

export const OxShowLoginStatus = (): JSX.Element => {
  const logout = useLogout();
  const { currentUser } = useAppSelector((state: any) => state.access);
  const initials = useValueMemo(
    () => getInitials(currentUser?.firstName, currentUser?.lastName),
    [currentUser]
  );
  const dropDownContent = useValueMemo(
    () => [
      {
        text: 'Logout',
        handleOnClick: logout
      }
    ],
    [logout]
  );

  const ButtonContent = useCallback(
    ({ initials }: { initials: string }) => {
      if (_.isEmpty(initials)) {
        return <PersonFillSvg data-testid='SettingsIcon' width={21.5} height={24} />;
      }
      return <OxProfileImage initials={initials} />;
    },
    [initials]
  );

  return (
    <OxDropDown
      buttonSize={ButtonSize.compact}
      dropDownContent={dropDownContent}
      buttonContent={<ButtonContent {...{ initials }} />}
    />
  );
};
