import { client } from '@core/rest-client';
import _ from 'lodash';
import { Endpoints } from '../EndpointManager';
import { DataMngrQueryMeta } from '../typings';

export const handleRequest = async (meta: DataMngrQueryMeta) => {
  const { extractData, requestOptions, dynamicRouteKeys, routeParams, name } = meta;
  let options = requestOptions;
  const getOptions = Endpoints.Map.get(name)?.getRequestOptions;
  if (getOptions) {
    options = await getOptions(requestOptions);
  }

  try {
    let { url } = options;
    /* replace dynamic route indicators with corresponding route param */
    if (dynamicRouteKeys.length) {
      _.each(dynamicRouteKeys, (key) => {
        url = _.replace(url as string, `:${key}`, _.toString(_.get(routeParams, key)));
      });
    }
    const resp = await client.request({ ...options, url });
    if (extractData) return resp.data;
  } catch (e) {
    return Promise.reject(e);
  }
};
