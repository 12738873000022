import { StyleGetter } from '@core/theme/typings';

const gtr = 8;
const transition = 'all 300ms ease-in-out';
export const css: StyleGetter = (theme) => {
  const {
    colors: { canvas, component, primary, text },
    fade
  } = theme;
  return {
    wpr: {
      '.legal-entities-dropdown': {
        marginTop: gtr * 1.5,
        transition,
        width: '100%',
        'div, svg': {
          transition,
          color: text.inverse
        },
        '.selected-label': {
          maxWidth: 100
        },
        '.value-tag, span.counter-tag': {
          backgroundColor: fade(text.inverse, 88),
          border: `0.5px solid ${text.hint}`
        },
        borderColor: text.inverse,

        '&:hover': {
          backgroundColor: fade(primary.saturated, 88),
          borderColor: primary.saturated,
          'div, svg': {
            color: primary.saturated
          }
        },
        '&:focus': {
          'div, svg': {
            color: primary.veryDark
          },
          backgroundColor: canvas.primary,
          borderColor: component.primary
        }
      }
    }
  };
};
