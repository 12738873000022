import { dateFormatStringMap, splitJoinedArray } from '@core/util';
import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import _ from 'lodash';
import { createDateFormatter, createDatetimeFormatter } from './createDateFormatter';
import { CreateFormatterConfig, CreateFormatterFormatFn } from './createFormatter.types';
import { createNumeralFormatter } from './createNumeralFormatter';
import { createRelativeTimeFormatter } from './createRelativeTimeFormatter';
import { msFormatter } from './msFormatter';

const pass = (v: any) => v;
const toString = (v: any) => _.toString(v);

export function createFormatter(conf: CreateFormatterConfig = {}): ValueFormatterFunc {
  const {
    applyDefaults = true,
    emptyValue = '',
    nullValue = '--',
    transform = pass,
    formatString: _formatString,
    parseFormat,
    isStrictParse = false,
    decimalPrecision = 8,
    useCentralTime = false
  } = conf;

  let format: CreateFormatterFormatFn = toString;
  if (_.isFunction(conf.format)) {
    format = conf.format;
  } else {
    const formatString =
      _formatString || dateFormatStringMap[conf.format as keyof typeof dateFormatStringMap];
    switch (conf.format) {
      case false:
      case 'none':
        format = pass;
        break;
      case 'date':
        format = createDateFormatter({
          formatString,
          parseFormat,
          isStrictParse,
          formatType: conf.format,
          useCentralTime
        });
        break;
      case 'datetime':
        format = createDatetimeFormatter({
          formatString,
          parseFormat,
          isStrictParse,
          formatType: conf.format,
          useCentralTime
        });
        break;
      case 'ms':
        format = msFormatter;
        break;
      case 'currency':
      case 'number':
      case 'numeric':
      case 'integer':
        format = createNumeralFormatter({ formatString, decimalPrecision });
        break;
      case 'fromNow':
      case 'toNow':
        format = createRelativeTimeFormatter(conf.format);
        break;
      case true:
      case 'string':
      default:
        break;
    }
  }

  return (params: ValueFormatterParams): string => {
    const { column, value } = params;
    const wasNull = _.isNull(value);
    const transformed = transform(value, params);
    let formatted = format(transformed, params);

    if (column?.getAggFunc() === 'list') {
      formatted = _.join(
        _.map(splitJoinedArray(transformed as string), (val) => format(val, params)).sort(),
        ', '
      );
    }

    /* when applying defaults, check to see if the value was null prior to initial format */
    if (applyDefaults && (_.isEmpty(formatted) || _.isNull(formatted))) {
      formatted = wasNull ? nullValue : emptyValue;
    }
    return formatted;
  };
}
