import { addNotification, IOxToastOptions } from '@core/notification';
import { useCallback } from 'react';

export type CreateNotificationFn = (notification: IOxToastOptions) => void;

/**
 * @deprecated A hook is no longer needed. Use the `addNotification` function directly to create a toast notification.
 */
export function useNotification(): CreateNotificationFn {
  return useCallback<CreateNotificationFn>((payload: IOxToastOptions) => {
    addNotification(payload);
  }, []);
}
