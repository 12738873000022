import { DeepEqual } from '@core/typings';
import { useRef } from 'react';
import { useDidUpdate } from './useDidUpdate';
import { useIsFirstRender } from './useIsFirstRender';
import { useUpdate } from './useUpdate';

/* memoizes a complex value based on deep-eq check */
export function useMemoizeValue<X>(rawValue: X, isEq?: DeepEqual): X {
  const isFirstRender = useIsFirstRender();
  const update = useUpdate();
  const value = useRef<X>();

  if (isFirstRender) value.current = rawValue;

  useDidUpdate(
    () => {
      value.current = rawValue;
      update();
    },
    [rawValue],
    isEq
  );

  return value.current as X;
}
