import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ITransportationAgreementRepository } from 'shared/api/interfaces/ITransportationAgreementRepository';
import {
  IPipelineTransportationAgreement,
  ITransportationAgreement
} from 'shared/models/TransportationAgreement';
import { IBaseThunk } from './base.thunks';

export class TransportationAgreementThunks implements IBaseThunk<ITransportationAgreement> {
  private readonly transportationAgreementRepository!: ITransportationAgreementRepository;

  constructor(_transportationAgreementRepository: ITransportationAgreementRepository) {
    this.transportationAgreementRepository = _transportationAgreementRepository;

    if (this.transportationAgreementRepository === null) {
      throw new Error('transportationAgreementRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'transportationAgreements';

  add(): AsyncThunk<
    HttpResponse<ITransportationAgreement>,
    ITransportationAgreement,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/addTransportationAgreement`;

    return createAsyncThunk(
      action,
      async (transportationAgreement: ITransportationAgreement, { rejectWithValue }) => {
        try {
          return await this.transportationAgreementRepository.add(transportationAgreement);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }

  update(): AsyncThunk<
    HttpResponse<ITransportationAgreement>,
    ITransportationAgreement,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/updateTransportationAgreement`;

    return createAsyncThunk(
      action,
      async (transportationAgreement: ITransportationAgreement, { rejectWithValue }) => {
        try {
          return await this.transportationAgreementRepository.update(transportationAgreement);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }

  delete(): AsyncThunk<
    AxiosResponse<ITransportationAgreement, any>,
    string,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/deleteTransportationAgreement`;

    return createAsyncThunk(action, (id: string) =>
      this.transportationAgreementRepository.delete(id)
    );
  }

  deleteAll(): AsyncThunk<
    AxiosResponse<ITransportationAgreement, any>,
    string[][],
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/deleteChargeType`;

    return createAsyncThunk(action, async (ids: string[][], { rejectWithValue }) => {
      try {
        return await this.transportationAgreementRepository.deleteAll(ids[0]);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  getAll(): AsyncThunk<
    AxiosResponse<ITransportationAgreement[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/fetchTransportationAgreements`;

    return createAsyncThunk(action, () => this.transportationAgreementRepository.getAll());
  }

  getAllPipelineTAs(): AsyncThunk<
    AxiosResponse<IPipelineTransportationAgreement[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/fetchPipelineTransportationAgreements`;

    return createAsyncThunk(action, () =>
      this.transportationAgreementRepository.getAllPipelineTAs()
    );
  }

  addPipelineTA(): AsyncThunk<
    HttpResponse<IPipelineTransportationAgreement>,
    IPipelineTransportationAgreement,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/addPipelineTransportationAgreement`;

    return createAsyncThunk(
      action,
      async (pipelineTA: IPipelineTransportationAgreement, { rejectWithValue }) => {
        try {
          return await this.transportationAgreementRepository.addPipelineTA(pipelineTA);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }

  deletePipelineTA(): AsyncThunk<
    AxiosResponse<ITransportationAgreement, any>,
    string[][],
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/deletePipelineTA`;

    return createAsyncThunk(action, async (ids: string[][], { rejectWithValue }) => {
      try {
        return await this.transportationAgreementRepository.deletePipelineTA(ids[0]);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  getById(): AsyncThunk<
    AxiosResponse<ITransportationAgreement, any>,
    string,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/fetchTransportationAgreementById`;

    return createAsyncThunk(action, (id: string) =>
      this.transportationAgreementRepository.getById(id)
    );
  }
}
