import chroma from 'chroma-js';
import { ColorRange, ColorScale, CssColor } from '../../../typings';

export const getStandardColors = (range: ColorRange): ColorScale => {
  const [lightest, veryLight, lighter, light, primary, dark, darker, veryDark, darkest] = chroma
    .scale(range)
    .colors(9) as CssColor[];

  return {
    lightest,
    veryLight,
    lighter,
    light,
    primary,
    dark,
    darker,
    veryDark,
    darkest,
    saturated: chroma(primary).set('hsl.s', '*1.33').css() as CssColor,
    muted: chroma(primary).set('hsl.s', '*0.6').css() as CssColor,
    bg: chroma(primary).alpha(0.12).css() as CssColor
  };
};
