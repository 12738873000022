import { usePreviousValue } from '@core/hooks';
import { addNotification } from '@core/notification';
import { ComposedSfc } from '@core/typings';
import { usePermissions, useUser } from '@core/user';
import routeStrings from 'Containers/Routing/routeStrings';
import _ from 'lodash';
import { useEffect } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getIsOnboarding, setIsOnboarding } from 'redux/slices/authentication/authentication.slice';

const { productSelection, login, changePassword, support } = routeStrings;
const noAuthPaths = ['/', `/products/support/${support.eleoxSupport}`];

export function withDOA(Composed: ComposedSfc): ComposedSfc {
  return function WithDOA(): JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname, hash } = useLocation();

    useEffect(() => {
      dispatch(getIsOnboarding());
    }, []);
    const { userCanVisit } = usePermissions();
    const { data: currentUser } = useUser();
    const { isOnboarding } = useAppSelector((state) => state.auth);
    /* keep track of previous sub-path so user isn't bounced completely off page if hashed route is disabled */
    const prevPath = usePreviousValue<[string, string | undefined]>([pathname, hash]);
    useEffect(() => {
      if (!_.includes(noAuthPaths, pathname) && currentUser.resourcesPermissions) {
        if (!userCanVisit(pathname, hash)) {
          addNotification({
            title: 'notifications.routeBlocked.title',
            message: 'notifications.routeBlocked.message',
            messageProps: { tParams: { route: _.trimStart(`${pathname}${hash}`, '/') } }
          });

          navigate(
            /* Check to see if the previous path is valid, if not, bounce the user off the page.
             * Type is assigned here because number or string are allowed, but the overrides can't
             * handle a scenario where it can be one or the other. */
            (prevPath ? (userCanVisit(...prevPath) ? _.join(prevPath, '') : -1) : '/') as To
          );
        }
      }
      /* set isOnboarding */
      const isNowOnboarding = _.includes(['/', productSelection, login, changePassword], pathname);
      if (isNowOnboarding !== isOnboarding) {
        dispatch(setIsOnboarding({ isOnboarding: isNowOnboarding }));
      }
    }, [pathname, hash]);

    return <Composed />;
  };
}
