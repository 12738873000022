import { OxTypography, TextSize } from '@core/components';
import { OxDateRangePicker } from '@core/elements/Datepickers';
import { GridRange } from '@core/typings';
import { displayFormatToUtc } from '@core/util';
import { add } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface DateRangeProps {
  useAppSelector: any;
  showDateLabel?: boolean;
  dispatchSetDateFilterRange: (dateRange: GridRange) => void;
  dispatchClearDateFilterRange?: () => void;
  months?: number;
  dateFilterRange?: GridRange;
}

export function ToolbarDateRangePicker({
  dispatchSetDateFilterRange,
  showDateLabel = true,
  months,
  dispatchClearDateFilterRange,
  dateFilterRange
}: DateRangeProps) {
  const { t } = useTranslation();
  const ignoreDateFilter = false;

  const startDate = displayFormatToUtc(dateFilterRange?.startDate || '');
  const endDate = displayFormatToUtc(dateFilterRange?.endDate || '');

  const label = showDateLabel ? (
    <OxTypography className='mx-3 mt-2 whitespace-nowrap' size={TextSize.Small}>
      {t('general.viewBy').toUpperCase()}
    </OxTypography>
  ) : null;

  return (
    <div className='inline-flex pr-2 border-r-[1px] align-middle'>
      {label}
      <div className='h-full'>
        <OxDateRangePicker
          startDateOverride={startDate}
          endDateOverride={endDate}
          setGridRange={dispatchSetDateFilterRange}
          clearDateRange={dispatchClearDateFilterRange}
          months={months && months}
          maxDate={add(new Date(), { years: 1 })}
          minDate={new Date('01/01/2022')}
          ignoreDateFilter={ignoreDateFilter}
        />
      </div>
    </div>
  );
}
