import { themes } from '@core/theme';
import { KeyboardIds } from '@core/typings';
import { ICellEditorParams } from 'ag-grid-community';
import React, {
  forwardRef,
  KeyboardEvent,
  KeyboardEventHandler,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { ThemeProvider } from 'styled-components';
import { StyledCellEditContainer } from './components';

export interface InputCellEditProps {
  getAllThunk: () => void;
  sliceKey: string;
  label?: string;
  name?: string;
  dataKey: string;
  validateInputText: (event: KeyboardEvent<HTMLInputElement>, rowData: any[]) => object;
  useAppSelector: any;
}

export const OxCellEditInput = forwardRef(
  (
    {
      getAllThunk,
      sliceKey,
      dataKey,
      label,
      name,
      validateInputText,
      useAppSelector,
      ...params
    }: InputCellEditProps & ICellEditorParams,
    ref: React.Ref<any>
  ) => {
    const [searchText, setSearchText] = useState('');
    const [selectedValue, setSelectedValue] = useState<object | undefined>();
    const rowData = useAppSelector((state: any) => state[sliceKey as keyof typeof state][dataKey]);

    useEffect(() => {
      getAllThunk();
    }, []);

    useEffect(() => {
      if (selectedValue) {
        params.stopEditing();
      }
    }, [selectedValue]);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return selectedValue ? selectedValue : params.value;
        }
      };
    });

    const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (event.code === KeyboardIds.ENTER) {
        event.preventDefault();
        const validatedInput = validateInputText(event, rowData);
        setSelectedValue(validatedInput);
      }
    };

    const cellEditContainer = (
      <StyledCellEditContainer className='cell-edit-container h-full rounded'>
        <input
          className='cell-edit-input pl-2 h-full w-full rounded'
          type='text'
          name={name}
          placeholder={label}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={onInputKeyDown}
        />
      </StyledCellEditContainer>
    );

    return <ThemeProvider theme={themes.lightTheme}>{cellEditContainer}</ThemeProvider>;
  }
);

OxCellEditInput.displayName = 'CellEditInput';
