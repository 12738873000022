export class Signals {
  private static _instance: Signals;

  private controllers = new Map<string, AbortController>();

  private constructor() {
    if (Signals._instance) {
      throw new Error('Error: Instantiation failed: Use Signals.getInstance() instead of new.');
    }
    Signals._instance = this;
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public get Controllers() {
    return this.controllers;
  }

  public add(id: string): AbortController {
    const controller = new AbortController();
    this.controllers.set(id, controller);
    return controller;
  }

  public cancel(id: string): void {
    this.controllers.get(id)?.abort();
    this.controllers.delete(id);
  }

  public remove(id: string): void {
    if (this.controllers.has(id)) {
      this.controllers.delete(id);
    }
  }
}
