import { StyleGetter } from '@core/theme/typings';
import { MenuStyleProps } from './Menu.types';

const hoverSelector = '.rc-menu-item-active, .rc-menu-submenu-active > .rc-menu-submenu-title';

const gtrVarName = '--menu-gutter-size';
const gtrVar = 'var(--menu-gutter-size)';
const multiplyGtrVar = (amt: string | number) => `calc(${gtrVar} * ${amt})`;

/* NOTE:
 * *****************************************************************************
 * Do NOT hard-code any widths/heights/padding/margins/etc. in this component.
 * Because it supports multiple layouts, follow the existing pattern and use
 * `gtrVar` and `multiplyGtrVar` to derive dynamic values based on the layout.
 * *****************************************************************************
 */
export const css: StyleGetter<MenuStyleProps> = (
  theme,
  { color = 'primary', minWidth = '20vw', maxWidth = '50vw' }
) => {
  const {
    colors: { canvas, component, text },
    colorWheel: { gray },
    darken,
    fade,
    getColor,
    getTextColor,
    gutter: gtr,
    lighten,
    opacify,
    toGtr
  } = theme;
  const clr = getColor(color);
  const clrBg = fade(clr, 88);
  const clrBgSelected = darken(clr, 20);
  const clrLight = lighten(clr, 20);
  const bdrClr = fade(text.hint, 88);
  const selectedTextColor = getTextColor(clr);
  const selectedHoverTextColor = getTextColor(clrBgSelected);

  return {
    menu: {
      [gtrVarName]: gtr * 1.5,

      padding: 0,
      whiteSpace: 'pre-wrap',
      backgroundColor: component.primary,
      boxShadow: `0 1px 9px 2px ${fade(gray[500], 67)}`,
      border: 'none',
      minWidth,
      maxWidth,

      /* *********************************************************************************** ITEM */
      '.rc-menu-item': {
        whiteSpace: 'pre-wrap',
        display: 'flex',
        flex: '1 0',

        '.ctr': {
          display: 'flex',
          flex: '1 0',
          flexDirection: 'column',
          justifyContent: 'center'
        },

        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ BORDERED */
        '&.--is-bordered': {
          outline: `0.5px solid ${opacify(bdrClr, 20)}`
        },
        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ JUSTIFIED */
        '&.--is-justified .ctr': {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        },
        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ VERTICAL */
        '&.--is-vertical .ctr': {
          flexDirection: 'column',
          '.title-wpr': { marginBottom: gtr / 2, alignSelf: 'flex-start' },
          '&.--is-justified': { alignItems: 'space-between', justifyContent: 'flex-start' }
        },
        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ CLICKABLE */
        '&.--is-clickable': {
          cursor: 'pointer',
          '*': {
            cursor: 'pointer'
          },
          '&:hover:not(.--is-selected)': {
            background: fade(clrLight, 70)
          }
        },
        /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ DISLABLED */
        '&.rc-menu-item-disabled': {
          cursor: 'not-allowed',
          color: text.disabled,
          backgroundColor: canvas.disabled,
          '*': {
            backgroundColor: canvas.disabled,
            cursor: 'not-allowed'
          },
          '&:hover': {
            background: canvas.disabled
          }
        },
        /* ********************************************************* SELECTED */
        '&.--is-selected': {
          backgroundColor: clr,
          color: selectedTextColor,
          '*': { color: 'inherit' },
          '&:hover': {
            background: clrBgSelected,
            color: selectedHoverTextColor
            // '*': { color: 'inherit' }
          }
        }
      },

      /* *************************************************************** ITEM + SUB-MENU (COMMON) */
      '.rc-menu-item, .rc-menu-submenu-title': {
        padding: toGtr(multiplyGtrVar(1.25), gtr)
      },
      [hoverSelector]: { backgroundColor: 'inherit' },

      /* ******************************************************************************* SUB-MENU */
      '.rc-menu-submenu-title': {
        display: 'flex',
        alignItems: 'center'
      },
      '.rc-menu-submenu-open .rc-menu-submenu-title': {
        backgroundColor: darken(component.offset6, 10)
      },
      '.rc-menu-sub': {
        backgroundColor: component.primary,
        border: 'none',
        outline: `0px solid ${bdrClr}`,

        '&.rc-menu-inline': {
          'li[tabindex="-1"]': { paddingLeft: multiplyGtrVar(3) },
          'li[tabindex="-2"]': { paddingLeft: multiplyGtrVar(5) }
        }
      },

      /* ******************************************************************************* DIVIDERS */
      '.rc-menu-item-divider': {
        backgroundColor: bdrClr,
        margin: 0,

        '&:last-child': { display: 'none' }
      },

      /* ***************************************************************************** TITLE/DESC */
      '.title-wpr': {
        display: 'flex',
        alignItems: 'center'
      },
      '.icon-wpr': {
        display: 'none',
        alignItems: 'center',
        minWidth: multiplyGtrVar(1.5),
        marginRight: gtrVar,
        '*': { height: multiplyGtrVar(1.5), width: multiplyGtrVar(1.5) }
      },
      '.item-title': {
        display: 'flex',
        lineHeight: 1,
        alignItems: 'center',
        alignSelf: 'center'
      },
      '.item-description': {
        width: '100%',
        overflowX: 'hidden',
        color: fade(gray[900], 0.6),
        fontStyle: 'italic',
        fontSize: 12,
        marginTop: gtr / 2,
        lineHeight: 1
      },

      /*  ************************************************************************** ICON SPACING */
      '&.--has-icons .icon-wpr': {
        display: 'flex'
      },

      /* ********************************************************************************** HOVER */
      '&.--is-hoverable': {
        [hoverSelector]: {
          '&:not(.--is-selected)': {
            backgroundColor: clrBg
          }
        }
      },
      /* ******************************************************************************** ROUNDED */
      '&.--is-rounded': {
        borderRadius: gtrVar,
        'li.rc-menu-item:first-child': {
          borderRadius: toGtr(gtrVar, gtrVar, 0, 0),
          '.rc-menu-item, .rc-menu-submenu-title': { borderRadius: toGtr(gtrVar, gtrVar, 0, 0) }
        },
        'li.--last-item': {
          borderRadius: toGtr(0, 0, gtrVar, gtrVar),
          '.rc-menu-item, .rc-menu-sub, .rc-menu-submenu-title': {
            borderRadius: toGtr(0, 0, gtrVar, gtrVar)
          }
        }
      },

      /* ******************************************************************************** STRIPED */
      '&.--is-striped': {
        '> .--even-item': { backgroundColor: canvas.offset3 },
        '.rc-menu-sub': {
          '> .--even-item': {
            backgroundColor: component.offset3
          }
        }
      },

      /* ********************************************************************************* LAYOUT */
      '&.--layout-compact': {
        [gtrVarName]: gtr * 0.75,

        '&.--has-icons .rc-menu-item .icon-wpr': {
          minWidth: multiplyGtrVar(2),
          marginRight: gtrVar,
          '*': { height: multiplyGtrVar(2), width: multiplyGtrVar(2) }
        }
      }
    }
  };
};
