/* eslint-disable quotes */
const admin = {
  admin: {
    userPermission: {
      title: 'User Permissions',
      body: `Existing user roles have predefined permissions. Custom roles can be created.`,
      addUser: 'Add User',
      addNewUser: 'Add a New User',
      roles: {
        confirmation: {
          header: 'Are you sure?',
          body: 'This will delete the role from the workspace.',
          confirm: 'Yes',
          cancel: 'Go back'
        }
      },
      products: {
        modal: {
          title: 'Manage Product Resources'
        }
      }
    },
    userReports: {
      title: 'User Reports',
      description: 'Get detailed information on user access and permissions.',
      csvReports: 'CSV Reports'
    },
    confirmation: {
      header: 'Are you sure?',
      body: 'This will delete the user from the workspace.',
      confirm: 'Yes',
      cancel: 'Go back'
    },
    userTemplate: {
      name: 'Name',
      products: 'Products',
      duns: 'Duns',
      role: {
        name: 'Role'
      },
      longName: 'Long Name',
      isActive: 'Is Active',
      createdBy: 'Created By',
      createdAt: 'Created At',
      modifiedBy: 'Modified By',
      updatedAt: 'Last Updated',
      status: 'Status',
      activity: 'Last Updated',
      invited: 'Date Invited'
    },
    userDrawer: {
      title: 'Account Information',
      name: 'Full Name',
      role: 'Role',
      email: 'Email Address',
      phone: 'Phone Number',
      legalEntity: 'Legal Entity',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      about: 'about',
      contact: 'contact',
      productsHeader: 'Products',
      productsDescription: "Manage this user's product capabilities and grant access.",
      productHeader: 'Product',
      productAccess: 'Access',
      rolesDropdown: {
        admin: 'Admin',
        confirmAnalyst: 'Confirm Analyst',
        dataAnalyst: 'Data Analyst',
        schedulerAnalyst: 'Scheduler Analyst',
        settlementAnalyst: 'Settlement Analyst',
        settlementManager: 'Settlement Manager',
        itSupport: 'IT Support',
        support: 'Eleox Support',
        etrm: 'ETRM'
      }
    },
    accessError:
      "You do not have access to this company. Please navigate to the appropriate company's Eleox URL."
  }
} as const;

export default admin;

/* eslint-enable quotes */
