import _ from 'lodash';
import { DataMngrEndpoint } from './typings';
import { EndpointKeyMap, EndpointMap, registerSection } from './util';

class EndpointManager {
  get Map(): Map<string, DataMngrEndpoint> {
    return EndpointMap;
  }
  get Keymap(): Map<string, Record<string, string>> {
    return EndpointKeyMap;
  }

  register = registerSection;

  toObject = () => Object.fromEntries(this.Map);

  getKeymap = (key?: string) => {
    const keysObj = Object.fromEntries(this.Keymap);
    if (key) return _.get(keysObj, [key]);
    return keysObj;
  };
}

const _Endpoints = new EndpointManager();
export const Endpoints = _Endpoints;
