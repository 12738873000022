import { useValueMemo } from '@core/hooks';
import { cn } from '@core/util';
import { memo, useEffect, useMemo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { TabProps, TabRendererParams } from './Tabs.types';
import { useTabs } from './useTabs';
import { tabClassNames } from './util';

export type TabRenderParams = {
  tab: TabProps;
  keyOrIndex: string | number;
};

const Tab = memo(function Tab(params: TabRendererParams): JSX.Element {
  const { duration, lazyLoad, unmountOnExit, _addEndListener, activeTab, isPending } = useTabs();
  const { className, id, children, component: El, render, transitionProps } = params;
  const isActive = useValueMemo(() => activeTab === id, [activeTab, id]);
  const initHasBeenActive = useMemo(() => lazyLoad && isActive, []);
  const [hasBeenActive, setHasBeenActive] = useState(initHasBeenActive);
  useEffect(() => {
    if (!hasBeenActive && isActive) setHasBeenActive(true);
  }, [isActive]);

  return (
    <CSSTransition
      timeout={duration}
      mountOnEnter={lazyLoad || unmountOnExit}
      unmountOnExit={unmountOnExit}
      {...{ ...transitionProps }}
      addEndListener={_addEndListener}
      enter
      exit
      appear
      key={id}
      in={activeTab === id}
      classNames={tabClassNames}
    >
      <div className={cn('tab', className)}>
        {(lazyLoad && !hasBeenActive) || (!isActive && isPending)
          ? null
          : children || (El ? <El /> : render?.()) || null}
      </div>
    </CSSTransition>
  );
});

export function renderTab(tab: TabProps, id: string): JSX.Element {
  return <Tab {...{ ...tab, id }} key={id} />;
}
