import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _split from 'lodash/split';

export const splitJoinedArray = (string: string | any[], delimiter?: string, limit?: number) => {
  /* return if it's already an array */
  if (_isArray(string)) return string;

  let sep = delimiter;
  switch (true) {
    /* if delimiter is explicitly declared, use that */
    case !_isEmpty(delimiter):
      break;
    case _includes(string, ', '):
      sep = ', ';
      break;
    case _includes(string, ','):
      sep = ',';
      break;
    case _includes(string, ' | '):
      sep = ' | ';
      break;
    case _includes(string, '|'):
      sep = '|';
      break;
    default:
      sep = ' ';
      break;
  }
  return _split(string, sep, limit);
};
