import { Endpoints } from '@core/data-manager';

export const userConfigEndpoints = Endpoints.register({
  key: 'user-configs',
  basePath: '/api/access/v1/users/currentUser/configs',
  endpointMap: {
    update: {
      path: '/',
      method: 'put'
    }
  }
});
