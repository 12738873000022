import './ErrorOverlay.style.scss';

import { BtnProps, Btns, Text } from '@core/components';
import { useValueMemo } from '@core/hooks';
import { cn } from '@core/util';
import _compact from 'lodash/compact';
import _isString from 'lodash/isString';
import _reduce from 'lodash/reduce';
import { ErrorOverlayProps } from '../../error-boundary.types';

import { ChevronUpSvg, CloseCircleFilledSvg, HomeSvg, RefreshSvg } from '@core/images';
import { ColorRef } from '@core/theme/typings';
import { useMemo } from 'react';
import { useErrorBoundary } from '../../useErrorBoundary';
import { ErrorDetails, SubmitBugReport } from './components';

const btnDefaults: { bordered: boolean; color: ColorRef } = {
  bordered: true,
  color: 'danger.primary'
};

export function ErrorOverlay(props: ErrorOverlayProps): JSX.Element {
  const {
    buttons: btnsConf = ['reset', 'reloadPage', 'goHome'],
    description = 'Please submit a bug report to help Eleox resolve this issue:',
    fullScreen = true,
    title = 'Error'
  } = props;
  const { detailsOpen, reset, toggleDetailsOpen } = useErrorBoundary();
  const ButtonPresetMap = useMemo<Record<string, BtnProps>>(
    () => ({
      goHome: {
        ...btnDefaults,
        label: 'Go Home',
        icon: HomeSvg,
        onClick: () => {
          window.location.replace('/');
        }
      },
      reloadPage: {
        ...btnDefaults,
        label: 'Reload Page',
        onClick: () => window.location.reload(),
        icon: RefreshSvg
      },
      resetApp: {
        ...btnDefaults,
        label: 'Resel App Data'
      },
      reset: {
        ...btnDefaults,
        label: 'Reset',
        icon: CloseCircleFilledSvg,
        onClick: () => reset()
      }
    }),
    [detailsOpen]
  );

  const buttons = useValueMemo<BtnProps[]>(
    () => [
      ...(_compact(
        _reduce(
          btnsConf,
          (memo: BtnProps[], btn: BtnProps | string) => {
            if (_isString(btn)) memo.push(ButtonPresetMap[btn]);
            else memo.push(btn);
            return memo;
          },
          []
        )
      ) as BtnProps[]),
      {
        bordered: !detailsOpen,
        className: 'details-ctrl',
        color: '#000',
        icon: ChevronUpSvg,
        label: `${detailsOpen ? 'Hide' : 'Show'} Error Details`,
        onClick: toggleDetailsOpen
      }
    ],
    [btnsConf, detailsOpen]
  );

  return (
    <div className={cn('error-overlay', { '--full-screen': fullScreen })}>
      <div className='overlay-ctr'>
        <div className='head-wpr'>
          <div className='title-wpr'>
            {_isString(title) ? <Text className='title' t={title as string} /> : title}
          </div>
          <div className='description-wpr'>
            {_isString(description) ? (
              <Text className='description' t={description as string} />
            ) : (
              description
            )}
          </div>
        </div>
        <SubmitBugReport />
        <ErrorDetails />
        <Btns {...{ buttons }} className='action-btns-wpr' gutter={16} />
      </div>
    </div>
  );
}
