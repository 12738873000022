import { ComposedSfc } from '@core/typings';
import { useState } from 'react';
import { IsDarkContext } from '../Contexts';

export function withIsDark(Composed: ComposedSfc): ComposedSfc {
  return function WithIsDark(p: any): JSX.Element {
    const value = useState(false);

    return (
      <IsDarkContext.Provider value={value}>
        <Composed {...p} />
      </IsDarkContext.Provider>
    );
  };
}
