import chroma from 'chroma-js';
import _ from 'lodash';
import { COLORS } from '../colors';
import { lightTheme } from '../themes';

export function legacy_getColor(color: any, fallback: any = '#000'): string {
  let ret = fallback;
  if (_.includes(['inherit', 'unset', 'initial', 'revert', 'currentColor'], color as string)) {
    return color;
  }
  if (_.has(lightTheme, [color])) ret = lightTheme[color];
  else if (chroma.valid(color)) ret = color;
  else {
    const paletteRef = COLORS[`$${_.snakeCase(color)}` as keyof typeof COLORS];
    if (paletteRef) ret = paletteRef;
  }
  return chroma(ret).hex();
}
