import { ButtonSize, ButtonType, OxButton } from '@eleox/libs';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ClearAllProps {
  grid: React.RefObject<AgGridReactType>;
  clearFilter: () => void;
  className?: string;
}

function ClearAll({ grid, clearFilter, className }: ClearAllProps) {
  const { t } = useTranslation();

  const clearFilters = () => {
    grid.current?.api.setFilterModel(null);
    grid.current?.api.onFilterChanged();
    clearFilter();
  };

  return (
    <OxButton
      buttonType={ButtonType.text}
      buttonSize={ButtonSize.default}
      className={`core-btn mr-2 ${className}`}
      onClick={clearFilters}
    >
      <div className='text-lg btn-label'>
        {t('general.clearAllFilters')} <span className='ml-1 font-bold'>X</span>
      </div>
    </OxButton>
  );
}

export default ClearAll;
