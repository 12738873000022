import { Endpoints } from '@core/data-manager';

export const CompanyConfigEndpoints = Endpoints.register({
  key: 'company-config',
  basePath: '/api/admin/v1/company-configurations',
  endpointMap: {
    fetch: {
      path: '/',
      staleTime: '8h',
      gcTime: '12h',
      refetchOnWindowFocus: false,
      retry: 1
    },
    update: {
      path: '/',
      method: 'put'
    }
  }
});
