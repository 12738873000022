import { createSelector, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { LocationGroupThunks } from 'redux/thunks/locationGroup.thunk';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { ILocationGroupRepository } from 'shared/api/interfaces/ILocationGroupRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { DeleteResponse } from 'shared/models/DeleteResponse';
import { ILocationGroup, ILocationGroupSpec } from 'shared/models/LocationGroup';
import { IPipeline } from 'shared/models/Pipeline';
import { RootState } from '../../store';
import { selectPipelineMap } from '../pipeline/pipeline.slice';

type InitialStateType = {
  count?: number;
  changeId: number;
  locationGroups: ILocationGroup[];
  locationGroupSpecs: ILocationGroupSpec[];
};

const count = undefined;
const changeId = 0;
const locationGroups: ILocationGroup[] = [];
const locationGroupSpecs: ILocationGroupSpec[] = [];

export const initialState: InitialStateType = {
  count,
  changeId,
  locationGroups,
  locationGroupSpecs
};

const locationGroupRepository = container.get<ILocationGroupRepository>(
  SERVICE_KEYS.LOCATION_GROUP_REPOSITORY
);
const locationGroupThunks = new LocationGroupThunks(locationGroupRepository);
export const getAllLocationGroups = locationGroupThunks.getAll();
export const addLocationGroup = locationGroupThunks.add();
export const deleteAllLocationGroups = locationGroupThunks.deleteAll();
export const getAllLocationGroupSpecs = locationGroupThunks.getSpecs();
export const updateLocationGroup = locationGroupThunks.update();

const slice = buildGenericSlice<InitialStateType>('locationGroup', initialState);
export const locationGroupSlice = createSlice<
  InitialStateType,
  SliceCaseReducers<InitialStateType>
>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllLocationGroups.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data?.length) {
          const { data } = payload;
          state.locationGroups = data;
          state.changeId++;
          state.count = data.length;
        }
      }
    );
    builder.addCase(
      addLocationGroup.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload) {
          if (payload) {
            const locationGroups = [...state.locationGroups, payload.data];
            state.locationGroups = locationGroups;
            state.changeId++;
            state.count = locationGroups.length;
          }
        }
      }
    );
    builder.addCase(
      getAllLocationGroupSpecs.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data && payload.data.length > 0) {
          state.locationGroupSpecs = payload.data as ILocationGroupSpec[];
        }
      }
    );
    builder.addCase(
      deleteAllLocationGroups.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload.status === 200) {
          const { data } = payload;
          state.changeId++;
          state.locationGroups = state.locationGroups.filter(
            (locationGroup: ILocationGroup) =>
              !data.find((response: DeleteResponse) => response.id === locationGroup.etrmId)
                ?.isSuccess
          );
        }
      }
    );
  }
});

export const locationGroupReducer = locationGroupSlice.reducer;

export const selectLocationGroups = (state: RootState) => state.locationGroup.locationGroups;
export const selectLocationGroupSpecs = (state: RootState) =>
  state.locationGroup.locationGroupSpecs;

export const selectLocationGroupMap = createSelectorMap(selectLocationGroups, 'etrmId');
export const selectLocationGroupSpecMap = createSelectorMap(selectLocationGroupSpecs, 'specId');

export interface IFQLocationGroup extends ILocationGroup {
  spec?: ILocationGroupSpec;
  pipeline?: IPipeline;
}

export const selectFQLocationGroupRefs = createSelector(
  [selectLocationGroups, selectLocationGroupSpecMap, selectPipelineMap],
  (locationGroups, specMap, pipelineMap) => {
    return locationGroups.map<IFQLocationGroup>((group) => {
      return {
        ...group,
        spec: specMap.get(group.specId),
        pipeline: pipelineMap.get(group.etrmPipelineId)
      };
    });
  }
);
