import { DataMngrEndpoint } from '../typings';

/* map of endpoint name:endpoint-config */
export const EndpointMap = new Map<string, DataMngrEndpoint>();

/* map of section: endpoint-key:endpoint-name */
export const EndpointKeyMap = new Map<string, Record<string, string>>();

/* map of name:method+URL */
export const EndpointUrlMap = new Map<string, string>();

/* list of section keys */
export const EndpointSections = new Set<string>();

/* map of section-basepath:section-name */
export const EndpointSectionUriMap = new Map<string, string>();
