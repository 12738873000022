import { IOxUserData, ProductKey, ProductPermission, UserActivityMap } from '@core/typings';
import { ProductAccess } from 'shared/models/ProductAccess';

export const transformProductAccess = (products: ProductAccess) => {
  // TODO: The settings permissions match oxAdmin for now
  products.settings = { ...products.oxAdmin };
  return Object.keys(products).map((productName) => ({
    key: productName,
    ...(products[productName as ProductKey] as object)
  }));
};

const productAccess = ['oxAdmin', 'oxNom', 'oxOracle', 'oxPair', 'oxSettle'];

const mapProductsToUser = (user: IOxUserData) => {
  const products: ProductPermission[] = [];

  let key: keyof typeof user;
  for (key in user) {
    if (Object.prototype.hasOwnProperty.call(user, key)) {
      if (productAccess.indexOf(key) > -1 && user[key] && productAccess.includes(key)) {
        products.push({ key: key as ProductKey, ...(user[key] as object) } as ProductPermission);
      }
    }
  }

  return {
    ...user,
    products
  };
};

export const createUserActivityMap = (user: IOxUserData) => {
  /* build activityMap from activities array */
  const activityMap: UserActivityMap =
    user.activity?.reduce((memo, { product, lastAccessedAt }) => {
      memo[product] = lastAccessedAt;
      return memo;
    }, {} as UserActivityMap) || {};

  /* fill in missing gaps */
  productAccess.forEach((key) => {
    activityMap[key] = activityMap[key] || null;
    return;
  });
  return { ...user, activityMap };
};

export const formatUserData = (user: IOxUserData): IOxUserData => {
  /* NOTE: if any more processors are added, these fns probably need to be composed */
  if (user.activity) {
    return mapProductsToUser(createUserActivityMap(user));
  } else {
    return mapProductsToUser(user);
  }
};
