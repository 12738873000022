import styled from 'styled-components';

export const StyledStatus = styled.div`
  color: ${(props) => props.theme.statusTextDark};

  &.default-status {
    color: ${(props) => props.theme.primaryButtonText};
    background-color: ${(props) => props.theme.contrast_40};
  }

  &.success,
  &.primary-status {
    background-color: ${(props) => props.theme.settleBackground};
  }

  &.completed {
    background-color: ${(props) => props.theme.primaryButtonBackground};
    color: ${(props) => props.theme.primaryButtonText};
  }

  &.inprogress,
  &.inprogress-status {
    background-color: ${(props) => props.theme.inProgressStatus};
    color: ${(props) => props.theme.primaryButtonText};
  }

  &.done,
  &.primary-alt-status {
    background-color: ${(props) => props.theme.statusGreen};
  }

  &.donealternative,
  &.secondary-status {
    background-color: ${(props) => props.theme.adminStatusBackgroundColor};
  }

  &.warning,
  &.pending,
  &.warning-status {
    background-color: ${(props) => props.theme.oracleBackground};
  }

  &.processing,
  &.uploaded,
  &.warning-alt,
  &.warning-alt-status {
    background-color: ${(props) => props.theme.statusYellow};
  }

  &.completing,
  &.info,
  &.info-status {
    color: ${(props) => props.theme.dangerButtonText};
    background-color: ${(props) => props.theme.nomBackground};
  }

  &.info-alt-status {
    background-color: ${(props) => props.theme.infoBackground};
  }

  &.danger-status {
    color: ${(props) => props.theme.dangerButtonText};
    background-color: ${(props) => props.theme.dangerButtonBackground};
  }

  &.processalternative,
  &.danger-alt,
  &.danger-alt-status {
    background-color: ${(props) => props.theme.statusOxPair};
  }
`;
