import { IStaticData } from '@core/typings';
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RootState } from 'redux/store';
import { StaticDataThunk } from 'redux/thunks/staticData.thunks';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { IStaticDataRepository } from 'shared/api/interfaces/IStaticDataRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';

type InitialStateType = {
  [key: string]: IStaticData[];
  averagingMethods: IStaticData[];
  chargeIndicators: IStaticData[];
  chargeTypes: IStaticData[];
  currencies: IStaticData[];
  deliveryTypes: IStaticData[];
  flowDirections: IStaticData[];
  locationCategories: IStaticData[];
  locationCountries: IStaticData[];
  locationStates: IStaticData[];
  locationTypes: IStaticData[];
  masterContractTypes: IStaticData[];
  periodicals: IStaticData[];
  pricingCalendars: IStaticData[];
  rateTypes: IStaticData[];
  serviceCodes: IStaticData[];
  tradeTypes: IStaticData[];
  unitOfMeasures: IStaticData[];
};

const averagingMethods: IStaticData[] = [];
const chargeIndicators: IStaticData[] = [];
const chargeTypes: IStaticData[] = [];
const currencies: IStaticData[] = [];
const deliveryTypes: IStaticData[] = [];
const flowDirections: IStaticData[] = [];
const locationCategories: IStaticData[] = [];
const locationCountries: IStaticData[] = [];
const locationStates: IStaticData[] = [];
const locationTypes: IStaticData[] = [];
const masterContractTypes: IStaticData[] = [];
const periodicals: IStaticData[] = [];
const pricingCalendars: IStaticData[] = [];
const rateTypes: IStaticData[] = [];
const serviceCodes: IStaticData[] = [];
const tradeTypes: IStaticData[] = [];
const unitOfMeasures: IStaticData[] = [];

export const initialState: InitialStateType = {
  averagingMethods,
  chargeIndicators,
  chargeTypes,
  currencies,
  deliveryTypes,
  flowDirections,
  locationCategories,
  locationCountries,
  locationStates,
  locationTypes,
  masterContractTypes,
  periodicals,
  pricingCalendars,
  rateTypes,
  serviceCodes,
  tradeTypes,
  unitOfMeasures
};

const staticDataRepository = container.get<IStaticDataRepository>(
  SERVICE_KEYS.STATIC_DATA_REPOSITORY
);
const staticDataThunks = new StaticDataThunk(staticDataRepository);

export const fetchAveragingMethods = staticDataThunks.getAllAveragingMethods();
export const fetchChargeIndicators = staticDataThunks.getAllChargeIndicators();
export const fetchChargeTypes = staticDataThunks.getAllChargeTypes();
export const fetchCurrencies = staticDataThunks.getAllCurrencies();
export const fetchDeliveryTypes = staticDataThunks.getAllDeliveryTypes();
export const fetchFlowDirections = staticDataThunks.getAllFlowDirections();
export const fetchLocationCategories = staticDataThunks.getAllLocationCategories();
export const fetchLocationCountries = staticDataThunks.getAllLocationCountries();
export const fetchLocationStates = staticDataThunks.getAllLocationStates();
export const fetchLocationTypes = staticDataThunks.getAllLocationTypes();
export const fetchMasterContractTypes = staticDataThunks.getAllMasterContractTypes();
export const fetchPeriodicals = staticDataThunks.getAllPeriodicals();
export const fetchPricingCalendars = staticDataThunks.getAllPricingCalendars();
export const fetchRateTypes = staticDataThunks.getAllRateTypes();
export const fetchServiceCodes = staticDataThunks.getAllServiceCodes();
export const fetchTradeTypes = staticDataThunks.getAllTradeTypes();
export const fetchUnitOfMeasures = staticDataThunks.getAllUnitOfMeasures();

const slice = buildGenericSlice<InitialStateType>('staticData', initialState, '', []);

export const staticDataSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchAveragingMethods.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.averagingMethods = payload || [];
      }
    );
    builder.addCase(
      fetchChargeIndicators.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.chargeIndicators = payload || [];
      }
    );
    builder.addCase(
      fetchChargeTypes.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.chargeTypes = payload || [];
      }
    );
    builder.addCase(
      fetchCurrencies.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.currencies = payload || [];
      }
    );
    builder.addCase(
      fetchDeliveryTypes.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.deliveryTypes = payload || [];
      }
    );
    builder.addCase(
      fetchFlowDirections.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.flowDirections = payload || [];
      }
    );
    builder.addCase(
      fetchLocationCategories.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.locationCategories = payload || [];
      }
    );
    builder.addCase(
      fetchLocationCountries.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.locationCountries = payload || [];
      }
    );
    builder.addCase(
      fetchLocationStates.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.locationStates = payload || [];
      }
    );
    builder.addCase(
      fetchLocationTypes.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.locationTypes = payload || [];
      }
    );
    builder.addCase(
      fetchMasterContractTypes.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.masterContractTypes = payload || [];
      }
    );
    builder.addCase(
      fetchPeriodicals.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.periodicals = payload || [];
      }
    );
    builder.addCase(
      fetchPricingCalendars.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.pricingCalendars = payload || [];
      }
    );
    builder.addCase(
      fetchRateTypes.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.rateTypes = payload || [];
      }
    );
    builder.addCase(
      fetchServiceCodes.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.serviceCodes = payload || [];
      }
    );
    builder.addCase(
      fetchTradeTypes.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.tradeTypes = payload || [];
      }
    );
    builder.addCase(
      fetchUnitOfMeasures.fulfilled,
      (state: any, { payload }: PayloadAction<IStaticData[]>) => {
        state.unitOfMeasures = payload || [];
      }
    );
  }
});

export const {
  getAllAveragingMethods,
  getAllChargeIndicators,
  getAllChargeTypes,
  getAllCurrencies,
  getAllDeliveryTypes,
  getAllFlowDirections,
  getAllLocationCategories,
  getAllLocationCountries,
  getAllLocationStates,
  getAllLocationTypes,
  getAllMasterContractTypes,
  getAllPeriodicals,
  getAllPricingCalendars,
  getAllRateTypes,
  getAllServiceCodes,
  getAllTradeTypes,
  getAllUnitOfMeasures
} = staticDataSlice.actions;

export const staticDataReducer = staticDataSlice.reducer;

export const selectFlowDirections = (state: RootState) => state.staticData.flowDirections;
export const selectFlowDirectionMap = createSelectorMap(selectFlowDirections, 'id');

export const selectLocationTypes = (state: RootState) => state.staticData.locationTypes;
export const selectLocationTypeMap = createSelectorMap(selectLocationTypes, 'id');

export const selectCountries = (state: RootState) => state.staticData.locationCountries;
export const selectCountryMap = createSelectorMap(selectCountries, 'id');

export const selectStates = (state: RootState) => state.staticData.locationStates;
export const selectStateMap = createSelectorMap(selectStates, 'id');
