import { useSetState, useValueMemo } from '@core/hooks';
import { StandardSelectOption } from '@core/inputs';
import { ComposedSfc, Nullable } from '@core/typings';
import { ISetFilter } from 'ag-grid-community';
import { diff as getDiff } from 'deep-object-diff';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GridFiltersContext } from '../Grid.contexts';
import { useAgGridRef } from '../hooks/useAgGridRef';
import {
  FilterOptions,
  FiltersConfigMap,
  FilterValue,
  GridFiltersCV,
  GridFiltersLib,
  GridFiltersState
} from '../typings';

function convertFiltersToState<Filters extends FiltersConfigMap = FiltersConfigMap>(
  filters: Filters
): GridFiltersState<Filters> {
  return _.mapValues(filters, (filter) => ({
    options: [],
    value: filter.defaultValue ?? (filter.isMulti ? [] : null)
  })) as GridFiltersState<Filters>;
}

export function withGridFilters<TData extends object = any>(filterConf: FiltersConfigMap = {}) {
  type Filters = {
    [T in keyof typeof filterConf]: FilterOptions;
  };
  type State = GridFiltersState<Filters>;
  type Lib = GridFiltersLib<Filters>;
  type CtxVal = GridFiltersCV<Filters>;
  const Conf = {
    get getConf() {
      return <T extends keyof Filters = keyof Filters>(field: T) => filterConf[field];
    }
  };

  return function _withGridFilters(Composed: ComposedSfc) {
    return function WithGridFilters(p: any): JSX.Element {
      const Grid = useAgGridRef<TData>();
      const [dataHasRendered, setDataHasRendered] = useState<boolean>(false);
      const initialState = useMemo(() => convertFiltersToState(filterConf), []);
      const getFilterInstance = useCallback(
        (name: string): Nullable<ISetFilter> =>
          (Grid.current?.api.getFilterInstance(name) ?? null) as Nullable<ISetFilter>,
        [!!Grid.current?.api]
      );

      const [state, setState] = useSetState<State>(initialState, (nState, pState) => {
        const nVals = _.mapValues(nState, 'value');
        const diff = getDiff(_.mapValues(pState, 'value'), nVals);

        if (!_.isEmpty(diff)) {
          _.each(_.keys(diff), async (key) => {
            /* NOTE: diff converts arrays to objects, so get the value separately */
            const val = _.get(nVals, [key]);
            const instance = getFilterInstance(key);
            if (!instance) return;
            const { isMulti } = Conf.getConf(key) ?? {};
            const isNull = isMulti ? _.isEmpty(val) : _.isNull(val);
            await instance.setModel(
              isNull ? null : { values: _.isArray(val) ? _.compact(val) : [val] }
            );
            return;
          });
          Grid.current?.api.onFilterChanged();
        }
      });

      const lib = useMemo<Lib>(
        () => ({
          clear: () => {
            setState((pState) =>
              _.mapValues(pState, (fld, key) => ({
                ...fld,
                value: initialState[key]?.value ?? null
              }))
            );
          },
          getParams: <T extends keyof Filters = keyof Filters>(field: T) => Conf.getConf(field),
          setOptions: <T extends keyof Filters = keyof Filters>(
            field: T,
            options: StandardSelectOption[]
          ) => {
            setState(
              (pState) =>
                ({
                  [field]: { ...pState[field], options }
                }) as unknown as Partial<State>
            );
          },
          setValue: <T extends keyof Filters = keyof Filters>(
            field: T,
            value: FilterValue<Filters, T>
          ) => {
            setState(
              (pState) =>
                ({
                  [field]: { ...pState[field], value }
                }) as unknown as Partial<State>
            );
          }
        }),
        []
      );

      /* prettier-ignore */
      const value = useValueMemo<CtxVal>(() => ({ ...lib, filterMap: state }), [state]);

      const setAllOptions = useCallback(
        (/* api: GridApi<TData> */) => {
          setState((pState) => {
            const nState = _.cloneDeep(pState);
            _.each(_.keys(initialState), (filterKey) => {
              const instance = getFilterInstance(filterKey) as ISetFilter;
              if (instance) {
                instance.refreshFilterValues();
                const optionMap = (instance as any)?.valueModel?.allValues as Map<string, string>;
                const opts: StandardSelectOption[] = [];
                // const { optionCreator = defaultOptionCreator } = Conf.getConf()[filterKey]
                optionMap.forEach((label, value) => {
                  opts.push({ value, label: label ?? '(Blanks)' });
                });
                _.set(nState, `${filterKey}.options`, _.orderBy(opts, ['label']));
              } else console.warn(`could not find filter instance for ${filterKey}`);
              return;
            });
            return nState;
          });
        },
        []
      );

      useEffect(() => {
        const filterChangedListener = (/* e: FilterChangedEvent */) => {
          setAllOptions(/* e.api */);
        };
        const dataChangedListener = (/* e: RowDataUpdatedEvent */) => {
          setAllOptions(/* e.api */);
        };
        const dataFirstRenderedListener = () => {
          setDataHasRendered(true);
        };
        /* add listeners to grid */
        if (Grid.current?.api) {
          if (!dataHasRendered) {
            Grid.current.api.addEventListener('firstDataRendered', dataFirstRenderedListener);
          }
          Grid.current.api.addEventListener('filterChanged', filterChangedListener);
          Grid.current.api.addEventListener('rowDataUpdated', dataChangedListener);
          /* grid has rendered but now longer exists, so local filters need to be reset */
        } else if (dataHasRendered) {
          setState(initialState);
        }

        /* remove old listeners when re-firing */
        return () => {
          if (Grid.current?.api) {
            if (dataHasRendered) {
              Grid.current.api.removeEventListener('firstDataRendered', dataFirstRenderedListener);
            }
            Grid.current.api.removeEventListener('filterChanged', filterChangedListener);
            Grid.current.api.removeEventListener('rowDataUpdated', dataChangedListener);
          }
        };
      }, [!!Grid.current?.api]);

      return (
        <GridFiltersContext.Provider value={value as unknown as GridFiltersCV}>
          <Composed {...p} />
        </GridFiltersContext.Provider>
      );
    };
  };
}
