import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIsFirstRender } from './useIsFirstRender';

type SetStateCallback<X> = (newState: X, prevState: X) => void;
type SetStateFn<X> = (prev: X) => Partial<X>;
type SetStateArgs<X> = [update: Partial<X> | SetStateFn<X>, cb?: SetStateCallback<X>];
type SetState<X> = (...args: SetStateArgs<X>) => void;
type WriteStateFn<X> = (prev: X) => X;
type WriteStateArgs<X> = [update: X | WriteStateFn<X>, cb?: SetStateCallback<X>];
type WriteState<X> = (...args: WriteStateArgs<X>) => void;
export type UseSetStateReturn<X> = [X, SetState<X>, WriteState<X>];

/*
 * useSetState: drop-in replacement for this.setState in React.Component, with some extra utils
 *
 * accepts: optional params (initialState: object, onChange: function)
 * returns: [state: object, setState: function]
 * setState function accepts the following arguments:
 *  - a partial update object OR an update function that passes the previous state as its sole arg
 *  - an optional callback that runs with the updated and previous states as its args after the state is updated
 */
export function useSetState<X extends object>(
  initialState: X = {} as X,
  onChange?: SetStateCallback<X>
): UseSetStateReturn<X> {
  const isFirstRender = useIsFirstRender();
  const firstState = useMemo<X>(() => ({ ...initialState }), []);
  const [state, set] = useState<X>(firstState);
  const pendingCb = useRef<SetStateCallback<X> | undefined>();
  const prevState = useRef<X>(firstState);

  const setState = useCallback<SetState<X>>((update, cb?) => {
    if (_.isPlainObject(update)) {
      set((prev: X) => {
        prevState.current = prev;
        return { ...prev, ...update };
      });
    } else if (_.isFunction(update)) {
      set((prev: X) => {
        prevState.current = prev;
        return { ...prev, ...update(prev) };
      });
    }
    pendingCb.current = cb;
  }, []);

  const writeNewState = useCallback<WriteState<X>>((nState, cb?) => {
    pendingCb.current = cb;
    if (_.isPlainObject(nState)) {
      set((prev: X) => {
        prevState.current = prev;
        return nState as X;
      });
    } else if (_.isFunction(nState)) {
      set((prev: X) => {
        prevState.current = prev;
        return nState(prev);
      });
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      onChange?.(state, prevState.current);
    }
    pendingCb.current?.(state, prevState.current);
    pendingCb.current = undefined;
  }, [state]);

  return [state, setState, writeNewState];
}
