import { HttpResponse } from '@core/typings';
import { IOxRole } from '@core/typings/Role';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IRole } from 'shared/models/Role';
import { IRoleRepository } from '../../shared/api/interfaces/IRoleRepository';
import { IBaseThunk } from './base.thunks';

export class RoleThunks implements IBaseThunk<IOxRole> {
  private readonly roleRepository!: IRoleRepository;

  constructor(_roleRepository: IRoleRepository) {
    this.roleRepository = _roleRepository;

    if (this.roleRepository === null) {
      throw new Error('roleRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'role';

  getAll(): AsyncThunk<AxiosResponse<IOxRole[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchRoles`;

    return createAsyncThunk(action, () => this.roleRepository.getAll());
  }

  getOne(): AsyncThunk<AxiosResponse<IRole, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchRole`;

    return createAsyncThunk(action, (id: string) => this.roleRepository.getOne(id));
  }

  getActiveUserCountByRole(): AsyncThunk<
    AxiosResponse<number, any>,
    string,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/fetchActiveUserCountByRole`;

    return createAsyncThunk(action, (roleId: string) =>
      this.roleRepository.getActiveUserCountByRole(roleId)
    );
  }

  add(): AsyncThunk<HttpResponse<IOxRole>, any, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addRole`;

    return createAsyncThunk(action, async (role: any, { rejectWithValue }) => {
      try {
        return await this.roleRepository.add(role);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IOxRole>, any, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateRole`;

    return createAsyncThunk(action, async (payload, { rejectWithValue }) => {
      try {
        return await this.roleRepository.update(payload);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IOxRole, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteRole`;

    return createAsyncThunk(action, async (id: string, { rejectWithValue }) => {
      try {
        return await this.roleRepository.delete(id);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }
}
