import { JsonMap, Nullable } from '@core/typings';
import { InternalStateBase } from '@core/util';
import _ from 'lodash';
import { UserConfig, UserConfigManager } from './lib';
import {
  BatchRegisterParams,
  IUserConfig,
  IUserConfigs,
  RegisterConfigParams,
  TTValMap,
  UserConfigRegistryParams,
  UserConfigsState,
  UserConfigStoredData
} from './typings';
import { UserConfigMap, UserConfigRegistryMap } from './util';

const defaultParams: Omit<UserConfigRegistryParams<JsonMap>, 'type' | 'key' | 'schema'> = {
  pathname: null,
  preferredHydrationValue: 'remote',
  label: null,
  initialValue: {},
  autoSave: false
};

const initialState: UserConfigsState = {
  hasRegisteredInitialEntries: false
};

class _UserConfigs extends InternalStateBase<UserConfigsState> implements IUserConfigs {
  private _updateQueue: IUserConfig[] = [];
  constructor() {
    super({ initialState });

    this.Manager.once('ready', async () => {
      await this._flushQueue();
      this.setState({ hasRegisteredInitialEntries: true });
    });
  }

  private _flushQueue = async () => {
    if (this._updateQueue.length && this.Manager.getState().isReady) {
      const vals = await Promise.all<UserConfigStoredData>(
        _.map(
          this._updateQueue,
          (Config) =>
            new Promise((res) =>
              Config.hydrate({ isBatched: true }).then((val) => res(val as UserConfigStoredData))
            )
        )
      );

      await this.Manager.batchUpdateRemote(_.keyBy(_.compact(vals), 'key'));
      this._updateQueue = [];
    }
  };

  public register = <TVal extends JsonMap>(
    params: RegisterConfigParams<TVal>,
    batchKey?: number
  ): string => {
    const item = {
      ...defaultParams,
      ...params
    } as UserConfigRegistryParams<JsonMap>;
    const exists = this.Registry.has(item.key);
    if (exists) {
      console.error(`cannot re-register Config "${item.key}"`);
      return item.key;
    }
    /* set in registry */
    this.Registry.set(item.key, item);
    const Config = new UserConfig({ key: item.key, type: item.type });
    let shouldHydrate = false;
    try {
      /* set in instance map */
      this.ConfigMap.set(item.key, Config);
      /* if batched; add to bulk-update queue */
      if (!this.Manager.state.isReady || !!batchKey) {
        this._updateQueue.push(Config);
      } else shouldHydrate = true;
      return item.key;
    } finally {
      if (shouldHydrate) {
        Config.hydrate();
      }
    }
  };

  public batchRegister = <TValMap extends TTValMap>(
    _items: BatchRegisterParams<TValMap>
  ): Record<keyof TValMap, keyof TValMap> => {
    try {
      const items = _.map<BatchRegisterParams<TValMap>, RegisterConfigParams>(
        _items,
        (item, key: string) => ({ ...item, key }) as RegisterConfigParams
      );
      const keys = _.compact(_.map<RegisterConfigParams, Nullable>(items, this.register));
      return _.reduce(
        keys,
        (memo, key) => ({ ...memo, [key]: key }),
        {} as Record<keyof TValMap, keyof TValMap>
      );
    } finally {
      this._flushQueue();
    }
  };

  public findByKey = <TVal extends JsonMap = JsonMap>(key: string) =>
    (this.ConfigMap.get(key) as unknown as IUserConfig<TVal>) ?? null;

  /* ************************************************************************************ GETTERS */
  get ConfigMap() {
    return UserConfigMap;
  }
  get Manager() {
    return UserConfigManager;
  }
  get Registry() {
    return UserConfigRegistryMap;
  }
}

const instance = new _UserConfigs();
export const UserConfigs = instance;
