import { createSelector, createSlice } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RootState } from 'redux/store';
import { LegalEntityThunks } from 'redux/thunks/legalEntity.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { ILegalEntityRepository } from 'shared/api/interfaces/ILegalEntityRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { ICounterparty } from 'shared/models/Counterparty';
import { IFQCounterparty, selectCounterpartySpecMap } from '../counterparty/counterparty.slice';

type InitialStateType = {
  legalEntities: ICounterparty[];
};

export const initialState: InitialStateType = {
  legalEntities: []
};

const legalEntityRepository = container.get<ILegalEntityRepository>(
  SERVICE_KEYS.LEGAL_ENTITY_REPOSITORY
);
const legalEntityThunks = new LegalEntityThunks(legalEntityRepository);
export const getAllLegalEntities = legalEntityThunks.getAll();

const slice = buildGenericSlice<InitialStateType>('legalEntity', initialState);
export const legalEntitySlice = createSlice<InitialStateType, any>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllLegalEntities.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data && payload.data.length > 0) {
          state.legalEntities = payload.data as ICounterparty[];
        }
      }
    );
  }
});

export const legalEntityReducer = legalEntitySlice.reducer;
export const selectAllLegalEntities = (state: RootState) => state.legalEntity.legalEntities ?? [];

export const selectAllFQLegalEntities = createSelector(
  selectAllLegalEntities,
  selectCounterpartySpecMap,
  (entities, specMap) =>
    entities.map<IFQCounterparty>((entity) => ({
      ...entity,
      spec: specMap.get(entity.specId)
    }))
);
