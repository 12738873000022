import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IPayIndexRepository } from 'shared/api/interfaces/IPayIndexRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IPayIndex, IPayIndexSpec, IPayIndexSpecRequest } from 'shared/models/PayIndex';

// All the below are placeholders for api integration

@injectable()
export class PayIndexRepository implements IPayIndexRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/pay-indexes/');

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, { params: { ids: ids } });
  }

  async filter(startDate: Date, endDate: Date): Promise<AxiosResponse<IPayIndex[]>> {
    return await client.get(this.apiUrl + 'filter/' + { ...startDate, ...endDate });
  }

  async add(payIndex: IPayIndex): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...payIndex });
  }

  async update(payIndex: IPayIndex): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...payIndex });
  }

  async getAll(): Promise<AxiosResponse<IPayIndex[]>> {
    return await client.get(this.apiUrl);
  }

  async getSpecs(): Promise<AxiosResponse<IPayIndexSpec[]>> {
    return await client.get(this.apiUrl + 'spec-pay-indexes');
  }

  async getById(id: string): Promise<AxiosResponse<IPayIndex>> {
    return await client.get(this.apiUrl + id);
  }

  async forceUpdate(payIndex: IPayIndex): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'update-deal', { ...payIndex });
  }

  async createSpecRequest(specRequest: IPayIndexSpecRequest): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'spec-request', specRequest);
  }
}
