import { Permissions, ProductPermission } from './Product';

export enum PermissionLabel {
  readOnly = 'Read Only',
  write = 'Write'
}
export interface SelectedOption<S, T, U> {
  optionKey: S;
  optionLabel: string;
  nestedOptionKey: keyof T;
  data: U;
  formattedLabel: string;
}

export type SelectedOptions<S, T, U> = SelectedOption<S, T, U>[];

export const noAccess: Permissions = { read: false, write: false };

export const noSelectedProduct: ProductPermission = {
  key: 'noProduct',
  ...noAccess
};
