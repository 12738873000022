import { cn } from '@core/util';
import _ from 'lodash';
import { Divider, MenuItem, SubMenu } from 'rc-menu';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Fragment } from 'react';
import { Text } from '../Text';
import { IMenuEntry, IMenuItem, ISubMenu } from './Menu.types';

type CreateRenderMenuItemParams = {
  itemCount: number;
  isBordered: boolean;
};

type RenderEntryParams<TItem = IMenuEntry | ISubMenu> = {
  item: TItem;
  index: number;
  isLast: boolean;
  isBordered: boolean;
};

function renderTopMenuItem({ index, isBordered, item, isLast }: RenderEntryParams<IMenuEntry>) {
  const {
    children,
    className,
    isJustified,
    isVertical,
    hideDivider,
    render: El,
    key = index.toString(),
    description,
    isSelected,
    onChange,
    onClick = onChange,
    title,
    icon: Icon,
    activeIcon: ActiveIcon,
    ...rest
  } = item;
  const isOdd = !(index % 2);

  return (
    <Fragment {...{ key }}>
      <MenuItem
        {...{
          key,
          ...rest,
          ...(onClick
            ? {
                onClick: (e) => {
                  /* NOTE: type-casting here because the typescript for different signatures is a mess */
                  if (_.isBoolean(isSelected)) onClick(!isSelected as any, e);
                  else (onClick as (e: MenuInfo) => void)(e as any);
                }
              }
            : {})
        }}
        className={cn(className, {
          '--is-clickable': !!onClick && !rest.disabled,
          '--is-justified': isJustified,
          '--is-vertical': isVertical,
          '--is-selected': isSelected,
          '--last-item': isLast,
          '--even-item': !isOdd,
          '--odd-item': isOdd
        })}
      >
        <div className='ctr'>
          <div className='title-wpr'>
            <div className='icon-wpr'>
              {isSelected && !!ActiveIcon ? (
                <ActiveIcon className='item-icon' />
              ) : (
                Icon && <Icon className='item-icon' />
              )}
            </div>
            <div className='title-desc-ctr'>
              {title && (
                <Text m className='item-title'>
                  {title}
                </Text>
              )}
              {description && <Text className='item-description'>{description}</Text>}
            </div>
          </div>
          {El ? <El /> : children}
        </div>
      </MenuItem>
      {!isLast && hideDivider !== true && isBordered && <Divider className='item-divider' />}
    </Fragment>
  );
}

function renderSubMenu({ index, item, isBordered, isLast }: RenderEntryParams<ISubMenu>) {
  const { items = [], key = _.toString(index), hideDivider, title } = item;
  const isOdd = !(index % 2);

  const hasIcons = _.some(items, ({ isSubmenu, ...rest }) => {
    if (isSubmenu) return false;
    const { icon, activeIcon } = rest as IMenuEntry;
    return !!icon || !!activeIcon;
  });

  return (
    <Fragment {...{ key }}>
      <SubMenu
        className={cn({
          '--has-icons': hasIcons,
          '--odd-item': isOdd,
          '--even-item': !isOdd,
          '--last-item': isLast
        })}
        {...{ key }}
        {...(title
          ? {
              title: (
                <Text h5 className='item-title submenu'>
                  {title}
                </Text>
              )
            }
          : {})}
      >
        <Divider className='item-divider' />
        {_.map(items, (item, index) =>
          renderTopMenuItem({ item, index, isLast: index + 1 === items.length, isBordered })
        )}
      </SubMenu>
      {!isLast && hideDivider !== true && <Divider className='item-divider' />}
    </Fragment>
  );
}

function toSubMenuItems(item: IMenuItem, parentIndex: number) {
  return {
    ...item,
    items: _.map((item as ISubMenu).items, (child, ind) => ({
      key: `${parentIndex}-${ind}`,
      ...child
    }))
  } as ISubMenu;
}

export function createRenderMenuItem({ itemCount, isBordered }: CreateRenderMenuItemParams) {
  return function renderMenuItem(item: IMenuItem, index: number) {
    const isLast = index + 1 === itemCount;
    if (item.isSubmenu) {
      return renderSubMenu({ item: toSubMenuItems(item, index), index, isLast, isBordered });
    }
    return renderTopMenuItem({ item, index, isLast, isBordered });
  };
}
