export const tabClassNames = {
  appear: '--appear',
  appearActive: '--appear-active',
  appearDone: '--appear-done',
  enter: '--enter',
  enterActive: '--enter-active',
  enterDone: '--enter-done',
  exit: '--exit',
  exitActive: '--exit-active',
  exitDone: '--exit-done'
};
