interface IDealComment {
  itemId: string;
  user: {
    fullName: string;
    email: string;
  };
  date: string;
  text: string;
  company?: string;
  isShared?: boolean;
}

interface IDealCommentOuput {
  comment: string;
  company: string;
  itemId: string;
  timeCreated: string;
  user: {
    fullName: string;
    email: string;
  };
  isShared?: boolean;
}

interface IDealCommentInput {
  comment: string;
  itemId: string;
  itemType: CommentType;
}

export enum CommentType {
  Deal = 'deal'
}

export type { IDealComment, IDealCommentOuput, IDealCommentInput };
