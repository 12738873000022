import './DrawerHeader.style.scss';

import { CloseOutlineSvg } from '@core/images';
import { cn } from '@core/util';
import _isString from 'lodash/isString';
import { Btn } from '../../Btn';
import { Text } from '../../Text';
import { DrawerHeaderProps } from '../Drawer.types';
import { useDrawer } from '../useDrawer';

export function DrawerHeader(props: DrawerHeaderProps) {
  const { bordered = true, handleClose, title, className } = props;
  const { close } = useDrawer();

  return (
    <div
      className={cn('dwr-hdr-wpr', className, {
        '--is-bordered': bordered
      })}
    >
      <div className='title-wpr'>
        {title &&
          (_isString(title) ? <Text h2 className='dwr-title' t={title as string} /> : title)}
      </div>
      <Btn
        className='dwr-close'
        onClick={() => {
          handleClose?.() || close();
        }}
        icon={CloseOutlineSvg}
        color='text'
        size='small'
      />
    </div>
  );
}
