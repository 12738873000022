import { createSlice } from '@reduxjs/toolkit';
import { ITrade } from '../../../shared/models/Trade';
import mockTradeData from '../../../shared/utils/mockTradeData.json';
import { buildGenericSlice } from '../../helpers/slice.helper';
import { RootState } from '../../store';

type InitialStateType = {
  tradeList: ITrade[];
};

const tradeList: ITrade[] = mockTradeData;

const initialState: InitialStateType = {
  tradeList
};

const slice = buildGenericSlice<InitialStateType>('trade', initialState);

export const tradeSlice = createSlice({ ...slice });

export const { addTrade, updateTrade, removeTrade } = tradeSlice.actions;
export const getTradeList = (state: RootState) => state.trade.tradeList;

export const tradeReducer = tradeSlice.reducer;
