import styled from 'styled-components';

export const StyledTag = styled.div`
  color: ${(props) => props.theme.secondaryBtnText};

  &.gold-tag {
    background-color: ${(props) => props.theme.oracleTagBackground};
  }

  &.green-tag {
    background-color: ${(props) => props.theme.oxsettleTagBackground};
  }

  &.orange-tag {
    background-color: ${(props) => props.theme.oxpairTagBackground};
  }

  &.purple-tag {
    background-color: ${(props) => props.theme.oxnomTagBackground};
  }

  &.blue-tag {
    background-color: ${(props) => props.theme.oxadminTagBackground};
  }
`;
