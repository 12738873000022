import { ColorScheme, ThemeScheme } from './typings';

const light: ColorScheme = {
  primary: '#227262',
  secondary: '#227262',
  tertiary: '#227262',
  accent: '#3d486a',
  highlight: '#c0ae0b',

  success: '#529654',
  info: '#00afce',
  warning: '#e08c0f',
  danger: '#cd3c4b',

  canvas: ['#fff', '#f1f1f1'],
  component: ['#ebeef2', '#e0e2e9'],
  text: '#2d333f',
  heading: '#3c475b'
};

export const modernScheme: ThemeScheme = {
  light,
  /* placeholder */
  dark: {
    ...light,
    canvas: ['#26282f', '#353741']
  }
};
