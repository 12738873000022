import { Ajv } from '../../Ajv';
import { UserConfigIntegration } from '../UserConfigIntegration';
import { formatGridViewsConfig } from './formatGridViewsConfig';
import { GridViewsConfigTVal } from './GridStateConfig.types';
import { gridViewsSchema } from './gridViewsSchema';

const validate = Ajv.compile<GridViewsConfigTVal>(gridViewsSchema);

export const gridViewsIntegration = new UserConfigIntegration<GridViewsConfigTVal>({
  type: 'gridState',
  format: formatGridViewsConfig,
  validate
});
