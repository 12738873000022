export const gridViewsSchema = {
  properties: {
    activeGridView: { type: 'string', nullable: true },
    customGridViews: {
      elements: {
        properties: {
          gridState: { type: 'string' },
          key: { type: 'string' },
          label: { type: 'string' }
        },
        optionalProperties: {
          isCustom: { type: 'boolean' }
        }
      }
    }
  },
  optionalProperties: {
    lastKnownState: { type: 'string', nullable: true },
    defaultGridView: { type: 'string', nullable: true }
  }
} as const;
