import { ErrorInfo, PureComponent, ReactNode } from 'react';

interface ErrorObserverState {
  hasError: boolean;
}

export interface ErrorObserverProps {
  children: ReactNode;
  handleError: (error: Error, info: ErrorInfo) => void;
}

export class ErrorObserver extends PureComponent<ErrorObserverProps, ErrorObserverState> {
  constructor(props: ErrorObserverProps) {
    super(props);
    this.state = { hasError: false };
  }
  render(): JSX.Element {
    return <>{this.state.hasError ? null : this.props.children}</>;
  }
  componentDidCatch = this.props.handleError;
  static getDerivedStateFromError() {
    return { hasError: true };
  }
}
/* NOTE:
 * this component must be a class because there is no SFC equivalent for the
 * componentDidCatch method on Component/PureComponent.
 *
 * If/when this method is covered with an official React Hook, this
 * component should be removed from the internal withErrorBoundary hoc stack
 */
