import { GlobalConfig } from '@core/global-config';
import { createSlice } from '@reduxjs/toolkit';
import { User } from 'oidc-client-ts';

type InitialStateType = {
  config: GlobalConfig;
};
const initialState: InitialStateType = {
  config: {
    company: '',
    oauth: {
      authority: '',
      clientId: ''
    },
    backendUrl: '',
    webSocketUrl: '',
    agGridLicenseKey: ''
  }
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setUserConfig(state, action) {
      state.config = action.payload;
    }
  }
});

export const { setUserConfig } = configSlice.actions;
export const configReducer = configSlice.reducer;

export const userInCompany = (user: User | null, config: GlobalConfig | null) => {
  const userCompany = user?.profile['https://eleox.com/company'];
  const appCompany = config?.company;

  return typeof userCompany === 'string' && typeof appCompany === 'string'
    ? userCompany.toLowerCase() === appCompany.toLowerCase()
    : false;
};

export const authToken = (config: GlobalConfig) => {
  const storageString = sessionStorage.getItem(`actual-tokens-${config.oauth.clientId}`);
  return storageString ? JSON.parse(storageString) : null;
};

export const setTokens = (config: GlobalConfig, tokenData: any) => {
  sessionStorage.setItem(`actual-tokens-${config.oauth.clientId}`, JSON.stringify(tokenData));
};
