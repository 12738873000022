import { cn } from '@core/util';
import _ from 'lodash';
import { useCallback } from 'react';
import { Btn } from './Btn';
import { BtnProps, BtnsProps } from './Btn.types';
import { BtnsWpr } from './Btns.style';

export function Btns(props: BtnsProps) {
  const { buttons, className, defaultProps, gutter } = props;

  const renderBtn = useCallback(
    (btn: BtnProps, ind: number) => <Btn {...btn} key={ind.toString()} />,
    []
  );

  return (
    <BtnsWpr className={cn('core-btns', className)} {...{ gutter }}>
      {_.map(buttons, (btn: BtnProps, index: number) =>
        renderBtn(
          {
            ...defaultProps,
            ...btn
          },
          index
        )
      )}
    </BtnsWpr>
  );
}
