import { useValueMemo } from '@core/hooks';
import { OxRoute } from '@core/typings';
import routeStrings from 'Containers/Routing/routeStrings';
import _ from 'lodash';
import { lazy } from 'react';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';

export const authenticatedRoutes: OxRoute[] = [
  {
    product: 'productSelection',
    path: '',
    component: lazy(() => import('Sections/ProductSelection/ProductSelection'))
  },
  {
    product: 'productSelection',
    path: routeStrings.productSelection,
    component: lazy(() => import('Sections/ProductSelection/ProductSelection'))
  },
  {
    product: 'oxAdmin',
    path: routeStrings.oxAdmin.root,
    component: lazy(() => import('Sections/Admin/Admin'))
  },
  {
    product: 'oxOracle',
    path: routeStrings.oxOracle.root,
    component: lazy(() => import('Sections/Oracle/Oracle'))
  },
  {
    product: 'oxPair',
    path: routeStrings.oxPair.root,
    component: lazy(() => import('Sections/Pair/routes/Pair'))
  },
  {
    product: 'oxNom',
    path: routeStrings.oxNom.root,
    component: lazy(() => import('Sections/Nom/routes/Nom')),
    isHidden: ({ featureFlags }) => !featureFlags.nom
  },
  {
    product: 'oxSettle',
    path: routeStrings.oxSettle.root,
    component: lazy(() => import('Sections/Settle/Settle'))
  },
  {
    product: 'settings',
    path: routeStrings.settings.root,
    component: lazy(() => import('Sections/Settings/Settings'))
  },
  {
    product: 'support',
    path: routeStrings.support.root,
    component: lazy(() => import('Sections/Support'))
  }
];

export function useProductRoutes() {
  const { currentUser } = useAppSelector((state: RootState) => state.access);

  return useValueMemo<OxRoute[]>(() => {
    if (!currentUser) return [];
    return _.filter(authenticatedRoutes, ({ product }: OxRoute) => {
      if (!product) return false;
      if (product === 'productSelection') return true;
      if (product === 'support') return true;
      /* TODO: Remove these once they're included in the user data */
      if (product === 'settings') return true;
      if (
        product === 'oxSettle' &&
        (currentUser.role as unknown as string) === 'settlement-manager'
      ) {
        return true;
      }

      const readPermission = currentUser[product]?.read;
      return readPermission;
    });
  }, [currentUser]);
}
