import './SubmitBugReport.style.scss';

import { Btn, Text } from '@core/components';
import { useErrorBoundary } from '@core/error-boundary/useErrorBoundary';
import { BugSvg, CheckmarkCircleFilledSvg, CopySvg } from '@core/images';
import routeStrings from 'Containers/Routing/routeStrings';
import copy from 'copy-to-clipboard';
import { useCallback } from 'react';

export function SubmitBugReport(): JSX.Element {
  const { hasError, error, errorInfo, handleHasCopiedStack, hasCopiedStack } = useErrorBoundary();
  const copyBugData = useCallback(() => {
    copy(`Location: ${window.location.href}

Message: ${error?.message}

Stacktrace: ${errorInfo?.componentStack}`);
    handleHasCopiedStack();
  }, [hasError]);
  return (
    <div className='submit-bug-wpr'>
      <div className='submit-step-wpr'>
        <div className='step-description-wpr'>
          <Text b text='1.' className='step-num' />
          <Text
            m
            text='Gather information about the incident for the Eleox support team.'
            className='step-description'
          />
        </div>
        <Btn
          label='Copy to clipboard'
          onClick={copyBugData}
          icon={hasCopiedStack ? CheckmarkCircleFilledSvg : CopySvg}
          color={hasCopiedStack ? 'success' : 'danger'}
          block
        />
      </div>
      <div className='submit-step-wpr'>
        <div className='step-description-wpr'>
          <Text b text='2.' className='step-num' />
          <Text
            className='step-descirption'
            m
            text='Paste this information into a bug report and add any additional information about what you were doing when the incident occurred.'
          />
        </div>
        <a
          className='submit-bug'
          href={routeStrings.support.helpdesk}
          target='_blank'
          rel='noreferrer'
        >
          <Btn
            label='Submit bug report'
            color='indigo.700'
            icon={BugSvg}
            disabled={!hasCopiedStack}
            block
          />
        </a>
      </div>
    </div>
  );
}
