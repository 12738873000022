import _ from 'lodash';
import { Css } from './virtualCssOm.types';
/*
 * creates a flat selector for a nested style while determining  whether parent selectors need to
 * spread across multiple children [separated by ", " in the selector string].
 */
function getNestedSelector(selector: string, key: string) {
  return _.join(
    _.reduce<string, string[]>(
      _.split(key, ','),
      (memo, sel) => {
        _.each(_.split(selector, ','), (parent) => {
          memo.push(
            _.indexOf(sel, '&') > -1 ? sel.replace(/&/g, parent) : parent ? parent + ' ' + sel : sel
          );
        });
        return memo;
      },
      []
    ),
    ','
  );
}

/* creates a flat css body (tree) from nested object structure (css) */
function walkCssBody(tree: Css, css: Css, selector: string, prelude: string = '') {
  const declarations: Record<string, string> = {};
  const objs: Css = {};
  _.each(css, (val, key) => {
    if (!_.isPlainObject(val)) declarations[key] = val;
    else objs[key] = val;
  });

  if (!_.isEmpty(declarations)) {
    if (!tree[prelude]) tree[prelude] = {};
    tree[prelude][selector] = declarations;
  }
  _.each(objs, (val, key) => {
    if (_.startsWith(key, '@')) walkCssBody(tree, val, selector, key);
    else {
      walkCssBody(tree, val, getNestedSelector(selector, key), prelude);
    }
  });
}

export function flattenCss(css: Css, selector: string, prelude: string = '') {
  const tree: Css = {};
  walkCssBody(tree, css, selector, prelude);
  return tree;
}
