import localforage from 'localforage';
import { LocalForageOptions } from '../typings';

export function createStore(config: LocalForageOptions): LocalForage {
  return localforage.createInstance({
    /* don't use websql; it's deprecated */
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    name: `eleox-${location.hostname.split('.')[0]}`,
    ...config
  });
}
