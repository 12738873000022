export const formatNumber = (num: number | string): string => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumberLocale = (
  num: number | string,
  options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
): string => {
  if (!num) return '';

  if (typeof num === 'string') {
    num = parseFloat(num);
  }

  const formatter = new Intl.NumberFormat('en-US', options);

  return formatter.format(num);
};

export const isNumeric = (str: string) => {
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
};

export const convertMsToMinutes = (milliseconds: number) => {
  return milliseconds / 60000;
};

export const convertMinutesToMs = (milliseconds: number) => {
  return milliseconds * 60000;
};
