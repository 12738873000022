import { StyleGetter } from '@core/theme/typings';
const gtr = 6;
const iconSize = 18;
export const css: StyleGetter = (theme) => {
  const {
    colors: { danger, accent, canvas, component, highlight, primary, secondary, tertiary, text },
    fade,
    lighten,
    saturate,
    desaturate,
    darken
  } = theme;

  return {
    wpr: {
      height: '100%',
      width: '100%',
      marginTop: gtr * 2,
      /* COLORS ********************************************************************** COLORS */
      '--ag-alpine-active-color': primary.primary,
      '--ag-background-color': canvas.offset1,
      '--ag-column-hover-color': fade(accent.dark, 90),
      '--ag-control-panel-background-color': lighten(component.primary, 50),
      '--ag-data-color': text.primary,
      '--ag-disabled-foreground-color': text.disabled,
      '--ag-foreground-color': text.primary,
      '--ag-header-background-color': canvas.offset4,
      '--ag-invalid-color': danger.primary,
      '--ag-odd-row-background-color': darken(canvas.offset3, 2),
      '--ag-row-hover-color': fade(component.offset6, 25),
      '--ag-subheader-background-color': canvas.offset6,
      '--ag-secondary-foreground-color': text.offset2,
      '--ag-tooltip-background-color': component.offset2,

      /* BORDERS ******************************************************************** BORDERS */
      '--ag-borders': 'solid 0.5px',
      '--ag-border-radius': gtr,
      '--ag-row-border-color': fade(canvas.inverse, 96),
      '--ag-secondary-border-color': fade(canvas.inverse, 92) /* ctrl panel section borders */,
      '--ag-border-color': fade(canvas.inverse, 96) /* all other borders that aren't rows */,
      '--ag-borders-side-button': 'none' /* column/filters sidebar button borders */,

      /* PADDING/SPACING/SIZING ************************************** PADDING/SPACING/SIZING */
      '--ag-cell-horizontal-padding': gtr * 2,
      '--ag-cell-widget-spacing': gtr * 1,
      '--ag-column-select-indent-size': iconSize,
      '--ag-grid-size': gtr /* gutter size (used a lot!) */,
      '--ag-header-height': gtr * 8,
      '--ag-list-item-height': gtr * 4.5,
      '--ag-row-height': gtr * 7,
      '--ag-set-filter-indent-size': iconSize,
      '--ag-widget-container-horizontal-padding': gtr * 1.5,
      '--ag-widget-container-vertical-padding': gtr * 2,
      '--ag-widget-vertical-spacing': gtr * 1.5,
      '--ag-row-group-indent-size': gtr * 3,

      /* INPUTS ********************************************************************** INPUTS */
      '--ag-input-border-color-invalid': 'var(--ag-invalid-color)',
      '--ag-input-border-color': 'var(--ag-border-color)',
      '--ag-input-disabled-background-color': canvas.offset4,
      '--ag-input-disabled-border-color': fade(text.hint),
      '--ag-input-focus-border-color': primary.primary,
      '--ag-input-focus-box-shadow': '0 0 4px 0px var(--ag-input-focus-border-color)',

      /* ~~~~ CHECKBOX ******************************************************** CHECKBOX ~~~~ */
      '--ag-checkbox-background-color': 'var(--ag-background-color)',
      '--ag-checkbox-checked-color': 'var(--ag-alpine-active-color)',
      '--ag-checkbox-unchecked-color': accent.bg,
      '--ag-checkbox-indeterminate-color': accent.lighter,
      /* ~~~~ TOGGLE BUTTON *********************************************  TOGGLE BUTTON ~~~~ */
      '--ag-toggle-button-height': iconSize,
      '--ag-toggle-button-width': iconSize + 10,
      /* ~~~~ ADVANCED FILTER ****************************************** ADVANCED FILTER ~~~~ */
      '--ag-advanced-filter-builder-indent-size': iconSize + gtr * 2,
      '--ag-advanced-filter-column-pill-color': primary.light,
      '--ag-advanced-filter-join-pill-color': highlight.saturated,
      '--ag-advanced-filter-option-pill-color': secondary.light,
      '--ag-advanced-filter-value-pill-color': tertiary.light,

      /* PANELS/UI ELMENTS ************************************************ PANELS/UI ELMENTS */
      '--ag-card-shadow': `0 1px 4px 1px ${fade(canvas.inverse, 80)}`,
      '--ag-chip-background-color': accent.bg,
      '--ag-popup-shadow': 'var(--ag-card-shadow)',
      '--ag-selected-tab-underline-color': 'var(--ag-alpine-active-color)',
      '--ag-selected-tab-underline-transition-speed': '200ms',
      '--ag-selected-tab-underline-width': 3,
      '--ag-side-bar-panel-width': 280,
      '--ag-side-button-selected-background-color': primary.bg,
      '--ag-tab-min-width': 240,

      /* TYPOGRAPHY ************************************************************** TYPOGRAPHY */
      '--ag-font-family':
        'GibsonRegular, -apple-system, BlinkMacSystemFont, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
      '--ag-font-size': 13,

      /* RESIZE HANDLE ******************************************************** RESIZE HANDLE */
      '--ag-header-column-resize-handle-color': fade(canvas.inverse, 92),
      '--ag-header-column-resize-handle-display': 'block',
      '--ag-header-column-resize-handle-height': '55%',
      '--ag-header-column-resize-handle-width': 3,

      /* ICONS ************************************************************************ ICONS */
      '--ag-icon-font-family': 'agGridQuartz',
      '--ag-icon-size': iconSize,

      /* SELECTION **************************************************************** SELECTION */
      '--ag-range-selection-background-color-2': fade(accent.dark, 67),
      '--ag-range-selection-background-color-3': fade(accent.dark, 57),
      '--ag-range-selection-background-color-4': fade(accent.dark, 41),
      '--ag-range-selection-background-color': fade(accent.dark, 80),
      '--ag-range-selection-border-color': 'var(--ag-alpine-active-color)',
      '--ag-selected-row-background-color': fade(primary.bg, 92),

      '.ag-checkbox-input-wrapper': {
        '&:focus-within, &:active': { boxShadow: 'none' }
      },

      '.ag-sort-order': {
        lineHeight: 1.5
      },

      '.ag-row-group-expanded': {
        background: `linear-gradient(
          ${fade(component.offset1, 33)} 33%,
          ${fade(component.offset6, 29)} 60%,
          ${fade(component.offset6, 25)}
        )`,
        '.ag-cell': {
          borderTop: `0.5px solid ${accent.veryLight}`,
          borderBottom: `1px inset ${accent.veryLight}`,
          fontFamily: 'GibsonMedium'
        }
      },

      '.ag-root-wrapper': {
        border: 'none',
        borderRadius: gtr
      },

      '.ag-pinned-right-header .ag-header-cell-resize': {
        left: 0
      },

      '.ag-header-row-column-filter': {
        backgroundColor: canvas.offset6
      },

      '.ag-row': {
        borderWidth: 0,

        '&.ag-row-hover.ag-row-selected::before': {
          backgroundImage: 'none'
        }
      },

      '.ag-header-group-cell': {
        '&.core-primary-col': { backgroundColor: desaturate(darken(primary.primary, 9), 20) },
        '&.core-secondary-col': { backgroundColor: desaturate(darken(secondary.primary, 9), 20) },
        '&.core-tertiary-col': { backgroundColor: desaturate(darken(tertiary.primary, 9), 20) },
        '&.core-accent-col': { backgroundColor: desaturate(darken(accent.primary, 9), 20) },
        '&.core-highlight-col': { backgroundColor: desaturate(darken(highlight.primary, 9), 20) },

        '&.core-primary-col, &.core-secondary-col, &.core-tertiary-col, &.core-accent-col, &.core-accent-col, &.core-highlight-col':
          {
            color: text.inverse,
            '.ag-icon': { color: text.inverse }
          }
      },
      '.ag-header-cell': {
        '&.core-primary-col': { backgroundColor: desaturate(primary.veryLight, 15) },
        '&.core-secondary-col': { backgroundColor: desaturate(secondary.veryLight, 15) },
        '&.core-tertiary-col': { backgroundColor: desaturate(tertiary.veryLight, 15) },
        '&.core-accent-col': { backgroundColor: desaturate(accent.veryLight, 15) },
        '&.core-highlight-col': { backgroundColor: desaturate(highlight.veryLight, 15) }
      },

      '.ag-cell': {
        '&.core-primary-col': { backgroundColor: saturate(fade(primary.bg, 85), 10) },
        '&.core-secondary-col': { backgroundColor: saturate(fade(secondary.bg, 85), 10) },
        '&.core-tertiary-col': { backgroundColor: saturate(fade(tertiary.bg, 85), 10) },
        '&.core-accent-col': { backgroundColor: saturate(fade(accent.bg, 85), 10) },
        '&.core-highlight-col': { backgroundColor: saturate(fade(highlight.bg, 85), 10) }
      },

      '.ag-row-hover': {
        '.core-primary-col': { backgroundColor: lighten(primary.light, 55) },
        '.core-secondary-col': { backgroundColor: lighten(secondary.light, 55) },
        '.core-tertiary-col': { backgroundColor: lighten(tertiary.light, 55) },
        '.core-accent-col': { backgroundColor: lighten(accent.light, 55) },
        '.core-highlight-col': { backgroundColor: lighten(highlight.light, 55) },

        '&.ag-row-group-expanded': {
          '.core-primary-col': { backgroundColor: lighten(primary.light, 40) },
          '.core-secondary-col': { backgroundColor: lighten(secondary.light, 40) },
          '.core-tertiary-col': { backgroundColor: lighten(tertiary.light, 40) },
          '.core-accent-col': { backgroundColor: lighten(accent.light, 40) },
          '.core-highlight-col': { backgroundColor: lighten(highlight.light, 40) }
        }
      }
    }
  };
};
