export enum ResourceType {
  api = 'api',
  page = 'page',
  component = 'component'
}

export type IOxResource = {
  id: string;
  name: string;
  type: ResourceType;
  enabled: boolean;
  productId: number;
};
