import { createContext } from 'react';

const dHandle = () => {
  console.warn('handleReset can only be used inside a ResetBoundary');
};

export type ResetFn = (delay?: number) => void;
export interface ResetContextValue {
  handleReset: ResetFn;
}

export const ResetContext = createContext<ResetContextValue>({ handleReset: dHandle });
