import './DevTools.style.scss';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// const noop = () => {
//   /* do nothing */
// };

export function DevTools(): JSX.Element {
  return <ReactQueryDevtools buttonPosition='bottom-left' />;
}
