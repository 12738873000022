import { CanvasColorRange, CanvasColorScale, CssColor } from '@core/theme/typings';
import chroma from 'chroma-js';
import _ from 'lodash';
import { getTextColors } from './getTextColors';

export const getCanvasColors = (range: CanvasColorRange, isDark = false): CanvasColorScale => {
  const base = getTextColors(range, isDark);

  return {
    ..._.omit(base, ['faded', 'hint']),
    disabled: chroma(base.primary)
      // .set('hsl.s', 0)
      .set('hsl.l', isDark ? 0.4 : 0.85)
      .hex() as CssColor
  };
};
