import { useContext } from 'react';
import { GridFieldsContext } from '../Grid.contexts';
import { GridFieldsCV } from '../typings';

export function useGridFields<
  TData extends object = any,
  FieldName extends string = any
>(): GridFieldsCV<TData, FieldName> {
  return useContext(GridFieldsContext);
}
