import { client } from '@core/rest-client';
import { IOxUser } from '@core/typings';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { ProductKeys } from 'redux/slices/tableFilter/tableFilters.slice';
import { IUserRepository } from 'shared/api/interfaces/IUserRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';

@injectable()
export class UserRepository implements IUserRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('access/v1/users');

  getAll = async (): Promise<AxiosResponse<IOxUser[]>> => {
    const response = await client.get(this.apiUrl);
    return response.data;
  };

  getAllAuth0 = async (): Promise<AxiosResponse<any[]>> => {
    const response = await client.get(`${this.apiUrl}/auth0users`);
    return response.data;
  };

  getAllForRole = async (roleId: string): Promise<AxiosResponse<IOxUser[]>> => {
    const response = await client.get(`${this.apiUrl}`, { params: { roleId } });
    return response.data;
  };

  getOne = async (id: string): Promise<AxiosResponse<IOxUser[]>> => {
    return await client.get(`${this.apiUrl}/${id}`);
  };

  getCurrentUser = async (): Promise<AxiosResponse<IOxUser[]>> => {
    return await client.get(`${this.apiUrl}/currentUser`);
  };

  add = async (user: IOxUser): Promise<AxiosResponse> => {
    return await client.post(this.apiUrl, user);
  };

  update = async (user: IOxUser): Promise<AxiosResponse> => {
    return await client.put(this.apiUrl, user);
  };

  delete = async (id: string): Promise<any> => {
    return await client.delete(`${this.apiUrl}`, { data: { email: id } });
  };

  getChangeLogs = async (start: string, end: string): Promise<AxiosResponse> => {
    return await client.get(`${this.apiUrl}/change-logs`, { params: { start, end } });
  };

  logUserActivity = async (
    product: Omit<ProductKeys, ProductKeys.AllProducts>
  ): Promise<AxiosResponse> => {
    return await client.post(`${this.apiUrl}/currentUser/activity`, { product });
  };
}
