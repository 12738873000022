import { themes } from '@core/theme';
import { JSXElementConstructor, ReactElement } from 'react';
import { Popover } from 'react-tiny-popover';
import { ThemeProvider } from 'styled-components';
import { StyledDatePicker } from './OxDatePickerWrapper.styles';

interface Props {
  popoverContent: JSX.Element;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  showCalendar: boolean;
  setShowCalendar: (show: boolean) => void;
  bordersEnabled?: boolean;
}

export const OxDatePickerWrapper = ({
  popoverContent,
  children,
  showCalendar,
  setShowCalendar,
  bordersEnabled = false
}: Props) => {
  return (
    <ThemeProvider theme={themes.lightTheme}>
      <Popover
        containerClassName='z-50 date-picker-popover'
        padding={10}
        onClickOutside={() => setShowCalendar(false)}
        isOpen={showCalendar}
        positions={['bottom', 'top']}
        content={popoverContent}
      >
        <StyledDatePicker bordersEnabled={bordersEnabled}>{children}</StyledDatePicker>
      </Popover>
    </ThemeProvider>
  );
};
