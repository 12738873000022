import styled from 'styled-components';

export const StyledInfoDrawer = styled.div`
  width: 50%;
  padding-bottom: 64px;
  color: ${(props) => props.theme.fontColor};
  background-color: ${(props) => props.theme.backgroundColor};
  z-index: 2;

  &.user {
    background-color: ${(props) => props.theme.drawerBackground};
  }

  &.drawer-background {
    background-color: ${(props) => props.theme.drawerBackground};
  }

  .arrow-toggle {
    fill: ${(props) => props.theme.fontColor} !important;
    stroke: ${(props) => props.theme.fontColor} !important;

    &:hover {
      fill: ${(props) => props.theme.arrowToggleHoverColor} !important;
      stroke: ${(props) => props.theme.arrowToggleHoverColor} !important;
    }
  }
`;
