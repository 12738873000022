import dayjs from 'dayjs';

type FlexibleDate = null | undefined | string | Date;

export function dateComparator(a: FlexibleDate, b: FlexibleDate) {
  if (!a && !b) return 0;
  else if (!a) return 1;
  return new Date(a as string) > new Date(b as string) ? 1 : -1;
}
const parseFormat = [
  'MM/YYYY',
  'YYYY/MM',
  'MM-DD-YYYY',
  'YYYY MMM',
  'YYYY MMMM',
  'MMM YYYY',
  'MMMM YYYY'
];

export function monthComparator(a: FlexibleDate, b: FlexibleDate) {
  if (!a && !b) return 0;
  else if (!a) return 1;

  const dayA = dayjs(a, parseFormat);
  const dayB = dayjs(b, parseFormat);
  if (dayA.isSame(dayB, 'month')) return 0;
  if (dayA.isBefore(dayB, 'month')) return -1;
  return 1;
}
