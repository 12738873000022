import { ComposedSfc } from '@core/typings';
import { forwardRef } from 'react';
import { ResetBoundary, ResetBoundaryProps } from './ResetBoundary';

export function withResetBoundary(conf: Omit<ResetBoundaryProps, 'children'>) {
  return function _withReset(Composed: ComposedSfc) {
    return forwardRef(function WithReset(props: any, ref: any) {
      return (
        <ResetBoundary {...conf}>
          <Composed {...{ ...props }} ref={ref} />
        </ResetBoundary>
      );
    });
  };
}

const hoc = withResetBoundary({} as ResetBoundaryProps);

export function withReset(Composed: ComposedSfc): ComposedSfc {
  return hoc(Composed);
}
