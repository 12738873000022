import { createSelector, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { PipelineThunks } from 'redux/thunks/pipeline.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { IPipelineRepository } from 'shared/api/interfaces/IPipelineRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { DeleteResponse } from 'shared/models/DeleteResponse';
import { IPipeline, IPipelineSpec } from 'shared/models/Pipeline';
import { buildGenericSlice } from '../../helpers/slice.helper';
import { RootState } from '../../store';

export type InitialPipelinesStateType = {
  count?: number;
  changeId: number;
  pipelines: IPipeline[];
  pipelineSpecs: IPipelineSpec[];
};

const count = undefined;
const changeId = 0;
const pipelines: IPipeline[] = [];
const pipelineSpecs: IPipelineSpec[] = [];

export const initialState: InitialPipelinesStateType = {
  count,
  changeId,
  pipelines,
  pipelineSpecs
};

const pipelineRepository = container.get<IPipelineRepository>(SERVICE_KEYS.PIPELINE_REPOSITORY);
const pipelineThunks = new PipelineThunks(pipelineRepository);
export const getAllPipelines = pipelineThunks.getAll();
export const addPipeline = pipelineThunks.add();
export const deleteAllPipeline = pipelineThunks.deleteAll();
export const getAllPipelineSpecs = pipelineThunks.getSpecs();
export const updatePipeline = pipelineThunks.update();
export const forceUpdatePipeline = pipelineThunks.forceUpdate();
export const createSpecRequest = pipelineThunks.createSpecRequest();

const slice = buildGenericSlice<InitialPipelinesStateType>('pipeline', initialState);
export const pipelineSlice = createSlice<
  InitialPipelinesStateType,
  SliceCaseReducers<InitialPipelinesStateType>,
  string
>({
  ...slice,
  initialState,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllPipelines.fulfilled,
      (state: InitialPipelinesStateType, { payload }: GenericPayload) => {
        if (payload && payload.data?.length) {
          const { data } = payload;
          state.pipelines = data;
          state.changeId++;
          state.count = data.length;
        }
      }
    );
    builder.addCase(
      addPipeline.fulfilled,
      (state: InitialPipelinesStateType, { payload }: GenericPayload) => {
        if (payload) {
          if (payload) {
            const pipelines = [...state.pipelines, payload.data];
            state.pipelines = pipelines;
            state.changeId++;
            state.count = pipelines.length;
          }
        }
      }
    );
    builder.addCase(
      getAllPipelineSpecs.fulfilled,
      (state: InitialPipelinesStateType, { payload }: GenericPayload) => {
        if (payload && payload.data && payload.data.length > 0) {
          state.pipelineSpecs = payload.data as IPipelineSpec[];
          state.changeId++;
        }
      }
    );
    builder.addCase(
      deleteAllPipeline.fulfilled,
      (state: InitialPipelinesStateType, { payload }: GenericPayload) => {
        if (payload.status === 200) {
          const { data } = payload;
          state.changeId++;
          state.pipelines = state.pipelines.filter(
            (pipeline: IPipeline) =>
              !data.find((response: DeleteResponse) => response.id === pipeline.etrmId)?.isSuccess
          );
        }
      }
    );
  }
});

export const pipelineReducer = pipelineSlice.reducer;

export const selectPipelines = (state: RootState) => state.pipeline.pipelines;
export const selectPipelineSpecs = (state: RootState) => state.pipeline.pipelineSpecs;

export const selectPipelineMap = createSelectorMap(selectPipelines, 'etrmId');
export const selectPipelineSpecMap = createSelectorMap(selectPipelineSpecs, 'specId');
export const selectPipelineSpecDunsMap = createSelectorMap(selectPipelineSpecs, 'duns');

export interface IFQPipeline extends IPipeline {
  spec?: IPipelineSpec;
}

export const selectFQPipelineRefs = createSelector(
  selectPipelines,
  selectPipelineSpecMap,
  (pipelines, specMap) => {
    return pipelines.map<IFQPipeline>((pipeline) => {
      return {
        ...pipeline,
        spec: specMap.get(pipeline.specId)
      };
    });
  }
);
