import { client } from '@core/rest-client';
import { injectable } from 'inversify';
import { IFeatureFlagRepository } from 'shared/api/interfaces/IFeatureFlagRepository';
import { FeatureFlags } from 'shared/models/FeatureFlags';
import { BaseRepository } from '../BaseRepository';

@injectable()
export class FeatureFlagRepository implements IFeatureFlagRepository {
  private baseRepo = new BaseRepository();
  apiUrl: string = this.baseRepo.getApiUrl('v1/feature-flags');
  fetch = async (): Promise<FeatureFlags> => {
    try {
      const resp = await client.get(this.apiUrl);
      return resp.data as FeatureFlags;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}
