const oracle = {
  oracle: {
    tariffRates: {
      chargeType: 'Charge Type',
      deliveryLocation: 'Delivery Location',
      flowDate: 'Flow Date',
      locations: 'Locations',
      pipelineSpec: 'Pipeline Spec',
      title: 'Tariff Rates Matrix',
      zones: 'Zones'
    },
    referenceData: {
      affectedDealsModalBody:
        'You are about to make a change that will impact {{deals}} deal(s) and {{invoices}} invoice(s). Are you sure you want to make this change?',
      affectedDealsModalHeader: 'Editing An Active Record',
      dealInfoModalHeader: 'Pairing Fields by Trade Type',
      uploadTitle: 'Reference Data',
      uploadBody:
        'Eleox will maintain spec aka master reference data to address inconsistencies of data among various ETRM systems. Before this reference data is sent from the ETRM to Eleox, the ETRM system should map their data records to an Eleox spec reference data record. This will ensure that each company when interacting with Eleox products will be able to view the reference data in their own ETRM language. In addition, each company would be able to align their reference data against the mapping they did to Eleox spec reference data to have a single source of truth. Given the underlying distributed technology and smart contracting language, the company ETRM specific reference data will not be visible to Eleox or other companies on the platform due to its sensitivity. Eleox spec reference data will be visible to the companies.',
      dataSetTitle: 'Reference Data Stored',
      dataSetBody:
        'Eleox will maintain spec aka master reference data to address inconsistencies of data among various ETRM systems. Before this reference data is sent from the ETRM to Eleox, the ETRM system should map their data records to an Eleox spec reference data record. This will ensure that each company when interacting with Eleox products will be able to view the reference data in their own ETRM language. In addition, each company would be able to align their reference data against the mapping they did to Eleox spec reference data to have a single source of truth. Given the underlying distributed technology and smart contracting language, the company ETRM specific reference data will not be visible to Eleox or other companies on the platform due to its sensitivity. Eleox spec reference data will be visible to the companies.',
      addBrokerTitle: 'Add New Broker Data Row',
      addChargeTypeTitle: 'Add New Charge Type Data Row',
      addCounterpartyTitle: 'Add New Counterparty Data Row',
      addPipelineTitle: 'Add New Pipeline Data Row',
      addLocationGroupTitle: 'Add New Location Group Row',
      addLocationTitle: 'Add New Location Data Row',
      addRateDefinitionTitle: 'Add New Rate Definition Row',
      addZoneTitle: 'Add New Zone Data Row',
      addInterconnectTitle: 'Add New Interconnect Data Row',
      addRateScheduleTitle: 'Add New Rate Schedule Data Row',
      addPayIndexTitle: 'Add New Pay Index Data Row',
      addTransactionTypeTitle: 'Add New Transaction Type Row',
      addTransportationAgreementTitle: 'Add New Transportation Agreement Row',
      addPipelineTransportationAgreementTitle: 'Add New Pipeline Transportation Agreement Row',
      requestBrokerTitle: 'Request Spec Broker',
      requestCounterpartyTitle: 'Request Spec Counterparty',
      requestPipelineTitle: 'Request Spec Pipeline',
      requestLocationTitle: 'Request Spec Location',
      requestZoneTitle: 'Request Spec Zone',
      requestPayIndexTitle: 'Request Spec Pay Index',
      modalCancel: 'Cancel',
      modalSubmit: 'Submit',
      duplicateField: 'Pipeline IDs cannot be the same',
      missingField: '{{field}} is required.',
      recommended: '{{field}} (Recommended)',
      addedRecordSuccessfully: 'Successfully created a {{field}}.',
      addRecordFailure: 'Failed to create a {{field}}.',
      updateRecordFailure: 'Failed to update the {{field}}. Please try again.',
      duplicateRecord: 'Error, this record already exists.',
      invalidEntry: 'The {{field}} entered does not match any known records.',
      invalidUUID: '{{field}} must be a UUID.',
      invalidNumber: '{{field}} must be a number.',
      snackbar: 'Drop files onto this page to upload them for reference data.',
      downloadTemplatesDropDown: 'Download Templates for Upload',
      fileUpload: 'File Upload',
      locationCategoryOptions: {
        location: 'LOCATION',
        all: 'ALL',
        zone: 'ZONE',
        locationGroup: 'LOCATION_GROUP'
      },
      rateTypeOptions: {
        fixed: 'Fixed',
        negotiated: 'Negotiated'
      },
      chargeIndicatorOptions: {
        receipt: 'Receipt',
        delivery: 'Delivery'
      }
    },
    specReferenceData: {
      title: 'Spec Reference Data',
      body: ''
    },
    specRequest: {
      action: 'Action',
      actionOptions: {
        create: 'Create',
        update: 'Update',
        delete: 'Delete'
      },
      comment: 'Comment',
      tabRoute: {
        singleRequest: 'Single Request',
        fileUpload: 'File Upload'
      }
    },
    staticData: {
      title: 'Spec Static Data',
      body: 'Eleox will maintain predefined data values as enums to address inconsistencies of certain data field values among various ETRM systems. Before this data field value is sent from the ETRM to Eleox, it will have to conform to the Eleox enums standard for Eleox to accept the data field value. Enum data is visible to Eleox and other companies on the platform due its insensitivity.',
      tabRoute: {
        averagingMethods: 'Averaging Methods',
        chargeIndicators: 'Charge Indicators',
        currencies: 'Currencies',
        deliveryTypes: 'Delivery Types',
        flowDirections: 'Flow Directions',
        locationCategories: 'Location Categories',
        locationCountries: 'Location Countries',
        locationStates: 'Location States',
        locationTypes: 'Location Types',
        masterContractTypes: 'Master Contract Types',
        periodicals: 'Periodicals',
        pricingCalendars: 'Pricing Calendars',
        rateTypes: 'Rate Types',
        serviceCodes: 'Service Codes',
        tradeTypes: 'Trade Types',
        unitOfMeasures: 'Unit of Measures'
      }
    },
    bannerTitle: 'Create a decentralized Master Data System',
    bannerBody: `Get started by clicking on Upload to add a file or drag and drop existing
    files into Eleox`,
    emptyTitle: 'A little empty here for {{templateName}}',
    emptyBody: `Get started by clicking on Upload to add a file or you can drag and drop an
      existing file to map your internal data to the Eleox master data set.`,
    refEmptyTitle: 'A little empty here for {{referenceName}}',
    emptyUpload: 'Upload data',
    tabRoute: {
      counterparty: 'Counterparties',
      interconnect: 'Interconnects',
      location: 'Locations',
      pipeline: 'Pipelines',
      zone: 'Zones',
      rateDefinition: 'Rate Definitions',
      rateSchedule: 'Rate Schedules',
      locationGroup: 'Location Groups',
      payIndex: 'Indexes',
      transactionType: 'Transaction Types',
      broker: 'Brokers',
      chargeType: 'Charge Types',
      transportationAgreement: 'ETRM Transportation Agreements',
      pipelineTransportationAgreement: 'Pipeline Transportation Agreements'
    },
    confirmation: {
      header: 'Are you sure you want to delete?',
      body: 'These rows will be permanently deleted',
      confirm: 'Yes',
      cancel: 'Cancel'
    },
    table: {
      averagingMethod: {
        id: 'Averaging Method Id',
        value: 'Description'
      },
      chargeIndicator: {
        id: 'Charge Indicator Id',
        value: 'Description'
      },
      chargeType: {
        id: 'Charge Type Id',
        value: 'Description',
        specName: 'Spec Name',
        pipelineName: 'ETRM Pipeline Name',
        name: 'Name',
        specChargeTypeId: 'Spec Id',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By',
        specId: 'Spec Id',
        description: 'Description'
      },
      chargeTypeSpec: {
        description: 'Description',
        name: 'Name',
        specId: 'Spec Id'
      },
      currency: {
        id: 'Currency Id',
        value: 'Description'
      },
      deliveryType: {
        id: 'Delivery Type Id',
        value: 'Description'
      },
      flowDirection: {
        id: 'Flow Direction Id',
        value: 'Description'
      },
      locationCategory: {
        id: 'Location Category Id',
        value: 'Description'
      },
      locationCountry: {
        id: 'Location State Id',
        value: 'Description'
      },
      locationState: {
        id: 'Location State Id',
        value: 'Description'
      },
      locationType: {
        id: 'Location Type Id',
        value: 'Description'
      },
      masterContractType: {
        id: 'Master Contract Type Id',
        value: 'Description'
      },
      periodical: {
        id: 'Periodical Id',
        value: 'Description'
      },
      pricingCalendar: {
        id: 'Pricing Calendar Id',
        value: 'Description'
      },
      rateType: {
        id: 'Rate Type Id',
        value: 'Description'
      },
      serviceCode: {
        id: 'Service Code Id',
        value: 'Description'
      },
      tradeType: {
        id: 'Trade Type Id',
        value: 'Description'
      },
      pipelineTransportationAgreement: {
        name: 'Name',
        specPipelineName: 'Spec Pipeline Name',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By'
      },
      transportationAgreement: {
        value: 'Description',
        name: 'Name',
        pipelineTAName: 'Pipeline TA Name',
        pipelineTAId: 'Pipeline TA Id',
        pipelineName: 'ETRM Pipeline Name',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By'
      },
      unitOfMeasure: {
        id: 'Unit of Measure Id',
        value: 'Description'
      },
      dataTemplate: {
        filename: 'Name',
        status: 'Status',
        rows: 'Rows',
        successes: 'Successes',
        failures: 'Failures',
        date: 'Date',
        uploadedBy: 'Uploaded By'
      },
      broker: {
        row: 'Row',
        etrmId: 'ETRM ID',
        spec: {
          name: 'Spec Name',
          specId: 'Spec Id'
        },
        specId: 'Spec Id',
        name: 'Name',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By'
      },
      brokerSpec: {
        specId: 'Spec Id',
        name: 'Name'
      },
      counterparty: {
        row: 'Row',
        etrmId: 'ETRM ID',
        spec: {
          name: 'Spec Name',
          specId: 'Spec Id'
        },
        specId: 'Spec Id',
        duns: 'DUNS',
        shortName: 'Short Name',
        name: 'Name',
        isAffiliate: 'Affiliate',
        affiliateLabel: 'Is this entity affiliated with your company?',
        yes: 'Yes',
        no: 'No',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By'
      },
      counterpartySpec: {
        specId: 'Spec Id',
        duns: 'DUNS',
        shortName: 'Short Name',
        name: 'Name',
        isAffiliate: 'Affiliate'
      },
      pipeline: {
        id: 'ETRM ID',
        row: 'Row',
        name: 'Name',
        spec: {
          name: 'Spec Name',
          specId: 'Spec Id'
        },
        specId: 'Spec Id',
        shortName: 'Short Name',
        etrmId: 'ETRM ID',
        pipelineModelType: 'Type',
        duns: 'DUNS',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By'
      },
      pipelineSpec: {
        name: 'Name',
        specId: 'Spec Id',
        shortName: 'Short Name',
        duns: 'DUNS'
      },
      zone: {
        row: 'Row',
        name: 'Name',
        id: 'ID',
        etrmId: 'ETRM ID',
        spec: {
          name: 'Spec Name',
          specId: 'Spec Id'
        },
        specId: 'Spec Id',
        pipeline: {
          name: 'ETRM Pipeline Name'
        },
        specPipelineName: 'Spec Pipeline Name',
        etrmPipelineId: 'ETRM Pipeline Id',
        specPipelineId: 'Spec Pipeline Id',
        zoneType: 'Type',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By',
        missingEtrmPipelineId: 'Please upload Pipeline Data in order to select an ETRM Pipeline ID.'
      },
      zoneSpec: {
        name: 'Name',
        specId: 'Spec Id',
        specPipelineId: 'Spec Pipeline Id',
        specPipeline: {
          name: 'Spec Pipeline Name'
        },
        zoneType: 'Type'
      },
      location: {
        row: 'Row',
        id: 'ID',
        etrmId: 'ETRM ID',
        etrmLocationGroupId: 'ETRM Location Group Name',
        etrmPipelineId: 'ETRM Pipeline Name',
        etrmInterconnectId: 'ETRM Interconnect Name',
        etrmZoneId: 'ETRM Zone Name',
        spec: {
          name: 'Spec Name',
          specId: 'Spec Id'
        },
        specId: 'Spec Id',
        shortName: 'Short Name',
        locationGroup: {
          name: 'ETRM Location Group Name'
        },
        name: 'Name',
        interconnect: {
          name: 'ETRM Interconnect Name'
        },
        zone: {
          name: 'ETRM Zone Name'
        },
        locationType: 'Location Type Name',
        locationTypeData: {
          value: 'Location Type Name'
        },
        pipeline: {
          name: 'ETRM Pipeline Name'
        },
        locationCountry: 'Location Country',
        locationCountryData: {
          value: 'Location Country'
        },
        locationCounty: 'Location County',
        locationState: 'Location State',
        locationStateData: {
          value: 'Location State'
        },
        milePost: 'Mile Post',
        sequence: 'Sequence',
        publicMeterId: 'Public Meter ID',
        zoneId: 'Zone ID',
        flowDirection: 'Flow Direction',
        flowDirectionData: {
          value: 'Flow Direction'
        },
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By',
        missingEtrmPipelineId:
          'Please upload Pipeline Data in order to select an ETRM Pipeline ID.',
        missingEtrmZoneId: 'Please upload Zone Data in order to select an ETRM Zone ID.',
        missingEtrmInterconnectId:
          'Please upload Interconnect Data in order to select an ETRM Interconnect ID.',
        missingLocationGroupId:
          'Please upload Location Group Data in order to select a Location Group ID.',
        specPipelineId: 'Spec Pipeline Id',
        specZoneName: 'Spec Zone Name',
        specInterconnectId: 'Spec Interconnect Id',
        specLocationGroupId: 'Spec Location Group Id',
        specPhysicalLocationId: 'Spec Physical Location Id',
        specPhysicalLocationName: 'Spec Physical Location Name'
      },
      locationSpec: {
        specId: 'Spec Id',
        shortName: 'Short Name',
        name: 'Name',
        locationSourceType: 'Source Type',
        locationType: 'Location Type',
        locationCounty: 'Location County',
        locationState: 'Location State',
        locationCountry: 'Location Country',
        milePost: 'Mile Post',
        sequence: 'Sequence',
        publicMeterId: 'Public Meter ID',
        flowDirection: 'Flow Direction',
        specPipelineId: 'Spec Pipeline Id',
        specPipeline: {
          name: 'Spec Pipeline Name'
        },
        specZoneId: 'Spec Zone Id',
        specZone: {
          name: 'Spec Zone Name'
        },
        specInterconnectId: 'Spec Interconnect Id',
        specLocationGroupId: 'Spec Location Group Id'
      },
      interconnect: {
        row: 'Row',
        id: 'ID',
        etrmId: 'ETRM ID',
        spec: {
          name: 'Spec Name'
        },
        specId: 'Spec Id',
        name: 'Name',
        etrmPipelineOneName: 'ETRM Pipeline One Name',
        etrmPipelineTwoName: 'ETRM Pipeline Two Name',
        etrmPipelineOneId: 'ETRM Pipeline One Id',
        etrmPipelineTwoId: 'ETRM Pipeline Two Id',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By',
        locationOneName: 'Location One Name',
        locationTwoName: 'Location Two Name',
        locationOneId: 'Location One Id',
        locationTwoId: 'Location Two Id',
        locationOnePublicMeterId: 'Location One Public Meter ID',
        locationTwoPublicMeterId: 'Location Two Public Meter ID',
        missingEtrmPipelineId: 'Please upload Pipeline Data in order to select an ETRM Pipeline ID.'
      },
      interconnectSpec: {
        specId: 'Spec Id',
        locationOneName: 'Location One Name',
        locationOnePublicMeterId: 'Location One Public Meter ID',
        pipelineOneId: 'Pipeline One Id',
        locationTwoName: 'Location Two Name',
        locationTwoPublicMeterId: 'Location Two Public Meter ID',
        pipelineTwoId: 'Pipeline Two Id'
      },
      rateDefinition: {
        row: 'Row',
        etrmId: 'ETRM ID',
        spec: {
          name: 'Spec Name'
        },
        specId: 'Spec Id',
        etrmRateScheduleId: 'ETRM Rate Schedule ID',
        receiptLocationCategory: 'Receipt Location Category',
        etrmReceiptLocationId: 'ETRM Receipt Location ID',
        deliveryLocationCategory: 'Delivery Location Category',
        etrmDeliveryLocationId: 'ETRM Delivery Location ID',
        chargeIndicator: 'Charge Indicator',
        rateType: 'Rate Type',
        chargeRate: 'Charge Rate',
        startDate: 'Start Date',
        endDate: 'End Date',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By'
      },
      rateSchedule: {
        row: 'Row',
        etrmId: 'ETRM ID',
        spec: {
          name: 'Spec Name'
        },
        specId: 'Spec Id',
        name: 'Name',
        description: 'Description',
        etrmPipelineId: 'ETRM Pipeline ID',
        etrmProviderChargeTypeId: 'ETRM Provider Charge Type ID',
        startDate: 'Start Date',
        endDate: 'End Date',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By'
      },
      locationGroup: {
        row: 'Row',
        etrmId: 'ETRM ID',
        spec: {
          name: 'Spec Name'
        },
        specId: 'Spec Id',
        name: 'Name',
        etrmPipelineName: 'ETRM Pipeline Name',
        etrmPipelineId: 'ETRM Pipeline Id',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By',
        missingEtrmPipelineId: 'Please upload Pipeline Data in order to select an ETRM Pipeline ID.'
      },
      locationGroupSpec: {
        specId: 'Spec Id',
        name: 'Name',
        specPipelineId: 'Spec Pipeline Id'
      },
      payIndex: {
        row: 'Row',
        etrmId: 'ETRM ID',
        spec: {
          name: 'Spec Name',
          specId: 'Spec Id'
        },
        specId: 'Spec Id',
        shortName: 'Short Name',
        name: 'Name',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        updatedBy: 'Updated By'
      },
      payIndexSpec: {
        specId: 'Spec Id',
        shortName: 'Short Name',
        name: 'Name'
      }
    }
  }
} as const;

export default oracle;
