import { JsonMap } from '@core/typings';
import {
  IUserConfigIntegration,
  UserConfigDefinedIntegrationConstructor,
  UserConfigFormatFn,
  UserConfigIntegrationConstructor
} from '../../typings';
import { Ajv } from '../Ajv';

/* NOTE: leaving this as a subclass for now; if no additional functionality is required,
 * setup can be moved into UserConfig at some point
 */
export class UserConfigIntegration<TVal extends JsonMap, TRawVal extends JsonMap = TVal>
  implements IUserConfigIntegration<TVal, TRawVal>
{
  public format: UserConfigFormatFn<TVal, TRawVal>;
  public validate: IUserConfigIntegration<TVal, TRawVal>['validate'];
  constructor(params: UserConfigIntegrationConstructor<TVal, TRawVal>) {
    const { format, type } = params;
    this.format = format;

    /* TODO: NEED TO REGISTER SCHEMA FOR GRID STATE AND BENTO STATE
     * 1. for ad hoc types, look for key schema (probably doesn't exist)
     * 2. if it doesn't exist, compile the schema and cache it by key
     * 2. for pre-defined types, look for type schema
     */
    if (type === 'custom' || type === 'filterSet') {
      const { key, schema } = params;
      this.validate = Ajv.getSchema<TVal>(key) || Ajv.compile<TVal>(schema);
    } else {
      this.validate = (params as UserConfigDefinedIntegrationConstructor<TVal>).validate;
    }
  }

  // process = (raw: TRawVal) => {
  //   const formatted = this.format(raw);
  //   if (this.validate(formatted)) return formatted;
  //   throw this.validate.errors
  //     ? Ajv.errorsText(this.validate.errors)
  //     : 'An unknown validation error occurred';
  // };

  // processAsync = async (raw: TRawVal) => {
  //   try {
  //     return this.process(raw);
  //   } catch (errors) {
  //     return Promise.reject(errors);
  //   }
  // };
}
