import { useDidUpdate } from '@core/hooks';
import { useReset, withReset } from '@core/reset';
import { GridReadyEvent } from 'ag-grid-community';
import _ from 'lodash';
import { useGridViewsConfig } from '../useGridViewsConfig';

import { isDev } from '@core/util';
import { ReactNode, useEffect } from 'react';
import { GridViewsProviderState } from '../GridStateConfig.types';
import { getGridViewsToolPanel, gridViewsToolPanelId } from './getGridViewsToolPanel';

type GridViewsToolPanelProviderProps = { children: ReactNode };
type GridReadyConfigEventParams = {
  grid: GridReadyEvent;
  providerState: GridViewsProviderState;
};
export const GridViewsMonitor = withReset(function GridViewToolPanelProvider({
  children
}: GridViewsToolPanelProviderProps) {
  const reset = useReset();
  const Config = useGridViewsConfig();

  useEffect(() => {
    /* FIRED @ withGridViewsIntegration() */
    Config.once('grid ready', ({ grid, providerState }: GridReadyConfigEventParams) => {
      const { reloadCount } = providerState;
      Config.debug && console.log('grid ready has fired w/ reload count:', reloadCount);
      // const { activeGridView } = Config.getCurrent();
      const { unshiftToolPanel } = Config.providerParams;
      const sidebar = grid.api.getSideBar();
      if (!_.find(sidebar?.toolPanels, { id: gridViewsToolPanelId })) {
        const panel = getGridViewsToolPanel(Config.providerParams);
        const otherPanels = sidebar?.toolPanels || [];
        const toolPanels = unshiftToolPanel ? [panel, ...otherPanels] : [...otherPanels, panel];
        grid.api.setGridOption('sideBar', { ...sidebar, toolPanels });
        if (isDev && Config.debug && reloadCount > 0) grid.api.openToolPanel(gridViewsToolPanelId);
      }
    });
    const handleColDefsUpdated = () => {
      Config.debug && console.log('resetting grid state due to columnDefs update');
      reset();
    };
    Config.on('colDefs updated', handleColDefsUpdated);

    return () => {
      Config.off('colDefs updated', handleColDefsUpdated);
    };
  }, []);

  useDidUpdate(() => {
    /* HANDLED @ withGridViewsIntegration() */
    Config.emit('grid reloading');
    reset();
  }, [Config.initialState]);
  return <>{children}</>;
});
