import { Switch, SwitchProps } from '@core/inputs';
import { ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';

const switchKeys: (keyof SwitchProps)[] = [
  'disabled',
  'prefixCls',
  'checkedChildren',
  'unCheckedChildren',
  'color',
  'className',
  'style'
];

export function switchCellRenderer<TData = any>(
  params: ICellRendererParams<TData, boolean> & SwitchProps
) {
  return (
    <div className='switch-value-cell'>
      <Switch
        {..._.pick(params, switchKeys)}
        checked={params.value ?? undefined}
        onChange={params.setValue}
      />
    </div>
  );
}
