/* REFACTOR: kinda everything needs to be fixed here :(((( */
import { ChevronLeftSvg, ChevronRightSvg } from '@core/images';
import { useIsRouteHiddenParams } from '@core/routing';
import { themes } from '@core/theme';
import { DataTemplateType, SubRoute } from '@core/typings';
import { camelize } from '@core/util';
import { createBrowserHistory } from 'history';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ButtonSize, ButtonType, OxButton } from '../OxButton';
import { OxScroll, StyledTabRoutes } from './OxTabRoutes.styles';

export interface TabRouteProps {
  subRoutes: SubRoute[];
  setSelectedTemplate?: Dispatch<SetStateAction<DataTemplateType>>;
  removeSliders?: boolean;
}

export const OxTabRoutes = ({
  subRoutes,
  setSelectedTemplate,
  removeSliders = false
}: TabRouteProps) => {
  const { t } = useTranslation();
  const isRouteHiddenParams = useIsRouteHiddenParams();

  const history = createBrowserHistory({ window });
  const [selectedRoute, setSelectedRoute] = useState(0);

  const displayRef = useRef<HTMLElement | undefined>();
  const leftIconRef = useRef<HTMLElement | undefined>();
  const rightIconRef = useRef<HTMLElement | undefined>();
  const [start, setStart] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);

  const scroll = (direction = 1) => {
    setScrollEnd(false);
    if (displayRef && displayRef.current) {
      const scrollableLength = displayRef.current.clientWidth;
      const step = 450 * direction;
      displayRef.current.scrollLeft += step;

      const distanceFromStart = start + step;

      //handle going above scrollable legnth and below zero else send the distanceFromStart
      if (distanceFromStart >= scrollableLength) {
        setScrollEnd(true);
        setStart(scrollableLength);
      } else if (start < 0) {
        setStart(0);
      } else {
        setStart(distanceFromStart);
      }
    }
  };

  function isSelected(route: string, index: number) {
    const pathName = history.location.pathname;

    const noRouteMatch = subRoutes.filter((obj) => pathName === obj.path).length === 0;
    return (noRouteMatch && !selectedRoute && !index) || pathName === route;
  }

  const tabRoutes = (
    <StyledTabRoutes
      className={`flex items-center justify-start no-scrollbar relative ${
        start !== 0 ? 'pl-[45px]' : ''
      }`}
    >
      <OxScroll ref={displayRef as any}>
        {subRoutes.map(({ title, path, isHidden }, index) =>
          isHidden?.(isRouteHiddenParams) ? null : (
            <Link key={title} to={path}>
              <OxButton
                buttonType={ButtonType.text}
                buttonSize={ButtonSize.large}
                key={title}
                text={t(title)}
                className={`text-sm mr-7 cursor-pointer whitespace-nowrap ${
                  isSelected(path, index)
                    ? 'font-medium template-button-active'
                    : 'font-normal template-button-inactive'
                }`}
                onClick={() => {
                  setSelectedRoute(index);

                  if (setSelectedTemplate) {
                    const currentPage = path.split('upload-')[1];
                    const formattedPage = camelize(currentPage);
                    setSelectedTemplate(formattedPage as DataTemplateType);
                  }
                }}
              />
            </Link>
          )
        )}
        {removeSliders ? null : (
          <>
            {start !== 0 && (
              <div
                ref={leftIconRef as any}
                className='absolute left-2 scroll-icon'
                onClick={() => scroll(-1)}
              >
                <ChevronLeftSvg width={18} height={18} color='#000' fill='#000' />
              </div>
            )}

            {!scrollEnd && (
              <div
                ref={rightIconRef as any}
                className='absolute right-2 scroll-icon'
                onClick={() => scroll(1)}
              >
                <ChevronRightSvg width={18} height={18} color='#000' fill='#000' />
              </div>
            )}
          </>
        )}
      </OxScroll>
    </StyledTabRoutes>
  );

  return <ThemeProvider theme={themes.lightTheme}>{tabRoutes}</ThemeProvider>;
};
