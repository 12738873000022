import { AggValueConfig, IAggValue } from '@core/grid/typings';
import _ from 'lodash';
import pluralize from 'pluralize';

function countAndLabel(this: IAggValue) {
  if (!this.length) return '';
  if (this.length === 1) return _.toString(this.values[0]);
  return pluralize(this.countLabel, this.length, true);
}

export const aggValuePresetMap: Record<string, AggValueConfig> = {
  count: {
    calculate: countAndLabel
  },
  unique: {
    calculate: countAndLabel,
    uniqueValues: true
  },
  list: {
    calculate(this: IAggValue) {
      return _.join(this.values, ', ');
    },
    uniqueValues: true
  },
  med: {
    transformValues: (vals) => vals.sort?.() || vals,
    calculate(this: IAggValue) {
      const midInd = _.floor(this.length / 2);
      /* get the middle value for odd-length arrays, or the middle 2 values for even-length */
      const midpoint = _.slice<number>(
        this.values as number[],
        midInd,
        this.length % 2 ? midInd : midInd + 1
      );
      return _.mean(midpoint);
    }
  },
  sum: {
    transformValues: (vals) => _.map(vals, _.toFinite),
    calculate(this: IAggValue) {
      return _.sum(this.values);
    }
  }
};

export type AggValuePreset = keyof typeof aggValuePresetMap;
