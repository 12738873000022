import {
  ValueFormatterLiteFunc,
  ValueFormatterLiteParams,
  ValueFormatterParams
} from 'ag-grid-community';
import { createFormatter } from './createFormatter';
import { CreateFormatterConfig } from './createFormatter.types';

export function createFormatterLite<TData = any, TValue = any>(
  conf: CreateFormatterConfig = {}
): ValueFormatterLiteFunc<TData, TValue> {
  const format = createFormatter(conf);
  return (params: ValueFormatterLiteParams<TData, TValue>) =>
    format(params as ValueFormatterParams);
}
