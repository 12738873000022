import { StyleGetter } from '@core/theme/typings';
import _ from 'lodash';
import { TabsProps } from '../Tabs.types';

export type TabHeadStyleProps = {
  lblWidth: number;
  pfxWidth: number;
  sfxWidth: number;
  wprWidth: number;
  headerAlign: TabsProps['headerAlign'];
};

export const css: StyleGetter<TabHeadStyleProps> = (theme, props) => {
  const {
    fade,
    gutter,
    colorWheel: { common, gray },
    colors: { text }
  } = theme;
  const { headerAlign = 'center', pfxWidth = 0, sfxWidth = 0, lblWidth = 0, wprWidth = 0 } = props;
  const hGtrRight = sfxWidth + (sfxWidth ? gutter : 0);
  const hGtrLeft = pfxWidth + (pfxWidth ? gutter : 0);

  const pfxSfxMinWidth = headerAlign === 'center' ? (_.max([hGtrRight, hGtrLeft]) as number) : 0;

  let lblLeftGtr =
    (headerAlign === 'center'
      ? pfxWidth < pfxSfxMinWidth
        ? pfxSfxMinWidth - pfxWidth
        : 0
      : gutter * 2) ?? 0;
  let lblRightGtr =
    (headerAlign === 'center'
      ? sfxWidth < pfxSfxMinWidth
        ? pfxSfxMinWidth - sfxWidth
        : 0
      : gutter * 2) ?? 0;

  if (
    headerAlign === 'center' &&
    lblWidth + lblRightGtr + sfxWidth + lblLeftGtr + pfxWidth >= wprWidth
  ) {
    lblLeftGtr = pfxWidth ? gutter : 0;
    lblRightGtr = sfxWidth ? gutter : 0;
  }

  return {
    tabsHead: {
      display: 'flex',
      position: 'relative',
      borderBottom: `1px solid ${fade(text.primary, 88)}`,
      marginBottom: gutter,
      width: '100%',
      alignSelf: 'stretch',
      alignItems: 'center',

      '.labels-wpr': {
        display: 'flex',
        flex: `1 1 auto`,
        justifyContent: headerAlign,
        marginLeft: lblLeftGtr,
        marginRight: lblRightGtr,
        overflowX: 'auto',
        scrollbarGutter: 'auto',
        '&::-webkit-scrollbar': { height: gutter },
        '&::-webkit-scrollbar-track': { backgroundColor: common.transparent },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: fade(gray[500], 40),
          borderRadius: gutter
        },
        '&::-webkit-scrollbar-button': { backgroundColor: fade(gray[500], 60) }
      },

      '.labels-ctr': {
        display: 'inline-flex',
        minWidth: 0,
        overflowX: 'visible'
      },
      '.pfx-wpr, .sfx-wpr': { display: 'flex' }
    }
  };
};
