import { CloseOutlineSvg } from '@core/images';
import { ReactElement, useEffect, useRef } from 'react';
import { StyledInfoDrawer } from './OxInfoDrawer.styles';

export enum DrawerType {
  User = 'user',
  ExceptionDetail = 'exceptionDetail',
  Accordion = 'accordion'
}

interface Props {
  children: ReactElement;
  icons?: ReactElement;
  isOpen: boolean;
  closeDrawer: () => void;
  type?: DrawerType;
  className?: string;
}

export const OxInfoDrawer = ({
  children,
  icons,
  isOpen,
  type,
  closeDrawer,
  className = ''
}: Props): JSX.Element => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event: Event) => {
        // List of class names that should not trigger the drawer close (We need to close the drawer
        // when clicking outside of it, but not when clicking on certain elements like the "Withdraw"
        // modal popup for example that exists *outside* the Drawer markup)
        const allowedElementsClassnames = ['ox-modal-default', '___option'];

        // Check if the clicked element or any of its parents has one of the allowed classes
        let currentElement: HTMLElement | null = event.target as HTMLElement;
        let shouldCloseDrawer = true;

        // Traverse up the DOM to check if the clicked element or any of its parents has one of the allowed classes
        while (currentElement) {
          if (
            ref.current?.contains(currentElement) ||
            allowedElementsClassnames.some((className) =>
              currentElement!.classList.contains(className)
            )
          ) {
            shouldCloseDrawer = false;
            break;
          }
          currentElement = currentElement.parentElement;
        }

        if (ref.current && !ref.current?.contains(event.target) && shouldCloseDrawer) {
          closeDrawer();
        }
      };

      /* NOTE: this is a workaround for batched update handlers in React 18.2.x;
       * it probably won't be necessary in future versions of React.
       */
      setTimeout(() => {
        document.addEventListener('click', handleClickOutside);
      }, 0);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
    return () => {
      /* noop */
    };
  }, [ref, isOpen]);
  return (
    <StyledInfoDrawer
      ref={ref}
      data-testid='OxInfoDrawer'
      className={`${type} ox-info-drawer drawer-background fixed border-l right-0 top-[65px] h-full delay-400 duration-300 ease-in-out transition-all transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className='flex flex-col overflow-y-scroll h-full'>
        <div className='bg-white flex h-14 sticky top-0 w-full delay-400 duration-500 ease-in-out transition-all items-center z-50'>
          <div className='w-full flex justify-end items-center h-[40px]'>
            {icons}
            <CloseOutlineSvg
              color='#1BDAAA'
              width={30}
              height={30}
              onClick={closeDrawer}
              className='mr-[10px] mt-[10px] cursor-pointer'
            />
          </div>
        </div>
        <div className={className}>{children}</div>
      </div>
    </StyledInfoDrawer>
  );
};
