/*
  Please keep these organized alphabetically :D
*/

export const SERVICE_KEYS = {
  AUTH_REPOSITORY: Symbol('AUTH_REPOSITORY'),
  COMPANY_CONFIGURATION: Symbol('COMPANY_CONFIGURATION_REPOSITORY'),
  BROKER_REPOSITORY: Symbol('BROKER_REPOSITORY'),
  COUNTERPARTY_REPOSITORY: Symbol('COUNTERPARTY_REPOSITORY'),
  CHARGE_TYPE_REPOSITORY: Symbol('CHARGE_TYPE_REPOSITORY'),
  DEAL_ACTIVITY_REPOSITORY: Symbol('DEAL_ACTIVITY_REPOSITORY'),
  DEAL_REPOSITORY: Symbol('DEAL_REPOSITORY'),
  FEATURE_FLAG_REPOSITORY: Symbol('FEATURE_FLAG_RESPOSITORY'),
  INTERCONNECT_REPOSITORY: Symbol('INTERCONNECT_REPOSITORY'),
  LEGAL_ENTITY_REPOSITORY: Symbol('LEGAL_ENTITY_REPOSITORY'),
  LOCATION_GROUP_REPOSITORY: Symbol('LOCATION_GROUP_REPOSITORY'),
  LOCATION_REPOSITORY: Symbol('LOCATION_REPOSITORY'),
  PAY_INDEX_TYPE_REPOSITORY: Symbol('PAY_INDEX_TYPE_REPOSITORY'),
  REJECTED_ETRM_INVOICES_REPOSITORY: Symbol('REJECTED_ETRM_INVOICES_REPOSITORY'),
  PIPELINE_REPOSITORY: Symbol('PIPELINE_REPOSITORY'),
  PRODUCT_REPOSITORY: Symbol('PRODUCT_REPOSITORY'),
  RATE_DEFINITION_REPOSITORY: Symbol('RATE_DEFINITION_REPOSITORY'),
  RATE_SCHEDULE_REPOSITORY: Symbol('RATE_SCHEDULE_REPOSITORY'),
  STATIC_DATA_REPOSITORY: Symbol('STATIC_DATA_REPOSITORY'),
  TRANSPORTATION_AGREEMENT_REPOSITORY: Symbol('TRANSPORTATION_AGREEMENT_REPOSITORY'),
  UPLOAD_REPOSITORY: Symbol('UPLOAD_REPOSITORY'),
  USERS_REPOSITORY: Symbol('USERS_REPOSITORY'),
  ZONE_REPOSITORY: Symbol('ZONE_REPOSITORY'),
  ROLE_REPOSITORY: Symbol('ROLE_REPOSITORY'),
  ROLE_RESOURCE_REPOSITORY: Symbol('ROLE_RESOURCE_REPOSITORY'),
  RESOURCE_REPOSITORY: Symbol('RESOURCE_REPOSITORY')
};
