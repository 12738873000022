import { StyleGetter } from '@core/theme/typings';

export const css: StyleGetter = (theme) => {
  const {
    colors: { canvas, primary, text },
    colorWheel: { common },
    fade,
    gutter: gtr
  } = theme;
  const borderClr = fade(canvas.inverse, 80);
  return {
    displayWpr: {
      '--border-clr': primary.primary,
      backgroundColor: canvas.primary,
      padding: '1.5px 6px',
      outline: `1px solid ${borderClr}`,
      border: `1px solid ${common.clear}`,
      borderRadius: gtr / 2,
      minHeight: 32,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      transition: '200ms all ease-in-out',

      '&.--is-disabled': {
        cursor: 'not-allowed',
        backgroundColor: canvas.disabled,

        '.date-picker-display, .date-picker-icon': {
          color: text.disabled
        }
      },

      '&:hover:not(.--is-disabled)': {
        outline: `1px solid var(--border-clr)`,
        '.date-picker-icon': {
          color: primary.primary
        }
      },
      '&.--is-focused:not(.--is-disabled)': {
        borderColor: 'var(--border-clr)'
      },

      '.date-picker-display': {
        cursor: 'inherit',
        fontSize: 16,
        lineHeight: '24px',
        padding: '0 2px'
      },

      '.date-picker-icon': {
        transition: '200ms all ease-in-out',
        marginLeft: gtr * 2,
        color: fade(canvas.inverse)
      }
    },
    popover: {
      zIndex: 999,

      '.date-picker-calendar': {
        border: `1px solid ${fade(text.hint, 60)}`,
        borderRadius: 4
      }
    }
  };
};
