import { useDataMutation } from '@core/data-manager';
import { useNotification } from '@core/notification';
import { RootState, useAppSelector } from '@core/redux';
import { ComposedSfc, IOxUserData } from '@core/typings';
import _ from 'lodash';
import { useEffect } from 'react';
import { UserConfigManager } from '../lib/UserConfigManager';
import { userConfigEndpoints } from '../UserConfigs.endpoints';
import { UserConfigMap, UserConfigRegistryMap } from '../util';

const cUserSel = (state: RootState) => state.access.currentUser;

export function withUserConfigs(Composed: ComposedSfc): ComposedSfc {
  return function WithUserConfigs(p: any) {
    const currentUser = useAppSelector(cUserSel);
    const { mutateAsync: commit } = useDataMutation<IOxUserData>({
      key: userConfigEndpoints.update
    });
    const notify = useNotification();

    useEffect(() => {
      if (!_.isEmpty(currentUser) && !UserConfigManager.getState().isReady) {
        UserConfigManager.init({ payload: currentUser, notify, commit });
      }
    }, [currentUser]);

    /* nuke maps on unmount */
    useEffect(
      () => () => {
        UserConfigMap.clear();
        UserConfigRegistryMap.clear();
      },
      []
    );
    if (!currentUser) return null;
    return <Composed {...p} />;
  };
}
