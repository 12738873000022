import { PathPattern } from 'react-router-dom';

const patternConf: Partial<PathPattern> = { caseSensitive: false, end: false };

export const productMatchPattern: PathPattern = {
  ...patternConf,
  path: '/products/:product/*'
};

export const pageMatchPattern: PathPattern = {
  ...patternConf,
  path: '/products/:product/:page/*'
};

export const subPageMatchPattern: PathPattern = {
  ...patternConf,
  path: '/products/:product/:page/:subPage/*'
};

export const rogueMatchPattern: PathPattern = {
  ...patternConf,
  path: '/*'
};
