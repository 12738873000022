import { DataTemplateType } from '@core/typings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUploadRepository } from 'shared/api/interfaces/IUploadRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IDataTemplate } from 'shared/models/DataTemplate';
import { updateItemInArray } from '../../helpers/data-utils.helper';
import { buildGenericSlice, MergedReducers } from '../../helpers/slice.helper';
import { RootState } from '../../store';
import { UploadThunks } from '../../thunks/upload.thunks';
import { GenericPayload } from '../../utils/array.slice';

interface DataTemplateAction extends GenericPayload {
  meta?: {
    arg: DataTemplateType;
  };
}

type InitialStateType = {
  counterpartyTemplates: IDataTemplate[];
  interconnectTemplates: IDataTemplate[];
  locationTemplates: IDataTemplate[];
  pipelineTemplates: IDataTemplate[];
  zoneTemplates: IDataTemplate[];
  rateTemplates: IDataTemplate[];
  counterpartyCount: number;
  interconnectCount: number;
  locationCount: number;
  pipelineCount: number;
  zoneCount: number;
  rateCount: number;
};

const counterpartyTemplates: IDataTemplate[] = [];
const interconnectTemplates: IDataTemplate[] = [];
const locationTemplates: IDataTemplate[] = [];
const pipelineTemplates: IDataTemplate[] = [];
const zoneTemplates: IDataTemplate[] = [];
const rateTemplates: IDataTemplate[] = [];
const counterpartyCount = 0;
const interconnectCount = 0;
const locationCount = 0;
const pipelineCount = 0;
const zoneCount = 0;
const rateCount = 0;

export const initialState: InitialStateType = {
  counterpartyTemplates,
  interconnectTemplates,
  locationTemplates,
  pipelineTemplates,
  zoneTemplates,
  rateTemplates,
  counterpartyCount,
  interconnectCount,
  locationCount,
  pipelineCount,
  zoneCount,
  rateCount
};

const updateTemplatesByTypeReducer = {
  reducer: (state: any, { payload }: PayloadAction<GenericPayload>) => {
    const { subData } = payload;
    state[subData.type] = updateItemInArray(state[subData.type], payload);
  },
  prepare: (payload: GenericPayload) => {
    return { payload };
  }
};

const uploadRepository = container.get<IUploadRepository>(SERVICE_KEYS.UPLOAD_REPOSITORY);
const uploadThunks = new UploadThunks(uploadRepository);

const mergedReducers: MergedReducers = {
  name: 'updateTemplatesByType',
  reducer: updateTemplatesByTypeReducer
};

const slice = buildGenericSlice<InitialStateType>('dataTemplates', initialState, 'List', [
  mergedReducers
]);

export const fetchDataTemplates = uploadThunks.getAllByType();

export const dataTemplateSlice = createSlice({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchDataTemplates.fulfilled,
      (state: any, { payload, meta }: DataTemplateAction) => {
        if (payload) {
          const { arg: type } = meta || {};
          const { data } = payload;
          if (data) {
            const listName = type + 'Templates';

            state[listName] = data;
            state[type + 'Count'] = data.length;
          }
        }
      }
    );
  }
});

export const { updateTemplatesByType, addDatatemplates, updateDatatemplates, removeDatatemplates } =
  dataTemplateSlice.actions;

export const getDataTemplates = (state: RootState) => state.dataTemplates;

export const dataTemplateReducer = dataTemplateSlice.reducer;
