import { createSelector, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RootState } from 'redux/store';
import { ChargeTypeThunks } from 'redux/thunks/chargeType.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { IChargeTypeRepository } from 'shared/api/interfaces/IChargeTypeRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IChargeType, IChargeTypeSpec } from 'shared/models/ChargeType';

type InitialStateType = {
  count?: number;
  changeId: number;
  chargeTypes: IChargeType[];
  chargeTypeSpecs: IChargeTypeSpec[];
};

const count = undefined;
const changeId = 0;
const chargeTypes: IChargeType[] = [];
const chargeTypeSpecs: IChargeTypeSpec[] = [];

export const initialState: InitialStateType = {
  count,
  changeId,
  chargeTypes,
  chargeTypeSpecs
};

const chargeTypeRepository = container.get<IChargeTypeRepository>(
  SERVICE_KEYS.CHARGE_TYPE_REPOSITORY
);
const chargeTypeThunks = new ChargeTypeThunks(chargeTypeRepository);
export const getAllChargeTypes = chargeTypeThunks.getAll();
export const addChargeType = chargeTypeThunks.add();
export const getAllChargeTypeSpecs = chargeTypeThunks.getSpecs();
export const updateChargeType = chargeTypeThunks.update();
export const deleteChargeType = chargeTypeThunks.deleteAll();

const slice = buildGenericSlice<InitialStateType>('chargeType', initialState);
export const chargeTypeSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllChargeTypes.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload) {
          if (payload && payload.data?.length) {
            const { data } = payload;
            state.chargeTypes = data as IChargeType[];
            state.changeId++;
            state.count = data.length;
          }
        }
      }
    );
    builder.addCase(
      addChargeType.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload?.status === 200) {
          const chargeTypes = [...state.chargeTypes, payload.data];
          state.chargeTypes = chargeTypes;
          state.changeId++;
          state.count = chargeTypes.length;
        }
      }
    );
    builder.addCase(
      getAllChargeTypeSpecs.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data && payload.data.length > 0) {
          state.chargeTypeSpecs = payload.data as IChargeTypeSpec[];
          state.changeId++;
        }
      }
    );
  }
});

export const chargeTypeReducer = chargeTypeSlice.reducer;

export const selectChargeTypes = (state: RootState) => state.chargeType.chargeTypes;
export const selectChargeTypeSpecs = (state: RootState) => state.chargeType.chargeTypeSpecs;
export const selectPipelines = (state: RootState) => state.pipeline.pipelines;

export const selectChargeTypeMap = createSelectorMap(selectChargeTypes, 'specChargeTypeId');
export const selectChargeTypeSpecMap = createSelectorMap(selectChargeTypeSpecs, 'specId');
export const selectPipelinesMapEtrmId = createSelectorMap(selectPipelines, 'etrmId');

export const selectPipelinesNameMap = createSelectorMap(selectPipelines, 'name');
export const selectChargeTypeSpecNameMap = createSelectorMap(selectChargeTypeSpecs, 'name');

export interface IFQChargeType extends IChargeType {
  spec?: IChargeTypeSpec;
}

export const selectFQChargeTypeRefs = createSelector(
  [selectChargeTypes, selectChargeTypeSpecMap, selectPipelinesMapEtrmId],
  (chargeTypes, chargeTypeSpecMap, pipelineMap) => {
    return chargeTypes.map((chargeType: IChargeType) => {
      return {
        ...chargeType,
        specName: chargeTypeSpecMap.get(chargeType.specChargeTypeId)?.name,
        pipelineName: pipelineMap.get(chargeType.etrmPipelineId)?.name
      };
    });
  }
);
