import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import _ from 'lodash';
import { DataMngrQueryMeta, UseDataOptions } from '../../typings';

import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getIsRequestEnabled } from './getIsRequestEnabled';
import { getValidatedEndpoint } from './getValidatedEndpoint';

export function getNormalizedQuery<Resp = any, Formatted = Resp, Err = Error>(
  conf: UseDataOptions<Resp, Formatted, Err>,
  authState: Auth0ContextInterface
): UseQueryOptions<Resp, Err, Formatted, any> {
  const { endpoint, isValid, routeParams } = getValidatedEndpoint({
    key: conf.key,
    routeParams: conf?.routeParams
  });

  /* only allow query to run when validation criteria are met */
  const enabled: boolean = getIsRequestEnabled({
    enabled: conf?.enabled,
    isValid,
    isAuthedRequest: endpoint?.isAuthedRequest,
    authState
  });

  /* prettier-ignore */
  const queryKey: QueryKey = _.compact(_.uniq(_.concat(
    [],
    endpoint?.query.queryKey,
    conf?.queryKey,
    endpoint?.hasDynamicUrl ? conf?.routeParams : [],
    conf?.includeFiltersInQueryKey !== false && conf?.filters
  )));

  /* prettier-ignore */
  const meta: DataMngrQueryMeta & { isValid: boolean } = {
    isValid: true,
    ...(endpoint?.query.meta as DataMngrQueryMeta),
    ...conf?.meta,
    requestOptions: {
      ...endpoint?.query.meta.requestOptions,
      /* attach options.filters to the get-type requests */
      ...(endpoint?.isMutation ? {} : {
        params: {
          ...endpoint?.query.meta.requestOptions.params,
          ...conf?.meta?.requestOptions?.params,
          ...conf?.filters
        }
      })
    },
    routeParams
  };
  return {
    ...({
      ...endpoint?.query,
      ...conf
    } as UseQueryOptions<Resp, Err, Formatted, any>),
    meta,
    queryKey,
    enabled
  };
}
