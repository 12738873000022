import { Http } from '@core/rest-client';
import { HttpResponse, InfiniteFilterParams, SortOrder } from '@core/typings';
import { handleDataResponse, reformatDateString } from '@core/util';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig, ResponseType } from 'axios';
import { MetaSearchParams } from 'shared/constants/Search';

export const buildSearchParams = async (
  client: Http,
  filterParams: InfiniteFilterParams,
  additionalFilters: {
    [key: string]: string | number | Date | string[] | Date[] | number[] | undefined;
  },
  endpoint = 'search',
  responseType?: ResponseType
) => {
  const {
    paging,
    dateRange,
    sort = { key: 'tradeDate', order: SortOrder.DESC },
    searchFilters
  } = filterParams;

  const sortParams = JSON.stringify(sort);
  const start = dateRange?.startDate && reformatDateString(dateRange?.startDate);
  const end = dateRange?.endDate && reformatDateString(dateRange?.endDate);

  const filtersParams = JSON.stringify(searchFilters);

  let searchParams: AxiosRequestConfig = {
    params: {
      offset: paging?.offset,
      limit: paging?.limit || 10,
      sort: sortParams,
      ...additionalFilters,
      filters: filtersParams,
      start,
      end
    }
  };

  if (responseType) {
    searchParams = {
      ...searchParams,
      responseType
    } as AxiosRequestConfig;
  }

  const response = await client.get(endpoint, searchParams);

  if (response.status === 200 || response.status === 201) {
    return response;
  } else {
    return handleDataResponse(response, response.data);
  }
};

export const buildSearchThunk = (
  action: string,
  repoEndpoint: (
    filters: InfiniteFilterParams
  ) => Promise<HttpResponse<{ data: any; meta: MetaSearchParams }>>
): AsyncThunk<{ result: any; meta: any }, InfiniteFilterParams, Record<string, unknown>> => {
  return createAsyncThunk(action, async (filterParams: InfiniteFilterParams) => {
    const response = await repoEndpoint(filterParams);

    if (response.data) {
      const { data } = response;

      const result = data.data || [];
      const meta = data.meta || {};

      return {
        result,
        meta
      };
    }

    return {
      result: [],
      meta: response
    };
  });
};
