import { client } from '@core/rest-client';
import { ColumnValues, HttpResponse, InfiniteFilterParams } from '@core/typings';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IDealRepository } from 'shared/api/interfaces/IDealRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { MetaSearchParams } from 'shared/constants/Search';
import { buildSearchParams } from 'shared/helpers/search.helper';
import { DealRollups, IDeal, IRejectedDeal, WithdrawReason } from 'shared/models/Deal';

@injectable()
export class DealRepository implements IDealRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('pair/v1/deals');

  getAll = async (): Promise<AxiosResponse<IDeal>> => {
    const response = await client.get(this.apiUrl);
    return response.status === 200 ? response.data : [];
  };

  search = async (
    filterParams: InfiniteFilterParams
  ): Promise<HttpResponse<{ data: IDeal[]; meta: MetaSearchParams }>> => {
    const statusValue = filterParams.filters?.status ? filterParams.filters.status.value : [];
    const isExternalValue = filterParams.filters?.isExternal
      ? filterParams.filters.isExternal.value
      : '';

    const additionalFilters = {
      statuses: statusValue,
      isExternal: isExternalValue,
      entities: filterParams.filters?.legalEntity?.value ?? [],
      pipelines: filterParams.filters?.pipelines?.value ?? [],
      counterparties: filterParams.filters?.counterparties?.value ?? []
    };
    return buildSearchParams(client, filterParams, additionalFilters, `${this.apiUrl}/search`);
  };

  searchRejected = async (
    filterParams: InfiniteFilterParams
  ): Promise<HttpResponse<{ data: IRejectedDeal[]; meta: MetaSearchParams }>> => {
    const etrmIdValue = filterParams.filters?.etrmId ? filterParams.filters.etrmId.value : '';
    const statusValue = filterParams.filters?.status ? filterParams.filters.status.value : [];

    const additionalFilters = {
      etrmId: etrmIdValue,
      statuses: statusValue,
      entities: filterParams.filters?.legalEntity?.value ?? [],
      pipelines: filterParams.filters?.pipelines?.value ?? [],
      counterparties: filterParams.filters?.counterparties?.value ?? []
    };

    return buildSearchParams(
      client,
      filterParams,
      additionalFilters,
      `${this.apiUrl}/search-rejected`
    );
  };

  getRollups = async (
    filterParams: InfiniteFilterParams
  ): Promise<HttpResponse<{ data: DealRollups; meta: MetaSearchParams }>> => {
    const statusValue = filterParams.filters?.status ? filterParams.filters.status.value : [];
    const isExternalValue = filterParams.filters?.isExternal
      ? filterParams.filters.isExternal.value
      : '';

    const additionalFilters = {
      statuses: statusValue,
      isExternal: isExternalValue,
      entities: filterParams.filters?.legalEntity?.value ?? [],
      pipelines: filterParams.filters?.pipelines?.value ?? [],
      counterparties: filterParams.filters?.counterparties?.value ?? []
    };

    return buildSearchParams(client, filterParams, additionalFilters, `${this.apiUrl}/rollups`);
  };

  getPotentialMatches = async (ids: string[]): Promise<AxiosResponse<IDeal[]>> => {
    const response: AxiosResponse<IDeal[], any> = await client.post(this.apiUrl + '/find-many', {
      ids
    });
    return response;
  };

  add = async (): Promise<AxiosResponse<any, any>> => {
    throw new Error('Method not implemented.');
  };

  update = async (): Promise<AxiosResponse<any, any>> => {
    throw new Error('Method not implemented.');
  };

  delete = async (): Promise<AxiosResponse<any, any>> => {
    throw new Error('Method not implemented.');
  };

  getColumnValues = async (
    field: string,
    status: string
  ): Promise<AxiosResponse<ColumnValues[], any> | undefined> => {
    if (field && status) {
      const searchParams = {
        params: {
          statuses: status
        }
      };
      const response = await client.get(`${this.apiUrl}/column-values/${field}`, searchParams);
      return response.status === 200 ? response.data : [];
    }
  };

  getRejectedColumnValues = async (
    field: string
  ): Promise<AxiosResponse<ColumnValues[], any> | undefined> => {
    const response = await client.get(`${this.apiUrl}/rejected-column-values/${field}`);

    return response.status === 200 ? response.data : [];
  };

  clickToPair = async (
    companyEtrmId: string | undefined,
    counterpartyEtrmId: string | undefined,
    comment: string
  ): Promise<any> => {
    const response = await client.put(`${this.apiUrl}/click-to-pair`, {
      companyDealEtrmId: companyEtrmId,
      counterpartyDealEtrmId: counterpartyEtrmId,
      comment
    });

    return response;
  };

  withdraw = async (
    etrmId: string | undefined,
    comment: string,
    reason: WithdrawReason
  ): Promise<any> => {
    const response = await client.put(
      `${this.apiUrl}/withdraw/${etrmId}?comment=${comment}&reason=${reason}`
    );
    return response;
  };

  downloadDeals = async (filterParams: InfiniteFilterParams): Promise<HttpResponse<any>> => {
    const statusValue = filterParams.filters?.status ? filterParams.filters.status.value : [];
    const isExternalValue = filterParams.filters?.isExternal
      ? filterParams.filters.isExternal.value
      : '';

    const additionalFilters = {
      statuses: statusValue,
      isExternal: isExternalValue,
      entities: filterParams.filters?.legalEntity?.value ?? [],
      pipelines: filterParams.filters?.pipelines?.value ?? [],
      counterparties: filterParams.filters?.counterparties?.value ?? []
    };

    return buildSearchParams(
      client,
      filterParams,
      additionalFilters,
      `${this.apiUrl}/download`,
      'blob'
    );
  };

  deleteDealReplica = async (date: string): Promise<any> => {
    const response = await client.delete(`${this.apiUrl}/deal-replica?date=${date}`);
    return response;
  };

  deleteRejectedDeals = async (ids: string[]): Promise<any> => {
    const encodedIds = ids.map((id) => `ids[]=${id}`).join('&');
    const response = await client.delete(`${this.apiUrl}/rejected-deals?${encodedIds}`);
    return response;
  };
}
