import _ from 'lodash';
import { DataMngrEndpoint, DataMngrSectionOptions } from '../typings';
import { createRegisterEndpoint } from './createRegisterEndpoint';
import { EndpointKeyMap } from './EndpointMap';
import { indexSection } from './indexSection';

export const registerSection = (section: DataMngrSectionOptions): Record<string, string> => {
  const { endpointMap, path: secPath, key: secKey, isProduct = true, basePath } = section;

  if (!_.isString(secKey)) {
    throw new TypeError(
      `[Data Manager]: property "key" must be a string; received ${typeof secKey}`
    );
  }

  const sectionPath = isProduct ? secPath || secKey : '';
  const sectionKeyPfx = isProduct ? `${_.camelCase(secKey)}.` : '';
  const uriRoot = _.trim(basePath, '/') || `api${isProduct ? '/' + sectionPath : ''}/v1`;

  indexSection({ key: secKey, uriRoot });
  const registerEndpoint = createRegisterEndpoint({ ...section, sectionKeyPfx, uriRoot });

  const nameMap = _.reduce(
    endpointMap,
    (memo, route, _key) => {
      let endpoint: DataMngrEndpoint;
      try {
        endpoint = registerEndpoint(route, _key);
        if (endpoint) memo[_key] = endpoint.name;
      } catch (e) {
        console.warn(e);
      }
      return memo;
    },
    {} as Record<string, string>
  );

  EndpointKeyMap.set(secKey, nameMap);
  return nameMap;
};
