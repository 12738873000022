import { createRef, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { StyledCheckboxLabel } from './PrimitiveInputs.style';

export function TextInput(props: any): JSX.Element {
  return <input data-testid='OxInputField' {...props} />;
}

export const MaskInput = (props: any): JSX.Element => {
  const maskRef = useMemo(() => createRef(), []);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { type = 'Phone', maxLength, ...rest } = props;
  /* eslint-enable @typescript-eslint/no-unused-vars */

  return (
    <InputMask
      data-testid={`${type}Input`}
      ref={maskRef}
      type='Phone'
      mask='(999)-999-9999'
      alwaysShowMask={false}
      {...rest}
    />
  );
};

export function CalendarInput(p: any): JSX.Element {
  return <MaskInput mask='99/99/9999' alwaysShowMask {...p} type='calendar' />;
}

export function CheckboxInput(/* props: any */): JSX.Element {
  const [checkedId, setCheckedId] = useState(1);
  return (
    <div className='flex w-[20%] mt-2.5' data-testid='CheckboxInput'>
      <input
        className='-ml-2'
        type='checkbox'
        value='true'
        checked={checkedId === 0}
        onChange={() => setCheckedId(0)}
      />
      <StyledCheckboxLabel className='checkbox-label !font-normal !text-md ml-1'>
        Yes
      </StyledCheckboxLabel>
      <input
        type='checkbox'
        value='false'
        checked={checkedId === 1}
        onChange={() => setCheckedId(1)}
      />
      <StyledCheckboxLabel className='checkbox-label !font-normal !text-md ml-1'>
        No
      </StyledCheckboxLabel>
    </div>
  );
}
