import { useValueMemo } from '@core/hooks';
import { getRouteMatchParams } from 'Containers/OxSidebar/util';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { RouteMatchParams } from '../OxSidebar.types';

export type UseRouteInfoReturn = { params: RouteMatchParams; pathname: string; hash?: string };

export function useRouteInfo(): UseRouteInfoReturn {
  const { pathname, hash } = useLocation();

  return useValueMemo<UseRouteInfoReturn>(
    () => ({
      params: getRouteMatchParams(pathname),
      pathname,
      hash: hash ? _.trimStart(hash, '#') : undefined
    }),
    [pathname, hash]
  );
}
