import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { IFeatureFlagRepository } from 'shared/api/interfaces/IFeatureFlagRepository';

import _isNull from 'lodash/isNull';
import { FeatureFlags } from 'shared/models/FeatureFlags';

export class FeatureFlagsThunks {
  private readonly repository!: IFeatureFlagRepository;

  constructor(repository: IFeatureFlagRepository) {
    this.repository = repository;
    if (_isNull(this.repository)) {
      throw new Error('featureFlags repository has not been instantiated');
    }
  }
  private actionRoot = 'feature-flags';

  fetch = (): AsyncThunk<FeatureFlags, never, any> => {
    const action = `${this.actionRoot}/fetch`;
    return createAsyncThunk(action, this.repository.fetch);
  };
}
