import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductThunks } from 'redux/thunks/products.thunks';
import { IProductRepository } from 'shared/api/interfaces/IProductRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IProduct } from '../../../shared/models/Role';
import { RootState } from '../../store';

type InitialStateType = {
  products: IProduct[];
  enabledProducts: IProduct[];
};

const products: IProduct[] = [];
const enabledProducts: IProduct[] = [];

export const initialState: InitialStateType = {
  products,
  enabledProducts
};

const productRepository = container.get<IProductRepository>(SERVICE_KEYS.PRODUCT_REPOSITORY);
const productThunks = new ProductThunks(productRepository);

export const fetchProducts = productThunks.getAll();
export const fetchEnabledProducts = productThunks.getAllEnabled();

const slice = {
  name: 'product',
  initialState,
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchProducts.fulfilled,
      (state: any, { payload }: PayloadAction<IProduct[]>) => {
        state.products = payload;
      }
    );

    builder.addCase(
      fetchEnabledProducts.fulfilled,
      (state: any, { payload }: PayloadAction<IProduct[]>) => {
        state.enabledProducts = payload;
      }
    );
  }
};

export const productSlice = createSlice({
  ...slice,
  reducers: {}
});

export const productReducer = productSlice.reducer;
export const getProduct = (state: RootState) => state.product;
