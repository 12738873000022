import { Text } from '@core/components';
import { EditableCallbackParams, ICellRendererParams } from 'ag-grid-community';
import _ from 'lodash';

export const selectCellRenderer = (params: ICellRendererParams) => {
  /* NOTE: this will need to be modified when upgrading to AG Grid 31 reactive elements */
  const isEditable = Boolean(
    _.isFunction(params.colDef?.editable)
      ? params.colDef?.editable(params as EditableCallbackParams)
      : params.colDef?.editable
  );
  return (
    <div className='select-value-cell'>
      <Text text={params.value} />
      {isEditable && <Text text={'\u25BE'} className='indicator' />}
    </div>
  );
};
