import { JsonMap } from '@core/typings';
import {
  AdHocUserConfigType,
  CreateIntegrationParams,
  UserConfigType
} from '@core/user-configs/typings';
import { createAdHocIntegration } from './createAdHocIntegration';
import { GridViewsConfigTVal, gridViewsIntegration } from './gridState';
import { UserConfigIntegration } from './UserConfigIntegration';

type CreateIntegrationReturn<
  TType extends UserConfigType,
  TVal extends JsonMap,
  TRawVal extends JsonMap = TVal
> = TType extends AdHocUserConfigType
  ? UserConfigIntegration<TVal, TRawVal>
  : TType extends 'gridState'
    ? UserConfigIntegration<GridViewsConfigTVal>
    : /* TODO: redefine */
      TType extends 'bentoState'
      ? UserConfigIntegration<GridViewsConfigTVal>
      : UserConfigIntegration<JsonMap>;

const definedTypeIntegrationMap = {
  gridState: gridViewsIntegration,
  bentoState: gridViewsIntegration /* TODO: redefine */
};

export function getIntegration<TVal extends JsonMap, TRawVal extends JsonMap = TVal>(
  params: CreateIntegrationParams<TVal, TRawVal>
): CreateIntegrationReturn<typeof params.type, TVal, TRawVal> {
  const { type } = params;
  if (type === 'custom' || type === 'filterSet') {
    return createAdHocIntegration<TVal, TRawVal>(params);
  }
  return definedTypeIntegrationMap[type];
}
