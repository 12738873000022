import {
  AgreementSvg,
  CalculatorFillSvg,
  ChartPieOutlineSvg,
  ChartPieSvg,
  CogSvg,
  ExchangeOutlineSvg,
  ExchangeSvg,
  GridSvg,
  RouteOutlineSvg,
  RouteSvg,
  SettingsSvg
} from '@core/images';
import { CalcIcon } from '../components';
import { ILinkInfo } from '../OxSidebar.types';
import { settingsLinkDefaults } from './settingsLinkDefaults';

export const getOxNomPageLinks: () => ILinkInfo[] = () => [
  {
    LinkIcon: RouteOutlineSvg,
    SelectedLinkIcon: RouteSvg,
    isHidden: ({ featureFlags }) => !featureFlags.nomPhaseOne,
    linkText: 'oxSidebar.oxNom.nomination',
    route: '#',
    children: [
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.scheduling',
        route: 'products/nom/scheduling'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.deal',
        route: 'products/nom/deals'
      }
    ]
  },
  {
    LinkIcon: ChartPieOutlineSvg,
    SelectedLinkIcon: ChartPieSvg,
    linkText: 'oxSidebar.oxNom.reporting',
    route: '#',
    isHidden: ({ featureFlags }) => !featureFlags.nomPhaseOne || featureFlags.hideQaFeatures,
    children: [
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.ediDashboard',
        route: 'products/nom/edi-dashboard'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.gasDaySummary',
        route: 'products/nom/gas-day-summary'
      },
      // {
      //   LinkIcon: GridSvg,
      //   SelectedLinkIcon: GridSvg,
      //   linkText: 'oxSidebar.oxNom.positionSummary',
      //   route: 'products/nom/position-summary'
      // },
      // {
      //   LinkIcon: GridSvg,
      //   SelectedLinkIcon: GridSvg,
      //   linkText: 'oxSidebar.oxNom.plannedVolumes',
      //   route: 'products/nom/planned-volumes'
      // },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.fuelRateScenarios',
        route: 'products/nom/fuel-rate-scenarios'
      },
      {
        route: 'products/nom/epsq-calculator',
        LinkIcon: CalcIcon,
        SelectedLinkIcon: CalculatorFillSvg,
        linkText: 'oxSidebar.oxNom.epsqCalculator'
      },
      {
        route: 'products/nom/mdq-summary',
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.mdqSummary',
        isHidden: ({ featureFlags }) => !featureFlags.tariffRates
      },
      {
        route: 'products/nom/storage-report',
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.storageReport',
        isHidden: ({ featureFlags }) => !featureFlags.tariffRates
      }
    ]
  },
  {
    LinkIcon: ExchangeOutlineSvg,
    SelectedLinkIcon: ExchangeSvg,
    linkText: 'oxSidebar.oxNom.integration',
    isHidden: ({ featureFlags }) => !featureFlags.nomPhaseOne,
    route: '#',
    children: [
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.pipelineSubmission',
        route: 'products/nom/ebb-edi-page'
      },
      {
        route: 'products/nom/processing-log',
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.processingLog'
      }
    ]
  },
  {
    LinkIcon: AgreementSvg,
    SelectedLinkIcon: AgreementSvg,
    isHidden: ({ featureFlags }) => !featureFlags.nomPhaseOne,
    linkText: 'oxSidebar.oxNom.referenceData',
    route: '#',
    children: [
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.counterparty',
        route: 'products/nom/counterparty'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.agency',
        route: 'products/nom/agency'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.crossReference',
        route: 'products/nom/cross-reference'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.location',
        route: 'products/nom/location'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.locationGroup',
        route: 'products/nom/group-location'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.rates',
        route: 'products/nom/rates'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.contractSegments',
        route: 'products/nom/contract-segments'
      },
      {
        LinkIcon: GridSvg,
        SelectedLinkIcon: GridSvg,
        linkText: 'oxSidebar.oxNom.contract',
        route: 'products/nom/contract'
      }
    ]
  },
  {
    LinkIcon: SettingsSvg,
    SelectedLinkIcon: CogSvg,
    linkText: 'oxSidebar.oxNom.columnConfiguration',
    route: 'products/nom/column-configuration',
    isHidden: ({ currentUser }) => currentUser.role?.value !== 'support'
  },
  {
    ...settingsLinkDefaults,
    route: '/products/nom/configurations',
    isHidden: () => true
  }
];
