import { legacy_getColor } from '@core/theme';
import styled from 'styled-components';

/* prettier-ignore */
export const TextDiv = styled.div((props: any) => {
  const { color, fontSize = 14 } = props;
  return {
    color: props.theme.fontColor,
    fontSize: fontSize,
    fontFamily: 'GibsonRegular',
    cursor: 'default',

    '&.--has-color': {
      color: legacy_getColor(color)
    },

    '&.bold': { fontFamily: 'GibsonBold' },

    '&.h1, &.h2, &.h3, &.h4, &.h5, &.h6, &.medium': {
      fontFamily: 'GibsonMedium'
    },

    '&:not(.--has-size)': {
      '&.h1': { fontSize: 32 },
      '&.h2': { fontSize: 24 },
      '&.h3': { fontSize: 18.72 },
      '&.h4': { fontSize: 16 },
      '&.h5': { fontSize: 13.28 },
      '&.h6': { fontSize: 10.72 }
    },

    '&.active': {
      color: '#2b84e3',

      '&:hover:not(.disabled)': {
        color: '#1763B5',
        cursor: 'pointer'
      },

    },

    '&.disabled': { color: '#717171' },
    '&.underline': { textDecoration: 'underline' },
    '&.em': { fontStyle: 'italic' },
    '&.strikethrough': { fontStyle: 'line-through' },
  };
});
