import { CloseOutlineSvg, OxSmSvg } from '@core/images';
import { themes } from '@core/theme';
import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { ButtonProps } from '../OxButton';
import { StyledBanner, StyledBannerText, StyledBannerTitle } from './OxBanner.styles';

export enum BannerType {
  primary = 'primary'
}

export interface BannerProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    React.AriaAttributes {
  bannerType: BannerType;
  title: string;
  description: string;
  children?: JSX.Element | JSX.Element[];
}

export const OxBanner = (props: BannerProps): React.ReactElement<ButtonProps> => {
  const { bannerType, title, description, children, className /* , ...rest */ } = props;
  const [displayBanner, setDisplayBanner] = useState(true);

  const titleElement = title ? (
    <StyledBannerTitle className='ox-banner-title font-medium text-2xl'>{title}</StyledBannerTitle>
  ) : null;
  const descriptionElement = description ? (
    <StyledBannerText className='ox-banner-text font-normal'>{description}</StyledBannerText>
  ) : null;

  const banner = (
    <StyledBanner
      data-testid='OxBanner'
      className={`relative rounded-lg my-7 py-16 pl-14 ox-banner-${bannerType.toString()} ${
        displayBanner ? '' : 'hidden'
      } ${className ? className : ''}`}
    >
      <OxSmSvg
        data-testid='OxLogo'
        className='absolute bottom-0 right-0 pointer-events-none'
        width={478}
        height={218}
      />
      <CloseOutlineSvg
        width={18}
        height={18}
        className='absolute top-4 right-4 ox-banner-icon cursor-pointer'
        onClick={() => {
          setDisplayBanner(!displayBanner);
        }}
      />
      {titleElement}
      {descriptionElement}

      {children}
    </StyledBanner>
  );

  return <ThemeProvider theme={themes.lightTheme}>{banner}</ThemeProvider>;
};
