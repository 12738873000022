import { ColumnApi, SortModelItem } from 'ag-grid-community';

export const columnStateToSortModel = (columnApi: ColumnApi) => {
  return columnApi
    .getColumnState()
    .filter((s) => {
      return s.sort != null;
    })
    .map((s) => {
      const sortModelItem: SortModelItem = {
        colId: s.colId,
        sort: s.sort || 'asc'
      };
      return sortModelItem;
    });
};
