import { client } from '@core/rest-client';
import { IStaticData } from '@core/typings';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IStaticDataRepository } from 'shared/api/interfaces/IStaticDataRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';

export enum StaticDataString {
  averagingMethods = 'averagingMethods',
  chargeIndicators = 'chargeIndicators',
  chargeTypes = 'chargeTypes',
  currencies = 'currencies',
  deliveryTypes = 'deliveryTypes',
  flowDirections = 'flowDirections',
  locationCategories = 'locationCategories',
  locationCountries = 'locationCountries',
  locationStates = 'locationStates',
  locationTypes = 'locationTypes',
  masterContractTypes = 'masterContractTypes',
  periodicals = 'periodicals',
  pricingCalendars = 'pricingCalendars',
  rateTypes = 'rateTypes',
  serviceCodes = 'serviceCodes',
  tradeTypes = 'tradeTypes',
  unitOfMeasures = 'unitOfMeasures'
}

@injectable()
export class StaticDataRepository implements IStaticDataRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/static-data/');

  async getAllAveragingMethods(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.averagingMethods)).data;
  }

  async getAllChargeIndicators(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.chargeIndicators)).data;
  }

  async getAllChargeTypes(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.chargeTypes)).data;
  }

  async getAllCurrencies(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.currencies)).data;
  }

  async getAllDeliveryTypes(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.deliveryTypes)).data;
  }

  async getAllFlowDirections(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.flowDirections)).data;
  }

  async getAllLocationCategories(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.locationCategories)).data;
  }

  async getAllLocationCountries(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.locationCountries)).data;
  }

  async getAllLocationStates(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.locationStates)).data;
  }

  async getAllLocationTypes(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.locationTypes)).data;
  }

  async getAllMasterContractTypes(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.masterContractTypes)).data;
  }

  async getAllPeriodicals(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.periodicals)).data;
  }

  async getAllPricingCalendars(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.pricingCalendars)).data;
  }

  async getAllRateTypes(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.rateTypes)).data;
  }

  async getAllServiceCodes(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.serviceCodes)).data;
  }

  async getAllTradeTypes(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.tradeTypes)).data;
  }

  async getAllUnitOfMeasures(): Promise<AxiosResponse<IStaticData>> {
    return (await client.get(this.apiUrl + StaticDataString.unitOfMeasures)).data;
  }
}
