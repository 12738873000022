import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ILocationGroupRepository } from 'shared/api/interfaces/ILocationGroupRepository';
import { ILocationGroup, ILocationGroupSpec } from 'shared/models/LocationGroup';
import { IBaseThunk } from './base.thunks';

export class LocationGroupThunks implements IBaseThunk<ILocationGroup> {
  private locationGroupRepository!: ILocationGroupRepository;

  constructor(_locationGroupRepository: ILocationGroupRepository) {
    this.locationGroupRepository = _locationGroupRepository;

    if (this.locationGroupRepository === null) {
      throw new Error('locationGroupRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'location-groups';

  add(): AsyncThunk<HttpResponse<ILocationGroup>, ILocationGroup, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addLocationGroup`;

    return createAsyncThunk(action, (rate: ILocationGroup) =>
      this.locationGroupRepository.add(rate)
    );
  }

  update(): AsyncThunk<HttpResponse<ILocationGroup>, ILocationGroup, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateLocationGroup`;

    return createAsyncThunk(action, async (locationGroup: ILocationGroup, { rejectWithValue }) => {
      try {
        return await this.locationGroupRepository.update(locationGroup);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<ILocationGroup, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteLocationGroup`;

    return createAsyncThunk(action, (id: string) => this.locationGroupRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<ILocationGroup, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteLocationGroups`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.locationGroupRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<ILocationGroup[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchLocationGroups`;

    return createAsyncThunk(action, () => this.locationGroupRepository.getAll());
  }

  getSpecs(): AsyncThunk<AxiosResponse<ILocationGroupSpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchLocationGroupSpecs`;

    return createAsyncThunk(action, () => this.locationGroupRepository.getSpecs());
  }

  getById(): AsyncThunk<AxiosResponse<ILocationGroup, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchLocationGroupById`;

    return createAsyncThunk(action, (id: string) => this.locationGroupRepository.getById(id));
  }
}
