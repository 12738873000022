import { Collapse, Text } from '@core/components';
import { usePreviousValue, useValueMemo } from '@core/hooks';
import { useIsRouteHiddenParams } from '@core/routing';
import { useSidebar } from '@core/sidebar';
import { cn } from '@core/util';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsRouteActive } from '../hooks';
import { PopulatedLinkInfo, ProductLinkConfig } from '../OxSidebar.types';
import { PageLink } from './PageLink';

export type ProductMenuProps = ProductLinkConfig & {
  className: string;
};

export function ProductMenu(props: ProductMenuProps) {
  const { id, className, items, matchParams, title, route, Logomark } = props;
  const { preventNavigation, setShowPreventNavigationModal, setRunRouteAction } = useSidebar();

  const isActive = useIsRouteActive(matchParams);
  const [isOpen, setIsOpen] = useState(isActive);
  const navigate = useNavigate();
  const wasActive = usePreviousValue(isActive);
  useEffect(() => {
    if (wasActive !== isActive) setIsOpen(isActive);
  }, [isActive]);
  const isRouteHiddenParams = useIsRouteHiddenParams();

  const renderMenuItem = useValueMemo(
    () =>
      function Link(item: PopulatedLinkInfo, key: number) {
        return <PageLink {...{ ...item, key, isRouteHiddenParams, preventNavigation }} />;
      },
    [_.omitBy(isRouteHiddenParams, _.isFunction), preventNavigation]
  );

  const handlePreventNavigation = () => {
    if (preventNavigation) {
      setShowPreventNavigationModal(true);
      setRunRouteAction({ action: () => navigate(route) });
    } else {
      navigate(route);
    }
  };

  return (
    <div className={cn('product-menu-wpr', className, { '--is-active': isActive })}>
      <div className='product-menu-hdr-wpr'>
        <div className='title-wpr' data-testid={id}>
          {Logomark ? (
            <Logomark height={25} onClick={handlePreventNavigation} />
          ) : (
            <Text onClick={handlePreventNavigation} m={isActive} className='title' t={title} />
          )}
        </div>
      </div>
      <Collapse {...{ isOpen }} springConfigType='default'>
        <ul className='link-menu'>{_.map(items, renderMenuItem)}</ul>
      </Collapse>
    </div>
  );
}
