import styled from 'styled-components';

export const StyledEmptyTitle = styled.h1`
  font-family: 'GibsonMedium';
  color: ${(props) => props.theme.referenceDataTitle};
`;

export const StyledEmptyText = styled.h1`
  color: ${(props) => props.theme.referenceDataText};
`;
