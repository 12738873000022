import { GridFieldsContext } from '@core/grid/Grid.contexts';
import { createStandardAggFuncs } from '@core/grid/lib';
import { useGetSetState, useValueMemo } from '@core/hooks';
import { ComposedSfc } from '@core/typings';
import { isDev } from '@core/util';
import { IAggFunc } from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  GridFieldsCV,
  GridView,
  GridViewConfig,
  WithGridFieldsConfig,
  WithGridFieldsLib,
  WithGridFieldsState
} from '../../typings';
import { createGridView, standardColumnTypes, standardDataTypeDefinitions } from './lib';

const fallbackState: WithGridFieldsState = {
  agGridProps: {},
  dataPathMap: {},
  fieldConfigMap: {},
  columnGroupMap: {},
  activeViewKey: 'default',
  gridViewMap: {}
};

export function withGridFields<TData extends object = any, FieldName extends string = any>(
  _conf: WithGridFieldsConfig<TData, FieldName>
) {
  type State = WithGridFieldsState<TData, FieldName>;
  type ViewConf = GridViewConfig<TData, FieldName>;
  type View = GridView<TData, FieldName>;
  const {
    gridViews: _gridViews = {},
    initialActiveView = 'default',
    agGridProps,
    ...stateConf
  } = _conf;
  const gridViews: ViewConf[] = _.isArray(_gridViews)
    ? _gridViews
    : _.map(_gridViews, (gView, key) => ({
        key,
        ...gView
      }));
  let _defaultActiveKey = initialActiveView;
  let configDefaultGridView: ViewConf | undefined = _.pullAt(
    gridViews,
    _.findIndex(gridViews, { key: _defaultActiveKey })
  )[0];
  if (!configDefaultGridView) {
    configDefaultGridView = _.pullAt(gridViews, [_.findIndex(gridViews, { isInitial: true })])[0];
  }
  if (configDefaultGridView && configDefaultGridView.key !== _defaultActiveKey) {
    _defaultActiveKey = configDefaultGridView.key;
  }

  return function _withGridFields(Composed: ComposedSfc): ComposedSfc {
    return function WithGridFields(props: any): JSX.Element {
      const defaultActiveKey = useMemo(() => _defaultActiveKey, []);
      const stdAggFuncs = useMemo(() => createStandardAggFuncs<TData, FieldName>(), []);

      const initialState: State = useMemo<State>(
        () => ({
          ..._.defaultsDeep({ activeViewKey: defaultActiveKey }, stateConf, fallbackState),
          agGridProps: {
            ...agGridProps,
            aggFuncs: {
              ...stdAggFuncs,
              ...agGridProps?.aggFuncs
            } as Record<string, IAggFunc<TData>>,
            columnTypes: {
              ...standardColumnTypes,
              ...agGridProps?.columnTypes
            },
            dataTypeDefinitions: {
              ...standardDataTypeDefinitions,
              ...agGridProps?.dataTypeDefinitions
            },
            /* TODO: clean this up */
            defaultColDef: {
              ...agGridProps?.defaultColDef,
              filterParams: {
                convertValuesToString: true,
                ...agGridProps?.defaultColDef?.filterParams
              }
            }
          }
        }),
        []
      );
      const [getState, setState] = useGetSetState<State>(initialState);
      const createView = useCallback((conf: ViewConf): View => createGridView(conf, getState), []);
      const defaultGridView = useMemo(
        () =>
          createView({
            ...configDefaultGridView,
            key: defaultActiveKey
          }),
        []
      );
      const ActiveGridView = useRef<View>(defaultGridView);
      const getActiveView = useCallback<() => View>(() => ActiveGridView.current, []);

      useEffect(() => {
        const gridViewMap = _.reduce<ViewConf, State['gridViewMap']>(
          gridViews,
          (memo, gridViewConf: ViewConf) => {
            /* ignore dev-only views in non-dev env */
            if (gridViewConf.isDevOnly && !isDev) return memo;
            /* create proper GridView from config; add to map */
            memo[gridViewConf.key] = createView(gridViewConf);
            return memo;
          },
          { [defaultActiveKey]: defaultGridView }
        );
        setState({ gridViewMap });
      }, []);

      const lib = useMemo<WithGridFieldsLib<TData, FieldName>>(
        () => ({
          createGridView: (conf) => {
            const nView = createView(conf);
            setState((pState) => ({ gridViewMap: { ...pState.gridViewMap, [conf.key]: nView } }));
            return nView;
          },
          getField: (name) => _.get(getState(), ['fieldConfigMap', name]) ?? null,
          getDataPath: (name) => _.get(getState(), ['dataPathMap', name]) ?? null,
          setActiveView: (activeViewKey: string) => {
            const nActiveView = _.get(getState(), ['gridViewMap', activeViewKey]);
            if (nActiveView) {
              ActiveGridView.current = nActiveView;
              setState({ activeViewKey });
            }
            return nActiveView ?? null;
          }
        }),
        []
      );

      const value = useValueMemo<GridFieldsCV<TData, FieldName>>(() => {
        const state = getState();
        const activeView = getActiveView();
        const agGridProps: AgGridReactProps<TData> = {
          ...state.agGridProps,
          columnDefs: activeView.colDefs
        };

        if (activeView.defaultColDef || state.agGridProps.defaultColDef) {
          agGridProps.defaultColDef = _.defaults(
            {},
            activeView.defaultColDef,
            state.agGridProps.defaultColDef
          );
        }
        return {
          ...state,
          ...lib,
          activeView,
          agGridProps
        };
      }, [_.omit(getState(), ['agGridProps'])]);

      return (
        <GridFieldsContext.Provider value={value}>
          <Composed {...props} />
        </GridFieldsContext.Provider>
      );
    };
  };
}
