import { useDidUpdate, useMediatedState, useValueMemo } from '@core/hooks';

import { useStyles } from '@core/theme';
import { Nullable } from '@core/typings';
import _ from 'lodash';
import { forwardRef, useCallback, useMemo } from 'react';
import ReactSelect, {
  ControlProps,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  mergeStyles,
  Theme as SelectTheme
} from 'react-select';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable';
import { Control as Ctrl } from './components';
import { getStyles } from './Select.style';
import { FormattedSelectOption, SelectProps, StandardSelectOption } from './Select.types';
import { createOptionsFormatter } from './util';
const animatedComponents = makeAnimated();

/**
 * Select
 *
 * flexible, *unopinionated* dropdown component. A few rational defaults have
 * been set but can all be overridden. By default, this is an uncontrolled component.
 *
 * The recommended implementation strategy is to not provide a `value` prop and
 * instead pass `onChange` as a change callback. To convert to a controlled
 * component, pass `value: FormattedSelectOption` and `onChange`.
 *
 * docs: https://react-select.com/props
 */
export const Select = forwardRef(function _Select<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: SelectProps<Option, IsMulti, Group>, ref?: any): JSX.Element {
  type CtrlProps = ControlProps<Option, IsMulti, Group>;

  const {
    className = '',
    color,
    components,
    defaultOption,
    defaultValue: _defaultValue,
    isCreatable,
    isMulti,
    label,
    labelKey = 'label',
    labelProps,
    labelType,
    minWidth,
    onChange,
    options: _options,
    placeholder,
    styles: _styles,
    theme: themeOverrides,
    value: _value,
    valueKey = 'value',
    sortOptions,
    ...rest
  } = props;
  const { styles, defaultTheme } = useStyles(getStyles, { color, minWidth });

  const formatOptions = useValueMemo(
    () => createOptionsFormatter({ labelKey, valueKey, sortOptions }),
    [labelKey, valueKey, sortOptions]
  );
  const __initOpts = useMemo<FormattedSelectOption[]>(() => formatOptions(_options), []);
  const [options, setOptions] = useMediatedState(formatOptions, __initOpts);

  useDidUpdate(() => {
    setOptions(_options);
  }, [_options]);

  const getOptionLabel = useCallback<GetOptionLabel<Option>>(
    (option: Option) => {
      if (isCreatable && (option as Option & { __isNew__: boolean }).__isNew__) {
        return (option as StandardSelectOption).label as string;
      }
      return _.toString(_.get(option, labelKey));
    },
    [labelKey]
  );
  const getOptionValue = useCallback<GetOptionValue<Option>>(
    (option: Nullable<Option>) => {
      if (_.isNull(option)) return null;
      if (isCreatable && (option as Option & { __isNew__: boolean }).__isNew__) {
        return (option as StandardSelectOption).value as Nullable;
      }
      return _.get(option, valueKey);
    },
    [valueKey]
  );

  const getOptionFromValue = useCallback(
    (value: any) => {
      const getter = (v: any) => {
        if (_.isPlainObject(v)) {
          return v;
        }

        const option = _.find(options, (opt) => _.get(opt, valueKey) === v);
        return option;
      };

      return isMulti ? _.map(value, getter) : getter(value) || null;
    },
    [valueKey, options, isMulti]
  );

  const defaultValue: any = useMemo(() => {
    if (typeof defaultOption !== 'undefined') return defaultOption;
    return getOptionFromValue(_defaultValue);
  }, []);

  const theme = useValueMemo(
    () => (theme: SelectTheme) => _.defaultsDeep({}, defaultTheme, themeOverrides, theme),
    [themeOverrides, defaultTheme]
  );

  const value = useValueMemo(() => {
    if (_.isUndefined(_value)) return undefined;
    return getOptionFromValue(_value);
  }, [_value, options]);

  const Control = useValueMemo(
    () =>
      function RenderedControl(p: CtrlProps) {
        return <Ctrl {...{ ...p, label, labelProps, labelType }} />;
      },
    [label, labelType, labelProps]
  );

  const El = useValueMemo(() => (isCreatable ? Creatable : ReactSelect), [isCreatable]);

  return (
    <El
      {...{
        defaultValue,
        getOptionLabel,
        getOptionValue,
        isMulti,
        options,
        ref,
        theme,
        value
      }}
      hideSelectedOptions={false}
      styles={mergeStyles(styles, _styles)}
      isClearable
      onChange={(selected: any, action) => {
        const nVal = isMulti
          ? (_.map(selected, (opt) => getOptionValue(opt) || null) as (string | null)[])
          : ((getOptionValue(selected) || null) as string | null);

        onChange?.(nVal, selected, action);
      }}
      /* NOTE: overriding this will remove all existing styling */
      classNamePrefix='_'
      {...rest}
      /* NOTE: SelectContainer can be safely overridden; it just provides a testid during testing */
      components={{
        ...(isMulti ? animatedComponents : {}),
        Control,
        ...components
      }}
      className={`core-select ${className}`}
      placeholder={labelType === 'inline' ? false : placeholder}
    />
  );
}) as <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectProps<Option, IsMulti, Group> & { ref?: any }
) => JSX.Element;

/*
 * Future support roadmap:
 * [x] if `defaultValue` is passed, the option with that value should be found and passed
 * [x] test isMulti support
 * [x] implement a more thorough/extensible style solution (https://react-select.com/styles)
 * [x] typings!
 * [ ] add option group support
 */
