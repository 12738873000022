import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IBrokerRepository } from 'shared/api/interfaces/IBrokerRepository';
import { IBroker, IBrokerSpec, IBrokerSpecRequest } from 'shared/models/Broker';
import { IBaseThunk } from './base.thunks';

export class BrokerThunks implements IBaseThunk<IBroker> {
  private brokerRepository!: IBrokerRepository;

  constructor(_brokerRepository: IBrokerRepository) {
    this.brokerRepository = _brokerRepository;

    if (this.brokerRepository === null) {
      throw new Error('brokerRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'brokers';

  add(): AsyncThunk<HttpResponse<IBroker>, IBroker, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addBroker`;

    return createAsyncThunk(action, async (broker: IBroker, { rejectWithValue }) => {
      try {
        return await this.brokerRepository.add(broker);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IBroker>, IBroker, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateBroker`;

    return createAsyncThunk(action, async (broker: IBroker, { rejectWithValue }) => {
      try {
        return await this.brokerRepository.update(broker);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IBroker, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteBroker`;

    return createAsyncThunk(action, (id: string) => this.brokerRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<IBroker, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteBroker`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.brokerRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<IBroker[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchBrokers`;

    return createAsyncThunk(action, () => this.brokerRepository.getAll());
  }

  getById(): AsyncThunk<AxiosResponse<IBroker, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchBrokerById`;

    return createAsyncThunk(action, (id: string) => this.brokerRepository.getById(id));
  }

  getSpecs(): AsyncThunk<AxiosResponse<IBrokerSpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchBrokerSpecs`;

    return createAsyncThunk(action, () => this.brokerRepository.getSpecs());
  }

  forceUpdate(): AsyncThunk<HttpResponse<IBroker>, IBroker, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/forceUpdateBroker`;

    return createAsyncThunk(action, async (broker: IBroker, { rejectWithValue }) => {
      try {
        return await this.brokerRepository.forceUpdate(broker);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  createSpecRequest(): AsyncThunk<
    HttpResponse<IBrokerSpecRequest>,
    IBrokerSpecRequest,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/createBrokerSpecRequest`;

    return createAsyncThunk(
      action,
      async (specRequest: IBrokerSpecRequest, { rejectWithValue }) => {
        try {
          return await this.brokerRepository.createSpecRequest(specRequest);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }
}
