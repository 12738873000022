import { addNotification } from '@core/notification';
import { DealStatus, InfiniteFilterParams } from '@core/typings';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { searchDeals, searchRejectedDeals } from 'redux/slices/deal/deal.slice';
import { RootState } from 'redux/store';
import { ICounterparty } from 'shared/models/Counterparty';
import { IPipeline } from 'shared/models/Pipeline';

export const recordLimit = 5000; // Default limit, can be overrridden by dealSearchLimit feature flag

export const webSocketThrottleDuration = 300000; // set to 5 minutes

export const initialDateRange = {
  startDate: dayjs().subtract(7, 'day').format('MM/DD/YYYY'),
  endDate: dayjs().format('MM/DD/YYYY')
};

interface dealFilters {
  selectedCounterparties?: ICounterparty[];
  selectedPipelines?: IPipeline[];
  dateFilterRange: {
    startDate: string;
    endDate: string;
  };
  selectedLegalEntities?: ICounterparty[];
  dealStatus: string;
  dealSearchLimit: number;
}

export const setDealStatus = (type: string) => {
  switch (type) {
    case DealStatus.Unpaired:
      return {
        name: DealStatus.Unpaired,
        value: [DealStatus.Outbound, DealStatus.Inbound]
      };
    case DealStatus.Inbound:
      return {
        name: DealStatus.Inbound,
        value: [DealStatus.Inbound]
      };

    case DealStatus.Outbound:
      return {
        name: DealStatus.Outbound,
        value: [DealStatus.Outbound]
      };
    case DealStatus.Paired:
      return { name: DealStatus.Paired, value: [DealStatus.Paired] };

    case DealStatus.PairedException:
      return { name: DealStatus.PairedException, value: [type] };
    case DealStatus.Withdrawn:
      return { name: DealStatus.Withdrawn, value: [type] };
    case DealStatus.Terminated:
      return { name: DealStatus.Terminated, value: [type] };
    case DealStatus.Default:
    default:
      return {
        name: DealStatus.Default,
        value: [
          DealStatus.Outbound,
          DealStatus.Inbound,
          DealStatus.Paired,
          DealStatus.PairedException
        ]
      };
  }
};

export const getDealFilters = ({
  selectedCounterparties = [],
  selectedLegalEntities = [],
  selectedPipelines = [],
  dateFilterRange,
  dealStatus,
  dealSearchLimit
}: dealFilters) => {
  const filters = {
    paging: {
      offset: 0,
      limit: dealSearchLimit
    },
    filters: {
      legalEntity: {
        name: 'legalEntity',
        value: selectedLegalEntities.map((entity) => entity.etrmId)
      },
      pipelines: {
        name: 'pipelines',
        value: selectedPipelines.map((pipeline) => pipeline.etrmId)
      },
      counterparties: {
        name: 'counterparties',
        value: selectedCounterparties.map((counterparty) => counterparty.etrmId)
      },
      status: setDealStatus(dealStatus)
    },
    dateRange: {
      startDate: dateFilterRange.startDate,
      endDate: dateFilterRange.endDate
    }
  };
  return filters;
};

// The below method allows the view to construct the filters, then borrows dispatch and setDeals to speak back to redux.
export const fetchDeals = (
  filters: InfiniteFilterParams,
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
  setDeals: any
) => {
  dispatch(searchDeals(filters)).then((response: any) => {
    if (response.payload?.result) {
      dispatch(setDeals(response.payload?.result));

      const meta = response.payload?.meta;
      if (meta && meta.total > meta.count) {
        addNotification({
          type: 'warning',
          title: 'Deal Limit Exceeded',
          message: `Showing ${meta.count} of ${meta.total} deals.`
        });
      }
    }
  });
};

export const fetchRejectedDeals = (
  filters: InfiniteFilterParams,
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
  setDeals: any
) => {
  dispatch(searchRejectedDeals(filters)).then((response: any) => {
    if (response.payload?.result) {
      dispatch(setDeals(response.payload?.result));

      const meta = response.payload?.meta;
      if (meta && meta.total > meta.count) {
        addNotification({
          type: 'warning',
          title: 'Deal Limit Exceeded',
          message: `Showing ${meta.count} of ${meta.total} deals.`
        });
      }
    }
  });
};
