export enum DataTemplateType {
  broker = 'broker',
  chargeType = 'chargeType',
  counterparty = 'counterparty',
  interconnect = 'interconnect',
  location = 'location',
  locationGroup = 'locationGroup',

  payIndex = 'payIndex',
  pipeline = 'pipeline',
  zone = 'zone',
  rateDefinition = 'rateDefinition',
  rateSchedule = 'rateSchedule',
  transactionType = 'transactionType',
  transportationAgreement = 'transportationAgreement',
  pipelineTransportationAgreement = 'pipelineTransportationAgreement',

  pipelineInvoice = 'pipelineInvoice',

  brokerSpecRequest = 'brokerSpecRequest',
  counterpartySpecRequest = 'counterpartySpecRequest',
  locationSpecRequest = 'locationSpecRequest',
  payIndexSpecRequest = 'payIndexSpecRequest',
  pipelineSpecRequest = 'pipelineSpecRequest',
  zoneSpecRequest = 'zoneSpecRequest'
}

export type DataTemplateRecordType =
  | DataTemplateType.broker
  | DataTemplateType.counterparty
  | DataTemplateType.interconnect
  | DataTemplateType.location
  | DataTemplateType.locationGroup
  | DataTemplateType.payIndex
  | DataTemplateType.pipeline
  | DataTemplateType.zone
  | DataTemplateType.rateDefinition
  | DataTemplateType.rateSchedule
  | DataTemplateType.transactionType
  | DataTemplateType.transportationAgreement
  | DataTemplateType.pipelineInvoice;
