import { ColorRef, StyleGetter } from '@core/theme/typings';
import { ResizeableListTheme } from './ResizableList.types';

type Props = { theme: ResizeableListTheme; color?: ColorRef; headerHeight?: number };

export const css: StyleGetter<Props> = (theme, props) => {
  const {
    colors: { canvas, component },
    colorWheel: { common },
    darken,
    fade,
    getColor
  } = theme;

  const {
    color: _color,
    headerHeight: _hHeight = 57,
    theme: {
      activeColor: _activeColor = _color || 'primary.primary',
      inactiveColor: _inactiveColor = 'text.primary',
      gutter: gtr = 6,
      listHeaderHeight: headerHeight = _hHeight
    }
  } = props;
  const activeClr = getColor(_activeColor);
  const inactiveClr = getColor(_inactiveColor);
  const activeDark = darken(activeClr, 15);
  return {
    wpr: {
      display: 'flex',
      flex: '1 0',
      height: `calc(100%)`,
      border: `2px solid ${component.offset6}`,
      borderRadius: gtr,

      '.list-wpr': {
        display: 'flex',

        '.list-ctr': {
          paddingRight: gtr * 2,
          marginRight: gtr * 2
        },

        '.resize-handle': {
          backgroundColor: component.offset6,
          width: 4
        },

        '.list-hdr': {
          display: 'flex',
          height: headerHeight,
          alignItems: 'center',
          paddingLeft: gtr * 2,
          backgroundColor: canvas.offset3,
          borderBottom: `1.5px solid ${component.offset6}`
        }
      },

      '.detail-wpr': {
        display: 'flex',
        flex: '1 1 auto',
        alignSelf: 'stretch',
        alignItems: 'stretch'
      },

      '.list, .list-resizer': {
        backgroundColor: canvas.offset3
      },

      '.list-itm-wpr': {
        display: 'flex',
        flex: '1 0 100%',
        padding: 0,
        transition: 'all 150ms ease-in-out',

        '.list-itm-ctr': {
          position: 'relative',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          padding: `0 ${gtr * 2}px`,
          transition: 'all 250ms ease-in-out',
          border: `3px solid ${common.clear}`,
          overflowY: 'hidden',

          '.title, .desc': {
            color: inactiveClr
          },

          '&:hover': {
            borderColor: inactiveClr,
            borderRadius: gtr
          }
        },

        '&.odd-row': {
          backgroundColor: canvas.offset6
        },

        '&.--is-active': {
          overflowX: 'visible',
          borderRadius: gtr,
          background: `linear-gradient(
            ${fade(activeClr, 92)},
            ${fade(activeClr, 86)},
            ${fade(activeClr, 82)}
          )`,

          '.title, .desc': {
            color: activeDark
          },

          '.list-itm-ctr': {
            borderColor: activeDark,
            borderRadius: gtr
          }
        }
      }
    }
  };
};
