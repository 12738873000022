import { useAuth0 } from '@auth0/auth0-react';
import { useValueMemo } from '@core/hooks';
import { useQueries, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { UseDataOptions } from '../../typings';
import { getNormalizedQuery } from '../util';
import { formatResponses } from './formatResponses';
import { UseOrchestratedDataReturn } from './useOrchestratedData.types';

type UseOrchestratedDataOptions<RetMap> = {
  [Key in keyof RetMap]: UseDataOptions;
};

export function useOrchestratedData<RetMap extends Record<string, unknown>, Err = Error>(
  conf: UseOrchestratedDataOptions<RetMap>
): UseOrchestratedDataReturn<RetMap, Err> {
  const authState = useAuth0();
  /* NOTE:
   * this seems unnecessarily complex, but it's the only way to guarantee iteratee order,
   * so DON'T CHANGE IT.
   */
  /* prettier-ignore */
  const returnKeys = useValueMemo<(keyof RetMap)[]>(() => _.reduce(
    conf, (memo, v, key) => _.concat(memo, key),
    [] as (keyof RetMap)[]
  ), _.keys(conf));

  /* convert */
  const queries = useValueMemo(
    () =>
      _.reduce(
        conf,
        (memo, val) => {
          return _.concat(memo, getNormalizedQuery(val, authState));
        },
        [] as UseQueryOptions<unknown, Error, unknown, any>[]
      ),
    [conf, authState.isAuthenticated, authState.isLoading]
  );

  const responses: UseQueryResult[] = useQueries({ queries });

  return useValueMemo<UseOrchestratedDataReturn<RetMap, Err>>(
    () => formatResponses<RetMap, Err>(_.cloneDeep([...responses]), returnKeys),
    responses
  );
}
