import { Auth0UserDetails, IOxUser, IOxUserData, UserStatus } from '@core/typings';
import { createSelector, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { formatUserData } from 'redux/dtos/user.dto';
import { GenericPayload } from 'redux/utils/array.slice';
import { IUserRepository } from 'shared/api/interfaces/IUserRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { ICounterparty } from 'shared/models/Counterparty';
import { buildGenericSlice, MergedReducers } from '../../helpers/slice.helper';
import { RootState } from '../../store';
import { UserThunks } from '../../thunks/user.thunks';
import { IFQCounterparty, selectCounterpartySpecMap } from '../counterparty/counterparty.slice';

type InitialStateType = {
  activeUsers: IOxUserData[];
  inactiveUsers: IOxUserData[];
  selectedUser: IOxUserData;
  currentUser: IOxUserData;
  auth0Users: Auth0UserDetails[];
  usersByRole: Record<string, IOxUser[]>;
};

const activeUsers: IOxUserData[] = [];
const inactiveUsers: IOxUserData[] = [];
const selectedUser: IOxUserData = {} as IOxUserData;
const currentUser: IOxUserData = {} as IOxUserData;
const auth0Users: Auth0UserDetails[] = [];
const usersByRole: Record<string, IOxUser[]> = {};

export const initialState: InitialStateType = {
  activeUsers,
  inactiveUsers,
  selectedUser,
  currentUser,
  auth0Users,
  usersByRole
};

const userRepository = container.get<IUserRepository>(SERVICE_KEYS.USERS_REPOSITORY);
const userThunks = new UserThunks(userRepository);

const isUserMatch = (user: IOxUserData, updatedUser: IOxUserData) =>
  user.auth0Id === updatedUser.auth0Id;

const updateAccessState = (state: any, updatedUser: IOxUserData) => {
  if (isUserMatch(state.currentUser, updatedUser)) {
    state.currentUser = { ...state.currentUser, ...updatedUser };
  }

  if (updatedUser.status === UserStatus.Suspended) {
    state.activeUsers = state.activeUsers.filter(
      (user: IOxUserData) => !isUserMatch(user, updatedUser)
    );

    const existingIndex = state.inactiveUsers.findIndex(
      (user: IOxUserData) => user.auth0Id === updatedUser.auth0Id
    );

    if (existingIndex !== -1) {
      const newUser = { ...state.inactiveUsers[existingIndex], ...updatedUser };
      state.inactiveUsers[existingIndex] = newUser;
      state.selectedUser = newUser;
    } else {
      state.inactiveUsers.push(updatedUser);
      state.selectedUser = updatedUser;
    }
  } else {
    state.inactiveUsers = state.inactiveUsers.filter(
      (user: IOxUserData) => !isUserMatch(user, updatedUser)
    );

    const existingIndex = state.activeUsers.findIndex(
      (user: IOxUserData) => user.auth0Id === updatedUser.auth0Id
    );

    if (existingIndex !== -1) {
      const newUser = { ...state.activeUsers[existingIndex], ...updatedUser };
      state.activeUsers[existingIndex] = newUser;
      state.selectedUser = newUser;
    } else {
      state.activeUsers.push(updatedUser);
      state.selectedUser = updatedUser;
    }
  }
};

const setSelectedUserReducer = {
  reducer: (state: any, { payload }: PayloadAction<GenericPayload>) => {
    state.selectedUser = payload;
  },
  prepare: (payload: GenericPayload) => {
    return { payload };
  }
};

const setMeReducer = {
  reducer: (state: any, { payload }: PayloadAction<GenericPayload>) => {
    state.currentUser = payload;
  },
  prepare: (payload: GenericPayload) => {
    return { payload };
  }
};

const mergedReducers: MergedReducers[] = [
  { name: 'setSelectedUser', reducer: setSelectedUserReducer },
  { name: 'setMe', reducer: setMeReducer }
];

const slice = buildGenericSlice<InitialStateType>('access', initialState, '', mergedReducers);

export const fetchUsers = userThunks.getAll();
export const fetchCurrentUser = userThunks.getCurrentUser();
export const fetchAuth0Users = userThunks.getAllAuth0();
export const addUser = userThunks.add();
export const updateUser = userThunks.update();
export const deleteUser = userThunks.delete();
export const getChangeLogs = userThunks.getChangeLogs();
export const getAllUsersForRole = userThunks.getAllForRole();
export const logUserActivity = userThunks.logUserActivity();

export const accessSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchUsers.fulfilled,
      (state: any, { payload }: PayloadAction<IOxUserData[]>) => {
        const users = (payload || []).map(formatUserData);
        state.activeUsers = users.filter(
          (user) => user.status === UserStatus.Pending || user.status === UserStatus.Active
        );
        state.inactiveUsers = users.filter((user) => user.status === UserStatus.Suspended);
      }
    );

    builder.addCase(
      getAllUsersForRole.fulfilled,
      (state: any, { payload }: PayloadAction<Record<string, IOxUser>>) => {
        state.usersByRole = {
          ...state.usersByRole,
          ...payload
        };
      }
    );

    builder.addCase(
      fetchCurrentUser.fulfilled,
      (state: any, { payload }: PayloadAction<{ data: IOxUserData }>) => {
        const user = payload?.data;
        state.currentUser = user;
      }
    );

    builder.addCase(addUser.fulfilled, (state: any, { payload }: GenericPayload) => {
      if (payload.status === 200) {
        const user = formatUserData(payload.data);
        state.activeUsers.push({ ...user, status: UserStatus.Pending });
      }
    });

    builder.addCase(updateUser.fulfilled, (state: any, { payload }: GenericPayload) => {
      if (payload.status === 200) {
        const updatedUser = formatUserData(payload.data);
        updateAccessState(state, updatedUser);
      }
    });

    builder.addCase(deleteUser.fulfilled, (state: any, { payload }: PayloadAction<IOxUserData>) => {
      const { email } = payload;

      state.activeUsers = state.activeUsers.filter((user: IOxUserData) => user.email !== email);
      state.inactiveUsers = state.inactiveUsers.filter((user: IOxUserData) => user.email !== email);
    });

    builder.addCase(
      fetchAuth0Users.fulfilled,
      (state: any, { payload }: PayloadAction<Auth0UserDetails[]>) => {
        const users = payload || [];
        state.auth0Users = users;
      }
    );

    builder.addCase(logUserActivity.rejected, (state: any, error: any) => {
      console.error(`Failed to log user activity: ${JSON.stringify(error)}`);
    });
  }
});

export const getActiveUsers = (state: RootState) => state.access.activeUsers;
export const { setSelectedUser, setMe } = accessSlice.actions;
export const accessReducer = accessSlice.reducer;
export const selectMyLegalEntities = (state: RootState): ICounterparty[] => {
  const myLegalEntityIds = state.access.currentUser.legalEntities ?? [];
  const allLegalEntities = state.legalEntity.legalEntities ?? [];

  return myLegalEntityIds.flatMap((entityId) => {
    return allLegalEntities.find((entity) => entity.etrmId === entityId) ?? [];
  });
};

export const selectMyFQLegalEntities = createSelector(
  selectMyLegalEntities,
  selectCounterpartySpecMap,
  (entities, specMap) => {
    return entities.map<IFQCounterparty>((entity) => {
      return {
        ...entity,
        spec: specMap.get(entity.specId)
      };
    });
  }
);
