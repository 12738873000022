import { themes } from '@core/theme';
import { ThemeProvider } from 'styled-components';
import { StyledTag } from './OxTag.styles';

export enum OxTagColors {
  Gold = 'gold-tag',
  Orange = 'orange-tag',
  Purple = 'purple-tag',
  Green = 'green-tag',
  Blue = 'blue-tag'
}

export enum OxTagText {
  Gold = 'Ox Oracle',
  Orange = 'Ox Pair',
  Purple = 'Ox Nom',
  Green = 'Ox Settle',
  Blue = 'Ox Admin'
}

export interface OxTagProps {
  color: OxTagColors;
  text: OxTagText;
}

export const OxTag = ({ color, text }: OxTagProps) => {
  const tag = (
    <StyledTag
      data-testid='OxTag'
      className={`inline-flex border-none p-2 text-center w-25 m-10px text-sm rounded-xl ${color}`}
    >
      {text}
    </StyledTag>
  );

  // need to account for dark theme
  return <ThemeProvider theme={themes.lightTheme}>{tag}</ThemeProvider>;
};
