import { ReactNode } from 'react';
import { StyledLoader } from './OxLoading.styles';

type LoaderProps = {
  title?: ReactNode;
};

export const OxLoading = (props: LoaderProps) => {
  const { title = 'Loading...' } = props;
  return <StyledLoader className='loading-text'>{title}</StyledLoader>;
};
