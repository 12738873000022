/* NOTE:
 * prettier ignores this file; please be mindful of formatting as you go.
 */
import { Container } from 'inversify';
import { SERVICE_KEYS } from 'shared/api/keys.const';
/* interfaces */
import { IAuthRepository } from 'shared/api/interfaces/IAuthRepository';
import { IBrokerRepository } from 'shared/api/interfaces/IBrokerRepository';
import { IChargeTypeRepository } from 'shared/api/interfaces/IChargeTypeRepository';
import { ICounterpartyRepository } from 'shared/api/interfaces/ICounterpartyRepository';
import { IDealActivityRepository } from 'shared/api/interfaces/IDealActivityRepository';
import { IDealRepository } from 'shared/api/interfaces/IDealRepository';
import { IFeatureFlagRepository } from 'shared/api/interfaces/IFeatureFlagRepository';
import { IInterconnectRepository } from 'shared/api/interfaces/IInterconnectRepository';
import { ILegalEntityRepository } from 'shared/api/interfaces/ILegalEntityRepository';
import { ILocationGroupRepository } from 'shared/api/interfaces/ILocationGroupRepository';
import { ILocationRepository } from 'shared/api/interfaces/ILocationRepository';
import { IPayIndexRepository } from 'shared/api/interfaces/IPayIndexRepository';
import { IPipelineRepository } from 'shared/api/interfaces/IPipelineRepository';
import { IProductRepository } from 'shared/api/interfaces/IProductRepository';
import { IRateScheduleRepository } from 'shared/api/interfaces/IRateScheduleRepository';
import { IRejectedEtrmInvoicesRepository } from 'shared/api/interfaces/IRejectedEtrmInvoicesRepository';
import { IStaticDataRepository } from 'shared/api/interfaces/IStaticDataRepository';
import { ITransportationAgreementRepository } from 'shared/api/interfaces/ITransportationAgreementRepository';
import { IUploadRepository } from 'shared/api/interfaces/IUploadRepository';
import { IUserRepository } from 'shared/api/interfaces/IUserRepository';
import { IZoneRepository } from 'shared/api/interfaces/IZoneRepository';
/* repositories */
import { AuthRepository } from 'shared/api/repositories/AuthRepository/AuthRepository';
import { BrokerRepository } from 'shared/api/repositories/BrokerRepository/BrokerRepository';
import { ChargeTypeRepository } from 'shared/api/repositories/ChargeTypeRepository/ChargeTypeRepository';
import { CounterpartyRepository } from 'shared/api/repositories/CounterpartyRepository/CounterpartyRepository';
import { DealActivityRepository } from 'shared/api/repositories/DealActivityRepository/DealActivityRepository';
import { DealRepository } from 'shared/api/repositories/DealRepository/DealRepository';
import { FeatureFlagRepository } from 'shared/api/repositories/FeatureFlagRepository/FeatureFlagRepository';
import { InterconnectRepository } from 'shared/api/repositories/InterconnectRepository/InterconnectRepository';
import { LegalEntityRepository } from 'shared/api/repositories/LegalEntityRepository/LegalEntityRepository';
import { LocationGroupRepository } from 'shared/api/repositories/LocationGroupRepository/LocationGroupRepository';
import { LocationRepository } from 'shared/api/repositories/LocationRepository/LocationRepository';
import { PayIndexRepository } from 'shared/api/repositories/PayIndexRepository/PayIndexRepository';
import { PipelineRepository } from 'shared/api/repositories/PipelineRepository/PipelineRepository';
import { ProductRepository } from 'shared/api/repositories/ProductRepository/ProductRepository';
import { RateDefinitionRepository } from 'shared/api/repositories/RateDefinitionRepository/RateDefinitionRepository';
import { RateScheduleRepository } from 'shared/api/repositories/RateScheduleRepository/RateScheduleRepository';
import { RejectedEtrmInvoicesRepository } from 'shared/api/repositories/RejectedEtrmInvoicesRepository/RejectedEtrmInvoicesRepository';
import { RoleRepository } from "shared/api/repositories/RoleRepository/RoleRepository";
import { StaticDataRepository } from 'shared/api/repositories/StaticDataRepository/StaticDataRepository';
import { TransportationAgreementRepository } from 'shared/api/repositories/TransportationAgreementRepository/TransportationAgreementRepository';
import { UploadRepository } from 'shared/api/repositories/UploadRepository/UploadRepository';
import { UserRepository } from 'shared/api/repositories/UserRepository/UsersRepository';
import { ZoneRepository } from 'shared/api/repositories/ZoneRepository/ZoneRepository';
import { IResourceRepository } from "./interfaces/IResourceRepository";
import { IRoleRepository } from "./interfaces/IRoleRepository";
import { IRoleResourceRepository } from "./interfaces/IRoleResourceRepository";
import { ResourceRepository } from "./repositories/ResourceRepository/ResourceRepository";
import { RoleResourceRepository } from "./repositories/RoleResourceRepository/RoleResourceRepository";

const _container = new Container();

_container.bind<IAuthRepository>(SERVICE_KEYS.AUTH_REPOSITORY).to(AuthRepository).inSingletonScope();
_container.bind<IChargeTypeRepository>(SERVICE_KEYS.CHARGE_TYPE_REPOSITORY).to(ChargeTypeRepository).inSingletonScope();
_container.bind<IBrokerRepository>(SERVICE_KEYS.BROKER_REPOSITORY).to(BrokerRepository).inSingletonScope();
_container.bind<ICounterpartyRepository>(SERVICE_KEYS.COUNTERPARTY_REPOSITORY).to(CounterpartyRepository).inSingletonScope();
_container.bind<IDealActivityRepository>(SERVICE_KEYS.DEAL_ACTIVITY_REPOSITORY).to(DealActivityRepository).inSingletonScope();
_container.bind<IDealRepository>(SERVICE_KEYS.DEAL_REPOSITORY).to(DealRepository).inSingletonScope();
_container.bind<IFeatureFlagRepository>(SERVICE_KEYS.FEATURE_FLAG_REPOSITORY).to(FeatureFlagRepository).inSingletonScope();
_container.bind<IInterconnectRepository>(SERVICE_KEYS.INTERCONNECT_REPOSITORY).to(InterconnectRepository).inSingletonScope();
_container.bind<ILegalEntityRepository>(SERVICE_KEYS.LEGAL_ENTITY_REPOSITORY).to(LegalEntityRepository).inSingletonScope();
_container.bind<ILocationGroupRepository>(SERVICE_KEYS.LOCATION_GROUP_REPOSITORY).to(LocationGroupRepository).inSingletonScope();
_container.bind<ILocationRepository>(SERVICE_KEYS.LOCATION_REPOSITORY).to(LocationRepository).inSingletonScope();
_container.bind<IPayIndexRepository>(SERVICE_KEYS.PAY_INDEX_TYPE_REPOSITORY).to(PayIndexRepository).inSingletonScope();
_container.bind<IRejectedEtrmInvoicesRepository>(SERVICE_KEYS.REJECTED_ETRM_INVOICES_REPOSITORY).to(RejectedEtrmInvoicesRepository).inSingletonScope();
_container.bind<IPipelineRepository>(SERVICE_KEYS.PIPELINE_REPOSITORY).to(PipelineRepository).inSingletonScope();
_container.bind<IProductRepository>(SERVICE_KEYS.PRODUCT_REPOSITORY).to(ProductRepository).inSingletonScope();
_container.bind<IRateScheduleRepository>(SERVICE_KEYS.RATE_SCHEDULE_REPOSITORY).to(RateScheduleRepository).inSingletonScope();
_container.bind<IStaticDataRepository>(SERVICE_KEYS.STATIC_DATA_REPOSITORY).to(StaticDataRepository).inSingletonScope();
_container
  .bind<ITransportationAgreementRepository>(SERVICE_KEYS.TRANSPORTATION_AGREEMENT_REPOSITORY)
  .to(TransportationAgreementRepository)
  .inSingletonScope();
_container.bind<IUploadRepository>(SERVICE_KEYS.UPLOAD_REPOSITORY).to(UploadRepository).inSingletonScope();
_container.bind<IUserRepository>(SERVICE_KEYS.USERS_REPOSITORY).to(UserRepository).inSingletonScope();
_container.bind<IZoneRepository>(SERVICE_KEYS.ZONE_REPOSITORY).to(ZoneRepository).inSingletonScope();
_container.bind<IRoleRepository>(SERVICE_KEYS.ROLE_REPOSITORY).to(RoleRepository).inSingletonScope();
_container.bind<IResourceRepository>(SERVICE_KEYS.RESOURCE_REPOSITORY).to(ResourceRepository).inSingletonScope();
_container.bind<IRoleResourceRepository>(SERVICE_KEYS.ROLE_RESOURCE_REPOSITORY).to(RoleResourceRepository).inSingletonScope();
_container.bind<RateDefinitionRepository>(SERVICE_KEYS.RATE_DEFINITION_REPOSITORY).to(RateDefinitionRepository).inSingletonScope();

const container = _container;
export { container };
