import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { ILocationGroupRepository } from 'shared/api/interfaces/ILocationGroupRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { ILocationGroup, ILocationGroupSpec } from 'shared/models/LocationGroup';

// All the below are placeholders for api integration

@injectable()
export class LocationGroupRepository implements ILocationGroupRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/location-groups/');

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, { params: { ids: ids } });
  }

  async filter(startDate: Date, endDate: Date): Promise<AxiosResponse<ILocationGroup[]>> {
    return await client.get(this.apiUrl + 'filter/' + { ...startDate, ...endDate });
  }

  async add(locationGroup: ILocationGroup): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...locationGroup });
  }

  async update(locationGroup: ILocationGroup): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...locationGroup });
  }

  async getAll(): Promise<AxiosResponse<ILocationGroup[]>> {
    return await client.get(this.apiUrl);
  }

  async getSpecs(): Promise<AxiosResponse<ILocationGroupSpec[]>> {
    return await client.get(this.apiUrl + 'spec-location-groups');
  }

  async getById(id: string): Promise<AxiosResponse<ILocationGroup>> {
    return await client.get(this.apiUrl + id);
  }
}
