import { AggValueAggFunc } from '@core/grid/typings';
import _ from 'lodash';
import { createAggFunc } from './AggValue';
import { AggValuePreset, aggValuePresetMap } from './aggValuePresetMap';

export type StdAggFuncs<
  TData extends object = any,
  FieldName extends string = string,
  TValue = any,
  TResult = TValue
> = Record<AggValuePreset, AggValueAggFunc<TData, FieldName, TValue, TResult>>;

export function createStandardAggFuncs<
  TData extends object = any,
  FieldName extends string = string,
  TValue = any,
  TResult = TValue
>(): StdAggFuncs<TData, FieldName, TValue, TResult> {
  const func = createAggFunc<TData, FieldName, TValue, TResult>();
  return _.reduce<AggValuePreset, StdAggFuncs<TData, FieldName, TValue, TResult>>(
    _.keys(aggValuePresetMap),
    (memo, key) => {
      memo[key] = func;
      return memo;
    },
    {}
  );
}
