import styled, { css } from 'styled-components';

interface StyledDatePickerProps {
  bordersEnabled?: boolean;
}

export const StyledDatePicker = styled.div<StyledDatePickerProps>`
  ${({ bordersEnabled }) => css`
    border: ${bordersEnabled ? '1px solid hsl(0, 0%, 80%)' : 'none'};
    border-radius: 4px;
    .datepicker {
      font-size: 8px !important;
    }
  `}
`;
