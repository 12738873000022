import { useUpdate } from '@core/hooks';
import { JsonMap } from '@core/typings';
import { useEffect, useRef } from 'react';
import { IUserConfig } from '../typings';
import { UserConfigs } from '../UserConfigs';

export function useConfig<TVal extends JsonMap = JsonMap>(key: string): IUserConfig<TVal> {
  const update = useUpdate();
  const Conf = UserConfigs.findByKey<TVal>(key);
  if (!Conf) {
    throw `Config "${key}" has not been registered`;
  }
  const ref = useRef<IUserConfig<TVal>>(Conf);

  useEffect(() => {
    if (Conf) {
      const handleChange = () => {
        ref.current = Conf;
        update();
      };
      Conf.on('change', handleChange);
      return () => {
        Conf.off('change', handleChange);
      };
    }
  }, [key]);

  return ref.current;
}
