import { createSelector, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RootState } from 'redux/store';
import { ZoneThunks } from 'redux/thunks/zone.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { IZoneRepository } from 'shared/api/interfaces/IZoneRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { DeleteResponse } from 'shared/models/DeleteResponse';
import { IPipeline, IPipelineSpec } from 'shared/models/Pipeline';
import { IZone, IZoneSpec } from 'shared/models/Zone';
import { selectPipelineMap, selectPipelineSpecMap } from '../pipeline/pipeline.slice';

type InitialStateType = {
  count?: number;
  changeId: number;
  zones: IZone[];
  zoneSpecs: IZoneSpec[];
};

const count = undefined;
const changeId = 0;
const zones: IZone[] = [];
const zoneSpecs: IZoneSpec[] = [];

export const initialState: InitialStateType = {
  count,
  changeId,
  zones,
  zoneSpecs
};

const zoneRepository = container.get<IZoneRepository>(SERVICE_KEYS.ZONE_REPOSITORY);
const zoneThunks = new ZoneThunks(zoneRepository);
export const getAllZones = zoneThunks.getAll();
export const addZone = zoneThunks.add();
export const updateZone = zoneThunks.update();
export const deleteAllZone = zoneThunks.deleteAll();
export const deleteZone = zoneThunks.delete();
export const getAllZoneSpecs = zoneThunks.getSpecs();
export const createSpecRequest = zoneThunks.createSpecRequest();

const slice = buildGenericSlice<InitialStateType>('zone', initialState);
export const zoneSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllZones.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data?.length) {
          const { data } = payload;
          state.zones = data;
          state.changeId++;
          state.count = data.length;
        }
      }
    );
    builder.addCase(addZone.fulfilled, (state: InitialStateType, { payload }: GenericPayload) => {
      if (payload) {
        if (payload) {
          const zones = [...state.zones, payload.data];
          state.zones = zones;
          state.changeId++;
          state.count = zones.length;
        }
      }
    });
    builder.addCase(
      getAllZoneSpecs.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data && payload.data.length > 0) {
          state.zoneSpecs = payload.data as IZoneSpec[];
          state.changeId++;
        }
      }
    );
    builder.addCase(
      deleteAllZone.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload.status === 200) {
          const { data } = payload;
          state.changeId++;
          state.zones = state.zones.filter(
            (zone: IZone) =>
              !data.find((response: DeleteResponse) => response.id === zone.etrmId)?.isSuccess
          );
        }
      }
    );
  }
});

export const { removeZone } = zoneSlice.actions;
export const zoneReducer = zoneSlice.reducer;

export const selectZones = (state: RootState) => state.zone.zones;
export const selectZoneSpecs = (state: RootState) => state.zone.zoneSpecs;

export const selectZoneMap = createSelectorMap(selectZones, 'etrmId');
export const selectZoneSpecMap = createSelectorMap(selectZoneSpecs, 'specId');

export interface IFQZone extends IZone {
  spec?: IZoneSpec;
  pipeline?: IPipeline;
}

export interface IFQZoneSpec extends IZoneSpec {
  specPipeline?: IPipelineSpec;
}

export const selectFQZoneRefs = createSelector(
  [selectZones, selectZoneSpecMap, selectPipelineMap],
  (zones, zoneSpecMap, pipelineMap) => {
    return zones.map<IFQZone>((zone) => {
      return {
        ...zone,
        spec: zoneSpecMap.get(zone.specId),
        pipeline: pipelineMap.get(zone.etrmPipelineId)
      };
    });
  }
);

export const selectFQZoneSpecRefs = createSelector(
  [selectZoneSpecs, selectPipelineSpecMap],
  (zones, pipelineSpecMap) => {
    return zones.map<IFQZoneSpec>((zoneSpec) => {
      return {
        ...zoneSpec,
        specPipeline: pipelineSpecMap.get(zoneSpec.specPipelineId)
      };
    });
  }
);
