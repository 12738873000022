import { createContext } from 'react';

export type SidebarState = {
  isOpen: boolean;
  preventNavigation: boolean;
  runRouteAction: { action: () => void };
  showPreventNavigationModal: boolean;
};

export type SidebarLib = {
  close: () => void;
  open: () => void;
  setIsOpen: (v: boolean) => void;
  toggle: () => void;
  setPreventNavigation: (v: boolean) => void;
  setRunRouteAction: (v: any) => void;
  setShowPreventNavigationModal: (v: boolean) => void;
};

export type SidebarCV = SidebarState & SidebarLib;

export const SidebarContext = createContext<SidebarCV>({} as SidebarCV);
