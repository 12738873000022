import { useGetSetState } from '@core/hooks';
import { QueryClientProvider } from '@tanstack/react-query';
import _ from 'lodash';
import { ReactNode, useMemo } from 'react';
import { DataManagerContext } from '../DataManager.context';
import { DevTools } from '../DevTools';
import { queryClient } from '../queryClient';
import { DataManagerLib, DataManagerState } from '../typings';

const hasDevTools = _.includes(window.location.origin, 'localtest');

const initialDataMgrState: DataManagerState = {
  devToolsIsOpen: false
};

export function DataProvider({ children }: { children?: ReactNode }): JSX.Element {
  const [getState, setState] = useGetSetState<DataManagerState>(initialDataMgrState);

  const ctxValue = useMemo(
    (): DataManagerLib => ({
      toggleDevTools: () => {
        setState((prev) => ({ devToolsIsOpen: !prev.devToolsIsOpen }));
      },
      closeDevTools: () => {
        setState({ devToolsIsOpen: false });
      },
      openDevTools: () => {
        setState({ devToolsIsOpen: true });
      },
      getState,
      hasDevTools
    }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <DataManagerContext.Provider value={ctxValue}>
        {children}
        {hasDevTools && <DevTools />}
      </DataManagerContext.Provider>
    </QueryClientProvider>
  );
}
