// import { COLORS, legacy_getColor, legacy_getTextColor, legacy_mods } from '@core/theme';
import { CSSGetter } from '@core/theme/typings';
import _ from 'lodash';
import { BtnProps } from './Btn.types';

function calcWithBorder(val: any, borderWidth = 1, multiplier = 2) {
  return `calc(\
    ${val}${_.isFinite(val) ? 'px' : ''} - \
    (${borderWidth}${_.isFinite(borderWidth) ? 'px' : ''} * \
    ${multiplier})\
  )`;
}

export const css: CSSGetter<BtnProps & { hasIcon: boolean; hasLabel: boolean }> = (
  theme,
  props
) => {
  const {
    block,
    borderRadius,
    color: clr,
    dense,
    disabled,
    hasIcon,
    hasLabel,
    height: _height,
    iconRight,
    inputGroup,
    label,
    mini,
    size,
    styles,
    transitionDuration,
    transitionType
  } = props;
  const {
    colors: { canvas, text },
    fade,
    darken,
    getColor,
    getTextColor,
    lighten,
    saturate,
    customColors: { breadcrumbActive }
  } = theme;

  let {
    /* in dark mode, buttons should default to bordered */
    bordered,
    borderWidth = inputGroup ? 1 : 1
  } = props;

  if (_.isUndefined(bordered) && !hasLabel) bordered = true;

  let color = getColor(clr || 'primary');
  if (disabled) color = bordered ? text.disabled : canvas.disabled;
  const elColor = getTextColor(darken(color, 5), {});

  const borderColor = disabled ? canvas.disabled : color;
  let backgroundColor = color;
  let labelColor = elColor;

  if (bordered) {
    backgroundColor = disabled ? canvas.disabled : 'rgba(0,0,0,0)';
    labelColor = disabled ? text.disabled : color;
  } else if (disabled) {
    /* set label color for non-bordered disabled */
    labelColor = text.disabled;
  }
  let height: string | number | undefined = _height;
  const hasDefinedHeight = _.isFinite(height);
  let padding = 10;
  let minHeight: string | number = height || 32; // NOTE: calculated below to include border
  let minWidth: any = 88;
  let fontSize = 16;
  switch (size) {
    case 'small':
      padding = 6;
      if (!hasDefinedHeight) minHeight = 28;
      minWidth = 50;
      fontSize = 14;
      break;
    case 'large':
      if (!hasDefinedHeight) minHeight = 36;
      break;
    default:
      break;
  }

  switch (true) {
    case !hasLabel:
      minWidth = 20;
      break;
    case mini:
      padding = 0;
      minWidth = 36;
      if (!hasDefinedHeight) minHeight = '100%';
      fontSize = 10;
      break;
    case dense:
      padding = 2;
      minWidth = 40;
      break;
    default:
      break;
  }

  if (!inputGroup) height = minHeight = calcWithBorder(minHeight, borderWidth, 2);
  else {
    height = minHeight = 32;
    borderWidth = 1;
  }

  return {
    btn: {
      alignItems: 'center',
      backgroundColor,
      borderColor,
      borderRadius,
      borderWidth,
      color: labelColor,
      cursor: disabled ? 'not-allowed' : 'pointer',
      display: 'flex',
      height,
      justifyContent: 'center',
      lineHeight: 'normal',
      minHeight,
      minWidth,
      transitionDuration: `${transitionDuration}ms`,
      transitionTimingFunction: transitionType,
      padding: `0 ${padding}px`,
      flexDirection: iconRight ? 'row-reverse' : 'row',
      ...styles?.btn,

      '.btn-label': {
        color: 'inherit',
        cursor: 'inherit',
        width: block ? 'auto' : '100%',
        fontSize,
        display: 'inherit',
        alignItems: 'center',
        justifyContent: 'inherit',
        alignSelf: 'center',
        [`margin${iconRight ? 'Right' : 'Left'}`]: hasIcon && padding / 2,
        fontFamily: 'GibsonRegular',
        fontWeight: '400',
        ...styles?.label
      },

      '.btn-icon': {
        color: 'inherit',
        cursor: 'inherit',
        height: label ? fontSize + 3 : fontSize,
        width: label ? fontSize + 3 : fontSize,
        ...styles?.icn
      },

      '&:hover': disabled
        ? {}
        : {
            backgroundColor: bordered ? fade(labelColor, 84) : darken(backgroundColor, 25),
            borderColor: bordered ? borderColor : backgroundColor
          },

      '&:active': disabled
        ? {}
        : {
            backgroundColor: (backgroundColor = saturate(lighten(backgroundColor, 33), 25)),
            borderColor: bordered ? borderColor : backgroundColor
          },

      // Creating a temporary modifier class for new Black&Gray button styles
      // (this will eventually replace the standard button styles above)
      '&.core-btn-beta': {
        height: 27,
        minHeight: 'auto',
        padding: '16px 13px',
        background: '#363A45',
        // color: text.inverse,
        border: 'none',
        borderRadius: 5,

        '.btn-label': {
          fontFamily: 'Rubik',
          fontSize: 10,
          fontWeight: 700,
          textTransform: 'uppercase',
          letterSpacing: 1
        },

        '.btn-icon': {
          fill: breadcrumbActive,
          color: breadcrumbActive
        },

        '&:hover': {
          background: '#45506d'
        },

        '&[disabled]': {
          opacity: 0.5,

          '&:hover': {
            background: '#363A45'
          }
        },

        '&.core-dropdown-trigger-btn': {
          background: 'none'
        }
      }
    }
  };
};
