import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IInterconnectRepository } from 'shared/api/interfaces/IInterconnectRepository';
import { IInterconnect, IInterconnectSpec } from 'shared/models/Interconnect';
import { IBaseThunk } from './base.thunks';

export class InterconnectThunks implements IBaseThunk<IInterconnect> {
  private interconnectRepository!: IInterconnectRepository;

  constructor(_interconnectRepository: IInterconnectRepository) {
    this.interconnectRepository = _interconnectRepository;

    if (this.interconnectRepository === null) {
      throw new Error('interconnectRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'interconnects';

  add(): AsyncThunk<HttpResponse<IInterconnect>, IInterconnect, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addInterconnect`;

    return createAsyncThunk(action, (rate: IInterconnect) => this.interconnectRepository.add(rate));
  }

  update(): AsyncThunk<HttpResponse<IInterconnect>, IInterconnect, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateInterconnect`;

    return createAsyncThunk(action, async (interconnect: IInterconnect, { rejectWithValue }) => {
      try {
        return await this.interconnectRepository.update(interconnect);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IInterconnect, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteInterconnect`;

    return createAsyncThunk(action, (id: string) => this.interconnectRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<IInterconnect, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteInterconnects`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.interconnectRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<IInterconnect[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchInterconnects`;

    return createAsyncThunk(action, () => this.interconnectRepository.getAll());
  }

  getSpecs(): AsyncThunk<AxiosResponse<IInterconnectSpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchInterconnectSpecs`;

    return createAsyncThunk(action, () => this.interconnectRepository.getSpecs());
  }

  getById(): AsyncThunk<AxiosResponse<IInterconnect, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchInterconnectById`;

    return createAsyncThunk(action, (id: string) => this.interconnectRepository.getById(id));
  }
}
