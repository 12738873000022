import { ClassName } from '@core/typings';
import { cn } from '@core/util';
import { CellClassParams, ColDef, ColGroupDef, HeaderClassParams } from 'ag-grid-community';
import _ from 'lodash';
import { FieldDef } from '../typings';

type ResolveClassNameConfig<TData extends object = any> = {
  additionalClassNames: (
    | ColDef<TData>['cellClass']
    | ColGroupDef<TData>['headerClass']
    | ClassName
  )[];
  align?: FieldDef<TData>['align'];
  params: CellClassParams<TData> | HeaderClassParams<TData>;
  type: 'cell' | 'header';
};

export function resolveClassName<TData extends object = any>({
  additionalClassNames,
  align,
  params,
  type
}: ResolveClassNameConfig<TData>): string {
  return cn(
    align && `ag-${align}-aligned-${type}`,
    ..._.reduce(
      _.flatten(additionalClassNames),
      (memo, val) => {
        if (_.isFunction(val)) memo.push(val(params));
        else memo.push(val as ClassName);
        return memo;
      },
      [] as ClassName[]
    )
  );
}
