import { BadgePercentOutlineSvg, BadgePercentSvg, GitMergeOutlineSvg } from '@core/images';
import { ILinkInfo } from '../OxSidebar.types';

export const getOxOraclePageLinks: () => ILinkInfo[] = () => [
  {
    LinkIcon: GitMergeOutlineSvg,
    SelectedLinkIcon: GitMergeOutlineSvg,
    linkText: 'oxSidebar.oxOracle.staticData',
    route: 'products/oracle/static-data'
  },
  {
    LinkIcon: GitMergeOutlineSvg,
    SelectedLinkIcon: GitMergeOutlineSvg,
    linkText: 'oxSidebar.oxOracle.specReferenceData',
    route: 'products/oracle/spec-reference-data'
  },
  {
    LinkIcon: GitMergeOutlineSvg,
    SelectedLinkIcon: GitMergeOutlineSvg,
    linkText: 'oxSidebar.oxOracle.referenceData',
    route: 'products/oracle/reference-data/stored',
    subRoutes: ['products/oracle/reference-data/upload', 'products/oracle/reference-data/stored']
  },
  {
    LinkIcon: BadgePercentOutlineSvg,
    SelectedLinkIcon: BadgePercentSvg,
    linkText: 'oxSidebar.oxOracle.tariffRates',
    route: 'products/oracle/tariff-rates',
    isHidden: ({ featureFlags }) => !featureFlags.tariffRates
  }
];
