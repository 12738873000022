import { SetterParams } from '@core/typings';
import { handleAddDispatch } from './util';

export const fieldValueSetter = (params: SetterParams, addRow: any, getAllRows: any) => {
  const { newValue, oldValue, column, data } = params;
  if (newValue === oldValue) return;
  const newData = { ...data };
  const col = column.getColId();

  newData[col] = newValue;
  return handleAddDispatch(newData, addRow, getAllRows);
};
