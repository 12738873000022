export const isObjectEmpty = (object: any) => {
  if (!object) return true;
  return Object.keys(object).length === 0;
};

export const filterByValue = (array: any[], lookup: string) => {
  return array.filter(
    (data) =>
      JSON.stringify(data)
        .replace(/("\w+":)/g, '')
        .toLowerCase()
        .indexOf(lookup.toLowerCase()) !== -1
  );
};

// To handle "." notation for fields
export const deepGetter = (t: any, path: string) => path.split('.').reduce((r, k) => r?.[k], t);
