import { themes } from '@core/theme';
import { Status, StatusColor } from '@core/typings';
import { ThemeProvider } from 'styled-components';
import { StyledStatus } from './OxStatus.styles';

export function stringToProcessingStatus(value: string): Status {
  switch (value) {
    case 'Processing':
    case 'Pending':
      return Status.Processing;
    case 'Ox Pair':
      return Status.ProcessAlternative;
    case 'Done':
      return Status.Done;
    case 'Active':
      return Status.Success;
    case 'Uploaded':
      return Status.Uploaded;
    case 'Ox Admin':
      return Status.DoneAlternative;
    case 'Ox Oracle':
    case 'Suspended':
      return Status.Warning;
    case 'Ox Nom':
      return Status.Completing;
    case 'Ox Settle':
      return Status.Success;
    default:
      return Status.Empty;
  }
}

interface Props {
  status: Status;
  text?: string;
  classes?: string;
  color?: StatusColor;
}

export const OxStatus = ({ status, text, classes, color }: Props): JSX.Element => {
  const value = status.valueOf();
  const statusClass = value.toLowerCase();

  const statusContainer = (
    <StyledStatus
      data-testid='OxStatus'
      className={`items-center px-2 h-full inline-flex border-none text-sm rounded
      ${statusClass} ${classes} ${color?.valueOf()}-status`}
    >
      {text || value}
    </StyledStatus>
  );

  return <ThemeProvider theme={themes.lightTheme}>{statusContainer}</ThemeProvider>;
};
