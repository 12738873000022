import {
  CalendarTypeProps,
  DateFormatOptions,
  DatePickerProps,
  DATE_FORMAT_REGEX
} from '@core/typings';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OxDatePicker } from './OxDatePicker';

interface Props extends DatePickerProps {
  label?: string;
  validationLogic?: any;
}

export function OxTypeableDatePicker({
  minDate,
  startDateOverride,
  setCalendarDate,
  label = '',
  validationLogic,
  dateOptions = {
    year: DateFormatOptions.NUMERIC,
    month: DateFormatOptions.TWO_DIGIT,
    day: DateFormatOptions.TWO_DIGIT
  },
  hasBorder = false,
  isEditable = true
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [inputError, setInputError] = useState('');

  const isValid = (value: Date | string) => validationLogic && !validationLogic(value);

  const handleErrors = (value: Date | string, validityStatement?: boolean) => {
    if (validityStatement || isValid(value)) {
      setInputError(t('validation.dateInvalid'));
      return true;
    }
  };

  const handleChange = (evt: ChangeEvent, setSelectedDate: any, setDateString: any) => {
    const { value } = evt.target as HTMLInputElement;
    const date = new Date(value);

    setDateString(value);
    setInputError('');
    setCalendarDate && setCalendarDate(date);

    const errors = handleErrors(value, !DATE_FORMAT_REGEX.test(value));

    if (errors) return;

    setSelectedDate({ startDate: date });
  };

  const typeData: CalendarTypeProps = {
    label,
    handleErrors,
    handleChange,
    inputError
  };

  return (
    <OxDatePicker
      minDate={minDate}
      startDateOverride={startDateOverride}
      setCalendarDate={setCalendarDate}
      dateOptions={dateOptions}
      typeData={typeData}
      isEditable={isEditable}
      hasBorder={hasBorder}
    />
  );
}
