import { UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { getConsolidatedState } from './getConsolidatedState';
import { getResponseDetails } from './getResponseDetails';
import { getResponseMap } from './getResponseMap';
import { UseOrchestratedDataReturn } from './useOrchestratedData.types';

function callAll(val: Record<string, () => void>) {
  return () =>
    _.each(val, (refetch) => {
      refetch();
    });
}

export function formatResponses<QMap, Err>(
  responses: UseQueryResult[],
  returnKeys: (keyof QMap)[]
): UseOrchestratedDataReturn<QMap, Err> {
  const responseMap = getResponseMap<QMap, Err>(responses, returnKeys);
  const responseDetails = getResponseDetails<QMap>(responseMap);
  const consolidated = getConsolidatedState<QMap, Err>(responseDetails);

  return {
    ...consolidated,
    refetchAll: callAll(consolidated.refetch),
    responseMap,
    responseDetails
  };
}
