import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IBrokerRepository } from 'shared/api/interfaces/IBrokerRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IBroker, IBrokerSpec, IBrokerSpecRequest } from 'shared/models/Broker';

@injectable()
export class BrokerRepository implements IBrokerRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/brokers/');

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, { params: { ids: ids } });
  }

  async filter(startDate: Date, endDate: Date): Promise<AxiosResponse<IBroker[]>> {
    return await client.get(this.apiUrl + 'filter/' + { ...startDate, ...endDate });
  }

  async add(broker: IBroker): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...broker });
  }

  async update(broker: IBroker): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...broker });
  }

  async getAll(): Promise<AxiosResponse<IBroker[]>> {
    return await client.get(this.apiUrl);
  }

  async getById(id: string): Promise<AxiosResponse<IBroker>> {
    return await client.get(this.apiUrl + id);
  }

  async getSpecs(): Promise<AxiosResponse<IBrokerSpec[]>> {
    return await client.get(this.apiUrl + 'spec-brokers');
  }

  async forceUpdate(broker: IBroker): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'update-deal', { ...broker });
  }

  async createSpecRequest(specRequest: IBrokerSpecRequest): Promise<AxiosResponse> {
    return await client.post(this.apiUrl + 'spec-request', specRequest);
  }
}
