import { createSlice, current, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RootState } from 'redux/store';
import { DealThunks } from 'redux/thunks/deal.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { IDealRepository } from 'shared/api/interfaces/IDealRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IDeal, IRejectedDeal } from 'shared/models/Deal';

type PotentialMatches = {
  [etrmId: string]: IDeal[];
};

type DealRollups = {
  dealsCount: number;
  pairedCount: number;
  inboundCount: number;
  outboundCount: number;
  terminatedCount: number;
  rejectedCount: number;
  pairExceptionCount: number;
};

type InitialStateType = {
  dealRollups: DealRollups;
  potentialMatches: PotentialMatches;
  count: number;
  errors: string[];
  allDeals: IDeal[];
  rejectedDeals: IRejectedDeal[];
  withdrawnDeals: IDeal[];
  terminatedDeals: IDeal[];
};

const dealRollups = {
  dealsCount: 0,
  pairedCount: 0,
  inboundCount: 0,
  outboundCount: 0,
  terminatedCount: 0,
  rejectedCount: 0,
  pairExceptionCount: 0
};
const potentialMatches: PotentialMatches = {};
const count = 0;
const errors: string[] = [];
const allDeals: IDeal[] = [];
const rejectedDeals: IRejectedDeal[] = [];
const withdrawnDeals: IDeal[] = [];
const terminatedDeals: IDeal[] = [];

export const initialState: InitialStateType = {
  dealRollups,
  potentialMatches,
  count,
  errors,
  allDeals,
  rejectedDeals,
  withdrawnDeals,
  terminatedDeals
};

const dealRepository = container.get<IDealRepository>(SERVICE_KEYS.DEAL_REPOSITORY);
const dealThunks = new DealThunks(dealRepository);

export const rollupDeals = dealThunks.rollup();
export const searchDeals = dealThunks.search();
export const getColumnValues = dealThunks.getColumnValues();
export const getRejectedColumnValues = dealThunks.getRejectedColumnValues();
export const fetchPotentialMatches = dealThunks.getPotentialMatches();
export const searchRejectedDeals = dealThunks.searchRejected();
export const clickToPair = dealThunks.clickToPair();
export const withdraw = dealThunks.withdraw();
export const downloadDeals = dealThunks.downloadDeals();
export const deleteDealReplica = dealThunks.deleteDealReplica();
export const deleteRejectedDeals = dealThunks.deleteRejectedDeals();

export const selectRejectedDeals = (state: RootState) => state.deal.rejectedDeals;

const slice = buildGenericSlice<InitialStateType>('deal', initialState, '', []);

const fulfillSearchState = (state: InitialStateType, { payload }: GenericPayload) => {
  if (payload) {
    const { result, meta } = payload;

    if (result?.length) {
      state.count = result.length;
    } else if (meta) {
      let error = meta?.message || '';
      const isLegalEntitiesError = error.includes('legal entities');

      if (isLegalEntitiesError) {
        error =
          'No legal entities assigned to current user - unable to load deals. Please contact your Company Admin to assign legal entities to your account.';
      }

      if (error && !current(state.errors).includes(error)) {
        state.errors = Array.from(new Set<string>([...state.errors, error]));
      }
      state.count = 0;
    }
  }
};

export const dealSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>({
  ...slice,
  reducers: {
    deleteDealError(state: InitialStateType, action: PayloadAction<string>) {
      const cloneErrors = [...state.errors];
      const index = cloneErrors.indexOf(action.payload);
      if (index > -1) {
        cloneErrors.splice(index, 1);
        state.errors = cloneErrors;
      }
    },
    setAllDeals(state: InitialStateType, action: PayloadAction<IDeal[]>) {
      state.allDeals = action.payload;
    },
    setWithdrawnDeals(state: InitialStateType, action: PayloadAction<IDeal[]>) {
      state.withdrawnDeals = action.payload;
    },
    setTerminatedDeals(state: InitialStateType, action: PayloadAction<IDeal[]>) {
      state.terminatedDeals = action.payload;
    },
    setRejectedDeals(state: InitialStateType, action: PayloadAction<IRejectedDeal[]>) {
      state.rejectedDeals = action.payload;
    }
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      rollupDeals.fulfilled,
      (state: InitialStateType, { payload }: { payload: { result: any; meta: any } }) => {
        if (payload && payload.result) {
          const { result } = payload;
          state.dealRollups = {
            dealsCount: result.defaultCount,
            pairedCount: result.pairedCount,
            inboundCount: result.inboundCount,
            outboundCount: result.outboundCount,
            rejectedCount: result.rejectedCount,
            terminatedCount: result.terminatedCount,
            pairExceptionCount: result.pairExceptionCount
          };
        }
      }
    );

    builder.addCase(downloadDeals.fulfilled, fulfillSearchState);
    builder.addCase(searchDeals.fulfilled, fulfillSearchState);
    builder.addCase(searchRejectedDeals.fulfilled, fulfillSearchState);

    builder.addCase(
      fetchPotentialMatches.fulfilled,
      (state: InitialStateType, { payload, meta }: GenericPayload) => {
        state.potentialMatches[meta.arg.dealId] =
          payload?.data?.filter((deal: IDeal) => deal.isExternal === true) ?? [];
      }
    );
  }
});

export const {
  deleteDealError,
  setAllDeals,
  setRejectedDeals,
  setWithdrawnDeals,
  setTerminatedDeals
} = dealSlice.actions;
export const dealReducer = dealSlice.reducer;
