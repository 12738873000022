import { useEffect, useMemo } from 'react';

import { useMediatedState, useValueMemo } from '@core/hooks';
import { themes } from '@core/theme';
import { useField } from 'formik';
import { isCompositeComponent } from 'react-dom/test-utils';
import { ThemeProvider } from 'styled-components';
import { OxErrorMessage } from '../OxErrorMessage';
import { CalendarInput, CheckboxInput, MaskInput, TextInput } from '../PrimitiveInputs';
import { StyledFieldContainer, StyledFieldLabel } from './OxField.styles';

import { cn } from '@core/util';
import isEq from 'react-fast-compare';

function mediateClassName(...addl: any): string {
  return cn('ox-field', ...addl);
}

export const OxField = (props: any): JSX.Element => {
  const {
    input: Custom,
    showLabel,
    fieldType,
    errors,
    className,
    errorClassName = '',
    containerClassName = '',
    labelClassName = '',
    isInline = false,
    ...rest
  } = props;
  const [field, meta] = useField(rest);

  /* only set the className when necessary */
  const initialClassName = useMemo(() => mediateClassName('field-default', className), []);
  const [inputClassName, setInputClassName] = useMediatedState(mediateClassName, initialClassName);
  useEffect(() => {
    const isErrorEmpty = meta.error?.length ? true : false;
    setInputClassName(`field-${meta.touched && isErrorEmpty ? 'error' : 'default'}`, className);
  }, [meta.touched && meta.error, className, rest.readOnly]);

  const Input = useValueMemo(
    () => {
      switch (true) {
        case typeof Custom === 'function' || isCompositeComponent(Custom):
          return Custom;
        case fieldType === 'phone':
          return MaskInput;
        case fieldType === 'checkbox':
          return CheckboxInput;
        case fieldType === 'calendar':
          return CalendarInput;
        default:
          return TextInput;
      }
      /* only update when these attrs change; use react-fast-compare to compare elements  */
    },
    [typeof Custom, isCompositeComponent(Custom), fieldType],
    isEq
  );

  return (
    <ThemeProvider theme={themes.lightTheme}>
      <StyledFieldContainer
        className={cn('field-container', containerClassName, {
          '--is-inline': isInline
        })}
      >
        {showLabel ? (
          <StyledFieldLabel className={cn('field-label', labelClassName)}>
            {rest.label}
            <Input className={inputClassName} {...{ ...field, ...rest }} />
          </StyledFieldLabel>
        ) : (
          <Input className={inputClassName} {...{ ...field, ...rest }} />
        )}
        {isInline && errors?.touched && errors?.error && (
          <OxErrorMessage error={errors?.error} className={errorClassName} />
        )}
      </StyledFieldContainer>
      {!isInline && errors?.touched && errors?.error && (
        <OxErrorMessage error={errors?.error} className={errorClassName} />
      )}
    </ThemeProvider>
  );
};
