import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IPipelineRepository } from 'shared/api/interfaces/IPipelineRepository';
import { IPipeline, IPipelineSpec, IPipelineSpecRequest } from 'shared/models/Pipeline';
import { IBaseThunk } from './base.thunks';

export class PipelineThunks implements IBaseThunk<IPipeline> {
  private pipelineRepository!: IPipelineRepository;

  constructor(_pipelineRepository: IPipelineRepository) {
    this.pipelineRepository = _pipelineRepository;

    if (this.pipelineRepository === null) {
      throw new Error('pipelineRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'pipelines';

  add(): AsyncThunk<HttpResponse<IPipeline>, IPipeline, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addPipeline`;

    return createAsyncThunk(action, async (pipeline: IPipeline, { rejectWithValue }) => {
      try {
        return await this.pipelineRepository.add(pipeline);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IPipeline>, IPipeline, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updatePipeline`;

    return createAsyncThunk(action, async (pipeline: IPipeline, { rejectWithValue }) => {
      try {
        return await this.pipelineRepository.update(pipeline);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IPipeline, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deletePipeline`;

    return createAsyncThunk(action, (id: string) => this.pipelineRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<IPipeline, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deletePipelines`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.pipelineRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<IPipeline[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchPipelines`;

    return createAsyncThunk(action, () => this.pipelineRepository.getAll());
  }

  getSpecs(): AsyncThunk<AxiosResponse<IPipelineSpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchPipelineSpecs`;

    return createAsyncThunk(action, () => this.pipelineRepository.getSpecs());
  }

  getById(): AsyncThunk<AxiosResponse<IPipeline, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchPipelineById`;

    return createAsyncThunk(action, (id: string) => this.pipelineRepository.getById(id));
  }

  forceUpdate(): AsyncThunk<HttpResponse<IPipeline>, IPipeline, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/forceUpdatePipeline`;

    return createAsyncThunk(action, async (pipeline: IPipeline, { rejectWithValue }) => {
      try {
        return await this.pipelineRepository.forceUpdate(pipeline);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  createSpecRequest(): AsyncThunk<
    AxiosResponse<IPipelineSpecRequest>,
    IPipelineSpecRequest,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/createPipelineSpecRequest`;

    return createAsyncThunk(
      action,
      async (specRequest: IPipelineSpecRequest, { rejectWithValue }) => {
        try {
          return await this.pipelineRepository.createSpecRequest(specRequest);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }
}
