import { css } from './BusinessProfile.style';

import { useValueMemo } from '@core/hooks';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import SearchableMultiSelect, {
  SearchableMultiSelectProps
} from 'Components/SearchableMultiSelect/SearchableMultiSelect';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectMyFQLegalEntities } from 'redux/slices/access/access.slice';
import { IFQCounterparty } from 'redux/slices/counterparty/counterparty.slice';
import {
  selectUserSelectedLegalEntities,
  updateSelectedLegalEntities
} from 'redux/slices/userPreferences/userPreferences.slice';
import { useRouteInfo } from '../../hooks';

const showEntitySelectStems = ['pair'];

export function BusinessProfile() {
  const {
    params: { product: activeProduct }
  } = useRouteInfo();
  const selectedLegalEntities = useAppSelector(selectUserSelectedLegalEntities);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const cls = useCss(css, {}, { watchSidebar: true });

  const showEntitySelect = useValueMemo(
    () => _.includes(showEntitySelectStems, activeProduct),
    [activeProduct]
  );

  const legalEntityFns = useMemo<SearchableMultiSelectProps<IFQCounterparty>>(
    () => ({
      onChange: (entities: IFQCounterparty[]) => dispatch(updateSelectedLegalEntities(entities)),
      keyFn: (entry) => entry.etrmId,
      labelFn: (entry) =>
        entry.spec && entry.spec.name !== entry.name
          ? `${entry.name} (${entry.spec.name})`
          : entry.name,
      placeholder: t('general.legalEntity')
    }),
    []
  );

  return (
    <div className={cn('business-profile', cls.wpr)}>
      {showEntitySelect && (
        <SearchableMultiSelect<IFQCounterparty>
          allowTypeAhead
          dataSelector={selectMyFQLegalEntities}
          defaultSelected={selectedLegalEntities}
          classes='legal-entities-dropdown'
          {...legalEntityFns}
        />
      )}
    </div>
  );
}
