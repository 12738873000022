import {
  formatNumberLocale,
  getFullDate,
  isNumeric,
  isValidTimestamp,
  utcToDisplayFormat
} from '@core/util';
import { CellClassParams, CellStyle } from 'ag-grid-community';
import { isDealValueMatching } from './isDealValueMatching';

interface HighlightColors {
  even: string;
  odd: string;
}

const isEven = (row: number) => row % 2 === 0;

const formatNumericValue = (value: string) =>
  isNumeric(value) ? formatNumberLocale(value) : value;

const formatValue = (value: string) =>
  isValidTimestamp(value) ? utcToDisplayFormat(value) : formatNumericValue(value);

const isFieldValid = (data: Record<string, any>, key: string) =>
  typeof data[key]?.isValid === 'string';

const isFieldToIgnore = (ignoreFields: string[] | undefined, field: string) =>
  ignoreFields && ignoreFields?.includes(field);

const createCombinedSearchFieldText = (
  data: any,
  combinedSearchFields: string[][],
  length: number
) => {
  let valuesToCheck = '';
  for (let rowIndex = 0; rowIndex < length; rowIndex++) {
    const row = combinedSearchFields[rowIndex];
    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      const searchField = row[colIndex];
      let dataValue = data[searchField];

      if (dataValue && isValidTimestamp(dataValue)) {
        dataValue = getFullDate(dataValue);
      }

      valuesToCheck += ` ${dataValue}`;
    }
  }
  return valuesToCheck;
};

const isSearchTextWithinCol = (value: string, filterText: string) =>
  String(value)?.includes(filterText) ||
  String(value).toLowerCase().includes(filterText.toLowerCase());

export function getHighlightCellStyle(
  filterText: string,
  highlightColors?: HighlightColors,
  targetData?: any,
  fieldsToIgnore?: string[],
  combinedSearchFields?: string[][]
): (params: any) => CellStyle {
  return function (params: CellClassParams): CellStyle {
    const { data, colDef, value } = params;
    if (data) {
      const rowData = data || {};
      const columnField = colDef.field || '';

      const rowBackgroundColor = { backgroundColor: '#e5fff8' };
      if (filterText.length) {
        const formattedValue = formatValue(value);

        const csfLength = combinedSearchFields?.length;
        if (csfLength) {
          const { data } = params;
          const valuesToCheck = createCombinedSearchFieldText(
            data,
            combinedSearchFields,
            csfLength
          );

          if (isSearchTextWithinCol(valuesToCheck, filterText)) {
            return rowBackgroundColor;
          }
        }

        if (isSearchTextWithinCol(formattedValue, filterText)) {
          return rowBackgroundColor;
        }
      }

      if (highlightColors && isFieldValid(data.data, columnField)) {
        const color = isEven(params.rowIndex) ? highlightColors.even : highlightColors.odd;
        return { backgroundColor: color };
      }

      if (
        targetData &&
        !isDealValueMatching(rowData, targetData, columnField) &&
        !isFieldToIgnore(fieldsToIgnore, columnField)
      ) {
        return { backgroundColor: '#f5222d40', borderRadius: '4px' };
      }
    }

    return {};
  };
}
