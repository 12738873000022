export enum EnvironmentVariables {
  localtest = 'localtest',
  qa1 = 'qa1',
  staging = 'staging',
  preprod = 'preprod',
  uat = 'uat'
}

export enum Product {
  oxAdmin = 'OxAdmin',
  oxNom = 'OxNom',
  oxOracle = 'OxOracle',
  oxPair = 'OxPair',
  oxSettle = 'OxSettle',
  settings = 'Settings',
  support = 'Support'
}

export enum ProductKeys {
  AllProducts = 'allProducts',
  OxAdmin = 'oxAdmin',
  OxPair = 'oxPair',
  OxOracle = 'oxOracle',
  OxNom = 'oxNom',
  OxSettle = 'oxSettle',
  Settings = 'settings'
}

export type ProductKey =
  | 'oxNom'
  | 'oxPair'
  | 'oxSettle'
  | 'oxOracle'
  | 'oxAdmin'
  | 'settings'
  | 'support';

export type ProductTitle =
  | Product.oxNom
  | Product.oxPair
  | Product.oxSettle
  | Product.oxOracle
  | Product.oxAdmin
  | Product.settings
  | Product.support;

export type ProductMap<Payload> = {
  [key in Product as string]: Payload;
};

export interface Permissions {
  read: boolean;
  write: boolean;
}

export type ProductSelection = ProductKey | 'noProduct';

export interface ProductPermission {
  key: ProductSelection;
  read: boolean;
  write: boolean;
}
