import { Text } from '@core/components';
import { cn } from '@core/util';
import _ from 'lodash';
import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { components, ControlProps, GroupBase } from 'react-select';
import { SelectProps } from '../Select.types';

type LabelProps = {
  label: SelectProps['label'];
  labelProps: SelectProps['labelProps'];
  labelType: SelectProps['labelType'];
};

/* string has a period without whitespace on either side OR already ends in a colon */
const UseRawLabelExp = /\S\.\S|:$/g;

const Label = memo(function Label({
  label,
  labelType,
  labelProps,
  hasValue
}: LabelProps & { hasValue: boolean }) {
  if (!label || labelType !== 'inline') return null;

  return (
    <div className='select-inline-label-wpr'>
      {_.isString(label) ? (
        <Text
          t={!hasValue || UseRawLabelExp.test(label) ? label : `${label}:`}
          {...labelProps}
          className={cn('select-inline-label', labelProps?.className, {
            '--is-placeholder': !hasValue
          })}
        />
      ) : (
        label
      )}
    </div>
  );
}, isEqual);

export function Control<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  label,
  labelProps,
  labelType,
  ...p
}: ControlProps<Option, IsMulti, Group> & LabelProps) {
  return (
    <components.Control {...p}>
      <Label {...{ label, labelType, labelProps, hasValue: p.hasValue }} />
      {children}
    </components.Control>
  );
}
