import { themes } from '@core/theme';
import { Calendar } from 'react-date-range';
import { ThemeProvider } from 'styled-components';
import { StyledDateCalendar } from './StyledDateCalendar';

interface Props {
  handleCalendarChange: ((date: Date) => void) | undefined;
  date: Date | undefined;
  minDate: Date | undefined;
  showMonthAndYearPickers?: boolean;
}

export const OxCalendar = ({
  handleCalendarChange,
  date,
  minDate,
  showMonthAndYearPickers = false
}: Props) => {
  const calendar = (
    <StyledDateCalendar
      onClick={(e) => e.stopPropagation()}
      className='border-2 drop-shadow-md calendar bg-white'
    >
      <Calendar
        onChange={handleCalendarChange}
        date={date}
        minDate={minDate}
        color='#26e4b4'
        rangeColors={['#26e4b4']}
        showMonthAndYearPickers={showMonthAndYearPickers}
      />
    </StyledDateCalendar>
  );

  return <ThemeProvider theme={themes.lightTheme}>{calendar}</ThemeProvider>;
};
