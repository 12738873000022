import { useCallback, useRef, useState } from 'react';

export type StateMediator<X = any> = (...args: any[]) => X;
export type SetMediatedStateAction = (...args: any[]) => void;
export type UseMediatedStateRV<X = any> = [X, SetMediatedStateAction];

// export function useMediatedState<X = undefined>(mediator: StateMediator<X | undefined>): UseMediatedStateRV<X | undefined>;
// export function useMediatedState<X = any>(mediator: StateMediator<X>, initialState: X): UseMediatedStateRV<X>;

export function useMediatedState<X = any>(
  mediator: StateMediator<X>,
  initialState?: X
): UseMediatedStateRV<X> {
  const mediatorFn = useRef<StateMediator>(mediator);
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const [state, setMediatedState] = useState<X>(() => initialState!);
  /* eslint-enable @typescript-eslint/no-non-null-assertion */

  const setState = useCallback(
    (...args: any[]) => {
      setMediatedState(mediatorFn.current(...args));
    },
    [state]
  );

  return [state, setState];
}
