import _isFunction from 'lodash/isFunction';
import { useCallback, useRef } from 'react';
import { useDidUpdate } from './useDidUpdate';
import { useUpdate } from './useUpdate';

export type GetSetStateCallback<X> = (newState: X, prevState: X) => void;
export type UseGetSetStateGetter<X> = () => X;
type SetStateFn<X> = (prev: X) => Partial<X>;
type SetStateArgs<X> = [update: Partial<X> | SetStateFn<X>, cb?: GetSetStateCallback<X>];
export type UseGetSetStateSetter<X> = (...args: SetStateArgs<X>) => void;
type WriteStateFn<X> = (prev: X) => X;
type WriteStateArgs<X> = [update: X | WriteStateFn<X>, cb?: GetSetStateCallback<X>];
type WriteState<X> = (...args: WriteStateArgs<X>) => void;
export type UseGetSetStateReturn<X> = [
  UseGetSetStateGetter<X>,
  UseGetSetStateSetter<X>,
  WriteState<X>
];

/*
 * useGetSetState: Like `useSetState`, except that it returns a getter. Useful when
 * state includes refs, functions, or complex objects that aren't easily evaluated for changes,
 * or when state needs to be referenced inside a memoized function, such as useCallback.
 *
 * accepts: optional params (initialState: object = {}, onChange: function)
 * returns: [getState: function (getter), setState: function, writeNewState: function]
 *
 * setState function accepts the following arguments:
 *  - a partial update object OR an update function that passes the previous state as its sole arg
 *  - an optional callback that runs with the updated state as it's sole arg after the state is updated
 *
 * writeNewState works like setState, accept that the return value overwrites the old state entirely.
 * Use writeNewState with caution.
 */
export function useGetSetState<X extends object>(
  initialState: X = {} as X,
  onChange?: GetSetStateCallback<X>
): UseGetSetStateReturn<X> {
  const state = useRef<X>(initialState);
  const getState = useCallback((): X => state.current, []);
  const pendingCb = useRef<GetSetStateCallback<X> | undefined>();
  const rerender = useUpdate();

  const setState = useCallback<UseGetSetStateSetter<X>>((update, cb?) => {
    const prev = getState();
    pendingCb.current = cb;
    state.current = { ...prev, ...(_isFunction(update) ? update(prev) : update) };
    rerender();
  }, []);

  const writeNewState = useCallback<WriteState<X>>((update, cb?) => {
    const prev = getState();
    pendingCb.current = cb;
    state.current = _isFunction(update) ? update(prev) : update;
    rerender();
  }, []);

  useDidUpdate(
    (prev) => {
      onChange?.(getState(), prev);
      pendingCb.current?.(getState(), prev);
      pendingCb.current = undefined;
    },
    [state.current]
  );

  return [getState, setState, writeNewState];
}
