import { StyleGetter } from '@core/theme/typings';

export const css: StyleGetter = () => {
  return {
    wpr: {
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      padding: 3,
      textAlign: 'center',
      zIndex: 100,
      backgroundColor: '#9cfdd7',
      color: 'black',
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: 12
    }
  };
};
