import { InfiniteFilterParams, SortOrder } from '@core/typings';
import { AxiosResponse } from 'axios';
import { deepGetter, filterByValue } from './object.helper';

export const handleResponse = async (
  method: Promise<AxiosResponse<any, any>>,
  noDataValue = {}
) => {
  try {
    const response = await method;

    return handleDataResponse(response, noDataValue);
  } catch (err: any) {
    return noDataValue;
  }
};

export const handleDataResponse = (response: AxiosResponse<any, any>, noDataValue = {}) => {
  if (response) {
    if (response.status === 200) {
      return response.data;
    }
  }

  return noDataValue;
};

export const handleSearchFilters = <T>(data: T[], filterParams: InfiniteFilterParams): T[] => {
  const searchText = filterParams?.filters?.searchText?.value as string;
  const sort = filterParams?.sort;
  const searchFilters = filterParams?.searchFilters;

  if (searchText) {
    data = filterByValue(data, searchText);
  }

  if (searchFilters) {
    for (const key in searchFilters) {
      if (Object.prototype.hasOwnProperty.call(searchFilters, key)) {
        const filter = searchFilters[key];
        data = data.filter((d: any) => filter.includes(deepGetter(d, key)));
      }
    }
  }

  if (sort) {
    const sortKey = sort.key as string;
    const sortOrder = sort.order;

    data = [...data].sort((a: any, b: any) => {
      const deepValueA = deepGetter(a, sortKey);
      const deepValueB = deepGetter(b, sortKey);

      const valueA = deepValueA ? (deepValueA.toString() as string) : '';
      const valueB = deepValueB ? (deepValueB.toString() as string) : '';

      return valueA.localeCompare(valueB);
    });

    if (sortOrder === SortOrder.ASC) {
      data = data.reverse();
    }
  }

  return data;
};

export const handleFiltersResponse = async <T>(
  repo: any,
  filterParams: InfiniteFilterParams
): Promise<T[]> => {
  const data = (await repo.getAll()).data || [];

  return handleSearchFilters<T>([...data], filterParams);
};
