import { titleCaseWord } from '@core/util';
import { PayloadAction } from '@reduxjs/toolkit';
import { removeItemInArray, updateItemInArray } from '../helpers/data-utils.helper';
import { MergedReducers } from '../helpers/slice.helper';

export interface GenericUnknown {
  [key: string]: GenericPayload[] | any;
}

export interface GenericPayload {
  id: string | number;
  [key: string]: any;
}

export const arrayReducer = (
  sliceName: string,
  type: string,
  mergedReducers?: MergedReducers[]
) => {
  const arrayName = sliceName + type;
  const capitalSliceName = titleCaseWord(sliceName);

  const addProp = 'add' + capitalSliceName;
  const updateProp = 'update' + capitalSliceName;
  const removeProp = 'remove' + capitalSliceName;

  const arrayReducerObject: {
    [key: string]: (state: GenericUnknown, { payload }: PayloadAction<GenericPayload>) => void;
  } = {
    [addProp]: (state: GenericUnknown, { payload }: PayloadAction<GenericPayload>) => {
      state[arrayName].push(payload);
    },
    [updateProp]: (state: GenericUnknown, { payload }: PayloadAction<GenericPayload>) => {
      state[arrayName] = updateItemInArray(state[arrayName], payload);
    },
    [removeProp]: (state: GenericUnknown, { payload }: PayloadAction<GenericPayload>) => {
      state[arrayName] = removeItemInArray(state[arrayName], payload.id);
    },

    ...buildMergedReducerObject(mergedReducers)
  };

  return arrayReducerObject;
};

const buildMergedReducerObject = (mergedReducers?: MergedReducers[]) => {
  // temporary any
  const reducers: any = {};

  (mergedReducers || []).map((item: MergedReducers) => {
    const { name, reducer } = item;
    return (reducers[name] = reducer);
  });

  return reducers;
};
