import { useValueMemo } from '@core/hooks';
import { themes } from '@core/theme';
import { IStaticData, KeyboardIds } from '@core/typings';
import { ColDef, ICellEditorParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact as AgGridReactType } from 'ag-grid-react';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
import { ThemeProvider } from 'styled-components';
import { StyledCellEditContainer } from './components';
import { OxTable } from './OxTable';

/* TODO: this interface needs to be conditional so that either sliceKey AND dataKey OR rowData is
 * required and are otherwise marked as `never`
 */
export interface CellEditProps {
  colDefs: ColDef[];
  label?: string;
  name?: string;
  getAllThunk?: () => void;
  rowData?: IStaticData[];
  sliceKey: string;
  dataKey: string;
  /** @deprecated do not pass this prop */
  useAppSelector?: unknown;
}

export const OxCellEditTable = forwardRef(function OxCellEditTable(
  {
    getAllThunk,
    colDefs,
    rowData = [],
    sliceKey,
    label,
    name,
    dataKey,
    ...params
  }: CellEditProps & ICellEditorParams,
  ref: React.Ref<any>
) {
  const grid = useRef<AgGridReactType>();
  const [searchText, setSearchText] = useState(params.value || '');
  const [selectedValue, setSelectedValue] = useState<object | undefined>();
  const dataSelector = useValueMemo(() => {
    if (sliceKey && dataKey) {
      return (state: RootState) => state[sliceKey as keyof typeof state][dataKey];
    }
    return () => [];
  }, [sliceKey, dataKey]);

  const selectedRowData = useAppSelector(dataSelector);

  useEffect(() => {
    getAllThunk && getAllThunk();
  }, []);

  useEffect(() => {
    if (selectedValue) {
      params?.stopEditing && params.stopEditing();
    }
  }, [selectedValue]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!selectedValue && !searchText.length) {
          return '';
        }
        return selectedValue ? selectedValue : params.value;
      }
    };
  });

  const selectValue = ({ data }: RowClickedEvent) => {
    setSelectedValue(data);
  };

  const input = document.getElementById('cell-edit-input');

  input?.addEventListener(
    'keydown',
    (event) => {
      if (event.key === KeyboardIds.ARROW_DOWN) {
        event.preventDefault();
        if (grid.current) {
          const { api, columnApi } = grid.current;
          api.ensureIndexVisible(0);

          const firstCol = columnApi.getAllDisplayedColumns()[0];
          api.ensureColumnVisible(firstCol);
          api.setFocusedCell(0, firstCol);
        }
      }
    },
    true
  );

  const onCellKeyDown = (params: any) => {
    const { event, data } = params;
    if (event?.key === KeyboardIds.ENTER) {
      event.preventDefault();
      setSelectedValue(data);
    }
  };

  const cellEditContainer = (
    <StyledCellEditContainer className='cell-edit-container w-[600px] rounded'>
      <input
        id='cell-edit-input'
        className='h-[42px] w-full rounded pl-3'
        type='text'
        name={name}
        placeholder={label}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {searchText.length >= 2 && (
        <OxTable
          className='font-exceptions'
          ref={grid}
          rowData={rowData.length ? rowData : selectedRowData}
          columnDefs={colDefs}
          domLayout='autoHeight'
          customSearchText={searchText}
          onRowClicked={selectValue}
          onCellKeyDown={onCellKeyDown}
          stopEditingWhenCellsLoseFocus
          onGridReady={() => {
            for (let i = 1; i < colDefs.length; i++) {
              const { field } = colDefs[i];
              if (field) {
                grid.current?.columnApi.autoSizeColumn(field);
              }
            }
          }}
        />
      )}
    </StyledCellEditContainer>
  );

  return <ThemeProvider theme={themes.lightTheme}>{cellEditContainer}</ThemeProvider>;
});
