import 'rc-menu/assets/index.css';
import 'rc-trigger/assets/index.css';
import { css } from './DropdownMenu.style';

import { EllipsisVerticalSvg } from '@core/images';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import Trigger from 'rc-trigger';
import { useState } from 'react';
import { Btn } from '../Btn';
import { Menu } from '../Menu';
import { DropdownMenuProps } from './DropdownMenu.types';

export function DropdownMenu(props: DropdownMenuProps): JSX.Element {
  const { btnProps = {}, className, items, menuProps, triggerProps, popupStyle = {} } = props;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const cls = useCss(css);

  return (
    <Trigger
      destroyPopupOnHide
      popupStyle={{ ...popupStyle }}
      defaultPopupVisible={isMenuOpen}
      prefixCls={cn('rc-trigger-popup', className)}
      action={['click']}
      mask={false}
      onPopupVisibleChange={setIsMenuOpen}
      popupAlign={{
        points: ['tr', 'br'],
        offset: [0, 6]
      }}
      {...triggerProps}
      popup={<Menu {...{ items, ...menuProps }} />}
    >
      <Btn
        iconRight
        icon={EllipsisVerticalSvg}
        size={btnProps.label ? 'small' : 'normal'}
        bordered={!isMenuOpen}
        color={`text.${isMenuOpen ? 'hint' : 'primary'}`}
        {...btnProps}
        className={cn('core-dropdown-trigger-btn', cls.triggerBtn, btnProps?.className, {
          '--icon-only': !btnProps?.label
        })}
      />
    </Trigger>
  );
}
