import { InfiniteTableParams } from '@core/typings';
import { IDatasource } from 'ag-grid-community';
import { Dispatch } from 'react';
import { updateClientSideDataSource } from './updateClientSideDataSource';

export const clientSideDataSource = <T>(
  { fetchData, infiniteFilterParams, dispatch }: InfiniteTableParams,
  setRowCount?: Dispatch<any>
): IDatasource => {
  // Load full data set into client when datasource is initialized
  const fullDatasetPromise = dispatch && dispatch(fetchData());

  return updateClientSideDataSource<T>(fullDatasetPromise, infiniteFilterParams, setRowCount);
};
