import { createContext } from 'react';
import { buildTheme } from './lib';
import { modernScheme } from './modernScheme';
import { IsDarkCV, ThemeCV, ThemeState } from './typings';
import { getThemeLib } from './util';

const noop = () => {
  /* noop */
};

const { light: colors } = buildTheme(modernScheme);
const initialLib = getThemeLib(colors);

export const initialThemeState: ThemeState = {
  colors,
  isDark: false,
  topNavHeight: 0,
  sidebarOpenWidth: 240,
  sidebarCurrentWidth: 240,
  activeThemeKey: 'modern'
};

export const IsDarkContext = createContext<IsDarkCV>([false, noop]);
export const ThemeContext = createContext<ThemeCV>({
  ...initialLib,
  ...initialThemeState
} as ThemeCV);
