import { useValueMemo } from '@core/hooks';
import { RootState, useAppSelector } from '@core/redux';
import { IsRouteHiddenParams } from '@core/typings';
import { usePermissions, useUser } from '@core/user';

const selectFeatureFlags = (state: RootState) => state.featureFlags;

export function useIsRouteHiddenParams(): IsRouteHiddenParams {
  const featureFlags = useAppSelector(selectFeatureFlags);
  const { data: currentUser } = useUser();
  const Permissions = usePermissions();
  return useValueMemo<IsRouteHiddenParams>(
    () => ({ ...Permissions, featureFlags, currentUser }),
    [Permissions.permissionsMap, featureFlags, currentUser]
  );
}
