import styled from 'styled-components';

export const StyledDropDown = styled.div`
  .ox-dropdown-content {
    color: ${(props) => props.theme.contrast_65};

    &:hover {
      background-color: ${(props) => props.theme.contrast_4};
    }
  }
`;

export const StyledDropDownButton = styled.button`
  &.standard {
    color: ${(props) => props.theme.secondaryDropDownText};
    background-color: ${(props) => props.theme.secondaryButtonBackground};
    stroke: ${(props) => props.theme.secondaryDropDownText};

    &:hover {
      background-color: ${(props) => props.theme.contrast_4};
    }
  }

  &.primary {
    color: ${(props) => props.theme.primaryDropDownText};
    background-color: ${(props) => props.theme.primaryDropDownBgColor};
    stroke: ${(props) => props.theme.primaryDropDownText};

    &:hover {
      background-color: ${(props) => props.theme.primaryDropDownBgColor};
    }
  }

  &.secondary {
    color: ${(props) => props.theme.secondaryDropDownText};
    background-color: ${(props) => props.theme.secondaryDropDownBgColor};
    stroke: ${(props) => props.theme.secondaryDropDownText};

    &:hover {
      background-color: ${(props) => props.theme.secondaryDropDownBgColor};
    }
  }

  &.inverse {
    color: ${(props) => props.theme.inverseDropDownText};
    background-color: ${(props) => props.theme.inverseDropDownBgColor};
    stroke: ${(props) => props.theme.inverseDropDownIconColor};

    &:hover {
      background-color: ${(props) => props.theme.inverseDropDownBgColor};
    }
  }

  &.ox-button-compact {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 2px 13px;
  }

  &.ox-button-default {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 5px 14px;
  }

  &.ox-button-large {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 14px;
  }
`;
