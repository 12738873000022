import { css } from './TabLabel.style';

import { Text } from '@core/components/Text';
import { useValueMemo } from '@core/hooks';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import _ from 'lodash';
import { TabProps, TabRendererParams } from '../Tabs.types';
import { useTabs } from '../useTabs';

export function TabLabel(props: TabRendererParams) {
  const { defaultColor, activeTab, indicatorType, isCtrl, setActive } = useTabs();
  const {
    icon: Icon,
    activeIcon: ActiveIcon = Icon,

    color = defaultColor,
    id,
    isDisabled = false,
    label,
    labelProps
  } = props;
  const isActive = useValueMemo(() => id === activeTab, [id, activeTab]);
  const cls = useCss(css, { color });

  return (
    <div
      className={cn('label-wpr', cls.lbl, `${indicatorType}-indicator`, {
        '--is-active': isActive,
        '--is-disabled': isDisabled,
        '--is-btn': !isCtrl
      })}
      role='button'
      onClick={() => {
        if (!isCtrl && !isDisabled) setActive(id);
      }}
    >
      <div className='label-ctr'>
        <div className='label-icon-wpr'>
          {Icon && ActiveIcon && (isActive ? <ActiveIcon /> : <Icon />)}
        </div>
        <Text m={isActive} size={18} {...labelProps} className={cn('label', labelProps?.className)}>
          {label || _.startCase(id)}
        </Text>
      </div>
    </div>
  );
}

export function renderTabLabel(tab: TabProps, id: string) {
  return <TabLabel {...{ ...tab, id }} key={id} />;
}
