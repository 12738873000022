import { ColDef } from 'ag-grid-community';
import { TFunction } from 'react-i18next';

export function buildTableHeaders(
  columnDefs: ColDef[],
  t: TFunction,
  tableName: string,
  section: string,
  ignoreFirstCol = true
): any {
  return columnDefs
    .filter((d) => d)
    .map((d, i) => {
      return i === 0 && ignoreFirstCol
        ? { ...d, headerName: '' }
        : { ...d, headerName: t(`${tableName}.${section}.${d?.field}`) };
    });
}
