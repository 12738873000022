const routeStrings = {
  login: 'login',
  changePassword: 'change-password',
  productSelection: '/products',
  oxAdmin: {
    root: 'products/admin/*',
    userPermission: {
      root: 'user-permission/*',
      profiles: 'profiles',
      invitations: 'invitations',
      inactiveProfiles: 'inactive-profiles'
    },
    userReports: {
      root: 'user-reports/*'
    },
    counterparty: 'counterparty',
    broker: 'broker',
    pipeline: 'pipeline'
  },
  oxPair: {
    root: 'products/pair/*',
    deals: {
      root: 'deals/*',
      terminated: 'terminated-deals',
      withdrawn: 'withdrawn-deals',
      rejected: 'rejected-deals'
    },
    configurations: 'configurations'
  },
  oxSettle: {
    root: 'products/settle/*',
    rejectedEtrmInvoices: 'rejected-etrm-invoices',
    approvedInvoices: 'approved-invoices',
    invoiceReconciliation: 'invoice-reconciliation',
    configurations: 'configurations',
    sqtsDailyLevels: 'daily-level-quantities',
    uploads: {
      root: 'uploads/*',
      pipelines: 'pipelines',
      counterparties: 'counterparties'
    }
  },
  oxOracle: {
    root: 'products/oracle/*',
    staticData: {
      root: 'static-data/*',
      averagingMethods: 'averaging-methods',
      chargeIndicators: 'charge-indicators',
      currencies: 'currencies',
      deliveryTypes: 'delivery-types',
      flowDirections: 'flow-directions',
      locationCategories: 'location-categories',
      locationCountries: 'location-countries',
      locationStates: 'location-states',
      locationTypes: 'location-types',
      masterContractTypes: 'master-contract-types',
      periodicals: 'periodicals',
      pricingCalendars: 'pricing-calendars',
      rateTypes: 'rate-types',
      serviceCodes: 'service-codes',
      tradeTypes: 'trade-types',
      unitOfMeasures: 'unit-of-measures'
    },
    specReferenceData: {
      root: 'spec-reference-data/*',
      spec: {
        root: '/*',
        counterparties: 'counterparty',
        locations: 'location',
        pipelines: 'pipeline',
        zones: 'zone',
        payIndex: 'pay-index',
        brokers: 'broker',
        chargeTypes: 'charge-type',
        transportationAgreements: 'transportation-agreements'
      }
    },
    referenceData: {
      root: 'reference-data/*',
      tariffRates: 'tariff-rates',
      tariffRatesMatrix: 'tariff-rates-matrix',
      upload: {
        root: 'upload/*',
        uploadCounterparties: 'upload-counterparty',
        uploadInterconnects: 'upload-interconnect',
        uploadLocations: 'upload-location',
        uploadPipelines: 'upload-pipeline',
        uploadZones: 'upload-zone',
        uploadRateDefinitions: 'upload-rate-definition',
        uploadRateSchedules: 'upload-rate-schedule',
        uploadLocationGroups: 'upload-location-group',
        uploadPayIndexes: 'upload-pay-index',
        uploadTransactionTypes: 'upload-transaction-type',
        uploadBrokers: 'upload-broker'
      },
      stored: {
        root: 'stored/*',
        storedChargeTypes: 'stored-charge-type',
        storedCounterparties: 'stored-counterparty',
        storedInterconnects: 'stored-interconnect',
        storedLocations: 'stored-location',
        storedPipelines: 'stored-pipeline',
        storedZones: 'stored-zone',
        storedRateDefinitions: 'stored-rate-definition',
        storedRateSchedules: 'stored-rate-schedule',
        storedLocationGroups: 'stored-location-group',
        storedPayIndex: 'stored-pay-index',
        storedTransactionTypes: 'stored-transaction-type',
        storedBrokers: 'stored-broker',
        storedTransportationAgreement: 'stored-transportation-agreement',
        storedPipelineTransportationAgreement: 'stored-pipeline-transportation-agreement'
      }
    }
  },
  oxNom: {
    root: 'products/nom/*',
    configurations: 'configurations',
    ediDashboard: 'edi-dashboard',
    gasDaySummary: 'gas-day-summary',

    exportHistory: 'export-history',
    eleoxSupport: 'eleox-support',
    ediLogs: 'edi-logs',
    ebbLogs: 'ebb-logs',
    processingLog: 'processing-log',
    mdqSummary: 'mdq-summary',
    storageReport: 'storage-report',

    counterparty: 'counterparty',
    agency: 'agency',
    location: 'location',
    locationGroup: 'group-location',
    pipelineZoneLocation: 'pipeline-zone-location',
    transportationAgreements: 'transportation-agreements',
    rates: 'rates',
    crossReference: 'cross-reference',
    contract: 'contract',
    contractSegments: 'contract-segments',

    ebbEdiPage: 'ebb-edi-page',
    etrm: 'etrm',

    positionSummary: 'position-summary',
    plannedVolumes: 'planned-volumes',
    fuelRateScenarios: 'fuel-rate-scenarios',

    scheduling: 'scheduling',
    deals: 'deals',

    epsqCalculator: 'epsq-calculator',
    columnConfiguration: 'column-configuration'
  },
  settings: {
    root: 'products/settings/*',
    company: 'company',
    user: 'user'
  },
  support: {
    root: 'products/support/*',
    eleoxSupport: 'eleox-support',
    helpdesk: 'https://eleox.atlassian.net/servicedesk/customer/portal/1'
  }
};

export default routeStrings;
