import styled from 'styled-components';

export const TableContainer = styled.div`
  .ag-root-wrapper {
    overflow-y: auto;
    max-height: 300px;
  }

  .ag-header {
    margin-top: 20px;
  }

  .ag-body-horizontal-scroll {
    position: absolute;
  }
`;

export const StyledInputGridContainer = styled.div`
  .cell-edit-input {
    border: 2px solid ${(props) => props.theme.primaryDropDownBgColor} !important;
  }

  .ag-cell-focus {
    background-color: ${(props) => props.theme.fieldHighlight} !important;
  }

  .ag-theme-alpine .ag-header {
    background-color: white;
    border-bottom: solid lightgray 2px !important;
  }

  .first-col {
    font-weight: bold;
    font-size: 16px;
  }

  .col {
    font-size: 12px;
    color: ${(props) => props.theme.secondaryButtonText};
  }
`;
