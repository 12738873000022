import { DeepEqual } from '@core/typings';
import isEq from 'fast-deep-equal';
import _ from 'lodash';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { Deps as AnyDeps } from './hooks.types';
import { usePreviousValue } from './usePreviousValue';

type Cb = (...deps: AnyDeps) => void;

/* SFC replacement for `this.componentDidUpdate` in class components */
export function useDidUpdate<Deps extends any[] = AnyDeps>(
  onChange: Cb,
  deps: Deps = [] as any,
  isEqual: DeepEqual = isEq,
  debug?: boolean
): void {
  const pDeps = usePreviousValue<Deps>(deps, isEqual);
  const iDeps = useMemo<Deps>(() => deps, []);
  const ref = useRef<Deps>(iDeps);

  if (!isEqual(ref.current, deps)) {
    debug && console.log('setting current deps:', ref.current);
    ref.current = deps;
  }

  /* NOTE: — MS, 6 Feb, 2024
   * useLayoutEffect is used here because in some cases where deps change rapidly multiple times
   * between renders, useEffect callbacks will be batched by React's state manager and are
   * called in the wrong order or ignored entirely.
   *
   * useLayoutEffect (currently) does not get batched, so its updates are called in the expected
   * order every time. THIS IS PROBABLY NOT A GREAT LONG-TERM SOLUTION but is currently the only
   * non-invasive solution that regularly produces the expected outcome. At some point in the
   * future, we should plan to move this back to useEffect or to some other (yet-undeveloped)
   * internal React hook with lower overhead and identical results.
   */
  useLayoutEffect(() => {
    /* pDeps is undefined prior to first change */
    _.isArray(pDeps) && onChange(...pDeps);
  }, [ref.current]);
}
