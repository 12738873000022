import { JsonMap } from '@core/typings';
import { CreateAdHocIntegrationParams } from '@core/user-configs/typings';
import { UserConfigIntegration } from './UserConfigIntegration';

export function createAdHocIntegration<TVal extends JsonMap, TRawVal extends JsonMap = TVal>(
  params: CreateAdHocIntegrationParams<TVal, TRawVal>
): UserConfigIntegration<TVal, TRawVal> {
  return new UserConfigIntegration({
    /* default formatter is pass-through */
    format: (val: TRawVal) => val as unknown as TVal,
    ...params
  });
}
