import { DeepEqual } from '@core/typings';
import { useRef } from 'react';
import { useDidUpdate } from './useDidUpdate';
import { useIsFirstRender } from './useIsFirstRender';
import { useUpdate } from './useUpdate';

type Deps = any[];
type Memo<X> = (...prevDeps: Deps) => X;

/* like useMemo, but for values where deep-eq eval is required */
export function useValueMemo<X>(memo: Memo<X>, deps: Deps = [], isEq?: DeepEqual): X {
  const isFirstRender = useIsFirstRender();
  const update = useUpdate();

  const value = useRef<X>();

  if (isFirstRender) value.current = memo();

  useDidUpdate(
    (...pDeps) => {
      value.current = memo(...pDeps);
      update();
    },
    deps,
    isEq
  );

  return value.current as X;
}
