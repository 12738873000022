import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IDealActivityRepository } from 'shared/api/interfaces/IDealActivityRepository';
import { IDealCommentInput } from 'shared/models/DealComment';
import { IBaseThunk } from './base.thunks';

export class DealActivityThunks implements IBaseThunk<any> {
  private readonly dealActivityRepository!: IDealActivityRepository;

  constructor(_dealActivityRepository: IDealActivityRepository) {
    this.dealActivityRepository = _dealActivityRepository;

    if (this.dealActivityRepository === null) {
      throw new Error('dealActivityRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'dealActivity';

  getAllCommentsByDealId(): AsyncThunk<
    AxiosResponse<any, any>,
    { dealId: string; companyId?: string },
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/getAllCommentsByDealId`;

    return createAsyncThunk(
      action,
      ({ dealId, companyId }: { dealId: string; companyId?: string }) =>
        this.dealActivityRepository.getAllCommentsByDealId(dealId, companyId)
    );
  }

  getAllExternalCommentsByDealId(): AsyncThunk<
    AxiosResponse<any, any>,
    { dealId: string; companyId?: string },
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/getAllExternalCommentsByDealId`;

    return createAsyncThunk(
      action,
      ({ dealId, companyId }: { dealId: string; companyId?: string }) =>
        this.dealActivityRepository.getAllExternalCommentsByDealId(dealId, companyId)
    );
  }

  getAllActionsByDealId(): AsyncThunk<AxiosResponse<any, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/getAllActionsByDealId`;

    return createAsyncThunk(action, (dealId: string) =>
      this.dealActivityRepository.getAllActionsByDealId(dealId)
    );
  }

  createComment(): AsyncThunk<AxiosResponse<any, any>, IDealCommentInput, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/createComment`;

    return createAsyncThunk(action, async (comment: any, { rejectWithValue }) => {
      try {
        return await this.dealActivityRepository.createComment(comment);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  add(): AsyncThunk<AxiosResponse<any, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<
    AxiosResponse<any, any>,
    { id: string; item: any },
    Record<string, unknown>
  > {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<any, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getAll(): AsyncThunk<AxiosResponse<any[], any>, void, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }
}
