import { css } from './Tag.style';

import { useCss } from '@core/theme';
import { cn } from '@core/util';
import { Text } from '../Text';
import { TagProps } from './Tag.types';

export function Tag(props: TagProps) {
  const {
    id,
    onClick,
    layout = 'normal',
    size = 'normal',
    color = 'primary',
    isOutline,
    isTranslucent,
    style,
    ...rest
  } = props;
  const cls = useCss(css, { color });
  return (
    <div
      className={cn('core-tag', cls.tag, `layout-${layout}`, `size-${size}`, {
        '--is-outline': isOutline,
        '--is-translucent': isTranslucent,
        '--is-clickable': Boolean(onClick)
      })}
      {...{ id, style, onClick }}
    >
      <Text m {...rest} className={cn('tag-label', rest.className)} />
    </div>
  );
}
