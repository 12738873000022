import styled from 'styled-components';

export const StyledMultiSelect = styled.div`
  .primary {
    color: ${(props) => props.theme.primaryDropDownText};
    background-color: ${(props) => props.theme.primaryDropDownBgColor};
    stroke: ${(props) => props.theme.primaryDropDownText};

    &:hover {
      background-color: ${(props) => props.theme.primaryDropDownBgColor};
    }
  }

  .secondary {
    color: ${(props) => props.theme.secondaryDropDownText};
    background-color: ${(props) => props.theme.secondaryDropDownBgColor};
    stroke: ${(props) => props.theme.secondaryDropDownText};

    &:hover {
      background-color: ${(props) => props.theme.secondaryDropDownBgColor};
    }
  }

  .inverse {
    color: ${(props) => props.theme.inverseDropDownText};
    background-color: ${(props) => props.theme.inverseDropDownBgColor};
    stroke: ${(props) => props.theme.inverseDropDownIconColor};

    &:hover {
      background-color: ${(props) => props.theme.inverseDropDownBgColor};
    }
  }

  .field-default {
    border: 1px solid ${(props) => props.theme.contrast_15};
  }

  .field-focus {
    border: 2px ${(props) => props.theme.fieldFocus} solid;
  }

  .link:hover {
    background-color: #eee;
  }
`;

export const StyledMultiSelectContent = styled.label`
  color: ${(props) => props.theme.dropDownContentText};
  font-size: 14px;
  cursor: pointer;

  input[type='checkbox'] {
    visibility: hidden;
  }

  input:active ~ .checkmark {
    background-color: ${(props) => props.theme.checkmark};
    border-color: transparent;
  }

  input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.checkmark};
    border-color: transparent;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  &:hover {
    background-color: ${(props) => props.theme.contrast_4};
  }
`;

export const StyledMultiSelectCheckmark = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 16px;
  width: 16px;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  &:after {
    left: 6px;
    bottom: 3px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const StyledTextInput = styled.input`
  width: 100%;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid ${(props) => props.theme.contrast_15};
  &:focus {
    border: 2px ${(props) => props.theme.fieldFocus} solid;
  }
`;
