import {
  GridView,
  GridViewConfig,
  RowGroupFieldMap,
  WithGridFieldsState
} from '@core/grid/typings';
import { UseGetSetStateGetter } from '@core/hooks';
import _ from 'lodash';
import { createColDefs } from './createColDefs';

export function createGridView<TData extends object = any, FieldName extends string = any>(
  conf: GridViewConfig<TData, FieldName>,
  getState: UseGetSetStateGetter<WithGridFieldsState<TData, FieldName>>
): GridView<TData, FieldName> {
  const state = getState();
  /* get initial columns from config (with callbacks) */
  const initialColumns = (
    _.isEmpty(conf.initialColumns)
      ? _.isEmpty(conf.availableFields)
        ? _.keys(state.fieldConfigMap)
        : conf.availableFields
      : conf.initialColumns
  ) as FieldName[];

  /* create config obj for  */
  const rowGroupFieldMap = _.reduce(
    conf.rowGroupFields,
    (memo, fldName, initialRowGroupIndex) => {
      _.pull(initialColumns, fldName);
      memo[fldName] = {
        initialRowGroupIndex,
        initialRowGroup: true,
        rowGroupIndex: initialRowGroupIndex,
        rowGroup: true
      };
      return memo;
    },
    {} as RowGroupFieldMap<FieldName>
  );

  const colDefs = createColDefs<TData, FieldName>(
    {
      ...conf,
      rowGroupFieldMap,
      initialColumns
    },
    getState
  );

  return {
    label: conf.label || _.startCase(conf.key),
    key: conf.key,
    defaultColDef: conf.defaultColDef ?? {},
    fieldNames: conf.availableFields?.length
      ? conf.availableFields
      : (_.keys(state.fieldConfigMap) as FieldName[]),
    colDefs,
    rowGroupFieldMap,
    initialColumns
  };
}
