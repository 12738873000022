import { IStaticData } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IStaticDataRepository } from 'shared/api/interfaces/IStaticDataRepository';

export class StaticDataThunk {
  private readonly staticDataRepository!: IStaticDataRepository;

  constructor(_staticDataRepository: IStaticDataRepository) {
    this.staticDataRepository = _staticDataRepository;

    if (this.staticDataRepository === null) {
      throw new Error('zoneRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'staticData';

  getAllAveragingMethods(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/averaging-methods`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllAveragingMethods());
  }

  getAllChargeIndicators(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/charge-indicators`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllChargeIndicators());
  }

  getAllChargeTypes(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/charge-types`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllChargeTypes());
  }

  getAllCurrencies(): AsyncThunk<AxiosResponse<IStaticData[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/currencies`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllCurrencies());
  }

  getAllDeliveryTypes(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/delivery-types`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllDeliveryTypes());
  }

  getAllFlowDirections(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/flow-directions`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllFlowDirections());
  }

  getAllLocationCategories(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/location-categories`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllLocationCategories());
  }

  getAllLocationCountries(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/location-countries`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllLocationCountries());
  }

  getAllLocationStates(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/location-states`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllLocationStates());
  }

  getAllLocationTypes(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/location-types`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllLocationTypes());
  }

  getAllMasterContractTypes(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/master-contract-types`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllMasterContractTypes());
  }

  getAllPeriodicals(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/periodicals`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllPeriodicals());
  }

  getAllPricingCalendars(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/pricing-calendars`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllPricingCalendars());
  }

  getAllRateTypes(): AsyncThunk<AxiosResponse<IStaticData[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/rate-types`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllRateTypes());
  }

  getAllServiceCodes(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/service-codes`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllServiceCodes());
  }

  getAllTradeTypes(): AsyncThunk<AxiosResponse<IStaticData[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/trade-types`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllTradeTypes());
  }

  getAllUnitOfMeasures(): AsyncThunk<
    AxiosResponse<IStaticData[], any>,
    void,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/unit-of-measures`;

    return createAsyncThunk(action, () => this.staticDataRepository.getAllUnitOfMeasures());
  }
}
