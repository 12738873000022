import { CreateSliceOptions } from '@reduxjs/toolkit';
import { arrayReducer as arrayReducers } from '../utils/array.slice';

// temporary any
export interface MergedReducers {
  name: string;
  reducer: { reducer: any; prepare: any };
}

export const buildGenericSlice = <T = any>(
  name: string,
  initialState: T,
  type = 'List',
  mergedReducers?: MergedReducers[]
): CreateSliceOptions => {
  /* NOTE: this is aliased b/c the TS error it generates is very complex and the code is legacy */
  const arrayFunctions = arrayReducers(name, type, mergedReducers) as any;

  const slice: CreateSliceOptions<T> = {
    name: name,
    initialState,
    reducers: {
      ...arrayFunctions
    }
  };

  return slice;
};
