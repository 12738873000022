import { themes } from '@core/theme';
import { ThemeProvider } from 'styled-components';
import { StyledSnackbar } from './OxSnackbar.styles';

interface Props {
  text: string;
}

export const OxSnackbar = ({ text }: Props) => {
  const snackbar = (
    <StyledSnackbar
      data-testid='ox-snackbar'
      className='ox-snackbar z-30 fixed inline-flex bottom-0 mr-auto rounded-t-lg py-3 px-5 delay-400 duration-200 ease-in-out transition-all'
    >
      {text}
    </StyledSnackbar>
  );

  // need to account for dark theme
  return <ThemeProvider theme={themes.lightTheme}>{snackbar}</ThemeProvider>;
};
