import { Btn, ButtonSize, ButtonType, OxButton } from '@core/components';
import { TextInput } from '@core/form/PrimitiveInputs';

import { useValueMemo } from '@core/hooks';
import { AlertCircleFilledSvg } from '@core/images';
import { themes } from '@core/theme';
import _ from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import {
  StyledConfirmModal,
  StyledModal,
  StyledModalBodyDefault,
  StyledModalBodyText,
  StyledModalCover,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalHeaderText
} from './ConfirmModal.styles';

export enum ConfirmModalType {
  default = 'default',
  confirm = 'confirm',
  warning = 'warning'
}

export enum ConfirmModalWarningType {
  Warning = 'warning',
  Danger = 'danger'
}

export interface ConfirmModalProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    React.AriaAttributes {
  modalType: ConfirmModalType;
  headerText: string;
  bodyText: string;
  buttonCancelText?: string;
  buttonConfirmText?: string;
  buttonConfirmHandler?: any;
  warningType?: ConfirmModalWarningType;
  displayModalHandler: React.Dispatch<React.SetStateAction<boolean>>;
  validationValue?: string;
  confirmDisabled?: boolean;
}
function DefaultModal(props: ConfirmModalProps): JSX.Element {
  const { t } = useTranslation();
  const { headerText, bodyText, buttonCancelText, buttonConfirmText } = props;

  return (
    <StyledModal
      data-testid='ConfirmModalDefault'
      className='flex flex-col shadow-lg ox-modal-default'
    >
      <StyledModalHeader className='items-center justify-between ox-modal-header-default'>
        <h1 className='font-medium text-base'>{headerText}</h1>
      </StyledModalHeader>
      <StyledModalBodyDefault className='grow items-center justify-between ox-modal-body-default'>
        <p className='font-normal text-sm'>{bodyText}</p>
      </StyledModalBodyDefault>
      <StyledModalFooter className='items-center justify-end ox-modal-footer-default'>
        <OxButton
          buttonType={ButtonType.secondary}
          buttonSize={ButtonSize.default}
          text={buttonCancelText ? buttonCancelText : t('general.cancel')}
          className='mr-2'
        />
        <OxButton
          buttonType={ButtonType.primary}
          buttonSize={ButtonSize.default}
          text={buttonConfirmText ? buttonConfirmText : t('general.confirm')}
        />
      </StyledModalFooter>
    </StyledModal>
  );
}

function ConfirmationModal(props: ConfirmModalProps): JSX.Element {
  const { t } = useTranslation();
  const {
    headerText,
    warningType,
    bodyText,
    buttonCancelText,
    buttonConfirmText,
    buttonConfirmHandler,
    displayModalHandler,
    validationValue,
    confirmDisabled = false
  } = props;

  const [userInput, setUserInput] = useState('');
  return (
    <StyledConfirmModal
      data-testid='ConfirmModalConfirm'
      className='flex flex-col shadow-lg ox-modal-confirm w-[30rem]'
    >
      <div className='flex flex-col items-center justify-start pt-8 pb-2'>
        <AlertCircleFilledSvg
          data-testid='OxAlertCircle'
          width={70}
          height={70}
          className={`ox-modal-confirm-icon ${warningType}-icon`}
        />
        <StyledModalHeaderText className='font-medium text-2xl ox-modal-header-text mt-3'>
          {headerText}
        </StyledModalHeaderText>
      </div>
      <div className='items-center justify-start grow'>
        <StyledModalBodyText className='font-normal text-md ox-modal-body-text text-center'>
          {bodyText}
        </StyledModalBodyText>
      </div>
      {validationValue && (
        <div className='validation-input-wpr'>
          <TextInput
            className='validation-input'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setUserInput(_.toString(e.target?.value))
            }
          />
        </div>
      )}

      <div className='flex items-center justify-center px-6 pt-6 pb-10 ox-modal-footer-confirm'>
        <Btn
          color={warningType === 'danger' ? 'danger' : 'primary'}
          size='large'
          label={buttonConfirmText ? buttonConfirmText : t('general.confirm')}
          className='mr-5'
          onClick={buttonConfirmHandler}
          disabled={validationValue ? userInput !== validationValue : confirmDisabled}
        />
        <Btn
          color='text'
          size='large'
          label={buttonCancelText ? buttonCancelText : t('general.cancel')}
          onClick={() => displayModalHandler(false)}
          bordered
        />
      </div>
    </StyledConfirmModal>
  );
}

type ModalEl = (props: ConfirmModalProps) => JSX.Element;

export function ConfirmModal(props: ConfirmModalProps): JSX.Element {
  const { modalType } = props;
  const El = useValueMemo<ModalEl>(
    () => (modalType === ConfirmModalType.confirm ? ConfirmationModal : DefaultModal),
    [modalType]
  );
  return (
    <ThemeProvider theme={themes.lightTheme}>
      <StyledModalCover
        data-testid='OxCover'
        className='fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-y-auto overflow-x-hidden z-50 ox-modal-cover-background'
      >
        <El {...props} />
      </StyledModalCover>
    </ThemeProvider>
  );
}
