import { OxStatus } from '@core/components';
import { InvoiceStatus, Status, StatusColor } from '@core/typings';
import { ICellRendererParams } from 'ag-grid-community';

export const renderOxStatus = (params: ICellRendererParams): JSX.Element => {
  if (params && params.data && params.data.status) {
    const status = params.data.status as InvoiceStatus;
    let color = StatusColor.Default;
    let statusField = Status.Empty;

    switch (status) {
      default:
      case InvoiceStatus.NotStarted:
        color = StatusColor.InfoAlt;
        statusField = Status.InProgress;
        break;
      case InvoiceStatus.BlockedMissingInternalData:
      case InvoiceStatus.BlockedMissingPipelineData:
        color = StatusColor.Danger;
        statusField = Status.Empty;
        break;
      case InvoiceStatus.Approved:
      case InvoiceStatus.Completed:
        color = StatusColor.Info;
        statusField = Status.Empty;
        break;
      case InvoiceStatus.PendingPipeline:
      case InvoiceStatus.PendingInternal:
      case InvoiceStatus.NeedsInternalReview:
      case InvoiceStatus.NeedsSchedulerReview:
      case InvoiceStatus.NeedsPipelineReview:
        color = StatusColor.Pending;
        statusField = Status.Pending;
        break;
    }

    return (
      <div className='w-full h-full py-2 inline-flex'>
        <OxStatus
          text={status.match(/[A-Z][a-z]+/g)?.join(' ')}
          color={color}
          status={statusField}
        />
      </div>
    );
  }

  return <></>;
};
