export enum DealStatus {
  Default = '',
  Outbound = 'Outbound',
  Inbound = 'Inbound',
  Unpaired = 'Unpaired',
  Paired = 'Paired',
  Terminated = 'Terminated',
  Rejected = 'Rejected',
  PairedException = 'PairedException',
  Withdrawn = 'Withdrawn'
}
