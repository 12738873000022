import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IResourceRepository } from '../../../shared/api/interfaces/IResourceRepository';
import { IResource } from '../../../shared/models/Role';
import { RootState } from '../../store';
import { ResourceThunks } from '../../thunks/resource.thunks';

type InitialStateType = {
  resources: IResource[];
};

const resources: IResource[] = [];

export const initialState: InitialStateType = {
  resources: resources
};

const resourceRepository = container.get<IResourceRepository>(SERVICE_KEYS.RESOURCE_REPOSITORY);
const resourceThunks = new ResourceThunks(resourceRepository);

export const fetchResources = resourceThunks.getAll();
export const fetchResourcesByProductId = resourceThunks.getAllByProductId();
export const addResource = resourceThunks.add();

const slice = {
  name: 'resource',
  initialState,
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchResources.fulfilled,
      (state: any, { payload }: PayloadAction<IResource[]>) => {
        state.resources = payload;
      }
    );

    builder.addCase(
      addResource.fulfilled,
      (state: any, { payload }: PayloadAction<{ data: IResource }>) => {
        const resource = payload.data;
        state.resources.push(resource);
      }
    );
  }
};

export const resourceSlice = createSlice({
  ...slice,
  reducers: {}
});

export const resourceReducer = resourceSlice.reducer;
export const getResource = (state: RootState) => state.resource;
