import { useDidUpdate, useUpdate, useValueMemo } from '@core/hooks';
import _ from 'lodash';
import { useMemo, useRef } from 'react';
import isEq from 'react-fast-compare';
import { StyleGetter, StyleObject, ThemeCV } from '../typings';
import { useTheme } from './useTheme';

const isDev = _.includes(window.location.hostname, 'localtest');

const noWatch = ['children', 'sty', 'className'];

export type UseStylesConfig = {
  watchDarkMode?: boolean;
  watchSidebar?: boolean;
  watchPalette?: boolean;
};

export function useStyles<Props extends object = any, Theme extends ThemeCV = ThemeCV>(
  getStyles: StyleGetter<Props, Theme>,
  props: Props = {} as Props,
  _config: UseStylesConfig = {}
) {
  const config = useMemo(() => _config, []);
  const update = useUpdate();
  const wProps = useValueMemo(
    () => _.omitBy(props, (val, key) => _.includes(noWatch, key) || _.isFunction(val)),
    [props]
  );

  const theme = useTheme();
  const { isDark, colors, sidebarCurrentWidth } = theme;

  const initialVal = useMemo(() => getStyles(theme as Theme, props), []);
  const valRef = useRef<StyleObject>(initialVal);
  useDidUpdate(
    () => {
      const patch = getStyles(theme as Theme, props);
      if (!isEq(patch, valRef.current)) {
        valRef.current = patch;
        update();
      }
    },
    [
      wProps,
      config.watchDarkMode === false ? undefined : isDark,
      config.watchPalette === false ? undefined : colors,
      config.watchSidebar ? sidebarCurrentWidth : undefined
    ],
    isEq
  );

  /* in development, also watch changes to the getStyles fn (hot module updates) and apply patch */
  const devPatch = useRef<StyleObject>({});
  if (isDev) {
    devPatch.current = getStyles(theme as Theme, props);
    if (!isEq(_.omitBy(devPatch.current, _.isFunction), _.omitBy(valRef.current, _.isFunction))) {
      valRef.current = devPatch.current;
    }
  }

  return valRef.current;
}
