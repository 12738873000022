import { themes } from '@core/theme';
import { cn } from '@core/util';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { StyledButton } from './OxButton.styles';
import { toInnerRef } from './util';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  standard = 'standard',
  disable = 'disable',
  danger = 'danger',
  icon = 'icon',
  ghost = 'ghost',
  text = 'text',
  inverse = 'inverse',
  settings = 'settings'
}

export enum ButtonSize {
  compact = 'compact',
  default = 'default',
  large = 'large'
}

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  text?: string;
}

export const OxButton = ({
  buttonType,
  buttonSize = ButtonSize.default,
  text,
  className,
  children,
  disabled,
  type = 'submit',
  ...rest
}: ButtonProps): React.ReactElement<ButtonProps> => {
  const ref = { ...rest }?.ref;
  const buttonRef = toInnerRef(ref || undefined);

  const button = (
    <StyledButton
      data-testid='OxButton'
      type={type}
      disabled={disabled}
      className={cn(
        'ox-button',
        buttonType && `ox-button-${buttonType}`,
        `ox-button-${buttonSize}`,
        className,
        disabled && 'ox-button-disable'
      )}
      {...rest}
      ref={buttonRef}
    >
      <div className='flex flex-row items-center justify-center'>
        {children}
        {text}
      </div>
    </StyledButton>
  );

  return <ThemeProvider theme={themes.lightTheme}>{button}</ThemeProvider>;
};
