import styled from 'styled-components';

export const CalendarInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
    width: 100%;
    font-size: 12px;
  }

  input.field-default {
    padding-left: 2px !important;
  }

  .no-bdr-top {
    top: 1.5rem;
  }
`;
