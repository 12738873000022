import { ExclamationCircleSvg } from '@core/images';
import { OxTable } from '@core/table';
import { themes } from '@core/theme';
import { KeyboardIds } from '@core/typings';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact as AgGridReactType } from 'ag-grid-react';
import { useField } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { ThemeProvider } from 'styled-components';
import { StyledFieldErrorMessage } from '../components';
import { StyledInputGridContainer, TableContainer } from './OxInputGrid.styles';
export interface InputGridProps {
  colDefs: ColDef[];
  label?: string;
  dropdownFieldName: string;
  modalFieldName: string;
  rowData: any[];
  errors: {
    error: string;
    touched: string;
  };
  initialValue?: string;
  /** @deprecated do not pass this prop */
  useAppSelector: unknown;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const OxInputGrid = ({
  colDefs,
  label,
  dropdownFieldName,
  modalFieldName,
  errors,
  setFieldValue,
  rowData,
  initialValue = ''
}: InputGridProps) => {
  const grid = useRef<AgGridReactType>();
  const [searchText, setSearchText] = useState('');
  const [selectedValue, setSelectedValue] = useState(
    initialValue ? rowData.find((x) => x[dropdownFieldName] === initialValue) : null
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleToggle = () => setIsOpen((isOpen) => !isOpen);
  const [field] = useField(modalFieldName);
  const input = document.getElementById(modalFieldName);

  useEffect(() => {
    setFieldValue(modalFieldName, searchText, true);

    if (searchText.length > 1 && !selectedValue && !isOpen) {
      setIsOpen(true);
    }

    if (selectedValue && searchText) {
      setIsOpen(false);
      setSelectedValue(undefined);
    }
  }, [searchText]);

  useEffect(() => {
    if (selectedValue) {
      setSearchText(selectedValue[dropdownFieldName]);
      setIsOpen(false);
    }
  }, [selectedValue]);

  const selectValue = ({ data }: RowClickedEvent) => {
    setSelectedValue(data);
  };

  input?.addEventListener(
    'keydown',
    (event) => {
      if (event.key === KeyboardIds.ARROW_DOWN) {
        event.preventDefault();
        if (grid.current) {
          const { api, columnApi } = grid.current;
          api.ensureIndexVisible(0);

          const firstCol = columnApi.getAllDisplayedColumns()[0];
          api.ensureColumnVisible(firstCol);
          api.setFocusedCell(0, firstCol);
        }
      }
    },
    true
  );

  const onCellKeyDown = (params: any) => {
    const { event, data } = params;
    if (event?.key === KeyboardIds.ENTER) {
      event.preventDefault();
      setSelectedValue(data);
    }
  };

  const popoverContent = () => (
    <TableContainer>
      <OxTable
        ref={grid}
        rowData={rowData}
        columnDefs={colDefs}
        domLayout='autoHeight'
        customSearchText={searchText}
        onRowClicked={selectValue}
        onCellKeyDown={onCellKeyDown}
        stopEditingWhenCellsLoseFocus
        onGridReady={() => {
          for (let i = 1; i < colDefs.length; i++) {
            const { field } = colDefs[i];
            if (field) {
              grid.current?.columnApi.autoSizeColumn(field);
            }
          }
        }}
      />
    </TableContainer>
  );

  const cellEditContainer = (
    <StyledInputGridContainer className='cell-edit-container w-full rounded'>
      <Popover
        isOpen={isOpen}
        content={() => popoverContent()}
        positions={['bottom']}
        align='end'
        ref={input as any}
        containerClassName='z-50 w-[600px]'
        onClickOutside={handleToggle}
      >
        <input
          {...field}
          id={modalFieldName}
          className='h-[42px] w-full rounded pl-3'
          type='text'
          name={modalFieldName}
          placeholder={label}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Popover>
      {errors?.touched && errors?.error && (
        <StyledFieldErrorMessage className='field-error-message text-sm flex'>
          <ExclamationCircleSvg height={16} className='mr-1' style={{ minWidth: 16 }} />
          {errors?.error}
        </StyledFieldErrorMessage>
      )}
    </StyledInputGridContainer>
  );

  return <ThemeProvider theme={themes.lightTheme}>{cellEditContainer}</ThemeProvider>;
};
