import { ButtonSize, ButtonType, OxTypography, TextColor, TextWeight } from '@core/components';
import { ChevronDownSvg, CloseOutlineSvg } from '@core/images';
import { themes } from '@core/theme';
import { debounce } from '@core/util';
import { ColDef } from 'ag-grid-community';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Popover, PopoverAlign } from 'react-tiny-popover';
import { NomTableSelector } from 'Sections/Nom/Common/Util/tableHelper';
import { ThemeProvider } from 'styled-components';
import { StyledMultiSelectButton } from '../components';
import { SelectOption } from '../OxSelect';
import { buttonSizeStylesMap, defaultButtonSizeStyle } from '../util';
import {
  StyledMultiSelect,
  StyledMultiSelectCheckmark,
  StyledMultiSelectContent,
  StyledTextInput
} from './OxMultiSelect.styles';

interface ButtonProps<T = string>
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {
  text: string;
  options: SelectOption<T>[];
  selectedOptions: SelectOption<T>[];
  handleChange: any;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  isShowLabel?: boolean;
  classes?: string;
  showSelectedOptionsText?: boolean;
  containerClassName?: string;
  dropDownClassName?: string;
  buttonClassName?: string;
  dropDownWidth?: number;
  maxSelectedOptionsShown?: number;
  menuAlign?: PopoverAlign;
  allowTypeAhead?: boolean;
  gridFields?: undefined | ColDef[];
  onSelectAll?: () => void;
  onClearAll?: () => void;
}

export const OxMultiSelect = <T,>(props: ButtonProps<T>): React.ReactElement<ButtonProps<T>> => {
  const {
    text,
    options,
    classes,
    selectedOptions,
    handleChange,
    buttonType = ButtonType.primary,
    buttonSize = ButtonSize.default,
    isShowLabel = true,
    showSelectedOptionsText = true,
    containerClassName,
    dropDownClassName,
    buttonClassName,
    dropDownWidth = 0,
    disabled = false,
    maxSelectedOptionsShown = Infinity,
    menuAlign = 'end',
    allowTypeAhead = false,
    gridFields,
    onSelectAll,
    onClearAll,
    ...rest
  } = props;

  const [isOpen, setOpen] = useState(false);
  const handleToggle = () => setOpen((isOpen) => !isOpen);

  const [typeAheadText, setTypeAheadText] = useState('');
  const [filterText, setFilterText] = useState('');
  const showAllSelected = maxSelectedOptionsShown === Infinity;

  const debouncedSetFilterText = useMemo(
    () => debounce((value: string) => setFilterText(value), 250),
    []
  );

  useEffect(() => {
    if (!selectedOptions.length) {
      setTypeAheadText('');
    }
  }, [selectedOptions]);

  const handleTypeAheadChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setTypeAheadText(value);
    debouncedSetFilterText(value);
  };
  /* prettier-ignore */
  const visibleOptions = filterText === ''
    ? options
    : options.filter((option) => option.label.toLowerCase().includes(filterText.toLowerCase()));

  const buttonRef = useRef<HTMLElement | undefined>();

  const { typography, sizeClasses, iconWidth, iconHeight, buttonHeight } =
    buttonSizeStylesMap.get(buttonSize) ?? defaultButtonSizeStyle;

  const buttonRest = { ...rest } as any;

  let selectedValuesBody: JSX.Element;

  if (!showSelectedOptionsText || selectedOptions.length === 0) {
    selectedValuesBody = (
      <OxTypography {...typography} weight={TextWeight.Regular} color={TextColor.VeryLight}>
        {text}
      </OxTypography>
    );
  } else {
    const hiddenCount = showAllSelected ? 0 : selectedOptions.length - maxSelectedOptionsShown;

    selectedValuesBody = (
      <>
        {
          // Display up to `maxSelectedOptionsShown` options on the button
          selectedOptions.slice(0, maxSelectedOptionsShown).map((selectedOption, i) => (
            <span
              title={(selectedOption.label as unknown as string) || ''}
              className={`value-tag inline-flex items-center bg-gray-100 rounded-sm mr-1 px-2 ${
                showAllSelected && i !== 0 ? 'mt-1' : ''
              } cursor-default`}
              key={i}
            >
              <OxTypography className='selected-label' {...typography}>
                {(selectedOption.label as unknown as string) || ''}
              </OxTypography>
              <span
                className='text-black block outline-none cursor-pointer'
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  handleChange(selectedOption);
                }}
              >
                <CloseOutlineSvg width={16} height={16} className='close-icon' />
              </span>
            </span>
          ))
        }
        {
          // Display a counter if there are any other selected options
          hiddenCount > 0 ? (
            <span className='counter-tag inline-flex items-center bg-gray-100 rounded-sm mr-1 px-2 cursor-default'>
              <OxTypography className='selected-label' {...typography}>
                {`+${hiddenCount}`}
              </OxTypography>
            </span>
          ) : (
            <div />
          )
        }
      </>
    );
  }

  const multiSelect = (
    <StyledMultiSelect
      className={`inline-block w-full ${containerClassName ? ' ' + containerClassName : ''}`}
    >
      {isShowLabel && <label htmlFor=''>{text}</label>}
      <Popover
        containerClassName='z-50'
        onClickOutside={handleToggle}
        isOpen={isOpen}
        positions={['bottom', 'top']}
        align={menuAlign ?? 'end'}
        ref={buttonRef as any}
        content={() => (
          <div
            className='z-20 bg-white shadow-lg py-2 rounded'
            onClick={(e) => e.stopPropagation()}
          >
            {allowTypeAhead && (
              <div className='px-2'>
                <StyledTextInput
                  className='text-md'
                  placeholder='Search...'
                  onChange={handleTypeAheadChange}
                  value={typeAheadText}
                />
                <hr className='my-2' />
              </div>
            )}
            <StyledMultiSelect
              id='dropDownContent'
              style={{
                minWidth: dropDownWidth ? dropDownWidth : buttonRef.current?.clientWidth
              }}
              className={`${dropDownClassName ? ' ' + dropDownClassName : ''}`}
            >
              {gridFields && (
                <NomTableSelector
                  fields={gridFields}
                  fieldValue='value'
                  options={visibleOptions}
                  onSelectAll={onSelectAll}
                  onClearAll={onClearAll}
                  onSelect={(e: any) => {
                    const selectedOption = visibleOptions.find((so) => so.value === e);
                    handleChange(selectedOption);
                  }}
                  stopEditing={handleToggle}
                />
              )}

              {!gridFields &&
                visibleOptions.map(({ label, value }) => {
                  const selectedOption = selectedOptions.filter((so) => so.value === value);

                  return (
                    <StyledMultiSelectContent
                      className='ox-multiselect-content relative py-2 px-2 flex items-center w-full'
                      key={label}
                    >
                      <input
                        className='mr-3'
                        type='checkbox'
                        name={value + 'option'}
                        checked={selectedOption.length === 1}
                        onChange={() => handleChange({ label, value })}
                      />
                      <StyledMultiSelectCheckmark className='checkmark border border-gray-300' />
                      <OxTypography {...typography}>{label}</OxTypography>
                    </StyledMultiSelectContent>
                  );
                })}
            </StyledMultiSelect>
          </div>
        )}
      >
        <div>
          <StyledMultiSelectButton
            type='button'
            buttonHeight={buttonHeight}
            className={`multi-select ox-${buttonType.toString()} ${sizeClasses} ${classes} ${
              showAllSelected ? '' : 'h-4'
            } relative inline-flex items-center w-full rounded border border-gray-500 shadow-sm font-medium px-2 py-0 ${
              isOpen ? 'field-focus' : 'field-default'
            }${buttonClassName ? ' ' + buttonClassName : 'mt-1 mb-3'}`}
            onClick={handleToggle}
            {...buttonRest}
          >
            <span
              data-testid='OxMultiSelectedValues'
              className={`truncate w-full text-left ${showAllSelected ? 'flex flex-wrap' : ''}`}
            >
              {selectedValuesBody}
            </span>
            {disabled ? null : (
              <ChevronDownSvg
                width={iconWidth}
                height={iconHeight}
                className={`select-icon transform ${isOpen ? 'rotate-180' : ''}`}
              />
            )}
          </StyledMultiSelectButton>
        </div>
      </Popover>
    </StyledMultiSelect>
  );

  return <ThemeProvider theme={themes.lightTheme}>{multiSelect}</ThemeProvider>;
};
