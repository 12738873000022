import { OxLoadingV2 } from '@core/components';
import { useGetSetState, useValueMemo } from '@core/hooks';
import { RootState, useAppSelector } from '@core/redux';
import { ComposedSfc, IOxResource, IOxRoleResource, Nullable } from '@core/typings';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useUser } from '../hooks';
import { PermissionsContext } from '../Permissions.context';
import {
  Permission,
  PermissionMap,
  PermissionsCV,
  PermissionsLib,
  PermissionsState
} from '../typings';

const selectResources = (state: RootState) => state.resource.resources;
const selectRoles = (state: RootState) => state.role.roles;

export function withPermissions(Composed: ComposedSfc): ComposedSfc {
  return function WithPermissions(props: any) {
    const { data: User } = useUser();
    const [isReady, setIsReady] = useState(false);
    /* IOxResource is actually the correct typing; the redux typings are screwed up */
    const allResources = useAppSelector(selectResources) as unknown as IOxResource[];
    const allRoles = useAppSelector(selectRoles);

    const [getState, setState] = useGetSetState<PermissionsState>();

    useEffect(() => {
      /* get all enabled permissions for the user's role */
      const enabledRolePermissions =
        _.find(allRoles, { id: _.toString(User.role?.id) })?.roleResources || [];
      const enabledRoleResourceMap = _.keyBy(enabledRolePermissions, 'resourceId');
      /* go through ALL stored resources and determine whether they're enabled in role */
      const permissionsMap = _.reduce<IOxResource, PermissionMap>(
        allResources,
        (memo, resource) => {
          const rResource = enabledRoleResourceMap[resource.id] as IOxRoleResource;
          memo[resource.name] = { ...resource, enabled: rResource?.enabled ?? false };
          return memo;
        },
        {}
      );
      setState({ permissionsMap });
      setIsReady(true);
    }, [User.role?.id]);

    const lib = useMemo<PermissionsLib>(
      () => ({
        userCan: (name: string): Nullable<boolean> => {
          const pMap = getState().permissionsMap;
          return pMap[name]?.enabled ?? null;
        },
        userCanVisit: (_route: string, _hash?: string): Nullable<boolean> => {
          const pMap = getState().permissionsMap;
          const route = _.startsWith(_route, '/') ? _route : `/${_route}`;
          const hash = _hash ? `${_.startsWith(_hash, '#') ? '' : '#'}${_hash}` : '';
          const subPage = hash ? pMap[`${route}${hash}`] : undefined;
          if (subPage) return subPage.enabled;

          const page = pMap[route];
          if (page) return page.enabled;
          return null;
        },
        findByName: (name: string): Nullable<Permission> => getState().permissionsMap[name] ?? null
      }),
      []
    );

    const value = useValueMemo<PermissionsCV>(() => ({ ...lib, ...getState() }), [getState()]);

    if (!isReady) return <OxLoadingV2 />;
    return (
      <PermissionsContext.Provider value={value}>
        <Composed {...props} />
      </PermissionsContext.Provider>
    );
  };
}
