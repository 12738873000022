import { ColorRef, StyleGetter } from '@core/theme/typings';

export type TransferStyleProps = {
  color?: ColorRef;
  rtl?: boolean;
};

export const css: StyleGetter<TransferStyleProps> = (theme, { color = 'primary', rtl }) => {
  const {
    getColor,
    getTextColor,
    fade,
    toGtr,
    gutter: gtr,
    colors: { canvas, text },
    colorWheel: { common }
  } = theme;
  const clr = getColor(color);
  const textClr = getTextColor(color);
  return {
    wpr: {
      display: 'flex',
      flex: '1 0',
      flexDirection: rtl ? 'row-reverse' : 'row',

      '.grid-wpr': {
        display: 'flex',
        flex: '1 1 auto',

        '> div': { height: '100%', width: '100%' },

        '.transfer-grid .ag-root-wrapper': {
          '.ag-header-row .ag-header-group-cell': {
            backgroundColor: clr,
            color: textClr
          },

          '.ag-row.ag-row-focus .ag-cell': {
            backgroundColor: common.clear
          },

          '.ag-row.ag-row-selected .ag-cell': {
            backgroundColor: fade(clr, 88)
          }
        }
      },
      '.transfer-btns-wpr': {
        margin: toGtr('auto', gtr * 1.5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        'button:first-child': {
          marginBottom: gtr
        }
      },
      '&.--is-disabled': {
        '.transfer-grid .ag-root-wrapper': {
          '.ag-header-row .ag-header-group-cell': {
            backgroundColor: canvas.disabled,
            color: text.disabled
          }
        }
      }
    }
  };
};
