import { dateComparator, monthComparator } from '@core/table-util';
import { ColDef } from 'ag-grid-community';
import _ from 'lodash';
import { formatters } from './formatters';

const mathAggFuncs = ['avg', 'count', 'first', 'last', 'max', 'med', 'min', 'sum'];
const stringAggFuncs = ['unique', 'count', 'first', 'last', 'list'];

const dateAgg = {
  initialAggFunc: 'unique',
  allowedAggFuncs: stringAggFuncs
};

export const standardColumnTypes: Record<string, ColDef> = {
  text: {
    initialAggFunc: 'unique',
    allowedAggFuncs: stringAggFuncs,
    valueFormatter: formatters.string
  },
  number: {
    allowedAggFuncs: mathAggFuncs,
    valueFormatter: formatters.number
  },
  monthString: {
    ...dateAgg,
    valueFormatter: formatters.month,
    filter: 'agDateColumnFilter',
    comparator: monthComparator
  },
  dateString: {
    ...dateAgg,
    valueFormatter: formatters.date,
    filter: 'agDateColumnFilter',
    comparator: dateComparator
  },
  datetimeString: {
    ...dateAgg,
    valueFormatter: formatters.datetime,
    filter: 'agDateColumnFilter'
  },
  object: {
    initialAggFunc: 'unique',
    allowedAggFuncs: stringAggFuncs,
    comparator: (valA, valB) => {
      const a = _.toString(valA);
      const b = _.toString(valB);
      if (!a && !b) return 0;
      else if (!a) return 1;
      return a > b ? 1 : -1;
    }
  },
  timeString: {
    ...dateAgg,
    valueFormatter: formatters.time,
    filter: 'agDateColumnFilter'
  },
  get month() {
    return this.monthString;
  },
  get date() {
    return this.dateString;
  },
  get datetime() {
    return this.datetimeString;
  },
  get time() {
    return this.timeString;
  }
};
