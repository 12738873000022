import styled from 'styled-components';

export const StyledSelectFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;

  .select-icon {
    right: 10px;
    top: calc(50% - 11px);

    stroke: ${(props) => props.theme.secondaryDropDownText};
  }
`;

export const StyledSelectLabel = styled.label`
  select {
    min-height: 47px;
    height: 1rem;
    background-position: right 5px top 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;

    option {
      height: 1rem;
      padding: 10px 0;
    }

    option:first-child {
      padding-top: 3px;
    }
  }
`;
