import { client } from '@core/rest-client';
import { IOxResource } from '@core/typings/Resource';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IResourceRepository } from '../../interfaces/IResourceRepository';

@injectable()
export class ResourceRepository implements IResourceRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('v1/resources');

  getAll = async (): Promise<AxiosResponse<IOxResource[]>> => {
    const response = await client.get(this.apiUrl);
    return response.data;
  };

  getAllByProductId = async (productId: string): Promise<AxiosResponse<IOxResource[]>> => {
    return await client.get(`${this.apiUrl}/product/${productId}`);
  };

  getOne = async (id: string): Promise<AxiosResponse<IOxResource[]>> => {
    return await client.get(`${this.apiUrl}/${id}`);
  };

  add = async (resource: any): Promise<AxiosResponse> => {
    return await client.post(this.apiUrl, resource);
  };

  update = async (resource: any): Promise<AxiosResponse> => {
    return await client.put(this.apiUrl, resource);
  };

  delete = async (id: string): Promise<any> => {
    return await client.delete(`${this.apiUrl}`, { data: { id: id } });
  };
}
