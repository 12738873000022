import _ from 'lodash';

export const getDynamicRouteParams = (path: string) =>
  _.reduce(
    _.split(path, '/'),
    (memo, part) => {
      if (_.startsWith(part, ':')) memo.push(_.trim(part, ':'));
      return memo;
    },
    [] as string[]
  );
