import _ from 'lodash';
import { create } from 'nano-css';
import { addon as Cache } from 'nano-css/addon/cache';
import { addon as Important } from 'nano-css/addon/important';
import { addon as Keyframes } from 'nano-css/addon/keyframes';
import { addon as Nesting } from 'nano-css/addon/nesting';
import { addon as Prefixer } from 'nano-css/addon/prefixer';
import { addon as Rule } from 'nano-css/addon/rule';
import { addon as Sheet } from 'nano-css/addon/sheet';
import { addon as Unitless } from 'nano-css/addon/unitless';
import { virtualCssOm } from './plugins';
import { RealNanoRenderer } from './plugins/virtualCssOm.types';

let ctr = 0;
const addonMap = {
  cache: Cache,
  important: Important,
  keyframes: Keyframes,
  nesting: Nesting,
  prefixer: Prefixer,
  rule: Rule,
  sheet: Sheet,
  unitless: Unitless,
  virtualCssOm
} as const;

type AddOn = keyof typeof addonMap;
const dftAddOns = _.without(_.keys(addonMap), 'virtualCssOm') as AddOn[];

export function createNano({
  addOns = dftAddOns,
  prefix
}: { addOns?: AddOn[]; prefix?: string } = {}): RealNanoRenderer {
  const nano = create({ pfx: `${prefix || 'ox'}-${++ctr}-` }) as RealNanoRenderer;
  try {
    _.each(addOns, (addOn) => {
      addonMap[addOn](nano);
    });

    return nano;
  } catch (e) {
    throw new Error(`Failed to add nano-css: ${_.toString(e)}`);
  }
}

export const nano: RealNanoRenderer = createNano({
  addOns: ['cache', 'important', 'prefixer', 'unitless', 'virtualCssOm']
});

export function useNanoCss() {
  return nano;
}
