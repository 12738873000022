import styled from 'styled-components';

export const StyledTabRoutes = styled.div`
  &.no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .template-button-active {
    font-family: 'GibsonRegular';
    color: ${(props) => props.theme.referenceDataTemplateActive};
    border-bottom: 2px ${(props) => props.theme.referenceDataTemplateActive} solid;
    border-radius: 0;
  }

  .template-button-inactive {
    font-family: 'GibsonRegular';
    color: ${(props) => props.theme.referenceDataTemplateInactive};
  }
`;

export const OxScroll = styled.div`
  overflow-x: auto;
  display: flex;
  width: 93%;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .scroll-icon {
    top: 3px;
    padding: 10px;
    cursor: pointer;
  }
`;
