import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.base};

  .field-default {
    border: 1px solid ${(props) => props.theme.contrast_15};
  }

  .field-focus {
    border: 2px ${(props) => props.theme.fieldFocus} solid;
  }
`;

export const StyledIconContainer = styled.button`
  min-height: 38px;
  height: 1rem;

  .select-icon {
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 10px;

    stroke: ${(props) => props.theme.secondaryDropDownText};
  }
`;

export const StyledCheckBoxContent = styled.label`
  color: ${(props) => props.theme.dropDownContentText};
  font-size: 14px;
  cursor: pointer;

  input[type='checkbox'] {
    visibility: hidden;
  }

  input:active ~ .checkmark {
    background-color: ${(props) => props.theme.checkmark};
    border-color: transparent;
  }

  input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.checkmark};
    border-color: transparent;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`;

export const StyledDropDownContent = styled.div`
  color: ${(props) => props.theme.dropDownContentText};
  font-size: 14px;
  cursor: pointer;
`;

export const StyledCheckmark = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 16px;
  width: 16px;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  &:after {
    left: 6px;
    bottom: 3px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
