import { JTDInferredValType } from '@core/user-configs/typings';
import { UserConfigs } from '../../UserConfigs';

const schema = {
  properties: {
    isAutoHideSidebarOnWorkspacePages: { type: 'boolean' },
    isPreferCentralTime: { type: 'boolean' },
    isPreferDarkMode: { type: 'boolean' }
  }
} as const;

export type UserAppSettings = JTDInferredValType<typeof schema>;

export const userAppSettingsConfigKey = UserConfigs.register<UserAppSettings>({
  type: 'custom',
  key: 'appSettings',
  initialValue: {
    isAutoHideSidebarOnWorkspacePages: false,
    isPreferCentralTime: false,
    isPreferDarkMode: false
  },
  label: 'User App Settings',
  schema
});
