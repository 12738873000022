import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ICounterpartyRepository } from 'shared/api/interfaces/ICounterpartyRepository';
import {
  ICounterparty,
  ICounterpartySpec,
  ICounterpartySpecRequest
} from 'shared/models/Counterparty';
import { IBaseThunk } from './base.thunks';

export class CounterpartyThunks implements IBaseThunk<ICounterparty> {
  private counterpartyRepository!: ICounterpartyRepository;

  constructor(_counterpartyRepository: ICounterpartyRepository) {
    this.counterpartyRepository = _counterpartyRepository;

    if (this.counterpartyRepository === null) {
      throw new Error('counterpartyRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'counterparties';

  add(): AsyncThunk<HttpResponse<ICounterparty>, ICounterparty, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addCounterparty`;

    return createAsyncThunk(action, async (cp: ICounterparty, { rejectWithValue }) => {
      try {
        return await this.counterpartyRepository.add(cp);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  forceUpdate(): AsyncThunk<HttpResponse<ICounterparty>, ICounterparty, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/forceUpdateCounterparty`;

    return createAsyncThunk(action, async (cp: ICounterparty, { rejectWithValue }) => {
      try {
        return await this.counterpartyRepository.forceUpdate(cp);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<ICounterparty>, ICounterparty, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateCounterparty`;

    return createAsyncThunk(action, async (cp: ICounterparty, { rejectWithValue }) => {
      try {
        return await this.counterpartyRepository.update(cp);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<ICounterparty, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteCounterparty`;

    return createAsyncThunk(action, (id: string) => this.counterpartyRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<ICounterparty, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteCounterparties`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.counterpartyRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<ICounterparty[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchCounterparties`;

    return createAsyncThunk(action, () => this.counterpartyRepository.getAll());
  }

  getSpecs(): AsyncThunk<AxiosResponse<ICounterpartySpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchCounterpartySpecs`;

    return createAsyncThunk(action, () => this.counterpartyRepository.getSpecs());
  }

  getById(): AsyncThunk<AxiosResponse<ICounterparty, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchCounterpartyById`;

    return createAsyncThunk(action, (id: string) => this.counterpartyRepository.getById(id));
  }

  createSpecRequest(): AsyncThunk<
    AxiosResponse<ICounterpartySpecRequest>,
    ICounterpartySpecRequest,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/createCounterpartySpecRequest`;

    return createAsyncThunk(
      action,
      async (specRequest: ICounterpartySpecRequest, { rejectWithValue }) => {
        try {
          return await this.counterpartyRepository.createSpecRequest(specRequest);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }
}
