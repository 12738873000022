import { css } from './Menu.style';

import { useValueMemo } from '@core/hooks';
import { useCss } from '@core/theme';
import { cn } from '@core/util';
import _ from 'lodash';
import RcMenu from 'rc-menu';
import { useCallback } from 'react';
import { IMenuEntry, IMenuItem, MenuProps } from './Menu.types';
import { createRenderMenuItem } from './renderMenuItem';

export function Menu(props: MenuProps) {
  const {
    isBordered = true,
    isHoverable = true,
    isRounded = true,
    isStriped = false,
    items,
    className,
    layout = 'normal',
    maxWidth,
    minWidth,
    ...rest
  } = props;
  const cls = useCss(css, { maxWidth, minWidth });

  const renderMenuItem = useCallback(
    createRenderMenuItem({ itemCount: items.length, isBordered }),
    [items.length, isBordered]
  );
  const render = useCallback(
    (item: IMenuItem, index: number) => renderMenuItem(item, index),
    [renderMenuItem]
  );
  const hasIcons = useValueMemo(
    () =>
      _.some(items, ({ isSubmenu, ...rest }) => {
        if (isSubmenu) return false;

        const { icon, activeIcon } = rest as IMenuEntry;
        return !!icon || !!activeIcon;
      }),
    [items.length]
  );

  return (
    <RcMenu
      className={cn(className, cls.menu, `--layout-${layout}`, {
        '--has-icons': hasIcons,
        '--is-hoverable': isHoverable && !isStriped,
        '--is-rounded': isRounded,
        '--is-striped': isStriped
      })}
      inlineIndent={0}
      mode='inline'
      activeKey=''
      {...rest}
      selectable={false}
    >
      {_.map(items, render)}
    </RcMenu>
  );
}
