import { UserChartOutlineSvg, UserChartSvg, UsersCogOutlineSvg, UsersCogSvg } from '@core/images';
import { ILinkInfo } from '../OxSidebar.types';

export const getOxAdminPageLinks: () => ILinkInfo[] = () => [
  {
    LinkIcon: UsersCogOutlineSvg,
    SelectedLinkIcon: UsersCogSvg,
    linkText: 'oxSidebar.oxAdmin.userPermission',
    route: 'products/admin/user-permission'
  },
  {
    LinkIcon: UserChartOutlineSvg,
    SelectedLinkIcon: UserChartSvg,
    linkText: 'oxSidebar.oxAdmin.userReports',
    route: 'products/admin/user-reports'
  }
];
