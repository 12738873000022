import styled from 'styled-components';

export const StyledSearch = styled.div`
  &.search-field-compact {
    padding: 3.5px 9px 3.5px 12px;
  }

  &.search-field-default {
    padding: 7.5px 9px 7.5px 12px;
  }

  .search-field-text {
    color: ${(props) => props.theme.contrast - 25};
  }

  .search-field-text::selection {
    background: ${(props) => props.theme.fieldHighlight};
  }

  .search-field-icon {
    color: ${(props) => props.theme.contrast - 45};
  }

  .search-field:focus-within {
    border: 1px ${(props) => props.theme.fieldFocus} solid !important;
  }
`;
