import styled from 'styled-components';

export const StyledModalCover = styled.div`
  background-color: ${(props) => props.theme.modalCoverBackground};
`;

export const StyledModalHeader = styled.div`
  border-radius: 0px;
  padding: 16px 24px 16px 24px;
  border-bottom: 1px ${(props) => props.theme.modalDefaultBorder} solid;
  position: relative;
`;

export const Body = styled.div`
  padding: 24px 24px 24px 24px;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0px;
  padding: 10px 24px 10px 24px;
  border-top: 1px ${(props) => props.theme.modalDefaultBorder} solid;
  button {
    min-width: 70px;
  }
`;

export const StyledModal = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.modalDefaultBackground};
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;
