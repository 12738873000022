import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IChargeTypeRepository } from 'shared/api/interfaces/IChargeTypeRepository';
import { IChargeType, IChargeTypeSpec } from 'shared/models/ChargeType';

import { IBaseThunk } from './base.thunks';

export class ChargeTypeThunks implements IBaseThunk<IChargeType> {
  private chargeTypeRepository!: IChargeTypeRepository;

  constructor(_chargeTypeRepository: IChargeTypeRepository) {
    this.chargeTypeRepository = _chargeTypeRepository;

    if (this.chargeTypeRepository === null) {
      throw new Error('chargeTypeRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'chargeTypes';

  add(): AsyncThunk<HttpResponse<IChargeType>, IChargeType, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addChargeType`;

    return createAsyncThunk(action, async (chargeType: IChargeType, { rejectWithValue }) => {
      try {
        return await this.chargeTypeRepository.add(chargeType);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<IChargeType>, IChargeType, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateChargeType`;

    return createAsyncThunk(action, async (chargeType: IChargeType, { rejectWithValue }) => {
      try {
        return await this.chargeTypeRepository.update(chargeType);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<IChargeType, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteChargeType`;

    return createAsyncThunk(action, (id: string) => this.chargeTypeRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<IChargeType, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteChargeType`;

    return createAsyncThunk(action, async (ids: string[][], { rejectWithValue }) => {
      try {
        return await this.chargeTypeRepository.deleteAll(ids[0]);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  getAll(): AsyncThunk<AxiosResponse<IChargeType[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchChargeTypes`;

    return createAsyncThunk(action, () => this.chargeTypeRepository.getAll());
  }

  getSpecs(): AsyncThunk<AxiosResponse<IChargeTypeSpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchChargeTypeSpecs`;

    return createAsyncThunk(action, () => this.chargeTypeRepository.getSpecs());
  }
}
