import _ from 'lodash';
import numeral from 'numeral';

export type CreateNumeralFormatterConfig = {
  formatString: string;
  decimalPrecision?: number;
};

export const createNumeralFormatter = (conf: CreateNumeralFormatterConfig) => (v: any) => {
  const { decimalPrecision = 8, formatString } = conf;
  let val;
  if (_.isNull(v)) return null;

  if (_.isFinite(v)) val = v;
  else if (_.isFinite(_.toNumber(v))) val = _.toNumber(v);

  if (!_.isFinite(val)) return v;
  return numeral(_.round(val, decimalPrecision)).format(formatString);
};
