import { useAuth0 } from '@auth0/auth0-react';
import { useCompanyConfig } from '@core/company-config';
import { useLogout } from '@core/user';
import { useEffect } from 'react';

export function useSessionTimeout() {
  const { isAuthenticated } = useAuth0();
  const { sessionTimeout = 0 } = useCompanyConfig().companyConfig;
  const logout = useLogout();

  useEffect(() => {
    if (isAuthenticated && sessionTimeout > 0) {
      let logoutTimer: string | number | NodeJS.Timeout | undefined;

      logoutTimer = setTimeout(logout, sessionTimeout);

      const resetTimer = () => {
        clearTimeout(logoutTimer);
        logoutTimer = setTimeout(logout, sessionTimeout);
      };

      window.addEventListener('mousedown', resetTimer);
      window.addEventListener('keydown', resetTimer);

      // Remove event listeners on component unmount
      return () => {
        window.removeEventListener('mousedown', resetTimer);
        window.removeEventListener('keydown', resetTimer);
        clearTimeout(logoutTimer);
      };
    }
  }, [isAuthenticated, sessionTimeout]);
}
