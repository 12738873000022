import { useAuth0 } from '@auth0/auth0-react';
import { withCompanyConfig } from '@core/company-config';
import { OxHeader, OxLoadingV2 } from '@core/components';
import { ScreenWpr } from '@core/elements/ScreenWpr';
import { useValueMemo } from '@core/hooks';
import { OxToast } from '@core/notification/OxToast';
import { useTheme } from '@core/theme';
import { ensureReadyConfig, userAppSettingsConfigKey } from '@core/user-configs';
import { withPermissions } from '@core/user/hoc';
import { cn } from '@core/util';
import { compose } from '@reduxjs/toolkit';
import { OxSidebar } from 'Containers/OxSidebar/OxSidebar';
import { PageRoutes } from 'Containers/Routing/routes';

import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import './Main.scss';
import { useSessionTimeout } from './useSessionTimeout';
import { withDOA } from './withDOA';

export function Main(): JSX.Element {
  const { isLoading } = useAuth0();
  const { isOnboarding } = useAppSelector((state) => state.auth);
  const { sidebarCurrentWidth } = useTheme();
  const { pathname } = useLocation();
  useSessionTimeout();
  const { renderHeader, renderSidebar } = useValueMemo(
    () => ({
      renderHeader: isOnboarding && pathname !== '/',
      renderSidebar: !isOnboarding
    }),
    [pathname, isOnboarding]
  );

  return isLoading ? (
    <OxLoadingV2 />
  ) : (
    <ScreenWpr>
      <OxToast />
      {renderHeader && <OxHeader />}
      <div className={cn('screen-ctr')}>
        {renderSidebar && <OxSidebar />}
        <div
          className='page-view-wpr'
          style={{ marginLeft: isOnboarding ? 0 : sidebarCurrentWidth }}
        >
          <PageRoutes />
        </div>
      </div>
    </ScreenWpr>
  );
}

const mainHocs = compose(
  withCompanyConfig,
  withPermissions,
  withDOA,
  ensureReadyConfig(userAppSettingsConfigKey)
);

export default mainHocs(Main);
