import { StyleGetter } from '@core/theme/typings';

export const css: StyleGetter = ({ gutter: gtr }) => {
  return {
    triggerBtn: {
      '&.--icon-only': {
        borderRadius: gtr / 1.5,
        borderWidth: 0,
        padding: 0
      }
    }
  };
};
