import { ExclamationCircleSvg } from '@core/images';
import { StyledFieldErrorMessage } from './components';

interface OxErrorMessageProps {
  error: string;
  className?: string;
}

export function OxErrorMessage({ error, className }: OxErrorMessageProps) {
  return (
    <StyledFieldErrorMessage
      className={`field-error-message text-sm flex ${className ? className : '-mt-[10px]'}`}
    >
      <ExclamationCircleSvg height={16} className='mr-1' style={{ minWidth: 16 }} />
      {error}
    </StyledFieldErrorMessage>
  );
}
