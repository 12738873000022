import { client } from '@core/rest-client';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IRateScheduleRepository } from 'shared/api/interfaces/IRateScheduleRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IRateSchedule } from 'shared/models/RateSchedule';

// All the below are placeholders for api integration

@injectable()
export class RateScheduleRepository implements IRateScheduleRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('oracle/v1/rate-schedules/');

  async delete(id: string): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl + id);
  }

  async deleteAll(ids: string[]): Promise<AxiosResponse> {
    return await client.delete(this.apiUrl, { params: { ids: ids } });
  }

  async filter(startDate: Date, endDate: Date): Promise<AxiosResponse<IRateSchedule[]>> {
    return await client.get(this.apiUrl + 'filter/' + { ...startDate, ...endDate });
  }

  async add(rateSchedule: IRateSchedule): Promise<AxiosResponse> {
    return await client.post(this.apiUrl, { ...rateSchedule });
  }

  async update(rateSchedule: IRateSchedule): Promise<AxiosResponse> {
    return await client.put(this.apiUrl, { ...rateSchedule });
  }

  async getAll(): Promise<AxiosResponse<IRateSchedule[]>> {
    return await client.get(this.apiUrl);
  }

  async getById(id: string): Promise<AxiosResponse<IRateSchedule>> {
    return await client.get(this.apiUrl + id);
  }
}
