import styled from 'styled-components';

export const StyledDropDownFieldError = styled.div`
  border-radius: 4px;
  &.field-error-message {
    color: ${(props) => props.theme.fieldError};
  }

  &.disabled-error {
    color: ${(props) => props.theme.contrast_15} !important;
  }
`;

export const StyledDropDownField = styled.div`
  .primary {
    color: ${(props) => props.theme.primaryDropDownText};
    background-color: ${(props) => props.theme.primaryDropDownBgColor};
    stroke: ${(props) => props.theme.primaryDropDownText};

    &:hover {
      background-color: ${(props) => props.theme.primaryDropDownBgColor};
    }
  }

  .secondary {
    color: ${(props) => props.theme.secondaryDropDownText};
    background-color: ${(props) => props.theme.secondaryDropDownBgColor};
    stroke: ${(props) => props.theme.secondaryDropDownText};

    &:hover {
      background-color: ${(props) => props.theme.secondaryDropDownBgColor};
    }
  }

  .inverse {
    color: ${(props) => props.theme.inverseDropDownText};
    background-color: ${(props) => props.theme.inverseDropDownBgColor};
    stroke: ${(props) => props.theme.inverseDropDownIconColor};

    &:hover {
      background-color: ${(props) => props.theme.inverseDropDownBgColor};
    }
  }

  .link:hover {
    background-color: #eee;
  }

  .field-border {
    border: 1px ${(props) => props.theme.fieldFocus} solid;
  }

  .select-icon {
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 10px;
  }
  .group-form-style {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledDropDownContent = styled.label`
  color: ${(props) => props.theme.dropDownContentText};

  &.reset {
    border-top: 1px solid ${(props) => props.theme.contrast_15} !important;
  }
`;

export const StyledScrollable = styled.div`
  max-height: 200px;
  overflow: scroll;
`;
