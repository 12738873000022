import { CogSvg } from '@core/images';
import { themes } from '@core/theme';
import { DispatchResponse } from '@core/typings/redux.types';
import { useUser } from '@core/user';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { fetchCurrentUser } from 'redux/slices/access/access.slice';
import { setIsOnboarding } from 'redux/slices/authentication/authentication.slice';
import { fetchFeatureFlags } from 'redux/slices/featureFlags/featureFlags.slice';
import { fetchEnabledProducts } from 'redux/slices/products/products.slice';
import { ThemeProvider } from 'styled-components';
import { OxShowLoginStatus } from '../OxShowLoginStatus';
import { StyledHeader, StyledHeaderConfigButton } from './OxHeader.styles';

export const OxHeader = (): JSX.Element => {
  const { isAuthenticated, logout, data: currentUser } = useUser();
  /* support link is only shown on homepage; otherwise, it's in the sidebar */
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchEnabledProducts()).then((response: DispatchResponse) => {
        /* NOTE: this shouldn't run, as withCompanyConfig will catch un-authed users,
         * but it's here just in case */
        if (!response.payload) logout();
      });
      dispatch(fetchCurrentUser());
      dispatch(fetchFeatureFlags());
    }
  }, [isAuthenticated]);

  const header = (
    <StyledHeader data-testid='oxHeader' className='ox-header top-0 border-b z-30'>
      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between h-16 -mb-px'>
          <div onClick={() => window.location.replace('/')} className='flex'>
            <img
              className='w-30 h-10 cursor-pointer'
              src='/images/eleox-logo.png'
              alt='Eleox Logo'
            />
          </div>
          <div className='flex items-center justify-end'>
            {currentUser?.settings?.read && (
              <StyledHeaderConfigButton
                onClick={() => dispatch(setIsOnboarding({ isOnboarding: false }))}
              >
                <Link
                  to='/products/settings/company'
                  key={t('companySettings.title')}
                  title={t('companySettings.title')}
                >
                  <CogSvg className='config-icon ml-5 mr-1' width={28} height={28} />
                </Link>
              </StyledHeaderConfigButton>
            )}
            <OxShowLoginStatus />
          </div>
        </div>
      </div>
    </StyledHeader>
  );

  return <ThemeProvider theme={themes.lightTheme}>{header}</ThemeProvider>;
};
