import styled from 'styled-components';

export const StyledTypography = styled.div`
  &.weight-light {
    font-weight: 200;
  }

  &.weight-regular {
    font-family: 'GibsonRegular';
  }

  &.weight-medium {
    font-family: 'GibsonMedium';
  }

  &.weight-bold {
    font-family: 'GibsonBold';
  }

  &.color-very-light {
    color: ${(props) => props.theme.exceptionTextVeryLight};
  }

  &.color-light {
    color: ${(props) => props.theme.exceptionTextLight};
  }

  &.color-medium {
    color: ${(props) => props.theme.exceptionTextMedium};
  }

  &.color-dark {
    color: ${(props) => props.theme.exceptionTextDark};
  }
`;
