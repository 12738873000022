import { createSelector } from '@reduxjs/toolkit';

export function createSelectorMap<T extends { [key: string]: any }>(
  selector: (...args: any) => T[],
  key: keyof T
) {
  return createSelector(selector, (entries) => {
    return entries.reduce((acc, entry) => {
      acc.set(entry[key], entry);
      return acc;
    }, new Map<string | undefined, T>());
  });
}
