import { Auth0UserDetails, GridRange } from '@core/typings';
import { compareDates } from './date.helper';

export const hashCode = function (str: string): number {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const validatePhone = (phoneStr: string) => {
  const phoneNum = phoneStr.replace(/[^\d]/g, '');
  const phoneLength = phoneNum.length;
  const validation = phoneLength === 10;

  return validation;
};

export const validateDate = (dateStr: string | undefined) => {
  // Assumes dateStr is "mm/dd/yyyy"
  if (!dateStr) return false;

  if (!/^\d{1,2}[/]\d{1,2}[/]\d{4}$/.test(dateStr)) {
    return false;
  }

  const dateParts = dateStr.split('/').map((p) => parseInt(p, 10));
  const [mm, dd, yyyy] = dateParts;
  const month = mm - 1;
  const d = new Date(yyyy, month, dd);
  return d.getMonth() === month && d.getDate() === dd && d.getFullYear() === yyyy;
};

export const validateUUID = (uuidStr: string) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(uuidStr);
};

export const validateEmail = (emailStr: string) => {
  return /(.+)@(.+){2,}\.(.+){2,}/.test(emailStr);
};

export const validateDuplicateEmail = (emailStr: string, existingUsers: Auth0UserDetails[]) => {
  const lowerCasedEmail = emailStr.toLowerCase();
  return existingUsers.length > 0
    ? !existingUsers.some((user) => user.email.toLowerCase() === lowerCasedEmail)
    : true;
};

export const isFutureDate = (date: Date) => {
  const now = new Date();

  return date > now;
};

export const checkDateFilterRange = (date: string | string[], range: GridRange) => {
  if (Array.isArray(date)) {
    if (date.length) {
      const startPass = compareDates(date[0], range?.endDate, 'lessThan');
      const endPass = compareDates(date[1], range?.startDate, 'greaterThan');
      return startPass && endPass;
    }

    return true;
  }

  const startPass = compareDates(date, range?.endDate, 'lessThan');
  const endPass = compareDates(date, range?.startDate, 'greaterThan');
  return startPass && endPass;
};

export const equalityComparator = (value: string | number, param: string | number) => {
  return !!value && !!param && value === param;
};
