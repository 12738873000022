import { addNotification } from '@core/notification';
import { store } from 'redux/store';

const defaultSuccessMessage = 'Update completed successfully.';
const defaultErrorMessage = 'Unable to perform the requested update.';

export const handleAddDispatch = (
  newData: any,
  addRow: any,
  getAllRows: any,
  successMessage = ''
) => {
  return store.dispatch(addRow(newData)).then((response: any) => {
    if (response.payload?.data?.error) {
      const responseMessage = response.payload.data.message;
      const isArray = Array.isArray(responseMessage);
      const message = isArray ? responseMessage.join(', ') : responseMessage;
      addNotification({ message });
    } else if (response.payload?.status === 200) {
      store.dispatch(getAllRows());
      addNotification({
        message: successMessage || defaultSuccessMessage,
        type: 'success'
      });
    } else if (response.payload?.status === 300) {
      const message = response.payload?.data?.message || defaultErrorMessage;
      addNotification({ message });
    } else if (response.error) {
      addNotification({ message: defaultErrorMessage });
    }
  });
};
