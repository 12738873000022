import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ILocationRepository } from 'shared/api/interfaces/ILocationRepository';
import { ILocation, ILocationSpec, ILocationSpecRequest } from 'shared/models/Location';
import { IBaseThunk } from './base.thunks';

export class LocationThunks implements IBaseThunk<ILocation> {
  private locationRepository!: ILocationRepository;

  constructor(_locationRepository: ILocationRepository) {
    this.locationRepository = _locationRepository;

    if (this.locationRepository === null) {
      throw new Error('locationRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'locations';

  add(): AsyncThunk<HttpResponse<ILocation>, ILocation, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addLocation`;

    return createAsyncThunk(action, async (location: ILocation, { rejectWithValue }) => {
      try {
        return await this.locationRepository.add(location);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  update(): AsyncThunk<HttpResponse<ILocation>, ILocation, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateLocation`;

    return createAsyncThunk(action, async (location: ILocation, { rejectWithValue }) => {
      try {
        return await this.locationRepository.update(location);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  delete(): AsyncThunk<AxiosResponse<ILocation, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteLocation`;

    return createAsyncThunk(action, (id: string) => this.locationRepository.delete(id));
  }

  deleteAll(): AsyncThunk<AxiosResponse<ILocation, any>, string[][], Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteLocations`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.locationRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<ILocation[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchLocations`;

    return createAsyncThunk(action, () => this.locationRepository.getAll());
  }

  getSpecs(): AsyncThunk<AxiosResponse<ILocationSpec[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchLocationSpecs`;

    return createAsyncThunk(action, () => this.locationRepository.getSpecs());
  }

  getById(): AsyncThunk<AxiosResponse<ILocation, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchLocationById`;

    return createAsyncThunk(action, (id: string) => this.locationRepository.getById(id));
  }

  forceUpdate(): AsyncThunk<HttpResponse<ILocation>, ILocation, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/forceUpdateLocation`;

    return createAsyncThunk(action, async (location: ILocation, { rejectWithValue }) => {
      try {
        return await this.locationRepository.forceUpdate(location);
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  }

  createSpecRequest(): AsyncThunk<
    HttpResponse<ILocationSpecRequest>,
    ILocationSpecRequest,
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/createLocationSpecRequest`;

    return createAsyncThunk(
      action,
      async (specRequest: ILocationSpecRequest, { rejectWithValue }) => {
        try {
          return await this.locationRepository.createSpecRequest(specRequest);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }
}
