import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ChangePasswordData } from 'redux/slices/authentication/authentication.slice';
import { IAuthRepository } from 'shared/api/interfaces/IAuthRepository';
import { IBaseThunk } from './base.thunks';

export class AuthThunks implements IBaseThunk<any> {
  private readonly authRepository!: IAuthRepository;

  constructor(_authRepository: IAuthRepository) {
    this.authRepository = _authRepository;

    if (this.authRepository === null) {
      throw new Error('authRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'auth';

  changePassword = (): AsyncThunk<
    AxiosResponse<ChangePasswordData, any>,
    ChangePasswordData,
    Record<string, unknown>
  > => {
    const action = `${this.baseIdentifier}/changePassword`;

    return createAsyncThunk(action, (data) => this.authRepository.changePassword(data));
  };

  add = (): AsyncThunk<AxiosResponse<any, any>, any, Record<string, unknown>> => {
    throw new Error('Method not implemented.');
  };

  update(): AsyncThunk<
    AxiosResponse<any, any>,
    { id: string; item: any },
    Record<string, unknown>
  > {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<any, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getAll(): AsyncThunk<AxiosResponse<any[], any>, void, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }
}
