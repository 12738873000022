import './Drawer.style.scss';

/*
 * TODO: this still needs an optional overlay component that makes underlying UI
 * non-interactive and provides a starker offset. Overlay opacity should be coordinated
 * via spring config and should be destroyed when drawer is fully closed.
 */

import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';

import { useDidUpdate, useGetSetState, useMemoizeValue, useValueMemo } from '@core/hooks';
import { cn } from '@core/util';
import { animated, config, EasingFunction, easings, useSpring } from '@react-spring/web';
import _includes from 'lodash/includes';
import _isBoolean from 'lodash/isBoolean';
import _isString from 'lodash/isString';
import _kebabCase from 'lodash/kebabCase';
import _mapKeys from 'lodash/mapKeys';
import {
  DrawerContextValue,
  DrawerHeaderProps,
  DrawerProps,
  DrawerState,
  DrawerVisualState
} from './Drawer.types';
import { DrawerContext } from './DrawerContext';
import { DrawerHeader } from './DrawerHeader';

export const Drawer = forwardRef(function Drawer(
  {
    children,
    className,
    containerClassName,
    destroyOnClose,
    duration = 250,
    easing = 'easeInCubic',
    handleClose,
    hasShadow = true,
    header = true,
    initiallyOpen = true,
    isOpen: isOpenCtrl,
    isRounded = true,
    isStretchy = true,
    lazyLoad,
    onChange,
    position = 'right',
    springConfig,
    springConfigType
  }: DrawerProps,
  ref: ForwardedRef<any>
): JSX.Element {
  /* determines whether state should be defined by prop value */
  const isCtrl = useValueMemo(() => _isBoolean(isOpenCtrl), [isOpenCtrl]);
  const isVertical = useValueMemo<boolean>(() => _includes(['top', 'bottom'], position), []);
  /* prettier-ignore */
  const initialState = useMemo<DrawerState>(() => {
    const iIsOpen = isCtrl ? Boolean(isOpenCtrl) : initiallyOpen;
    return {
      hasPendingTransition: false,
      hasOpened: iIsOpen,
      isOpen: iIsOpen,
      isTransitioning: false,
    };
  }, []);

  /* add css flags */
  const [visualStateFlags, setClassNameFlags] = useState<any>(() => ({
    '--is-open': initialState.isOpen,
    '--is-closed': !initialState.isOpen
  }));

  const [getState, setState] = useGetSetState<DrawerState>(initialState, (nState) => {
    const visState: DrawerVisualState = {
      isClosed: !nState.isOpen && !nState.isTransitioning && !nState.hasPendingTransition,
      isClosing: !nState.isOpen && nState.isTransitioning,
      isOpen: nState.isOpen && !nState.isTransitioning && !nState.hasPendingTransition,
      isOpening: nState.isOpen && nState.isTransitioning,
      isTransitioning: nState.isTransitioning
    };

    onChange?.(nState, visState);
    setClassNameFlags(_mapKeys(visState, (v, key) => `--${_kebabCase(key)}`));
  });

  /* watch for isOpen prop change and react when in controlled mode */
  useDidUpdate(() => {
    isCtrl && setState({ isOpen: isOpenCtrl, hasPendingTransition: true });
  }, [isCtrl, isOpenCtrl]);

  /* *** CONTEXT/HANDLE *** */
  /* prettier-ignore */
  const ctxValue = useValueMemo<DrawerContextValue>(() => ({
    close: (cb) => { setState({ isOpen: false, hasPendingTransition: true }, cb); },
    getState,
    open: (cb) => { setState({ isOpen: true, hasPendingTransition: true }, cb); },
    toggle: (cb) => { setState((prev) => ({ isOpen: !prev.isOpen, hasPendingTransition: true }), cb); }
  }), []);

  useImperativeHandle(ref, (): DrawerContextValue => ctxValue, []);

  /* *** SPRING *** */
  const onStart = useCallback(() => {
    setState({ isTransitioning: true, hasPendingTransition: false, hasOpened: true });
  }, []);
  const onRest = useCallback(() => {
    setState({ isTransitioning: false });
  }, []);

  const state = useMemoizeValue<DrawerState>(getState());
  const [{ transform }] = useSpring(() => {
    return {
      config: springConfigType
        ? config[springConfigType]
        : {
            clamp: true,
            duration,
            easing: (_isString(easing)
              ? easings[easing as keyof typeof easings]
              : easings.easeInOutCubic) as EasingFunction,
            ...springConfig
          },
      transform: `translate${isVertical ? 'Y' : 'X'}(${
        state.isOpen ? 0 : 100 * (position === 'left' || position === 'top' ? -1 : 1)
      }%)`,
      onRest,
      onStart
    };
  }, [state.isOpen, easing, springConfig, position, springConfigType]);

  const classNameFlags = useValueMemo(
    () => [
      `--position-${position}`,
      visualStateFlags,
      {
        '--no-header': header === false,
        '--is-rounded': isRounded,
        '--is-stretchy': isStretchy,
        '--no-shadow': !hasShadow
      }
    ],
    [Boolean(header), isRounded, isStretchy, hasShadow, visualStateFlags, position]
  );

  return (
    <DrawerContext.Provider value={ctxValue}>
      <animated.div
        style={{ transform }}
        className={cn('drawer-wpr', classNameFlags, className)}
        data-testid='Drawer'
      >
        {header && (
          <DrawerHeader
            handleClose={isCtrl ? handleClose : undefined}
            {...(header as Partial<DrawerHeaderProps>)}
          />
        )}
        <div className={cn('drawer-ctr', containerClassName)}>
          {(lazyLoad && !state.hasOpened) ||
          (destroyOnClose && !state.isOpen && !state.isTransitioning && !state.hasPendingTransition)
            ? null
            : children}
        </div>
      </animated.div>
    </DrawerContext.Provider>
  );
});
