import { themes } from '@core/theme';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import { ThemeProvider } from 'styled-components';
import { ButtonProps } from '../OxButton';
import { StyledDropDown, StyledDropDownButton } from './OxDropDown.styles';

export interface DropDownItemRedirect {
  text: string;
  location: string;
}

export interface DropDownItemOnClick {
  text: string;
  handleOnClick: () => void;
}

export interface DropDownProps extends ButtonProps {
  buttonContent: JSX.Element;
  dropDownContent: (DropDownItemRedirect | DropDownItemOnClick)[];
  useLastClearItem?: boolean;
  classes?: string;
}

export const OxDropDown = ({
  buttonContent,
  dropDownContent,
  buttonType,
  buttonSize,
  useLastClearItem = false,
  classes,
  ...rest
}: DropDownProps): React.ReactElement<ButtonProps> => {
  const [isOpen, setOpen] = useState(false);
  const handleToggle = () => setOpen((isOpen) => !isOpen);

  const buttonRef = useRef<HTMLElement | undefined>();

  function DropDownContent() {
    const contentOptions = DropDownOptions();
    return (
      <div
        id='dropDownContent'
        style={{ minWidth: '150px' }}
        className='z-40 bg-white shadow-2xl py-1 mt-1 rounded whitespace-nowrap'
      >
        {contentOptions}
      </div>
    );
  }

  function DropDownOptions() {
    return dropDownContent.map((item, index) => {
      if ((item as DropDownItemRedirect).location !== undefined) {
        return (
          <Link
            to={(item as DropDownItemRedirect).location}
            className='ox-dropdown-content text-md py-2.5 pr-2.5 pl-4 block'
            key={item.text}
          >
            {item.text}
          </Link>
        );
      }

      if ((item as DropDownItemOnClick).handleOnClick !== undefined) {
        return (
          <p
            onClick={() => (item as DropDownItemOnClick).handleOnClick()}
            className={`${
              useLastClearItem && index === dropDownContent.length - 1 && 'border-t-[1px] mt-1'
            } ox-dropdown-content text-md py-2.5 pr-2.5 pl-4 block cursor-pointer`}
            key={item.text}
          >
            {item.text}
          </p>
        );
      }
    });
  }

  const buttonRest = { ...rest } as any;

  const dropdownContainer = (
    <StyledDropDown className='inline-block'>
      <Popover
        containerClassName='ox-dropdown-container'
        onClickOutside={handleToggle}
        isOpen={isOpen}
        positions={['bottom']}
        align='end'
        ref={buttonRef as any}
        content={() => DropDownContent()}
      >
        <StyledDropDownButton
          type='button'
          className={`${
            buttonType && `ox-button-${buttonType} ${buttonType}`
          } ${classes} ox-button-${buttonSize} inline-flex items-center justify-center w-full`}
          onClick={handleToggle}
          {...buttonRest}
        >
          {buttonContent}
        </StyledDropDownButton>
      </Popover>
    </StyledDropDown>
  );

  return <ThemeProvider theme={themes.lightTheme}>{dropdownContainer}</ThemeProvider>;
};
