import { StyleGetter } from '@core/theme/typings';
const transition = 'all 200ms ease-in-out';
const zoom = 0.6;
export const css: StyleGetter = (theme) => {
  const {
    colors: { accent, canvas, highlight, secondary, primary, tertiary, text },
    colorWheel: { common, gray },
    gutter: gtr,
    darken,
    fade,
    desaturate,
    saturate,
    sidebarCurrentWidth: width,
    sidebarOpenWidth: openWidth,
    toGtr
  } = theme;
  const textClr = fade(text.inverse, 0);
  const activeClr = '#19daa9';
  const activePageLinkClr = '#92F2D8';
  const activeSubmenuClr = saturate(primary.lightest, 33);
  const bgClr = desaturate(darken(accent.primary, 50), 33);
  const userProfileText = '#B4B4B4';
  return {
    wpr: {
      padding: 0,
      background: `linear-gradient(
        15deg,
        ${darken(bgClr, 15)},
        ${bgClr} 60%

        )`,
      width,
      transition,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1,
      height: '100vh',
      userSelect: 'none',
      overflowX: 'clip',

      '.sidebar-ctr': {
        transition,
        pointerEvents: 'none',
        opacity: 0,
        display: 'flex',
        flex: '1',
        width: openWidth,
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',

        '.core-text': {
          color: textClr
        }
      },

      '.hamburger-wpr': {
        borderRadius: 7,
        color: tertiary.lightest,
        outline: `2px solid ${common.transparent}`,
        paddingRight: '10px',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 35,
        top: 65,
        transform: 'translateX(45%)',
        transition,
        zIndex: 100,
        zoom,
        width: 50,
        height: 50,
        padding: 1,

        '&:hover': {
          outline: 'none',
          background: '#45506d'
        }
      },

      '.logo-wpr': {
        flex: 0,
        height: 'auto',
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        alignItmems: 'center',
        padding: 15,
        marginTop: 20,
        width: openWidth,

        '.logo': { width: '147px', height: '38px' },
        '.logo-symbol': { width: 'auto', height: '38px' }
      },
      '.profile-wpr': {
        margin: '5px 15px 0 15px',
        padding: '15px 0 14px',
        borderBottom: `1px solid ${fade(textClr, 88)}`,

        '.user-profile': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: '0',
          padding: '0',
          fontFamily: 'Rubik',

          '.text-wpr': {
            marginTop: 20,
            marginLeft: gtr / 2,
            '.full-name': {
              fontSize: 15,
              fontWeight: 700,
              lineHeight: 1.2,
              color: userProfileText
            },

            '.role-wpr': {
              display: 'flex',
              '.core-text': { fontSize: 12, lineHeight: 1, color: userProfileText },
              '.sep': { margin: toGtr(0, gtr / 2) }
            }
          },
          '.circular-image': {
            width: 50,
            height: 50,
            background: '#B4B4B4'
          },

          '.role-wpr': {
            marginTop: 5
          }
        }
      },

      '.content-wpr': {
        overflowX: 'hidden',
        width: openWidth,
        transition,
        overflowY: 'auto',
        flex: '1',
        padding: 15,
        paddingTop: 0,

        '.secondary-menu': {
          borderTop: `1px solid ${fade(textClr, 88)}`,
          paddingTop: 15,

          '.product-menu-wpr': {
            margin: '0',

            '&.--is-active': {
              '> .product-menu-hdr-wpr .title-wpr .core-text': {
                color: 'white',
                opacity: 1
              }
            }
          },

          '> .product-menu-wpr .title-wpr .core-text': {
            width: '100%',
            justifyContent: 'flex-start',
            textTransform: 'uppercase',
            padding: 0,
            transition,
            fontSize: 12,
            fontFamily: 'Rubik',
            fontWeight: 600,
            letterSpacing: 1,
            color: activePageLinkClr,

            '&:hover': {
              background: 'none',
              // opacity: 1,
              color: 'white'
            }
          },

          '.core-btn': {
            width: '100%',
            justifyContent: 'flex-start',
            textTransform: 'uppercase',
            padding: 0,
            opacity: 0.5,
            transition,
            '.core-text': {
              fontSize: 12,
              fontFamily: 'Rubik',
              fontWeight: 600,
              letterSpacing: 1,
              textTransform: 'uppercase',
              color: activePageLinkClr
            },

            '&:hover': {
              background: 'none',
              opacity: 1,
              '.core-text': {
                color: 'white'
              }
            }
          }
        },

        '&::-webkit-scrollbar': {
          width: gtr
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: common.transparent
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: common.transparent,
          borderRadius: gtr
        },
        '&:hover::-webkit-scrollbar-thumb': {
          backgroundColor: fade(gray[500], 50)
        },
        '&::-webkit-scrollbar-button': {
          backgroundColor: secondary.dark
        }
      },

      '.footer-wpr': {
        display: 'flex',
        flex: '0 0 auto',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderTop: `1px solid ${fade(textClr, 88)}`,
        paddingTop: 15,
        paddingBottom: 15,
        margin: '0 15px',
        '.core-btn': {
          width: '100%',
          justifyContent: 'flex-start',
          textTransform: 'uppercase',
          padding: 0,
          opacity: 0.5,
          transition,
          '.core-text': {
            fontSize: 12,
            fontFamily: 'Rubik',
            fontWeight: 600,
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: activePageLinkClr
          },

          '&:hover': {
            background: 'none',
            opacity: 1,
            '.core-text': {
              color: 'white'
            }
          }
        }
      },

      '&.--is-open': {
        '.sidebar-ctr': {
          opacity: 1,
          pointerEvents: 'unset'
        },
        '.hamburger-wpr': {
          right: 25,
          transform: 'translateX(0)',

          svg: {
            transform: 'rotate(180deg)'
          },
          '&:not(:hover)': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderRadius: `0 ${gtr}px ${gtr}px 0`,
      opacity: 0.5,
      margin: '16px 0',
      transition: 'opacity 200ms ease-in-out',
      '.product-menu-hdr-wpr': {
        flex: '1 0 100%',
        display: 'flex',
        justifyContent: 'space-bewteen',
        alignItems: 'center',
        width: '100%',
        padding: gtr,
        paddingRight: gtr / 2,
        paddingLeft: 0,

        '.open-indicator-wpr': {
          display: 'none',
          padding: gtr,
          margin: `-${gtr}px 0`,
          borderRadius: gtr / 2,
          transition,
          '.open-indicator': {
            path: { stroke: 'none' },
            color: textClr,
            transition,
            transform: 'rotate(0deg)',
            '&.--is-open': {
              transform: 'rotate(90deg)'
            }
          },
          '&:hover': {
            backgroundColor: fade(canvas.primary, 88)
          }
        },
        '.title-wpr': {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          color: fade(text.inverse),
          svg: {
            cursor: 'pointer'
          }
        }
      },

      '&:hover': {
        '&:not(.--is-active)': {
          opacity: 1,
          '.core-text::before': {
            background: 'white'
          }
        }
      },

      '.link-menu': {
        paddingBottom: gtr,
        paddingLeft: 17,
        '.menu-item, .submenu-item': {
          color: 'white',
          padding: `${gtr / 2}px ${gtr * 2}px`,
          position: 'relative',
          '.menu-item-link-text, .submenu-item-link-text': {
            fontFamily: 'Rubik',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 8,
              left: -10,
              width: 4,
              height: 4,
              borderRadius: gtr,
              background: activeClr,
              opacity: 0,
              transition
            }
          },
          svg: {
            display: 'none',
            marginRight: gtr * 2
          },

          '&.active, &:hover': {
            '.menu-item-link-text': {
              color: activePageLinkClr,
              fontWeight: 600,
              '&::before': {
                opacity: 1
              }
            },
            svg: {
              display: 'none',
              path: {
                fill: activePageLinkClr
              },
              fill: activePageLinkClr
            }
          },

          '.submenu-item-link-text:hover': {
            '&::before': {
              opacity: 1
            }
          }
        },
        '.menu-item-content-wpr': {
          display: 'flex',
          alignItems: 'center'
        },
        '.submenu': {
          height: 0,
          overflow: 'hidden',
          paddingLeft: 12,
          '&.show': { height: 'auto' },

          '.submenu-list': {
            '.submenu-item': {
              padding: `${gtr / 4}px 0`,

              '.submenu-item-link': {
                position: 'relative',
                marginLeft: gtr,

                '&:hover': {
                  '.core-text': {
                    color: activePageLinkClr
                  }
                }
              },

              '&.active': {
                '.submenu-item-link': {
                  position: 'relative',
                  '.submenu-item-link-text': {
                    fontFamily: 'Rubik',
                    fontWeight: 600,
                    color: 'white'
                  },

                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 10,
                    left: -11,
                    width: 4,
                    height: 4,
                    borderRadius: gtr,
                    background: activeSubmenuClr,
                    transform: 'translateY(-50%)'
                  }
                }
              }
            }
          }
        }
      },
      '.title-wpr, .menu-item, .submenu-item': {
        '.core-text, svg': { transition },

        '&:hover:not(.active):not(.--has-children)': {
          '.core-text': {
            cursor: 'pointer',
            color: 'white'
          }
        },
        '&:not(.active).--has-children:hover': {
          '.menu-item-content-wpr': {
            '.core-text': {
              cursor: 'pointer',
              color: activePageLinkClr
            },
            svg: {
              path: {
                color: highlight.light,
                fill: highlight.light
              },
              color: highlight.light,
              fill: highlight.light
            }
          }
        }
      },

      '&.--is-active': {
        backgroundColor: 'transparent',
        opacity: 1,
        '.product-menu-hdr-wpr': {
          backgroundColor: 'transparent',
          '.title-wpr:not(:hover)': {
            '.title::before': {
              content: '""',
              background: '#91f2d8' // TODO: update hard-coded color
            }
          }
        }
      },

      '&:not(.--is-active)': {
        svg: {
          color: textClr,
          'polygon, path': { fill: '#e9e9e9' }
        }
      }
    }
  };
};
