import styled from 'styled-components';

export const StyledModalCover = styled.div`
  background-color: ${(props) => props.theme.modalCoverBackground};
`;

export const StyledModalHeaderText = styled.h1`
  color: ${(props) => props.theme.modalHeaderText};
`;

export const StyledModalBodyText = styled.p`
  color: ${(props) => props.theme.modalBodyText};
`;

export const StyledModalHeader = styled.div`
  border-radius: 0px;
  padding: 16px 24px 16px 24px;
  border-bottom: 1px ${(props) => props.theme.modalDefaultBorder} solid;
`;

export const StyledModalBodyDefault = styled.div`
  padding: 24px 24px 24px 24px;
`;

export const StyledModalFooter = styled.div`
  border-radius: 0px;
  padding: 10px 24px 10px 24px;
  border-top: 1px ${(props) => props.theme.modalDefaultBorder} solid;
`;

export const StyledConfirmModal = styled.div`
  padding: 20px;
  border-radius: 2px;
  max-width: 40%;
  background-color: ${(props) => props.theme.modalConfirmBackground};

  .ox-modal-confirm-icon {
    color: ${(props) => props.theme.modalConfirmWarningIcon};

    &.danger-icon {
      color: ${(props) => props.theme.modalConfirmIcon};
    }
  }

  .validation-input-wpr {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .validation-input {
      outline: ${(props) => `1px solid ${props.theme.borderColor}`};
      border-radius: 4px;
      padding: 0 3px;
      line-height: 32px;

      &:focus {
        outline: ${(props) => `1px solid ${props.theme.primaryButtonBackground}`};
      }
    }
  }
`;

export const StyledModal = styled.div`
  border-radius: 2px;
  width: 25%;
  height: 25%;
  background-color: ${(props) => props.theme.modalDefaultBackground};
`;
