import { DataTemplateType } from '../typings/DataTemplateType';
import { camelize } from './string.helper';

export const mapFileListToArray = (files: FileList | null, fileType: string) => {
  if (!files) return [];
  const array = [];
  for (let i = 0; i < files.length; i++) {
    if (files[i].name.slice(-3) === fileType) {
      array.push(files.item(i));
    }
  }
  return array;
};

export const downloadTemplate = () => {
  fetch(`${location.origin}/templates/templates.zip`).then((response) => {
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'templates.zip';
      a.click();
    });
  });
};

export const downloadSpecRequestTemplate = (templateType: string) => {
  fetch(`${location.origin}/templates/spec-request/${templateType}_spec_request_template.csv`).then(
    (response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${templateType}_spec_request.csv`;
        a.click();
      });
    }
  );
};

export const getTemplateTypeFromPath = (path = 'upload'): DataTemplateType => {
  const currentPage = window.location.pathname.split(path + '-')[1];
  const formattedPage = camelize(currentPage);
  switch (formattedPage) {
    case DataTemplateType.broker:
      return DataTemplateType.broker;

    case DataTemplateType.counterparty:
      return DataTemplateType.counterparty;

    case DataTemplateType.pipeline:
      return DataTemplateType.pipeline;

    case DataTemplateType.zone:
      return DataTemplateType.zone;

    case DataTemplateType.location:
      return DataTemplateType.location;

    case DataTemplateType.interconnect:
      return DataTemplateType.interconnect;

    case DataTemplateType.rateDefinition:
      return DataTemplateType.rateDefinition;

    case DataTemplateType.rateSchedule:
      return DataTemplateType.rateSchedule;

    case DataTemplateType.locationGroup:
      return DataTemplateType.locationGroup;

    case DataTemplateType.payIndex:
      return DataTemplateType.payIndex;

    case DataTemplateType.transactionType:
      return DataTemplateType.transactionType;

    default:
      return DataTemplateType.counterparty;
  }
};
