import { HttpResponse } from '@core/typings';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IRateDefinitionRepository } from 'shared/api/interfaces/IRateDefinitionRepository';
import { IRateDefinition } from 'shared/models/RateDefinition';
import { IBaseThunk } from './base.thunks';

export class RateDefinitionThunks implements IBaseThunk<IRateDefinition> {
  private rateDefinitionsRepository!: IRateDefinitionRepository;

  constructor(_rateDefinitionsRepository: IRateDefinitionRepository) {
    this.rateDefinitionsRepository = _rateDefinitionsRepository;

    if (this.rateDefinitionsRepository === null) {
      throw new Error('rateDefinitionsRepository has not been instantiated!');
    }
  }

  baseIdentifier = 'rate-definition';

  add(): AsyncThunk<HttpResponse<IRateDefinition>, IRateDefinition, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/addRate`;

    return createAsyncThunk(action, (rate: IRateDefinition) =>
      this.rateDefinitionsRepository.add(rate)
    );
  }

  update(): AsyncThunk<HttpResponse<IRateDefinition>, IRateDefinition, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/updateRate`;

    return createAsyncThunk(
      action,
      async (rateDefinition: IRateDefinition, { rejectWithValue }) => {
        try {
          return await this.rateDefinitionsRepository.update(rateDefinition);
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    );
  }

  delete(): AsyncThunk<AxiosResponse<IRateDefinition, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/deleteRate`;

    return createAsyncThunk(action, (id: string) => this.rateDefinitionsRepository.delete(id));
  }

  deleteAll(): AsyncThunk<
    AxiosResponse<IRateDefinition, any>,
    string[][],
    Record<string, unknown>
  > {
    const action = `${this.baseIdentifier}/deleteRates`;

    return createAsyncThunk(action, (ids: string[][]) =>
      this.rateDefinitionsRepository.deleteAll(ids.map((ids) => ids[0]))
    );
  }

  getAll(): AsyncThunk<AxiosResponse<IRateDefinition[], any>, void, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchRateDefinitions`;

    return createAsyncThunk(action, () => this.rateDefinitionsRepository.getAll());
  }

  getById(): AsyncThunk<AxiosResponse<IRateDefinition, any>, string, Record<string, unknown>> {
    const action = `${this.baseIdentifier}/fetchRatesById`;

    return createAsyncThunk(action, (id: string) => this.rateDefinitionsRepository.getById(id));
  }
}
