import { isDealValueMatching } from './isDealValueMatching';

const nestedFields = ['buyerCounterpartyId', 'sellerCounterpartyId', 'brokerId'];
const conditionalNestedFields = ['buyerPayIndexId'];
/* TODO: move -> Pair */
export const dealsMatchExceptFor = (
  sourceData: Record<string, any>,
  targetData: Record<string, any>
) => {
  let fieldsToIgnore = [
    'potentialMatchIds',
    'isExternal',
    'confirmLocationId',
    'version',
    'deliveryLocationId',
    'dealStatus',
    'buyerTraderName',
    'sellerTraderName',
    'buyerIndexAveragingMethod',
    'buyerIndexPricingCalendar',
    'pricingFrequency',
    'pricePrecision',
    'pipelineId',
    'etrmId',
    'company',
    'counterparty'
  ];

  const FPFieldsToExclude = ['indexDifferential'];
  const IDXFieldsToExclude = ['fixedPrice'];
  const IDXNestedFieldsToInclude = ['buyerPayIndexId'];

  const isFP = sourceData && targetData && targetData.tradeType === 'FP';
  const isIDX = sourceData && targetData && targetData.tradeType === 'IDX';

  if (isFP) {
    fieldsToIgnore = [...FPFieldsToExclude, ...fieldsToIgnore];
  }

  if (isIDX) {
    fieldsToIgnore = [...IDXFieldsToExclude, ...fieldsToIgnore];
  }

  const combinedIgnoredFields = [...fieldsToIgnore, ...nestedFields, ...conditionalNestedFields];
  const matchingFields = Object.keys(sourceData).filter(
    (field) => !combinedIgnoredFields.includes(field)
  );
  let isMatch = true;

  matchingFields.forEach((field) => {
    const match = isDealValueMatching(sourceData, targetData, field);
    if (!match) {
      isMatch = false;
    }
  });

  const nestedFieldsByTradeType = [...nestedFields, ...(isIDX ? IDXNestedFieldsToInclude : [])];
  nestedFieldsByTradeType.forEach((field) => {
    if (sourceData[field].specId !== targetData[field].specId) {
      isMatch = false;
    }
  });

  return isMatch;
};
