import { ColDef, ICellRendererParams, SuppressKeyboardEventParams } from 'ag-grid-community';
import _ from 'lodash';

/* NOTE:
 * this is a weird configuration because AgGrid is pretty resistant from editing from within a
 * cell editor. So, this config essentially merges the editor and renderer into the renderer.
 *
 * !IN ORDER FOR THIS TO WORK, .editable MUST BE SET TO false.¡
 * to disable the input, pass { disabled: true } to the cellRendererParams
 */

export function createSwitchCell<TData = any, TValue extends boolean = boolean>(
  rest: Partial<ColDef<TData, TValue>> = {}
): ColDef<TData, TValue> {
  return {
    cellRenderer: 'switchCellRenderer',
    comparator: (a, b) => (a === b ? 0 : a ? -1 : 1),
    editable: false /* editable must be set to false, or editor will render (I think?) */,
    suppressKeyboardEvent: (params: SuppressKeyboardEventParams<TData, TValue>) =>
      params.event.key === ' ',
    ...rest,
    cellRendererParams: (params: ICellRendererParams<TData, boolean>) => ({
      suppressKeyboardEvent: true,
      ...(_.isFunction(rest.cellRendererParams)
        ? rest.cellRendererParams(params)
        : rest.cellRendererParams),
      ...(_.isFunction(rest?.cellEditorParams)
        ? rest.cellEditorParams(params)
        : rest.cellEditorParams)
    })
  };
}
