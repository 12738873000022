import { UploadData } from '@core/typings';

// Needs refactor once backend is ready to accept snake case for rateDef and rateSchedule

export const createFormData = (
  { file, type, id }: UploadData,
  name: string,
  blob?: Blob,
  chunkIndex?: number,
  totalChunks?: number,
  isEnd?: boolean
) => {
  const form = new FormData();
  form.append('id', id);
  form.append('type', type);
  form.append('filename', name);
  form.append('data', blob || file, name);
  if (chunkIndex !== null && chunkIndex !== undefined && chunkIndex > -1) {
    form.append('index', chunkIndex.toString());
  }
  if (totalChunks) form.append('total', totalChunks.toString());
  if (isEnd) form.append('end', 'true');

  return form;
};
