import { TableEmptyState } from '@core/components';
import { IRowNode } from 'ag-grid-enterprise';
import { AgGridReact as AgGridReactType, AgGridReactProps } from 'ag-grid-react';
import React, { forwardRef, RefObject, useEffect, useMemo, useState } from 'react';
import { OxTable } from './OxTable';

interface OxTableProps extends AgGridReactProps {
  setSelectedRows?: React.Dispatch<React.SetStateAction<IRowNode[]>>;
  dataSource?: any;
  className?: string;
  count?: number;
  useInfiniteRowCount?: boolean;
  failureHighlightCellColors?: {
    even: string;
    odd: string;
  };
  targetData?: any;
  fieldsToIgnore?: string[];
  combinedSearchFields?: string[][];
  overwriteHighlighting?: boolean;
  customSearchText?: string;
  overlayChangeObject?: any;
  limit?: number;
  infiniteSettings?: {
    rowBuffer?: number;
    cacheBlockSize?: number;
    cacheOverflowSize?: number;
    maxConcurrentDatasourceRequests?: number;
    infiniteInitialRowCount?: number;
    maxBlocksInCache?: number;
    animateRows?: boolean;
    serverSideInfiniteScroll?: boolean;
  };
}

export const OxInfiniteTable = forwardRef(function OxInfiniteTable(props: OxTableProps, ref: any) {
  const grid = ref as RefObject<AgGridReactType<any>>;
  let defaultSettings = props.infiniteSettings;
  const maxRows = props.limit || 10;
  const rowCount = props.count ?? 0;
  const [tableShowsOverlay, setTableShowsOverlay] = useState(false);

  if (!defaultSettings) {
    defaultSettings = {
      rowBuffer: 0,
      cacheBlockSize: maxRows,
      cacheOverflowSize: 2,
      maxBlocksInCache: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: maxRows,
      animateRows: true,
      serverSideInfiniteScroll: true
    };

    if (props.rowModelType === 'infinite') {
      defaultSettings = {
        serverSideInfiniteScroll: false,
        rowBuffer: 5,
        cacheBlockSize: 100,
        cacheOverflowSize: 5,
        maxConcurrentDatasourceRequests: 1,
        infiniteInitialRowCount: 50,
        maxBlocksInCache: 20
      };
    }
  } else {
    defaultSettings = {
      rowBuffer: 0,
      cacheBlockSize: maxRows,
      cacheOverflowSize: 2,
      maxBlocksInCache: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: maxRows,
      animateRows: true,
      serverSideInfiniteScroll: true,
      ...defaultSettings
    };
  }

  const showNoRowsOverlay = (count: number) => {
    const tableRowCount = props.useInfiniteRowCount
      ? grid.current?.api.getInfiniteRowCount()
      : count;
    if (tableRowCount === 0) {
      if (!tableShowsOverlay) {
        setTableShowsOverlay(true);
        grid.current?.api.showNoRowsOverlay();
      }
    }
  };

  const hideOverlayIfCount = (count: number) => {
    const tableRowCount = props.useInfiniteRowCount
      ? grid.current?.api.getInfiniteRowCount()
      : count;
    if (tableRowCount !== 0 && tableShowsOverlay) {
      grid.current?.api.hideOverlay();
      setTableShowsOverlay(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (grid.current?.api) {
        showNoRowsOverlay(rowCount);
        hideOverlayIfCount(rowCount);
      }
    }, 300);

    return () => clearInterval(interval);
  }, [rowCount, tableShowsOverlay]);

  useEffect(() => {
    if (props.overlayChangeObject) {
      if (tableShowsOverlay) {
        setTableShowsOverlay(false);
      }
    }
  }, [props.overlayChangeObject]);

  const noRowsOverlayComponent = useMemo<any>(() => {
    return TableEmptyState;
  }, []);

  const loadingOverlay =
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';

  return (
    <OxTable
      rowSelection='multiple'
      rowModelType='serverSide'
      {...props}
      {...defaultSettings}
      ref={ref}
      noRowsOverlayComponent={noRowsOverlayComponent}
      className={props.className ? props.className : 'rounded-corners font-exceptions'}
      tooltipShowDelay={props.tooltipShowDelay || 0}
      overlayLoadingTemplate={loadingOverlay}
    />
  );
});
