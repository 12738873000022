import {
  ExclamationTriangleOutlineSvg,
  ExclamationTriangleSvg,
  PairedFilledSvg,
  PairedSvg,
  TimesCircleOutlineSvg,
  TimesCircleSvg,
  UnlinkOutlineSvg,
  UnlinkSvg
} from '@core/images';
import { ILinkInfo } from '../OxSidebar.types';

export const getOxPairPageLinks: () => ILinkInfo[] = () => [
  {
    LinkIcon: PairedSvg,
    SelectedLinkIcon: PairedFilledSvg,
    linkText: 'oxSidebar.oxPair.deals',
    route: '/products/pair/deals'
  },
  {
    LinkIcon: UnlinkOutlineSvg,
    SelectedLinkIcon: UnlinkSvg,
    linkText: 'oxSidebar.oxPair.withdrawnDeals',
    route: '/products/pair/withdrawn-deals'
  },
  {
    LinkIcon: TimesCircleOutlineSvg,
    SelectedLinkIcon: TimesCircleSvg,
    linkText: 'oxSidebar.oxPair.terminatedDeals',
    route: '/products/pair/terminated-deals'
  },
  {
    LinkIcon: ExclamationTriangleOutlineSvg,
    SelectedLinkIcon: ExclamationTriangleSvg,
    linkText: 'oxSidebar.oxPair.rejectedDeals',
    route: '/products/pair/rejected-deals'
  }
];
