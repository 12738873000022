import { createSelector, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { InterconnectThunks } from 'redux/thunks/interconnect.thunks';
import { GenericPayload } from 'redux/utils/array.slice';
import { createSelectorMap } from 'redux/utils/selector.utils';
import { IInterconnectRepository } from 'shared/api/interfaces/IInterconnectRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { DeleteResponse } from 'shared/models/DeleteResponse';
import { IInterconnect, IInterconnectSpec } from 'shared/models/Interconnect';
import { IPipeline } from 'shared/models/Pipeline';
import { RootState } from '../../store';
import { selectLocationMapByName } from '../location/location.slice';
import { selectPipelineMap } from '../pipeline/pipeline.slice';

type InitialStateType = {
  count?: number;
  changeId: number;
  interconnects: IInterconnect[];
  interconnectSpecs: IInterconnectSpec[];
};

const count = undefined;
const changeId = 0;
const interconnects: IInterconnect[] = [];
const interconnectSpecs: IInterconnectSpec[] = [];

export const initialState: InitialStateType = {
  count,
  changeId,
  interconnects,
  interconnectSpecs
};

const interconnectRepository = container.get<IInterconnectRepository>(
  SERVICE_KEYS.INTERCONNECT_REPOSITORY
);
const interconnectThunks = new InterconnectThunks(interconnectRepository);
export const getAllInterconnects = interconnectThunks.getAll();
export const addInterconnect = interconnectThunks.add();
export const deleteAllInterconnect = interconnectThunks.deleteAll();
export const getAllInterconnectSpecs = interconnectThunks.getSpecs();
export const updateInterconnect = interconnectThunks.update();

const slice = buildGenericSlice<InitialStateType>('interconnect', initialState);
export const interconnectSlice = createSlice<InitialStateType, SliceCaseReducers<InitialStateType>>(
  {
    ...slice,
    extraReducers: (builder: any) => {
      builder.addCase(
        getAllInterconnects.fulfilled,
        (state: InitialStateType, { payload }: GenericPayload) => {
          if (payload && payload.data?.length) {
            const { data } = payload;
            state.interconnects = data;
            state.changeId++;
            state.count = data.length;
          }
        }
      );
      builder.addCase(
        addInterconnect.fulfilled,
        (state: InitialStateType, { payload }: GenericPayload) => {
          if (payload) {
            if (payload) {
              const interconnects = [...state.interconnects, payload.data];
              state.interconnects = interconnects;
              state.changeId++;
              state.count = interconnects.length;
            }
          }
        }
      );
      builder.addCase(
        getAllInterconnectSpecs.fulfilled,
        (state: InitialStateType, { payload }: GenericPayload) => {
          if (payload && payload.data && payload.data.length > 0) {
            state.interconnectSpecs = payload.data as IInterconnectSpec[];
          }
        }
      );
      builder.addCase(
        deleteAllInterconnect.fulfilled,
        (state: InitialStateType, { payload }: GenericPayload) => {
          if (payload.status === 200) {
            const { data } = payload;
            state.changeId++;
            state.interconnects = state.interconnects.filter(
              (interconnect: IInterconnect) =>
                !data.find((response: DeleteResponse) => response.id === interconnect.etrmId)
                  ?.isSuccess
            );
          }
        }
      );
    }
  }
);

export const interconnectReducer = interconnectSlice.reducer;

export const selectInterconnects = (state: RootState) => state.interconnect.interconnects;
export const selectInterconnectSpecs = (state: RootState) => state.interconnect.interconnectSpecs;

export const selectInterconnectMap = createSelectorMap(selectInterconnects, 'etrmId');
export const selectInterconnectSpecMap = createSelectorMap(selectInterconnectSpecs, 'specId');

export interface IFQInterconnect extends IInterconnect {
  spec?: IInterconnectSpec;
  pipelineOne?: IPipeline;
  pipelineTwo?: IPipeline;
}

export const selectFQInterconnectRefs = createSelector(
  [selectInterconnects, selectInterconnectSpecMap, selectPipelineMap, selectLocationMapByName],
  (interconnects, specMap, pipelineMap, locationMapByName) => {
    return interconnects.map<IFQInterconnect>((interconnect) => {
      return {
        ...interconnect,
        spec: specMap.get(interconnect.specId),
        pipelineOne: pipelineMap.get(interconnect.etrmPipelineOneId),
        pipelineTwo: pipelineMap.get(interconnect.etrmPipelineTwoId),
        // TODO: these should be location ETRM IDs instead
        locationOne: locationMapByName.get(interconnect.locationOneName),
        locationTwo: locationMapByName.get(interconnect.locationTwoName)
      };
    });
  }
);
