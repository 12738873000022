import { createSlice } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { DealActivityThunks } from 'redux/thunks/dealActivity.thunk';
import { GenericPayload } from 'redux/utils/array.slice';
import { IDealActivityRepository } from 'shared/api/interfaces/IDealActivityRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IDealAction, IDealActionOutput } from 'shared/models/DealAction';
import { IDealComment, IDealCommentOuput } from 'shared/models/DealComment';

export interface DealActivity {
  internalComments?: IDealComment[];
  internalCommentsCounterparty?: IDealComment[];
  allInternalComments?: IDealComment[];
  externalComments?: IDealComment[];
  externalCommentsCounterparty?: IDealComment[];
  allExternalComments?: IDealComment[];
  actions?: IDealAction[];
  allActivity?: (IDealAction | IDealComment)[];
}

type DealActivityStore = {
  [id: string]: DealActivity;
};

const initialState: DealActivityStore = {};

const payloadToCommentMap = (data: IDealCommentOuput, isCompanyComment = true): IDealComment => {
  return {
    itemId: data.itemId,
    text: data.comment,
    date: data.timeCreated,
    company: data.company,
    user: data.user,
    isShared: isCompanyComment && data.isShared
  };
};

const payloadToActionMap = (data: IDealActionOutput, itemId: string): IDealAction => {
  return {
    itemId: itemId,
    text: data.description,
    date: data.timeStamp,
    user: data.user
  };
};

const sortByDate = (input: IDealComment[] | IDealAction[]) =>
  input.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

const dealActivityRepository = container.get<IDealActivityRepository>(
  SERVICE_KEYS.DEAL_ACTIVITY_REPOSITORY
);
const dealActivityThunks = new DealActivityThunks(dealActivityRepository);
export const createComment = dealActivityThunks.createComment();
export const getAllCommentsByDealId = dealActivityThunks.getAllCommentsByDealId();
export const getAllExternalCommentsByDealId = dealActivityThunks.getAllExternalCommentsByDealId();
export const getAllActionsByDealId = dealActivityThunks.getAllActionsByDealId();

const slice = buildGenericSlice<DealActivityStore>('dealActivity', initialState);

export const dealActivitySlice = createSlice({
  ...slice,
  reducers: {
    clearDealActivity: () => ({})
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      createComment.fulfilled,
      (state: DealActivityStore, { payload }: GenericPayload) => {
        if (payload.status === 200 || payload.status === 201) {
          const { itemId } = payload.data;
          const prevComments = state[itemId]?.internalComments;
          if (prevComments) {
            const comments = [...prevComments];
            comments.push(payloadToCommentMap(payload.data));
            state[itemId].internalComments = sortByDate(comments);
          } else {
            if (!state[itemId]) {
              state[itemId] = {};
            }

            state[itemId].internalComments = [payloadToCommentMap(payload.data)];
          }

          const internalComments = state[itemId]?.internalComments || [];
          const internalCommentsCounterparty = state[itemId]?.internalCommentsCounterparty || [];
          const externalComments = state[itemId]?.externalComments || [];
          const externalCommentsCounterparty = state[itemId]?.externalCommentsCounterparty || [];
          const actions = state[itemId]?.actions || [];
          const combinedActivity = [
            ...internalComments,
            ...internalCommentsCounterparty,
            ...externalComments,
            ...externalCommentsCounterparty,
            ...actions
          ];
          state[itemId].allActivity = sortByDate(combinedActivity);
        }
      }
    );
    builder.addCase(
      getAllCommentsByDealId.fulfilled,
      (state: DealActivityStore, { payload, meta }: GenericPayload) => {
        if (!payload.length) return;
        const itemId = meta.arg?.companyId || payload[0].itemId;
        const prevState = state[itemId] || {};
        const sortedComments = sortByDate(
          payload.map((comment: IDealCommentOuput) => payloadToCommentMap(comment))
        );
        let internalComments = prevState.internalComments || [];
        let internalCommentsCounterparty = prevState.internalCommentsCounterparty || [];
        const externalComments = prevState.externalComments || [];
        const externalCommentsCounterparty = prevState.externalCommentsCounterparty || [];
        const actions = prevState.actions || [];

        if (meta.arg?.companyId) {
          internalCommentsCounterparty = sortedComments;
        } else {
          internalComments = sortedComments;
        }

        const combinedActivity = [
          ...internalComments,
          ...internalCommentsCounterparty,
          ...externalComments,
          ...externalCommentsCounterparty,
          ...actions
        ];
        const allActivity = sortByDate(combinedActivity);
        const allInternalComments = sortByDate([
          ...internalComments,
          ...internalCommentsCounterparty
        ]);

        state[itemId] = {
          ...prevState,
          internalComments,
          internalCommentsCounterparty,
          allInternalComments,
          allActivity
        };
      }
    );
    builder.addCase(
      getAllExternalCommentsByDealId.fulfilled,
      (state: DealActivityStore, { payload, meta }: GenericPayload) => {
        if (!payload.length) return;
        const itemId = meta.arg?.companyId || payload[0].itemId;
        const prevState = state[itemId] || {};
        const sortedComments = sortByDate(
          payload.map((comment: IDealCommentOuput) => payloadToCommentMap(comment, false))
        );

        const internalComments = prevState.internalComments || [];
        const internalCommentsCounterparty = prevState.internalCommentsCounterparty || [];
        let externalComments = prevState.externalComments || [];
        let externalCommentsCounterparty = prevState.externalCommentsCounterparty || [];
        const actions = prevState.actions || [];

        if (meta.arg?.companyId) {
          externalCommentsCounterparty = sortedComments;
        } else {
          externalComments = sortedComments;
        }

        const combinedActivity = [
          ...internalComments,
          ...internalCommentsCounterparty,
          ...externalComments,
          ...externalCommentsCounterparty,
          ...actions
        ];
        const allActivity = sortByDate(combinedActivity);
        const allExternalComments = sortByDate([
          ...externalComments,
          ...externalCommentsCounterparty
        ]);

        state[itemId] = {
          ...prevState,
          externalComments,
          externalCommentsCounterparty,
          allExternalComments,
          allActivity
        };
      }
    );
    builder.addCase(
      getAllActionsByDealId.fulfilled,
      (state: DealActivityStore, { payload, meta }: any) => {
        if (!payload.length) return;
        const prevState = state[meta.arg] || {};
        const actions = sortByDate(
          payload.map((action: IDealActionOutput) => payloadToActionMap(action, meta.arg))
        );
        const internalComments = prevState.internalComments || [];
        const internalCommentsCounterparty = prevState.internalCommentsCounterparty || [];
        const externalComments = prevState.externalComments || [];
        const externalCommentsCounterparty = prevState.externalCommentsCounterparty || [];
        const combinedActivity = [
          ...internalComments,
          ...internalCommentsCounterparty,
          ...externalComments,
          ...externalCommentsCounterparty,
          ...actions
        ];
        const allActivity = sortByDate(combinedActivity);

        state[meta.arg] = {
          ...prevState,
          actions,
          allActivity
        };
      }
    );
  }
});

export const dealActivityReducer = dealActivitySlice.reducer;
export const { clearDealActivity } = dealActivitySlice.actions;
