import { useValueMemo } from '@core/hooks';
import { useIsDark } from '@core/theme';
import { ClassName } from '@core/typings';
import { HTMLAttributes } from 'react';
import { brandingImgMap } from '../branding';

export type ProductImgProps = Partial<HTMLAttributes<SVGSVGElement>> & {
  product: keyof typeof brandingImgMap;
  imgType: 'icon' | 'logomark' | 'wordmark';
  isDark?: boolean;
  className?: ClassName;
};

export function ProductImg({ product, imgType, isDark, className, ...rest }: ProductImgProps) {
  const [dftIsDark] = useIsDark();
  const El = useValueMemo(
    () => brandingImgMap[product]?.[imgType]?.[isDark ?? dftIsDark ? 'dark' : 'light'],
    [product, imgType, isDark, dftIsDark]
  );

  if (!El) return null;
  return <El className={className} {...rest} />;
}
