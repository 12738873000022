// This is a similar implementation to what the backend uses with minor
// differences to account for browser vs nodejs interfaces.

export interface ReconnectingWebSocketOptions {
  /**
   * Max time in seconds to wait before retrying a connection. Defaults to 30.
   */
  maxBackoff: number;
  /**
   * If true, closes and restarts the connection on errors. Defaults to true.
   */
  errorsFatal: boolean;
  /**
   * Number of seconds that need to elapse before considering the connection
   * stable and reset the connection attempt count. Defaults to 30.
   */
  minStableTime: number;
  onOpen: () => Promise<void>;
  onMessage: (message: string) => Promise<void>;
}

/**
 * Websocket that will reconnect if the socket connection closes or encounters
 * an error.
 */
export class ReconnectingWebSocket {
  private logger = console;
  private currentSocket?: WebSocket;
  private isStopped = true;
  private initTimeout: NodeJS.Timeout | null = null;
  private attempts = 0;
  private lastStartTimeMs = Date.now();

  private options: ReconnectingWebSocketOptions;

  constructor(
    private socketFactory: () => Promise<WebSocket>,
    options?: Partial<ReconnectingWebSocketOptions>
  ) {
    this.options = {
      maxBackoff: options?.maxBackoff ?? 30,
      errorsFatal: options?.errorsFatal ?? true,
      minStableTime: options?.minStableTime ?? 30,
      onOpen: options?.onOpen ?? Promise.resolve,
      onMessage: options?.onMessage ?? Promise.resolve
    };
  }

  public stop(): void {
    this.isStopped = true;
    this.currentSocket?.close();
  }

  public start(): void {
    if (!this.isStopped) {
      throw new Error('Socket already started');
    }

    this.isStopped = false;
    this.init();
  }

  public send(message: string): void {
    this.currentSocket?.send(message);
  }

  private handleError(error?: unknown) {
    this.logger.error(`Encountered error: ${error}`);

    // Terminate the current socket if we should consider all errors as fatal

    if (this.options.errorsFatal) {
      if (this.currentSocket?.readyState !== WebSocket.CLOSED) {
        this.currentSocket?.close();
      } else {
        // If the websocket is already closed, manually trigger the close handler
        this.handleClose(-1, String(error));
      }
    }
  }

  private handleClose(code: number, reason: string) {
    this.logger.debug(
      `Handling close event: ${JSON.stringify({
        code,
        reason: reason.toString()
      })}`
    );

    // If we were expecting this close event, stop here and don't attempt a reconnect

    if (this.isStopped) {
      this.logger.debug('Received expected stop. Not restarting.');
      return;
    }

    // If there's already an init scheduled, stop here

    if (this.initTimeout) {
      this.logger.debug('Next connection already scheduled. Skipping.');
      return;
    }

    // If the last time we started was more than `minStableTime`, then reset the
    // attempts counter

    const startTimeMs = Date.now();
    if (startTimeMs - this.lastStartTimeMs > this.options.minStableTime * 1000) {
      this.attempts = 0;
    }

    // Schedule a init based on the number of previous, consecutive unsuccessful
    // attempts.

    const backoffTimeMs = Math.min(2 ** this.attempts++, this.options.maxBackoff) * 1000;

    this.logger.debug(
      `Current connection attempt: ${this.attempts}. Next attempt in ${backoffTimeMs} ms`
    );

    this.initTimeout = setTimeout(() => {
      this.lastStartTimeMs = Date.now();
      this.initTimeout = null;
      this.init();
    }, backoffTimeMs);
  }

  private async init(): Promise<void> {
    this.logger.debug('Initializing new socket');
    this.currentSocket = await this.socketFactory();

    this.currentSocket.onopen = async () => {
      try {
        await this.options.onOpen();
      } catch (error) {
        this.handleError(error);
      }
    };

    this.currentSocket.onerror = (event: Event) => {
      this.handleError(event);
    };

    this.currentSocket.onclose = (event: CloseEvent) => {
      const { code, reason } = event;
      this.handleClose(code, reason);
    };

    this.currentSocket.onmessage = async (event: MessageEvent) => {
      try {
        await this.options.onMessage(event.data.toString());
      } catch (error) {
        this.handleError(error);
      }
    };
  }
}
