import { createSlice } from '@reduxjs/toolkit';
import { buildGenericSlice } from 'redux/helpers/slice.helper';
import { RateDefinitionThunks } from 'redux/thunks/rateDefinitions.thunk';
import { GenericPayload } from 'redux/utils/array.slice';
import { IRateDefinitionRepository } from 'shared/api/interfaces/IRateDefinitionRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IRateDefinition } from 'shared/models/RateDefinition';
import { RootState } from '../../store';

type InitialStateType = {
  count?: number;
  rateDefinitions: IRateDefinition[];
};

const count = undefined;
const rateDefinitions: IRateDefinition[] = [];

export const initialState: InitialStateType = {
  count,
  rateDefinitions
};

const rateDefinitionRepository = container.get<IRateDefinitionRepository>(
  SERVICE_KEYS.RATE_DEFINITION_REPOSITORY
);
const rateDefinitionThunks = new RateDefinitionThunks(rateDefinitionRepository);
export const getAllRateDefinitions = rateDefinitionThunks.getAll();
export const addRateDefinition = rateDefinitionThunks.add();
export const deleteAllRateDefinitions = rateDefinitionThunks.deleteAll();

const slice = buildGenericSlice<InitialStateType>('rateDefinition', initialState);
export const rateDefinitionSlice = createSlice({
  ...slice,
  extraReducers: (builder: any) => {
    builder.addCase(
      getAllRateDefinitions.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload && payload.data?.length) {
          const { data } = payload;
          state.rateDefinitions = data;
          state.count = data.length;
        }
      }
    );
    builder.addCase(
      deleteAllRateDefinitions.fulfilled,
      (state: InitialStateType, { payload }: GenericPayload) => {
        if (payload.status === 200) {
          const { ids } = payload.config.params;
          state.rateDefinitions = state.rateDefinitions.filter(
            (rate: IRateDefinition) => !ids.includes(rate.etrmId)
          );
        }
      }
    );
  }
});

export const getRateDefinitions = (state: RootState) => state.rateDefinition.rateDefinitions;
export const rateDefinitionReducer = rateDefinitionSlice.reducer;
