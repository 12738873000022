import { SearchOutlineSvg } from '@core/images';
import { debounce } from '@core/util';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSearch } from './OxSearch.styles';

export enum SearchSize {
  compact = 'compact',
  default = 'default'
}

interface SearchProps {
  searchSize: SearchSize;
  setSearchValue: (value: string) => void;
}

export const OxSearch = ({ searchSize, setSearchValue }: SearchProps) => {
  const { t } = useTranslation();

  const debouncedSetSearchText = useMemo(
    () => debounce((value: string) => setSearchValue(value), 250),
    []
  );

  function handleUnload() {
    debouncedSetSearchText('');
  }

  useEffect(() => {
    return handleUnload();
  }, []);

  window.onbeforeunload = () => {
    return handleUnload();
  };

  return (
    <div className='flex items-center justify-center mx-3'>
      <StyledSearch
        data-testid='OxSearchParent'
        className={`flex border rounded search-field search-field-${searchSize}`}
      >
        <input
          data-testid='OxSearchInput'
          className='search-field-text pt-0.5 focus:outline-none items-center font-normal text-md'
          type='text'
          placeholder={t('general.search')}
          onChange={(e) => debouncedSetSearchText(e.target.value)}
        />
        <button className='flex items-center justify-center'>
          <SearchOutlineSvg width={16} height={16} className='search-field-icon' />
        </button>
      </StyledSearch>
    </div>
  );
};

export interface OxSearchV2Props {
  searchText: string;
  searchSize: SearchSize;
  setSearchValue: (value: string) => void;
  uniqueDataId?: string;
  className?: string;
}

export const OxSearchV2 = ({
  searchText,
  searchSize,
  setSearchValue,
  uniqueDataId = '',
  className = ''
}: OxSearchV2Props) => {
  const { t } = useTranslation();

  // function handleUnload() {
  //   setSearchValue('');
  // }

  // useEffect(() => {
  //   return handleUnload();
  // }, []);

  // window.onbeforeunload = () => {
  //   return handleUnload();
  // };

  return (
    <div className={`flex items-center justify-center mx-3 ${className}`}>
      <StyledSearch
        data-testid='OxSearchParent'
        className={`flex border rounded search-field search-field-${searchSize}`}
      >
        <input
          data-testid={uniqueDataId}
          className='search-field-text pt-0.5 focus:outline-none items-center font-normal text-md'
          type='text'
          placeholder={t('general.search')}
          value={searchText}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className='flex items-center justify-center'>
          <SearchOutlineSvg width={16} height={16} className='search-field-icon' />
        </button>
      </StyledSearch>
    </div>
  );
};
