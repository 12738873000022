import { CloseOutlineSvg, SpinnerSmallSvg } from '@core/images';
import { themes } from '@core/theme';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { ButtonSize, ButtonType, OxButton } from '../OxButton';
import {
  Body,
  Footer,
  StyledCloseContainer,
  StyledModal,
  StyledModalCover,
  StyledModalHeader
} from './Modal.styles';
export interface ModalProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    React.AriaAttributes {
  headerText: string;
  buttonCancelText?: string;
  buttonConfirmText?: string;
  displayModalHandler: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm?: any;
  onCancel?: () => void;
  onControlledCancel?: () => void;
  isLoading?: boolean;
  footerClassName?: string;
  disableSubmit?: boolean;
  footerContent?: React.ReactNode;
}

export function Modal(props: ModalProps): JSX.Element {
  const { t } = useTranslation();
  const { displayModalHandler, onConfirm, onCancel, onControlledCancel } = props;

  const {
    headerText,
    children,
    buttonCancelText,
    buttonConfirmText,
    footerClassName,
    isLoading,
    disableSubmit,
    footerContent
  } = props;
  return (
    <ThemeProvider theme={themes.lightTheme}>
      <StyledModalCover
        data-testid='OxCover'
        className='fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-y-auto overflow-x-hidden z-50'
      >
        <StyledModal
          data-testid='OxModalDefault'
          className='flex flex-col shadow-lg ox-modal-default'
        >
          <StyledModalHeader className='items-center justify-between ox-modal-header-default'>
            <h1 className='font-medium text-base'>{headerText}</h1>
            <StyledCloseContainer
              className='close-container'
              onClick={() => displayModalHandler(false)}
            >
              <CloseOutlineSvg
                viewBox='0 0 512 512'
                preserveAspectRatio='none'
                width={24}
                height={24}
              />
            </StyledCloseContainer>
          </StyledModalHeader>
          <Body className='grow items-center justify-between'>{children}</Body>
          {onConfirm && (
            <Footer className={footerClassName}>
              <OxButton
                buttonType={ButtonType.danger}
                buttonSize={ButtonSize.default}
                text={buttonCancelText ? buttonCancelText : t('general.cancel')}
                onClick={() => {
                  if (onControlledCancel) onControlledCancel();
                  else {
                    onCancel && onCancel();
                    displayModalHandler(false);
                  }
                }}
              />
              {footerContent}
              {isLoading ? (
                <SpinnerSmallSvg
                  width={20}
                  height={20}
                  className={`ox-upload-status-item-secondary spinner-border animate-spin ${footerContent ? '' : 'ml-auto'}`}
                />
              ) : (
                <OxButton
                  buttonType={ButtonType.primary}
                  buttonSize={ButtonSize.default}
                  text={buttonConfirmText ? buttonConfirmText : t('general.confirm')}
                  className={`${footerContent ? '' : 'ml-auto'}`}
                  onClick={onConfirm}
                  disabled={disableSubmit}
                />
              )}
            </Footer>
          )}
        </StyledModal>
      </StyledModalCover>
    </ThemeProvider>
  );
}
